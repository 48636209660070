import React from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useFetchTaskTrial from '../../../../hooks/fetch/useFetchTaskTrial';
import useCreateTaskTrial from '../../../../hooks/create/useCreateTaskTrial';
import TaskTrialForm from '../../../../components/molecules/form/TaskTrialForm';
import { TaskTrial } from '../../../../../domain/models/task';

export default () => {
  const location = useLocation<{ studentId: string | undefined }>();
  if (!location.state) return null;

  const studentId =
    'studentId' in location.state ? location.state.studentId : undefined;

  const { loading, taskItems } = useFetchTaskTrial({
    queryParams: [{ fieldPath: 'student_id', opStr: '==', value: studentId }],
  });

  const { create, state, close } = useCreateTaskTrial();

  const formMethods = useForm<TaskTrial>({
    defaultValues: taskItems,
  });
  const onSubmit = formMethods.handleSubmit(async (data) => {
    const id = await create({
      ...taskItems,
      ...data,
      student_id: studentId || '',
    });
    if (id) formMethods.setValue('id', id);
  });

  return (
    <TaskTrialForm
      loading={loading}
      taskItems={taskItems}
      formMethods={formMethods}
      onSubmit={onSubmit}
      snackbarClose={close}
      snackbarState={state}
    />
  );
};
