import { createContext, Dispatch, SetStateAction } from 'react';

export type EnrolledCramSchoolContextType = {
  enrolledCramSchool: string;
  setEnrolledCramSchool: Dispatch<SetStateAction<string>>;
};

export const EnrolledCramSchoolContext = createContext<
  EnrolledCramSchoolContextType
>({
  enrolledCramSchool: '',
  setEnrolledCramSchool: () => null,
});
