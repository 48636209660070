import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Cell,
  CellValue,
  ColumnInstance,
  Row,
  TableInstance,
} from 'react-table';
import { useDebounce } from 'react-use';
import _ from 'lodash';
import Select from '../../../atoms/form/Select';
import { WeekDefaultList } from '../../../../../types/table/week-default-list';
import { EnrolledCramSchoolContext } from '../../../../context/EnrolledCramSchoolContext';
import { DayOfWeekContext } from '../../../../context/DayOfWeekContext';
import { WeekDefaultContext } from '../../../../context/WeekDefaultContext';
import {
  getLectureDayKey,
  jaLectureDaysArr,
  SeparatedLecture,
  StudentLectures,
  LectureItemForLecture,
  JaLectureDay,
} from '../../../../../domain/models/student-lectures';
import {
  DEFAULT_SEATS,
  DEFAULT_SEATS_FOR_PRAISE,
  NOTES_JAPAN_SCHOOLS,
} from '../../../../../data/form-data';
import { SubjectLabel } from './SubjectLabel';

const SelectTeacherCell: FC<PropsWithChildren<
  TableInstance<WeekDefaultList> & {
    cell: Cell<WeekDefaultList>;
    column: ColumnInstance<WeekDefaultList>;
    row: Row<WeekDefaultList>;
    value: CellValue<LectureItemForLecture>;
    updateTableData: (rowIndex: number, value: WeekDefaultList) => void;
  }
>> = ({ cell, value: initialValue, updateTableData }) => {
  const {
    create,
    weekDefaultList,
    studentBasics,
    studentLectures,
    staffs,
  } = useContext(WeekDefaultContext);
  const { enrolledCramSchool } = useContext(EnrolledCramSchoolContext);
  const [isNotesJapan, setIsNotesJapan] = useState<boolean>(true);
  const [selectDefaultSeats, setSelectDefaultSeats] = useState<string[]>(
    DEFAULT_SEATS,
  );
  const { dayOfWeek } = useContext(DayOfWeekContext);
  const [value, setValue] = useState(initialValue);

  type SelectValue = {
    label: string;
    value: string;
  } | null;
  const [selectValue, setSelectValue] = useState<SelectValue>(null);
  const [selectValueForSeat, setSelectValueForSeat] = useState<SelectValue>(
    null,
  );
  const [selectValue2, setSelectValue2] = useState<SelectValue>(null);
  const [selectValue2ForSeat, setSelectValue2ForSeat] = useState<SelectValue>(
    null,
  );

  const isDisabledSelectValue =
    typeof initialValue.subject !== 'string'
      ? !initialValue.subject.first
      : !initialValue.subject;
  const isDisabledSelectValue2 =
    typeof initialValue.subject !== 'string'
      ? !initialValue.subject.latter
      : true;

  const formattedLectureDayItemByWeekDefaultLists = (
    weekDefaultLists: WeekDefaultList[],
    jaLectureDay: JaLectureDay,
  ) => {
    const targetWeekDefaultList = weekDefaultLists.find(
      (wdl) => wdl.day_of_week === jaLectureDay,
    );

    return {
      lecture_1: targetWeekDefaultList?.lecture_1,
      lecture_2: targetWeekDefaultList?.lecture_2,
      lecture_3: targetWeekDefaultList?.lecture_3,
      lecture_4: targetWeekDefaultList?.lecture_4,
      lecture_5: targetWeekDefaultList?.lecture_5,
    };
  };

  useEffect(() => {
    setIsNotesJapan(NOTES_JAPAN_SCHOOLS.some((v) => v === enrolledCramSchool));
  }, [enrolledCramSchool, setIsNotesJapan]);

  useEffect(() => {
    setSelectDefaultSeats(
      isNotesJapan ? DEFAULT_SEATS : DEFAULT_SEATS_FOR_PRAISE,
    );
  }, [isNotesJapan, setSelectDefaultSeats]);

  useEffect(() => {
    let l;
    let v;
    let staff;
    if (typeof value.teacher_id !== 'string') {
      if (value.teacher_id.first) {
        staff = staffs?.find(
          (s) =>
            s?.id ===
            (typeof value.teacher_id !== 'string'
              ? value?.teacher_id?.first
              : ''),
        );
        if (staff) {
          l = `${staff.last_name} ${staff.first_name}`;
          v = value.teacher_id.first;
          setSelectValue({
            label: l,
            value: v,
          });
        }
      }

      if (value.teacher_id.latter) {
        staff = staffs?.find(
          (s) =>
            s?.id ===
            (typeof value.teacher_id !== 'string'
              ? value?.teacher_id?.latter
              : ''),
        );
        if (staff) {
          const l2 = `${staff.last_name} ${staff.first_name}`;
          const v2 = value.teacher_id.first;
          setSelectValue2({
            label: l2,
            value: v2,
          });
        }
      }
    } else {
      staff = staffs?.find((s) => s?.id === value?.teacher_id);
      if (staff) {
        l = `${staff.last_name} ${staff.first_name}`;
        v = value.teacher_id;
        setSelectValue({
          label: l,
          value: v,
        });
      }
    }

    if (typeof value.default_seat !== 'string') {
      if (value.default_seat.first) {
        setSelectValueForSeat({
          label: value.default_seat.first,
          value: value.default_seat.first,
        });
      }

      if (value.default_seat.latter) {
        setSelectValue2ForSeat({
          label: value.default_seat.latter,
          value: value.default_seat.latter,
        });
      }
    } else if (value.default_seat) {
      setSelectValueForSeat({
        label: value.default_seat,
        value: value.default_seat,
      });
    }
  }, [value, staffs]);

  useDebounce(
    async () => {
      if (initialValue === value) return;

      const studentId = cell.row.original.student_id;
      const targetWeekDefaultLists = _.cloneDeep(
        weekDefaultList.filter((wdl) => wdl.student_id === studentId),
      );
      const targetStudentBasic = studentBasics?.find(
        (sb) => sb.id === studentId,
      );
      const targetStudentLecture = studentLectures?.find(
        (sl) => sl.student_id === studentId,
      );

      // テーブルリスト用WeekDefaultListをデータ整形
      let newWeekDefaultList!: WeekDefaultList;
      if (dayOfWeek) {
        newWeekDefaultList = {
          id: cell.row.original.id,
          student_id: studentId,
          student_num: cell.row.original.student_num,
          student_name: cell.row.original.student_name,
          grade: cell.row.original.grade,
          day_of_week: dayOfWeek,
          lecture_1: cell.row.original.lecture_1,
          lecture_2: cell.row.original.lecture_2,
          lecture_3: cell.row.original.lecture_3,
          lecture_4: cell.row.original.lecture_4,
          lecture_5: cell.row.original.lecture_5,
        };
      }
      (newWeekDefaultList[
        cell.column.id as keyof WeekDefaultList
      ] as LectureItemForLecture).teacher_id = value.teacher_id;
      (newWeekDefaultList[
        cell.column.id as keyof WeekDefaultList
      ] as LectureItemForLecture).default_seat = value.default_seat;

      // StudentLectureをテーブルリスト用WeekDefaultListを元にデータ整形し保存する
      let newStudentLecture!: StudentLectures;
      if (targetStudentBasic && targetStudentLecture && dayOfWeek) {
        const targetLectureDayKey = getLectureDayKey(dayOfWeek);

        const targetWeekDefaultList = targetWeekDefaultLists.find(
          (wdl) => wdl.day_of_week === dayOfWeek,
        );

        if (targetLectureDayKey && targetWeekDefaultList) {
          let newLectureDayItem = targetStudentLecture[targetLectureDayKey];
          newLectureDayItem = {
            ...newLectureDayItem,
            lecture_1: { ...newWeekDefaultList.lecture_1 },
            lecture_2: { ...newWeekDefaultList.lecture_2 },
            lecture_3: { ...newWeekDefaultList.lecture_3 },
            lecture_4: { ...newWeekDefaultList.lecture_4 },
            lecture_5: { ...newWeekDefaultList.lecture_5 },
          };

          const [
            jaLectureDaySun,
            jaLectureDayMon,
            jaLectureDayTue,
            jaLectureDayWed,
            jaLectureDayThu,
            jaLectureDayFri,
            jaLectureDaySat,
          ] = jaLectureDaysArr;
          const lectureDayItemMonday = formattedLectureDayItemByWeekDefaultLists(
            targetWeekDefaultLists,
            jaLectureDayMon,
          );
          const lectureDayItemTuesday = formattedLectureDayItemByWeekDefaultLists(
            targetWeekDefaultLists,
            jaLectureDayTue,
          );
          const lectureDayItemWednesday = formattedLectureDayItemByWeekDefaultLists(
            targetWeekDefaultLists,
            jaLectureDayWed,
          );
          const lectureDayItemThursday = formattedLectureDayItemByWeekDefaultLists(
            targetWeekDefaultLists,
            jaLectureDayThu,
          );
          const lectureDayItemFriday = formattedLectureDayItemByWeekDefaultLists(
            targetWeekDefaultLists,
            jaLectureDayFri,
          );
          const lectureDayItemSaturday = formattedLectureDayItemByWeekDefaultLists(
            targetWeekDefaultLists,
            jaLectureDaySat,
          );
          const lectureDayItemSunday = formattedLectureDayItemByWeekDefaultLists(
            targetWeekDefaultLists,
            jaLectureDaySun,
          );

          newStudentLecture = {
            id: cell.row.original.id,
            student_id: studentId,
            ...targetStudentLecture,
            lecture_monday:
              lectureDayItemMonday ?? targetStudentLecture.lecture_monday,
            lecture_tuesday:
              lectureDayItemTuesday ?? targetStudentLecture.lecture_tuesday,
            lecture_wednesday:
              lectureDayItemWednesday ?? targetStudentLecture.lecture_wednesday,
            lecture_thursday:
              lectureDayItemThursday ?? targetStudentLecture.lecture_thursday,
            lecture_friday:
              lectureDayItemFriday ?? targetStudentLecture.lecture_friday,
            lecture_saturday:
              lectureDayItemSaturday ?? targetStudentLecture.lecture_saturday,
            lecture_sunday:
              lectureDayItemSunday ?? targetStudentLecture.lecture_sunday,
            [targetLectureDayKey]: newLectureDayItem,
          };
        }

        const studentLectureId = create ? await create(newStudentLecture) : '';
        if (studentLectureId) {
          newWeekDefaultList.id = studentLectureId;
        }
      }

      updateTableData(cell.row.index, newWeekDefaultList);
    },
    500,
    [value],
  );

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    <div onClick={(event) => event.stopPropagation()}>
      <div className="is-flex is-justify-content-flex-start my-1">
        <div className="subject-label-container">
          {typeof initialValue.subject !== 'string' ? (
            <SubjectLabel subject={initialValue?.subject?.first} />
          ) : (
            <SubjectLabel subject={initialValue?.subject} />
          )}
        </div>
        <div className="select-seat-teacher-container">
          <Select
            selectProps={{
              options: selectDefaultSeats?.map((defaultSeat) => ({
                label: defaultSeat,
                value: defaultSeat,
              })),
              value: selectValueForSeat,
              onChange: (v) => {
                if (!v) {
                  setSelectValueForSeat(null);
                  if (typeof initialValue.subject !== 'string') {
                    setValue({
                      ...value,
                      default_seat: {
                        first: '',
                        latter: (value.default_seat as SeparatedLecture).latter,
                      },
                    });
                  } else {
                    setValue({ ...value, default_seat: '' });
                  }
                } else if (typeof initialValue.subject !== 'string') {
                  setValue(
                    v && 'value' in v
                      ? {
                          ...value,
                          default_seat: {
                            first: v.value,
                            latter: (value.default_seat as SeparatedLecture)
                              .latter,
                          },
                        }
                      : value,
                  );
                } else {
                  setValue(
                    v && 'value' in v
                      ? { ...value, default_seat: v.value }
                      : value,
                  );
                }
              },
              isClearable: true,
              isDisabled: isDisabledSelectValue,
              className: 'select-seat',
            }}
          />
          <Select
            selectProps={{
              options: staffs?.map((staff) => ({
                label: `${staff.last_name} ${staff.first_name}`,
                value: staff.id,
              })),
              value: selectValue,
              onChange: (v) => {
                if (!v) {
                  setSelectValue(null);
                  if (typeof initialValue.subject !== 'string') {
                    setValue({
                      ...value,
                      teacher_id: {
                        first: '',
                        latter: (value.teacher_id as SeparatedLecture).latter,
                      },
                    });
                  } else {
                    setValue({ ...value, teacher_id: '' });
                  }
                } else if (typeof initialValue.subject !== 'string') {
                  setValue(
                    v && 'value' in v
                      ? {
                          ...value,
                          teacher_id: {
                            first: v.value,
                            latter: (value.teacher_id as SeparatedLecture)
                              .latter,
                          },
                        }
                      : value,
                  );
                } else {
                  setValue(
                    v && 'value' in v
                      ? { ...value, teacher_id: v.value }
                      : value,
                  );
                }
              },
              isClearable: true,
              isDisabled: isDisabledSelectValue,
              className: 'select-teacher',
            }}
          />
        </div>
      </div>
      {typeof initialValue.subject !== 'string' &&
      typeof value.teacher_id !== 'string' ? (
        <div className="is-flex is-justify-content-flex-start">
          <div className="subject-label-container">
            <SubjectLabel subject={initialValue?.subject?.latter} />
          </div>
          <div className="select-seat-teacher-container">
            <Select
              selectProps={{
                options: DEFAULT_SEATS?.map((defaultSeat) => ({
                  label: defaultSeat,
                  value: defaultSeat,
                })),
                value: selectValue2ForSeat,
                onChange: (v) => {
                  if (!v) {
                    setSelectValue2ForSeat(null);
                    setValue({
                      ...value,
                      default_seat: {
                        first: (value.default_seat as SeparatedLecture).first,
                        latter: '',
                      },
                    });
                  } else if (typeof initialValue.subject !== 'string') {
                    setValue(
                      v && 'value' in v
                        ? {
                            ...value,
                            default_seat: {
                              first: (value.default_seat as SeparatedLecture)
                                .first,
                              latter: v.value,
                            },
                          }
                        : value,
                    );
                  }
                },
                isClearable: true,
                isDisabled: isDisabledSelectValue2,
                className: 'select-seat',
              }}
            />
            <Select
              selectProps={{
                options: staffs?.map((staff) => ({
                  label: `${staff.last_name} ${staff.first_name}`,
                  value: staff.id,
                })),
                value: selectValue2,
                onChange: (v) => {
                  if (!v) {
                    setSelectValue2(null);
                    setValue({
                      ...value,
                      teacher_id: {
                        first: (value.teacher_id as SeparatedLecture).first,
                        latter: '',
                      },
                    });
                  } else if (typeof initialValue.subject !== 'string') {
                    setValue(
                      v && 'value' in v
                        ? {
                            ...value,
                            teacher_id: {
                              first: (value.teacher_id as SeparatedLecture)
                                .first,
                              latter: v.value,
                            },
                          }
                        : value,
                    );
                  }
                },
                isClearable: true,
                isDisabled: isDisabledSelectValue2,
                className: 'select-teacher',
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SelectTeacherCell;
