import clsx from 'clsx';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { FaSave, RiArrowGoBackFill, TiDelete } from 'react-icons/all';

import { DateTime } from 'luxon';
import { CircularProgress, TextField } from '@material-ui/core';
import {
  ROLES,
  ROLE_LABELS,
  EMPLOYMENT_STATUS,
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_SCHOOLS,
  RANKS,
  TRANSPORTATIONS,
  dateFormat,
  NOTES_HEADQUARTERS,
} from '../../../../../data/form-data';
import { AdminUser } from '../../../../../domain/models/admin-user';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import DotProgress from '../../../atoms/feedback/DotProgress';
import Input from '../../../atoms/form/Input';
import Select from '../../../atoms/form/Select';
import Button from '../../../atoms/button/Button';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import Backdrop from '../../../atoms/feedback/Backdrop';
import AddressItem from '../AddressItem';
import './style.scss';
import useRole from '../../../../hooks/useRole';
import DateField from '../../../atoms/form/DatePicker';
import ErrorMessage from '../../../atoms/form/ErrorMessage';
import { storage } from '../../../../../lib/firebase';

export type Props = {
  loading: boolean;
  staff: AdminUser | null;
  formMethods: UseFormMethods<AdminUser>;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
  creating: boolean;
  onChangeImageFile: (event: ChangeEvent<HTMLInputElement>) => void;
  removeImageFile: () => void;
};

const StaffForm: FC<Props> = ({
  loading,
  staff,
  formMethods,
  onSubmit,
  snackbarState,
  snackbarClose,
  creating,
  onChangeImageFile,
  removeImageFile,
}) => {
  const { isEditor, isViewer } = useRole();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { errors, register, control, watch, setValue, trigger } = formMethods;
  const defaultStaffImageSrc = `${process.env.PUBLIC_URL}/staff_image.png`;
  const [isDisabled, _] = useState(isEditor || isViewer);
  const [imageFilePreviewSrc, setImageFilePreviewSrc] = useState(
    defaultStaffImageSrc,
  );
  const [downloadImageUrlLoading, setDownloadedImageUrlLoading] = useState(
    false,
  );
  const [isDownloadedImageUrl, setIsDownloadedImageUrl] = useState(false); // ページ表示時のみに社員画像を表示させるためのURLを取得するためのstate値
  const [
    isSetRoleAdministratorOrHeadquarters,
    setIsSetRoleAdministratorOrHeadquarters,
  ] = useState(false);

  useEffectOnce(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  });

  // 社員画像を表示させるためのURLを取得しstateにセットする
  useEffect(() => {
    if (!isDownloadedImageUrl && staff?.image_path) {
      setDownloadedImageUrlLoading(true);
      void (async () => {
        const storageRef = storage.ref(staff?.image_path);
        const dlImageUrl = (await storageRef.getDownloadURL()) as string;
        setImageFilePreviewSrc(dlImageUrl);
        setDownloadedImageUrlLoading(false);
        setIsDownloadedImageUrl(true);
      })();
    }
  }, [staff, isDownloadedImageUrl]);

  useEffect(() => {
    if (watch('role')) {
      setIsSetRoleAdministratorOrHeadquarters(
        watch('role') === 'administrator' || watch('role') === 'headquarters',
      );
    } else if (staff?.role) {
      setIsSetRoleAdministratorOrHeadquarters(
        staff?.role === 'administrator' || staff?.role === 'headquarters',
      );
    } else {
      setIsSetRoleAdministratorOrHeadquarters(false);
    }
  }, [isSetRoleAdministratorOrHeadquarters, watch, staff?.role]);

  // 社員画像のプレビューを表示させるためのURLをstateにセットする
  const onPreviewImageFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    const image_url = (window.URL || window.webkitURL).createObjectURL(file);
    setImageFilePreviewSrc(image_url);
  };

  // 社員画像をデフォルトに戻す
  const onClickRemoveImageFile = () => {
    setValue('image_path', '');
    setImageFilePreviewSrc(defaultStaffImageSrc);
    removeImageFile();
  };

  return loading ? (
    <DotProgress />
  ) : (
    <div>
      <form onSubmit={onSubmit} className="detail-form card p-5">
        <h3 className="title pb-1">社員マスター</h3>
        <div className="staff-image-container">
          <div className="staff-image">
            {downloadImageUrlLoading ? (
              <CircularProgress />
            ) : (
              <>
                {!isDisabled && (
                  <button
                    type="button"
                    onClick={onClickRemoveImageFile}
                    className="delete-image-button"
                  >
                    <TiDelete />
                  </button>
                )}
                <img
                  src={imageFilePreviewSrc}
                  alt="社員画像"
                  className="image"
                />
              </>
            )}
          </div>
          {!isDisabled && (
            <div className="staff-image-operations">
              <input
                type="file"
                accept="image/png, image/jpeg"
                onChange={(event) => {
                  onChangeImageFile(event);
                  onPreviewImageFile(event);
                }}
                className="input-file"
              />
              <input
                type="hidden"
                name="image_path"
                ref={register()}
                defaultValue={watch('image_path') ?? staff?.image_path}
              />
            </div>
          )}
        </div>
        <div className="mt-5 mb-6">
          <div className="columns">
            <Input
              label="スタッフ番号"
              inputProps={{
                type: 'text',
                name: 'staff_num',
                ref: register({ required: 'スタッフ番号を入力してください' }),
                defaultValue: staff?.staff_num ?? '',
                disabled: isDisabled,
              }}
              error={errors.staff_num}
              isAsterisk
              className="column is-2 py-0"
            />
            <Input
              label="社員コード"
              inputProps={{
                type: 'text',
                name: 'staff_code',
                ref: register(),
                defaultValue: staff?.staff_code ?? '',
                disabled: isDisabled,
              }}
              error={errors.staff_code}
              className="column is-2 py-0"
            />
            <Input
              label="姓（漢字）"
              inputProps={{
                type: 'text',
                name: 'last_name',
                ref: register({ required: '姓（漢字）を入力してください' }),
                defaultValue: staff?.last_name ?? '',
                disabled: isDisabled,
              }}
              error={errors.last_name}
              isAsterisk
              className="column is-2 py-0"
            />
            <Input
              label="名（漢字）"
              inputProps={{
                type: 'text',
                name: 'first_name',
                ref: register({ required: '名（漢字）を入力してください' }),
                defaultValue: staff?.first_name ?? '',
                disabled: isDisabled,
              }}
              error={errors.first_name}
              isAsterisk
              className="column is-2 py-0"
            />
            <Input
              label="セイ（カナ）"
              inputProps={{
                type: 'text',
                name: 'last_name_kana',
                ref: register({ required: 'セイ（カナ）を入力してください' }),
                defaultValue: staff?.last_name_kana ?? '',
                disabled: isDisabled,
              }}
              error={errors.last_name_kana}
              isAsterisk
              className="column is-2 py-0"
            />
            <Input
              label="メイ（カナ）"
              inputProps={{
                type: 'text',
                name: 'first_name_kana',
                ref: register({ required: 'メイ（カナ）を入力してください' }),
                defaultValue: staff?.first_name_kana ?? '',
                disabled: isDisabled,
              }}
              error={errors.first_name_kana}
              isAsterisk
              className="column is-2 py-0"
            />
          </div>
          <div className="columns">
            <Controller
              rules={{
                required: isSetRoleAdministratorOrHeadquarters
                  ? false
                  : '在籍校舎を入力してください',
              }}
              control={control}
              name="enrolled_cram_school"
              defaultValue={staff?.enrolled_cram_school ?? ''}
              as={
                <Select
                  label="在籍校舎"
                  selectProps={{
                    name: 'enrolled_cram_school',
                    options: [
                      NOTES_HEADQUARTERS,
                      ...NOTES_JAPAN_SCHOOLS,
                      ...NOTES_PRAISE_SCHOOLS,
                    ].map((school) => ({
                      label: school,
                      value: school,
                    })),
                    defaultValue: {
                      label: staff?.enrolled_cram_school ?? '',
                      value: staff?.enrolled_cram_school ?? '',
                    },
                    onChange: async (value) => {
                      setValue(
                        'enrolled_cram_school',
                        value && 'value' in value ? value.value : '',
                      );
                      await trigger('enrolled_cram_school');
                    },
                    isDisabled,
                    isClearable: true,
                  }}
                  error={errors.enrolled_cram_school}
                  isAsterisk={!isSetRoleAdministratorOrHeadquarters}
                  className="column is-3 py-0"
                />
              }
            />
            <Controller
              rules={{ required: '権限を入力してください' }}
              control={control}
              name="role"
              defaultValue={staff?.role ?? ''}
              as={
                <Select
                  label="権限"
                  selectProps={{
                    name: 'role',
                    options: ROLES.map((role) => ({
                      label: ROLE_LABELS[role],
                      value: role,
                    })),
                    defaultValue: {
                      label: staff?.role ? ROLE_LABELS[staff?.role] : '',
                      value: staff?.role ?? '',
                    },
                    onChange: async (value) => {
                      setValue(
                        'role',
                        value && 'value' in value ? value.value : '',
                      );
                      await trigger('role');
                    },
                    isDisabled,
                  }}
                  error={errors.role}
                  isAsterisk
                  className="column is-2 py-0"
                />
              }
            />
            <Controller
              control={control}
              name="employment_status"
              defaultValue={staff?.employment_status ?? ''}
              as={
                <Select
                  label="雇用形態"
                  selectProps={{
                    name: 'employment_status',
                    options: EMPLOYMENT_STATUS.map((employment_status) => ({
                      label: employment_status,
                      value: employment_status,
                    })),
                    defaultValue: {
                      label: staff?.employment_status ?? '',
                      value: staff?.employment_status ?? '',
                    },
                    onChange: async (value) => {
                      setValue(
                        'employment_status',
                        value && 'value' in value ? value.value : '',
                      );
                      await trigger('employment_status');
                    },
                    isDisabled,
                    isClearable: true,
                  }}
                  error={errors.employment_status}
                  className="column is-2 py-0"
                />
              }
            />
            <Controller
              control={control}
              name="rank"
              defaultValue={staff?.rank ?? ''}
              as={
                <Select
                  label="講師ランク"
                  selectProps={{
                    name: 'rank',
                    options: RANKS.map((rank) => ({
                      label: rank,
                      value: rank,
                    })),
                    defaultValue: {
                      label: staff?.rank ?? '',
                      value: staff?.rank ?? '',
                    },
                    onChange: async (value) => {
                      setValue(
                        'rank',
                        value && 'value' in value ? value.value : '',
                      );
                      await trigger('rank');
                    },
                    isDisabled,
                    isClearable: true,
                  }}
                  error={errors.rank}
                  className="column is-2 py-0"
                />
              }
            />
            <div className="field column is-one-fifth py-0">
              <label className="label" htmlFor="birthday">
                生年月日
              </label>
              <Controller
                name="birthday"
                control={control}
                defaultValue={staff?.birthday || ''}
                as={
                  <DateField
                    DatePickerProps={{
                      name: 'birthday',
                      value:
                        !errors?.birthday && watch('birthday')
                          ? DateTime.fromFormat(
                              watch('birthday') ?? '',
                              dateFormat,
                            )
                          : (staff?.birthday &&
                              DateTime.fromFormat(
                                staff?.birthday,
                                dateFormat,
                              )) ||
                            null,
                      error: errors.birthday && true,
                      TextFieldComponent: (props) => (
                        <TextField {...props} margin="none" />
                      ),
                      onChange: (v) => {
                        setValue('birthday', v ? v.toFormat(dateFormat) : '');
                        void trigger('birthday');
                      },
                      disabled: isDisabled,
                      inputVariant: 'outlined',
                      className: 'text-field-outlined',
                    }}
                  />
                }
              />
              <ErrorMessage
                message={errors?.birthday?.message}
                className="mt-2"
              />
            </div>
          </div>
          <div className="columns">
            <Input
              label="本人電話番号"
              inputProps={{
                type: 'text',
                name: 'phone_number',
                ref: register(),
                defaultValue: staff?.phone_number ?? '',
                disabled: isDisabled,
              }}
              error={errors.phone_number}
              className="column is-one-fifth py-0"
            />
            <Input
              label="メールアドレス"
              inputProps={{
                type: 'text',
                name: 'mail_address',
                ref: register({ required: 'メールアドレスを入力してください' }),
                defaultValue: staff?.mail_address ?? '',
                disabled: isDisabled,
              }}
              error={errors.mail_address}
              isAsterisk
              className="column is-two-fifths py-0"
            />
            <div className="field column is-one-fifth py-0">
              <label className="label" htmlFor="hire_date">
                入社日
                <span className="has-text-danger ml-1">※</span>
              </label>
              <Controller
                rules={{ required: '入社日を入力してください' }}
                name="hire_date"
                control={control}
                defaultValue={staff?.hire_date || ''}
                as={
                  <DateField
                    DatePickerProps={{
                      name: 'hire_date',
                      value:
                        !errors?.hire_date && watch('hire_date')
                          ? DateTime.fromFormat(
                              watch('hire_date') ?? '',
                              dateFormat,
                            )
                          : (staff?.hire_date &&
                              DateTime.fromFormat(
                                staff?.hire_date,
                                dateFormat,
                              )) ||
                            null,
                      error: errors.hire_date && true,
                      TextFieldComponent: (props) => (
                        <TextField {...props} margin="none" />
                      ),
                      onChange: (v) => {
                        setValue('hire_date', v ? v.toFormat(dateFormat) : '');
                        void trigger('hire_date');
                      },
                      disabled: isDisabled,
                      inputVariant: 'outlined',
                      className: 'text-field-outlined',
                    }}
                  />
                }
              />
              <ErrorMessage
                message={errors?.hire_date?.message}
                className="mt-2"
              />
            </div>
            <div className="field column is-one-fifth py-0">
              <label className="label" htmlFor="termination_date">
                退職日
              </label>
              <Controller
                name="termination_date"
                control={control}
                defaultValue={staff?.termination_date || ''}
                as={
                  <DateField
                    DatePickerProps={{
                      name: 'termination_date',
                      value:
                        !errors?.termination_date && watch('termination_date')
                          ? DateTime.fromFormat(
                              watch('termination_date') ?? '',
                              dateFormat,
                            )
                          : (staff?.termination_date &&
                              DateTime.fromFormat(
                                staff?.termination_date,
                                dateFormat,
                              )) ||
                            null,
                      error: errors.termination_date && true,
                      TextFieldComponent: (props) => (
                        <TextField {...props} margin="none" />
                      ),
                      onChange: (v) => {
                        setValue(
                          'termination_date',
                          v ? v.toFormat(dateFormat) : '',
                        );
                        void trigger('termination_date');
                      },
                      disabled: isDisabled,
                      inputVariant: 'outlined',
                      className: 'text-field-outlined',
                    }}
                  />
                }
              />
              <ErrorMessage
                message={errors?.termination_date?.message}
                className="mt-2"
              />
            </div>
          </div>
          <div>
            <div className="field p-0 m-0">
              <p className="label">現住所</p>
              <AddressItem
                postalCode={{
                  name: 'current_address.postal_code',
                  defaultValue: staff?.current_address?.postal_code ?? '',
                  error: errors.current_address?.postal_code,
                  ref: register(),
                }}
                address={{
                  name: 'current_address.address',
                  defaultValue: staff?.current_address?.address ?? '',
                  error: errors.current_address?.address,
                  ref: register(),
                }}
                isDisabled={isDisabled}
              />
            </div>
            <div className="is-clearfix" />
          </div>
          <div>
            <div className="field py-0">
              <p className="label">住民票住所</p>
              <AddressItem
                postalCode={{
                  name: 'resident_card_address.postal_code',
                  defaultValue: staff?.resident_card_address?.postal_code ?? '',
                  error: errors.resident_card_address?.postal_code,
                  ref: register(),
                }}
                address={{
                  name: 'resident_card_address.address',
                  defaultValue: staff?.resident_card_address?.address ?? '',
                  error: errors.resident_card_address?.address,
                  ref: register(),
                }}
                isDisabled={isDisabled}
              />
            </div>
          </div>
          <div>
            <div className="field py-0">
              <p className="label">実家住所</p>
              <AddressItem
                postalCode={{
                  name: 'parents_home_address.postal_code',
                  defaultValue: staff?.parents_home_address?.postal_code ?? '',
                  error: errors.parents_home_address?.postal_code,
                  ref: register(),
                }}
                address={{
                  name: 'parents_home_address.address',
                  defaultValue: staff?.parents_home_address?.address ?? '',
                  error: errors.parents_home_address?.address,
                  ref: register(),
                }}
                isDisabled={isDisabled}
              />
              <div className="columns">
                <Input
                  label="実家電話番号"
                  inputProps={{
                    type: 'text',
                    name: 'parents_home_address.phone_number',
                    ref: register(),
                    defaultValue:
                      staff?.parents_home_address?.phone_number ?? '',
                    disabled: isDisabled,
                  }}
                  error={errors.parents_home_address?.phone_number}
                  className="column is-one-fifth py-0"
                />
              </div>
            </div>
          </div>
        </div>
        <h3 className="title pb-5">給与振込関連</h3>
        <div className="mt-5 mb-6">
          <div className="columns">
            <Input
              label="銀行名"
              inputProps={{
                type: 'text',
                name: 'payroll_transfer.bank_name',
                ref: register(),
                defaultValue: staff?.payroll_transfer?.bank_name ?? '',
                disabled: isDisabled,
              }}
              error={errors.payroll_transfer?.bank_name}
              className="column is-one-fifth py-0"
            />
            <Input
              label="支店名"
              inputProps={{
                type: 'text',
                name: 'payroll_transfer.branch_name',
                ref: register(),
                defaultValue: staff?.payroll_transfer?.branch_name ?? '',
                disabled: isDisabled,
              }}
              error={errors.payroll_transfer?.branch_name}
              className="column is-one-fifth py-0"
            />
            <Input
              label="口座番号"
              inputProps={{
                type: 'text',
                name: 'payroll_transfer.account_number',
                ref: register(),
                defaultValue: staff?.payroll_transfer?.account_number ?? '',
                disabled: isDisabled,
              }}
              error={errors.payroll_transfer?.account_number}
              className="column is-one-fifth py-0"
            />
            <Input
              label="口座名義(カナ)"
              inputProps={{
                type: 'text',
                name: 'payroll_transfer.account_name',
                ref: register(),
                defaultValue: staff?.payroll_transfer?.account_name ?? '',
                disabled: isDisabled,
              }}
              error={errors.payroll_transfer?.account_name}
              className="column is-one-fifth py-0"
            />
          </div>
        </div>
        <h3 className="title pb-5">交通手段</h3>
        <div className="mt-5 mb-6">
          <div className="columns">
            <Controller
              control={control}
              name="transportation.default_transportation"
              defaultValue={staff?.transportation?.default_transportation ?? ''}
              as={
                <Select
                  label="基本的に利用する交通手段"
                  selectProps={{
                    name: 'transportation.default_transportation',
                    options: TRANSPORTATIONS.map((transportation) => ({
                      label: transportation,
                      value: transportation,
                    })),
                    defaultValue: {
                      label:
                        staff?.transportation?.default_transportation ?? '',
                      value:
                        staff?.transportation?.default_transportation ?? '',
                    },
                    onChange: async (value) => {
                      setValue(
                        'transportation.default_transportation',
                        value && 'value' in value ? value.value : '',
                      );
                      await trigger('transportation.default_transportation');
                    },
                    isDisabled,
                    isClearable: true,
                  }}
                  error={errors.transportation?.default_transportation}
                  className="column is-one-fifth py-0"
                />
              }
            />
            <Input
              label="1日あたりの交通費"
              inputProps={{
                type: 'number',
                name: 'transportation.cost_per_day',
                ref: register(),
                defaultValue: staff?.transportation?.cost_per_day ?? '',
                disabled: isDisabled,
              }}
              error={errors.transportation?.cost_per_day}
              className="column is-one-fifth py-0"
            />
          </div>
        </div>
        <div className="btn-area">
          <Button
            buttonProps={{
              type: 'button',
              onClick: () => history.push('/staff'),
            }}
            className={clsx('with-icon', 'btn-primary')}
          >
            <RiArrowGoBackFill />
            戻る
          </Button>
          <Button
            buttonProps={{
              type: 'submit',
              disabled: isDisabled,
            }}
            color="primary"
            className={clsx('with-icon', 'btn-primary')}
          >
            <FaSave />
            保存
          </Button>
        </div>
      </form>
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default StaffForm;
