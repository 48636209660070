import React, { FC } from 'react';
import firebase from 'firebase';
import { DateTime } from 'luxon';

import InformationComponent from '../../../components/atoms/Information';
import { dateFormat } from '../../../../data/form-data';

interface Props {
  date: firebase.firestore.Timestamp;
  category: string;
  title: string;
}

const Information: FC<Props> = ({ date, category, title }) => {
  const formatted = DateTime.fromJSDate(date.toDate()).toFormat(dateFormat);

  return (
    <InformationComponent date={formatted} category={category} title={title} />
  );
};

export default Information;
