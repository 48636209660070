import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { PAGE_TITLE } from '../../../data/app-basic';
import Container from '../../components/layouts/Container';
import AdminWrapper from '../../components/layouts/AdminWrapper';
import ClassTransferIndex from '../../components/pages/ClassTransfer';

const ClassTransfer: FC = () => {
  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('授業振替申請マスター')}</title>
      </Helmet>
      <Container>
        <ClassTransferIndex />
      </Container>
    </AdminWrapper>
  );
};

export default ClassTransfer;
