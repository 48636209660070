import { useEffect, useState } from 'react';

import { ParentInfo } from '../../../domain/models/parent-info';
import getParents from '../../../domain/services/get-parent-info';
import { QueryParams } from '../../../types/firestore-simple';

export type Result = {
  loading: boolean;
  parents: ParentInfo[];
};

const useFetchParentInfo = ({
  id,
  queryParams,
}: {
  id?: string;
  queryParams?: QueryParams<ParentInfo>;
}): Result => {
  const [loading, setLoading] = useState(false);
  const [parents, setParents] = useState<ParentInfo[]>([]);

  useEffect(() => {
    void (async () => {
      setLoading(true);

      const result = await getParents({ id, queryParams });
      if (result.parents.length) {
        setParents(result.parents);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [id]);

  return { loading, parents };
};

export default useFetchParentInfo;
