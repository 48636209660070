import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { useEffectOnce } from 'react-use';
import { Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { FaSave, RiArrowGoBackFill } from 'react-icons/all';
import { TextField } from '@material-ui/core';

import { OptionTypeBase, ValueType } from 'react-select';
import {
  ADMISSION_ROUTES,
  AdmissionRoute,
  dateFormat,
  GENDERS,
  GRADES,
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_SCHOOLS,
  STUDENT_STATUS_LABELS,
} from '../../../../../data/form-data';
import DotProgress from '../../../atoms/feedback/DotProgress';
import Input from '../../../atoms/form/Input';
import Select from '../../../atoms/form/Select';
import Button from '../../../atoms/button/Button';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import Backdrop from '../../../atoms/feedback/Backdrop';
import { StudentBasic } from '../../../../../domain/models/student-basic';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import DateField from '../../../atoms/form/DatePicker';
import ErrorMessage from '../../../atoms/form/ErrorMessage';
import Textarea from '../../../atoms/form/Textarea';
import './style.scss';
import useRole from '../../../../hooks/useRole';
import { ParentInfo } from '../../../../../domain/models/parent-info';

export type Props = {
  loading: boolean;
  parentInfo: ParentInfo;
  studentBasic: StudentBasic;
  formMethods: UseFormMethods<StudentBasic>;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
  creating: boolean;
};

const StudentBasicForm: FC<Props> = ({
  loading,
  parentInfo,
  studentBasic,
  formMethods,
  onSubmit,
  snackbarState,
  snackbarClose,
  creating,
}) => {
  const { isViewer } = useRole();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { errors, register, control, watch, setValue, trigger } = formMethods;
  useEffectOnce(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  });

  return loading ? (
    <DotProgress />
  ) : (
    <div>
      <form onSubmit={onSubmit} className="detail-form card p-5">
        <div className="student-meta">
          <h3 className="student-name">{`${studentBasic?.last_name} ${studentBasic?.first_name}`}</h3>
          <button
            type="button"
            className="button is-primary"
            onClick={() => {
              history.push({
                pathname: '/student-score/detail',
                state: { studentBasic },
              });
            }}
          >
            成績情報ページへ
          </button>
          <div className="parent-info">
            <h4 className="parent-name">{parentInfo?.name}</h4>
            <button
              type="button"
              className="button is-primary"
              onClick={() => {
                history.push({
                  pathname: '/parent/detail',
                  state: { parentId: parentInfo?.id },
                });
              }}
            >
              保護者詳細ページへ
            </button>
          </div>
        </div>
        <Controller
          control={control}
          name="status"
          defaultValue={studentBasic?.status}
          as={
            <Select
              label="生徒ステータス"
              selectProps={{
                name: 'status',
                options: Object.entries(
                  STUDENT_STATUS_LABELS,
                ).map(([value, label]) => ({ label, value })),
                defaultValue: {
                  label: STUDENT_STATUS_LABELS[studentBasic?.status],
                  value: studentBasic?.status,
                },
                onChange: async (value) => {
                  setValue(
                    'status',
                    value && 'value' in value ? value.value : '',
                  );
                  await trigger('status');
                },
                isDisabled: isViewer,
              }}
              error={errors.status}
            />
          }
        />
        <Input
          label="生徒番号"
          inputProps={{
            type: 'text',
            name: 'student_num',
            ref: register({ required: '生徒番号を入力してください' }),
            defaultValue: studentBasic?.student_num,
            disabled: isViewer,
          }}
          error={errors.student_num}
        />
        <div className="field">
          <p className="label">氏名</p>
          <div className="columns is-multiline">
            <div className="column is-half">
              <Input
                label="姓"
                inputProps={{
                  type: 'text',
                  name: 'last_name',
                  ref: register({ required: '姓を入力してください' }),
                  defaultValue: studentBasic?.last_name,
                  disabled: isViewer,
                }}
                error={errors.last_name}
              />
            </div>
            <div className="column is-half">
              <Input
                label="名"
                inputProps={{
                  type: 'text',
                  name: 'first_name',
                  ref: register({ required: '名を入力してください' }),
                  defaultValue: studentBasic?.first_name,
                  disabled: isViewer,
                }}
                error={errors.first_name}
              />
            </div>
            <div className="column is-half">
              <Input
                label="セイ"
                inputProps={{
                  type: 'text',
                  name: 'last_name_kana',
                  ref: register({ required: 'セイを入力してください' }),
                  defaultValue: studentBasic?.last_name_kana,
                  disabled: isViewer,
                }}
                error={errors.last_name_kana}
              />
            </div>
            <div className="column is-half">
              <Input
                label="メイ"
                inputProps={{
                  type: 'text',
                  name: 'first_name_kana',
                  ref: register({ required: 'メイを入力してください' }),
                  defaultValue: studentBasic?.first_name_kana,
                  disabled: isViewer,
                }}
                error={errors.first_name_kana}
              />
            </div>
          </div>
        </div>
        <Controller
          control={control}
          name="gender"
          defaultValue={studentBasic?.gender}
          as={
            <Select
              label="性別"
              selectProps={{
                name: 'gender',
                options: GENDERS.map((gender) => ({
                  label: gender,
                  value: gender,
                })),
                defaultValue: {
                  label: studentBasic?.gender,
                  value: studentBasic?.gender,
                },
                onChange: async (value) => {
                  setValue(
                    'gender',
                    value && 'value' in value ? value.value : '',
                  );
                  await trigger('gender');
                },
                isDisabled: isViewer,
              }}
              error={errors.gender}
            />
          }
        />
        <div className="field">
          <label className="label" htmlFor="birthday">
            誕生日
          </label>
          <Controller
            rules={{ required: '誕生日を入力してください' }}
            name="birthday"
            control={control}
            defaultValue={studentBasic?.birthday || null}
            as={
              <DateField
                DatePickerProps={{
                  name: 'birthday',
                  value:
                    !errors?.birthday && watch('birthday')
                      ? DateTime.fromFormat(watch('birthday'), dateFormat)
                      : (studentBasic?.birthday &&
                          DateTime.fromFormat(
                            studentBasic?.birthday,
                            dateFormat,
                          )) ||
                        null,
                  error: errors.birthday && true,
                  TextFieldComponent: (props) => (
                    <TextField {...props} margin="none" />
                  ),
                  onChange: async (v) => {
                    setValue('birthday', v ? v.toFormat(dateFormat) : '');
                    await trigger('birthday');
                  },
                  disabled: isViewer,
                }}
              />
            }
          />
          <ErrorMessage message={errors?.birthday?.message} className="mt-2" />
        </div>
        <Controller
          control={control}
          name="grade"
          defaultValue={studentBasic?.grade}
          as={
            <Select
              label="学年"
              selectProps={{
                name: 'grade',
                options: GRADES.map((grade) => ({
                  label: grade,
                  value: grade,
                })),
                defaultValue: {
                  label: studentBasic?.grade,
                  value: studentBasic?.grade,
                },
                onChange: async (value) => {
                  setValue(
                    'grade',
                    value && 'value' in value ? value.value : '',
                  );
                  await trigger('grade');
                },
                isDisabled: isViewer,
              }}
              error={errors.grade}
            />
          }
        />
        <Input
          label="在籍学校名"
          inputProps={{
            type: 'text',
            name: 'school_name',
            ref: register({ required: '在籍学校名を入力してください' }),
            defaultValue: studentBasic?.school_name,
            disabled: isViewer,
          }}
          error={errors.school_name}
        />
        <Controller
          control={control}
          name="enrolled_cram_school"
          defaultValue={studentBasic?.enrolled_cram_school}
          as={
            <Select
              label="希望校舎/在籍校舎"
              selectProps={{
                name: 'enrolled_cram_school',
                options: [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS].map(
                  (school) => ({
                    label: school,
                    value: school,
                  }),
                ),
                defaultValue: {
                  label: studentBasic?.enrolled_cram_school,
                  value: studentBasic?.enrolled_cram_school,
                },
                onChange: async (value) => {
                  setValue(
                    'enrolled_cram_school',
                    value && 'value' in value ? value.value : '',
                  );
                  await trigger('enrolled_cram_school');
                },
                isDisabled: isViewer,
              }}
              error={errors.enrolled_cram_school}
            />
          }
        />
        <div className="field">
          <label className="label" htmlFor="admission_date">
            入会年月日
          </label>
          <Controller
            name="admission_date"
            control={control}
            defaultValue={studentBasic?.admission_date || null}
            as={
              <DateField
                DatePickerProps={{
                  name: 'admission_date',
                  value:
                    !errors?.admission_date && watch('admission_date')
                      ? DateTime.fromFormat(watch('admission_date'), dateFormat)
                      : (studentBasic?.admission_date &&
                          DateTime.fromFormat(
                            studentBasic?.admission_date,
                            dateFormat,
                          )) ||
                        null,
                  error: errors.admission_date && true,
                  TextFieldComponent: (props) => (
                    <TextField {...props} margin="none" />
                  ),
                  onChange: (v) => {
                    setValue('admission_date', v ? v.toFormat(dateFormat) : '');
                    void trigger('admission_date');
                  },
                  disabled: isViewer,
                }}
              />
            }
          />
          <ErrorMessage
            message={errors?.admission_date?.message}
            className="mt-2"
          />
        </div>
        <Controller
          control={control}
          name="admission_route"
          defaultValue={studentBasic?.admission_route.map((route) => route)}
          as={
            <Select
              label="お問い合わせ経路"
              selectProps={{
                isMulti: true,
                name: 'admission_route',
                options: ADMISSION_ROUTES.map((route) => ({
                  label: route,
                  value: route,
                })),
                defaultValue: studentBasic?.admission_route.map((route) => ({
                  label: route,
                  value: route,
                })),
                onChange: async (value) => {
                  let newValue: AdmissionRoute[];
                  if (value && value instanceof Array) {
                    newValue = value.reduce<AdmissionRoute[]>(
                      (
                        prev: AdmissionRoute[],
                        current: ValueType<OptionTypeBase>,
                      ) => {
                        return current && 'value' in current
                          ? prev.concat(current.value as AdmissionRoute)
                          : prev;
                      },
                      [],
                    );
                  } else {
                    newValue = [];
                  }
                  setValue('admission_route', newValue);
                  await trigger('admission_route');
                },
                isDisabled: isViewer,
              }}
              error={
                errors?.admission_route?.length
                  ? errors.admission_route[0]
                  : undefined
              }
            />
          }
        />
        <Textarea
          label="メモ"
          textareaProps={{
            name: 'memo',
            ref: register(),
            defaultValue: studentBasic?.memo,
            disabled: isViewer,
          }}
          error={errors.memo}
        />
        <div className="btn-area">
          <Button
            buttonProps={{
              type: 'button',
              onClick: () => history.push('/student'),
            }}
            className={clsx('with-icon', 'btn-primary')}
          >
            <RiArrowGoBackFill />
            戻る
          </Button>
          <Button
            buttonProps={{ type: 'submit', disabled: isViewer }}
            color="primary"
            className={clsx('with-icon', 'btn-primary')}
          >
            <FaSave />
            保存
          </Button>
        </div>
      </form>
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default StudentBasicForm;
