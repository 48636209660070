export type invoiceItemAutocomplete = {
  name: string;
  amount: number;
};

export const INVOICE_ITEM_AUTOCOMPLETES: invoiceItemAutocomplete[] = [
  { name: '[N中3] 夏期講習特訓アドバンスコース', amount: 56500 },
  { name: '[N中3] 夏期講習スタンダードコース', amount: 47500 },
  { name: '[N中3] 夏期講習ライトプラスコース', amount: 42500 },
  { name: '[N中3] 夏期講習ライトコース', amount: 37500 },
  { name: '[N中3] 合格特訓アドバンスコース', amount: 51500 },
  { name: '[N中3] 合格スタンダードコース', amount: 46500 },
  { name: '[N中3] 受験ライトコース', amount: 42500 },
  { name: '[N中3] 実践問題演習講座', amount: 30000 },
  { name: '[N中3] 私立合格特訓アドバンスコース', amount: 68500 },
  { name: '[N中3] 私立合格スタンダードコース', amount: 58000 },
  { name: '[N中3] 私立受験ライトコース', amount: 47500 },
  { name: '[N中3] 高校内容先取り講座2教科コース', amount: 33500 },
  { name: '[N中3] 高校内容先取り講座1教科コース', amount: 21000 },
  { name: '[N小学生] 夏期講習40分×4回', amount: 3000 },
  { name: '[N小学生] 夏期講習40分×8回', amount: 6000 },
  { name: '[N小学生] 夏期講習40分×12回', amount: 9000 },
  { name: '[N小学生] 夏期講習40分×16回', amount: 12000 },
  { name: '[N中1・2] 夏期講習80分×4回', amount: 8500 },
  { name: '[N中1・2] 夏期講習80分×8回', amount: 17000 },
  { name: '[N中1・2] 夏期講習80分×12回', amount: 24500 },
  { name: '[N中1・2] 夏期講習80分×16回', amount: 32000 },
  { name: '[N高1・2] 夏期講習80分×4回', amount: 12000 },
  { name: '[N高1・2] 夏期講習80分×8回', amount: 24000 },
  { name: '[N高1・2] 夏期講習80分×12回', amount: 35000 },
  { name: '[N高1・2] 夏期講習80分×16回', amount: 46000 },
  { name: '[N小学生] 冬期講習40分×4回', amount: 3000 },
  { name: '[N小学生] 冬期講習40分×8回', amount: 6000 },
  { name: '[N小学生] 冬期講習40分×12回', amount: 9000 },
  { name: '[N小学生] 冬期講習40分×16回', amount: 12000 },
  { name: '[N中1・2] 冬期講習80分×4回', amount: 8500 },
  { name: '[N中1・2] 冬期講習80分×8回', amount: 17000 },
  { name: '[N中1・2] 冬期講習80分×12回', amount: 24500 },
  { name: '[N中1・2] 冬期講習80分×16回', amount: 32000 },
  { name: '[N高1・2] 冬期講習80分×4回', amount: 12000 },
  { name: '[N高1・2] 冬期講習80分×8回', amount: 24000 },
  { name: '[N高1・2] 冬期講習80分×12回', amount: 35000 },
  { name: '[N高1・2] 冬期講習80分×16回', amount: 46000 },
  { name: '[N小学生] 春期講習40分×4回', amount: 3000 },
  { name: '[N小学生] 春期講習40分×8回', amount: 6000 },
  { name: '[N小学生] 春期講習40分×12回', amount: 9000 },
  { name: '[N小学生] 春期講習40分×16回', amount: 12000 },
  { name: '[N中1・2] 春期講習80分×4回', amount: 8500 },
  { name: '[N中1・2] 春期講習80分×8回', amount: 17000 },
  { name: '[N中1・2] 春期講習80分×12回', amount: 24500 },
  { name: '[N中1・2] 春期講習80分×16回', amount: 32000 },
  { name: '[N高1・2] 春期講習80分×4回', amount: 12000 },
  { name: '[N高1・2] 春期講習80分×8回', amount: 24000 },
  { name: '[N高1・2] 春期講習80分×12回', amount: 35000 },
  { name: '[N高1・2] 春期講習80分×16回', amount: 46000 },
  { name: '[P中3] 夏期講習', amount: 22000 },
  { name: '[P中3] 受験直前対策講座', amount: 22000 },
  { name: '[P小学生] 夏期講習', amount: 4000 },
  { name: '[P小学生] 冬期講習', amount: 4000 },
  { name: '[P小学生] 春期講習', amount: 4000 },
  { name: '[P小学生] 夏期講習（中学受験）', amount: 15000 },
  { name: '[P小学生] 冬期講習（中学受験）', amount: 15000 },
  { name: '[P小学生] 春期講習（中学受験）', amount: 15000 },
  { name: '[P中1・2] 夏期講習', amount: 8000 },
  { name: '[P中1・2] 冬期講習', amount: 8000 },
  { name: '[P中1・2] 春期講習', amount: 8000 },
  { name: '[P高1・2] 夏期講習', amount: 10000 },
  { name: '[P高1・2] 冬期講習', amount: 10000 },
  { name: '[P高1・2] 春期講習', amount: 10000 },
  { name: '[P高3] 夏期講習', amount: 20000 },
  { name: '[P高3] 受験対策講座', amount: 20000 },
  { name: '[新中3] 第１回新潟県統一模試（3月）', amount: 3591 },
  { name: '[中3] 第２回新潟県統一模試（５月）', amount: 3591 },
  { name: '[中3] 第３回新潟県統一模試（７月）', amount: 3591 },
  { name: '[中3] 第４回新潟県統一模試(８月）', amount: 3591 },
  { name: '[中3] 第５回新潟県統一模試（１０月）', amount: 3591 },
  { name: '[中3] 第６回新潟県統一模試（１１月）', amount: 3591 },
  { name: '[中3] 第７回新潟県統一模試（１２月）', amount: 3591 },
  { name: '[中3] 第８回新潟県統一模試（１月）', amount: 3591 },
  { name: '[中3] 第９回新潟県統一模試（２月）', amount: 3591 },
  { name: '[中3]ハイレベル３科マーク模試（１１月）', amount: 3000 },
  { name: '[中3]ハイレベル３科マーク模試（１月）', amount: 3000 },
  { name: '[中2] 第２回新潟県統一模試（８月）', amount: 2408 },
  { name: '[中2] 第３回新潟県統一模試（１２月）', amount: 3591 },
  { name: '漢字検定2級', amount: 3182 },
  { name: '漢字検定準2級', amount: 2273 },
  { name: '漢字検定3級', amount: 2273 },
  { name: '漢字検定4級', amount: 2273 },
  { name: '漢字検定5級', amount: 1819 },
  { name: '漢字検定6級', amount: 1819 },
  { name: '漢字検定7級', amount: 1819 },
  { name: '漢字検定8級', amount: 1364 },
  { name: '漢字検定9級', amount: 1364 },
  { name: '漢字検定10級', amount: 1364 },
  { name: '数学検定2級', amount: 4364 },
  { name: '数学検定準2級', amount: 3637 },
  { name: '数学検定3級', amount: 3182 },
  { name: '数学検定4級', amount: 2728 },
  { name: '数学検定5級', amount: 2728 },
  { name: '算数検定6級', amount: 2273 },
  { name: '算数検定7級', amount: 2273 },
  { name: '算数検定8級', amount: 2273 },
  { name: '算数検定9級', amount: 1819 },
  { name: '算数検定10級', amount: 1819 },
  { name: '数学検定2級（免除あり）', amount: 3455 },
  { name: '数学検定準2級（免除あり）', amount: 2728 },
  { name: '数学検定3級（免除あり）', amount: 2273 },
  { name: '数学検定4級（免除あり）', amount: 1819 },
  { name: '数学検定5級（免除あり）', amount: 1819 },
  { name: '［N中1・2］テスト対策授業 80分×1コマ', amount: 2500 },
  { name: '［N中1・2］テスト対策授業 80分×2コマ', amount: 5000 },
  { name: '［N中1・2］テスト対策授業 80分×3コマ', amount: 7500 },
  { name: '［N中1・2］テスト対策授業 80分×4コマ', amount: 8500 },
  { name: '［N中1・2］テスト対策授業 80分×5コマ', amount: 10625 },
  { name: '［N中1・2］テスト対策授業 80分×6コマ', amount: 12750 },
  { name: '［N中1・2］テスト対策授業 80分×7コマ', amount: 14875 },
  { name: '［N中1・2］テスト対策授業 80分×8コマ', amount: 17000 },
  { name: '［N中1・2］テスト対策授業 80分×9コマ', amount: 19125 },
  { name: '［N中1・2］テスト対策授業 80分×10コマ', amount: 21250 },
  { name: '［N中1・2］テスト対策授業 80分×11コマ', amount: 23375 },
  { name: '［N中1・2］テスト対策授業 80分×12コマ', amount: 25500 },
  { name: '［N中1・2］テスト対策授業 80分×13コマ', amount: 27625 },
  { name: '［N中1・2］テスト対策授業 80分×14コマ', amount: 29750 },
  { name: '［N中1・2］テスト対策授業 80分×15コマ', amount: 31875 },
  { name: '［N中3］テスト対策授業 80分×1コマ', amount: 2750 },
  { name: '［N中3］テスト対策授業 80分×2コマ', amount: 5500 },
  { name: '［N中3］テスト対策授業 80分×3コマ', amount: 8250 },
  { name: '［N中3］テスト対策授業 80分×4コマ', amount: 9000 },
  { name: '［N中3］テスト対策授業 80分×5コマ', amount: 11250 },
  { name: '［N中3］テスト対策授業 80分×6コマ', amount: 13500 },
  { name: '［N中3］テスト対策授業 80分×7コマ', amount: 15750 },
  { name: '［N中3］テスト対策授業 80分×8コマ', amount: 18000 },
  { name: '［N中3］テスト対策授業 80分×9コマ', amount: 20250 },
  { name: '［N中3］テスト対策授業 80分×10コマ', amount: 22500 },
  { name: '［N中3］テスト対策授業 80分×11コマ', amount: 24750 },
  { name: '［N中3］テスト対策授業 80分×12コマ', amount: 27000 },
  { name: '［N中3］テスト対策授業 80分×13コマ', amount: 29250 },
  { name: '［N中3］テスト対策授業 80分×14コマ', amount: 31500 },
  { name: '［N中3］テスト対策授業 80分×15コマ', amount: 33750 },
  { name: '［N中1・2・3］テスト対策授業 グループ個別1日', amount: 2000 },
  { name: '［N中1・2・3］テスト対策授業 グループ個別2日', amount: 4000 },
  { name: '[N］勉強合宿 参加費用 １日参加', amount: 3000 },
  { name: '[N］勉強合宿 参加費用 ２日参加', amount: 6000 },
  { name: '[N］勉強合宿 参加費用 １日分免除', amount: -3000 },
  { name: '[N］勉強合宿 参加費用 ２日分免除', amount: -6000 },
  { name: '[P］勉強合宿 参加費用 １日参加', amount: 4000 },
  { name: '[P］勉強合宿 参加費用 ２日参加', amount: 8000 },
  { name: '[P］勉強合宿 参加費用 １日分免除', amount: -4000 },
  { name: '[P］勉強合宿 参加費用 ２日分免除', amount: -8000 },
  { name: '入会金', amount: 3000 },
  { name: 'ロボット教室 入会金', amount: 10000 },
  { name: '友人紹介特典', amount: -3000 },
  { name: '友人紹介キャンペーン', amount: -5000 },
  { name: '転塾割', amount: -5000 },
  { name: 'グループ入会割', amount: -5000 },
  { name: '[N教材] 中学生 英数セット', amount: 5500 },
  { name: '[N教材] 中学生 英語セット', amount: 4000 },
  { name: '[N教材] 中学生 数学セット', amount: 3000 },
  { name: '[教材]スタディーノートス', amount: 1500 },
  { name: '[N] 小学生 国算セット', amount: 3000 },
  { name: '[教材]小学生 算数ワーク', amount: 1500 },
  { name: '[教材]小学生 国語ワーク', amount: 1500 },
  { name: '[教材]小学生 英語ワーク', amount: 1500 },
  { name: '[P教材] 中学生 ５教科セット', amount: 7500 },
  { name: '[P教材] 小学生 国算セット', amount: 3000 },
  { name: '[教材]小学ウィンパス 国語', amount: 1500 },
  { name: '[教材]小学ウィンパス 算数', amount: 1500 },
  { name: '[教材]小学ウィンパス 理科', amount: 1500 },
  { name: '[教材]小学ウィンパス 社会', amount: 1500 },
  { name: '[教材]小学ウィンパス 英語', amount: 1500 },
  { name: '[教材]高校受験セット', amount: 13000 },
  { name: '[教材]学力判定問題集', amount: 2700 },
  { name: '[教材]iワーク 数学', amount: 1500 },
  { name: '[教材]みんなの英語 英語', amount: 900 },
  { name: '[教材]必修テキスト 英語', amount: 1500 },
  { name: '[教材]必修テキスト 数学', amount: 1500 },
  { name: '[教材]必修テキスト 国語', amount: 1500 },
  { name: '[教材]必修テキスト 社会', amount: 1500 },
  { name: '[教材]必修テキスト 理科', amount: 1500 },
  { name: '[教材]Ｋｅｙワーク 英語', amount: 1500 },
  { name: '[教材]Ｋｅｙワーク 数学', amount: 1500 },
  { name: '[教材]Ｋｅｙワーク 国語', amount: 1500 },
  { name: '[教材]Ｋｅｙワーク 社会', amount: 1500 },
  { name: '[教材]Ｋｅｙワーク 理科', amount: 1500 },
  { name: '[教材]Ｋｅｙ tango 英語', amount: 1000 },
  { name: '[教材]ほーぷ 国語', amount: 1500 },
  { name: '[教材]ほーぷ 算数', amount: 1500 },
  { name: '[教材]ほーぷ 理科上', amount: 1500 },
  { name: '[教材]ほーぷ 社会上', amount: 1500 },
  { name: '[教材]ほーぷ 理科下', amount: 1500 },
  { name: '[教材]ほーぷ 社会下', amount: 1500 },
  { name: '[教材]小学ウィンパス 英語', amount: 1500 },
  { name: '[教材]iワーク 国語', amount: 1500 },
  { name: '[教材]iワーク 算数', amount: 1500 },
  { name: '[教材]読む書く漢字ドリル', amount: 600 },
  { name: '[教材]力がつく計算ドリル', amount: 600 },
  { name: '[教材]ＮＥＷ小学生ワーク 国語', amount: 1500 },
  { name: '[教材]ＮＥＷ小学生ワーク 算数', amount: 1500 },
  { name: '[教材]ＮＥＷ小学生ワーク 社会', amount: 1500 },
  { name: '[教材]ＮＥＷ小学生ワーク 理科', amount: 1500 },
  { name: '[教材]小学ビルダー 国語', amount: 1500 },
  { name: '[教材]小学ビルダー 算数', amount: 1500 },
  { name: '[教材]アインストーンプライマリー 社会', amount: 1500 },
  { name: '[教材]アインストーンプライマリー 理科', amount: 1500 },
  { name: '[教材]出る順英単語', amount: 760 },
  { name: '[教材]ウェイアップ 英語', amount: 1420 },
  { name: '[教材]ウェイアップ 数学', amount: 1420 },
  { name: '[教材]精選全国高校入試問題集 数学', amount: 1500 },
  { name: '[教材]級別漢字のトレーニング', amount: 900 },
  { name: '[教材]国語力養成ギブス', amount: 2200 },
  { name: '[教材]マイクリア 社会', amount: 2400 },
  { name: '[教材]マイクリア 理科', amount: 2400 },
  { name: '[教材]長文の完成 英語', amount: 900 },
  { name: '[教材]高校シリウス 英文法', amount: 1500 },
  { name: '[教材]高校ゼミスタンダード 英語Ⅰ', amount: 1500 },
  { name: '[教材]高校ゼミスタンダード 英語Ⅱ', amount: 1500 },
  { name: '[教材]高校スタンダード 数学Ⅰ', amount: 1500 },
  { name: '[教材]高校スタンダード 数学A', amount: 1500 },
  { name: '[教材]高校スタンダード 数学Ⅱ', amount: 1500 },
  { name: '[教材]高校スタンダード 数学B', amount: 1500 },
  { name: '[教材]高校ベーシック 数学Ⅰ', amount: 1500 },
  { name: '[教材]高校ベーシック 数学A', amount: 1500 },
  { name: '[教材]高校ベーシック 数学Ⅱ', amount: 1500 },
  { name: '[教材]高校ベーシック 数学B', amount: 1500 },
  { name: '[ロボ] キット代', amount: 30000 },
  { name: '[ロボ] タブレット代', amount: 18000 },
  { name: '[ロボ] アドプロ教具代', amount: 9000 },
];
