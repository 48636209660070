import { useState } from 'react';

import createRoom from '../../../domain/services/create-room';
import useSnackbar, { SnackbarState } from '../useSnackbar';
import { Room } from '../../../domain/models/rooms';

export type Result = {
  creating: boolean;
  create: (room: Room) => void;
  state: SnackbarState;
  close: () => void;
};

const useCreateRoom = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (room: Room) => {
    setCreating(true);
    try {
      const result = await createRoom(room);
      if (!result.id) throw new Error();

      open('success', 'ルームの更新に成功しました');
    } catch (e) {
      console.error(e);
      open('error', 'ルームの更新ができませんでした。もう一度お試しください。');
    } finally {
      setCreating(false);
    }
  };

  return { creating, create, close, state };
};

export default useCreateRoom;
