import { useState } from 'react';

import { SchoolCalendar } from '../../../domain/models/school-calendar';
import createSchoolCalendar from '../../../domain/services/create-school-calendar';
import useSnackbar, { SnackbarState } from '../useSnackbar';
import getWorkShifts from '../../../domain/services/get-work-shifts';
import bulkDeleteWorkShift from '../../../domain/services/bulk-delete-work-shift';

export type Result = {
  creating: boolean;
  create: (schoolCalendar: SchoolCalendar) => Promise<string | undefined>;
  state: SnackbarState;
  close: () => void;
};

const useCreateSchoolCalendar = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (
    schoolCalendar: SchoolCalendar,
  ): Promise<string | undefined> => {
    setCreating(true);

    // 校舎マスタ更新時にシフトデータが登録されている日付が休みになった場合にシフトデータを削除する
    const schoolName = schoolCalendar.school_name;
    const { workShifts } = await getWorkShifts({
      queryParams: [
        {
          fieldPath: 'school_name',
          opStr: '==',
          value: schoolName,
        },
      ],
    });
    const deleteWorkShiftIds = workShifts
      .filter((ws) => schoolCalendar.holidays.includes(ws.date))
      .map((ws) => ws.id);

    const result = await createSchoolCalendar(schoolCalendar);
    let isSuccess = !!result.id;
    if (deleteWorkShiftIds && deleteWorkShiftIds.length) {
      const isBulkDeleteSuccess = await bulkDeleteWorkShift(deleteWorkShiftIds);
      isSuccess = isSuccess && isBulkDeleteSuccess;
    }

    open(
      isSuccess ? 'success' : 'error',
      isSuccess
        ? '校舎の更新に成功しました'
        : '校舎の更新ができませんでした。もう一度お試しください。',
    );
    setCreating(false);

    return result.id;
  };

  return { creating, create, close, state };
};

export default useCreateSchoolCalendar;
