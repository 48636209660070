import { useContext, useState } from 'react';
import { useAsync } from 'react-use';
import _ from 'lodash';
import { AppContext } from '../../context/AppContext';
import { EnrolledCramSchoolContext } from '../../context/EnrolledCramSchoolContext';
import { YearMonthContext } from '../../context/YearMonthContext';
import getAdminUsers from '../../../domain/services/get-admin-users';
import getWorkShifts from '../../../domain/services/get-work-shifts';
import {
  getWorkedDaysAndLectureTimeWorkedDays,
  getNumAndTimeForWorks,
  SalaryList,
} from '../../../types/table/salary-list';
import { selectAllEnrolledCramSchools2 } from '../../../data/form-data';

export type Result = {
  loading: boolean;
  salaryList: SalaryList[];
};

const useFetchSalaryLists = (): Result => {
  const { adminUser } = useContext(AppContext);
  const [salaryList, setSalaryList] = useState<SalaryList[]>([]);
  const { enrolledCramSchool } = useContext(EnrolledCramSchoolContext);
  const { yearMonth } = useContext(YearMonthContext);

  let enrolledCramSchoolQueryParams = ['editor', 'viewer'].includes(
    adminUser.role,
  )
    ? [
        {
          fieldPath: 'enrolled_cram_school',
          opStr: '==',
          value: adminUser.enrolled_cram_school,
        },
      ]
    : [
        {
          fieldPath: 'enrolled_cram_school',
          opStr: '==',
          value: enrolledCramSchool,
        },
      ];
  enrolledCramSchoolQueryParams =
    enrolledCramSchool === selectAllEnrolledCramSchools2
      ? []
      : enrolledCramSchoolQueryParams;

  let schoolNameQueryParams = ['editor', 'viewer'].includes(adminUser.role)
    ? [
        {
          fieldPath: 'school_name',
          opStr: '==',
          value: adminUser.enrolled_cram_school,
        },
      ]
    : [
        {
          fieldPath: 'school_name',
          opStr: '==',
          value: enrolledCramSchool,
        },
      ];
  schoolNameQueryParams =
    enrolledCramSchool === selectAllEnrolledCramSchools2
      ? []
      : schoolNameQueryParams;

  const { loading } = useAsync(async () => {
    const { adminUsers } = await getAdminUsers({
      queryParams: enrolledCramSchoolQueryParams,
    });

    const { workShifts } = await getWorkShifts({
      queryParams: schoolNameQueryParams,
    });

    // 月末の日付を調べる
    const lastDay = new Date(
      Number(yearMonth.split('/')[0]),
      Number(yearMonth.split('/')[1]),
      0,
    ).getDate();

    // work_shiftデータのフィルター用に日付の配列を作成する
    const dateArr: string[] = [];
    _.times(lastDay, (number) => {
      const dateNumStr =
        number <= 8 ? `0${String(number + 1)}` : String(number + 1);
      const date = `${yearMonth}/${dateNumStr}`;

      dateArr.push(date);
    });

    // work_shiftデータを指定した年月にフィルター
    const filteredWorkShifts = workShifts.filter((ws) =>
      dateArr.includes(ws.date),
    );

    const formattedSalaryLists: SalaryList[] = adminUsers.map((staff) => {
      const [workedDays, lectureTime] = getWorkedDaysAndLectureTimeWorkedDays(
        staff,
        filteredWorkShifts,
      );
      const [
        officeTrainingTime,
        behindNum,
        behindTime,
        earlyLeaveNum,
        earlyLeaveTime,
      ] = getNumAndTimeForWorks(staff, filteredWorkShifts);

      return {
        staffId: staff.id,
        staff_num: staff.staff_num,
        name: `${staff.last_name} ${staff.first_name}`,
        enrolled_cram_school: staff.enrolled_cram_school,
        employment_status: staff.employment_status,
        worked_days: workedDays,
        lecture_time: lectureTime,
        office_training_time: officeTrainingTime,
        behind_num: behindNum,
        behind_time: behindTime,
        early_leave_num: earlyLeaveNum,
        early_leave_time: earlyLeaveTime,
      };
    });

    setSalaryList(formattedSalaryLists);
  }, [enrolledCramSchool, yearMonth]);

  return {
    loading,
    salaryList,
  };
};

export default useFetchSalaryLists;
