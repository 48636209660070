import React, { FC, useContext, useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { Column } from 'react-table';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import { StaffList } from '../../../../../types/table/staff-list';
import StaffListComponent from '../../../../components/molecules/table/StaffList';
import useFetchStaffs from '../../../../hooks/fetch/useFetchStaffs';
import { EnrolledCramSchoolContext } from '../../../../context/EnrolledCramSchoolContext';
import {
  SelectAllEnrolledCramSchools,
  SELECT_ENROLLED_CRAM_SCHOOLS,
  NotesJapanSchool,
  NotesPraiseSchool,
} from '../../../../../data/form-data';
import CheckboxColumnColumnFilter from '../../../../components/molecules/table/CheckboxColumnFilter';
import SelectColumnFilter from '../../../../components/molecules/table/SelectColumnFilter';
import useRole from '../../../../hooks/useRole';
import { AppContext } from '../../../../context/AppContext';

const columns: Column<StaffList>[] = [
  { Header: 'スタッフ番号', accessor: 'staff_num' },
  { Header: '氏名', accessor: 'name' },
  {
    Header: '在籍校舎',
    accessor: 'enrolled_cram_school',
    disableFilters: true,
  },
  {
    Header: '権限',
    accessor: 'role',
    filter: 'includeSelectValue',
    Filter: SelectColumnFilter,
  },
  {
    Header: '雇用形態',
    accessor: 'employment_status',
    filter: 'includeSelectValue',
    Filter: SelectColumnFilter,
  },
  {
    Header: '退職日',
    accessor: 'termination_date',
    disableFilters: true,
  },
  {
    id: 'is_terminated',
    Header: '退職者込み',
    accessor: (row) => row.is_terminated,
    filter: 'isTerminatedFilter',
    Filter: (filterProps) => (
      <CheckboxColumnColumnFilter filterProps={filterProps} />
    ),
  },
];

const StaffListContainer: FC = () => {
  const { loading, staffList, setStaffList, allStaffList } = useFetchStaffs();
  const { isAdministrator, isHeadquarters } = useRole();
  const { adminUser } = useContext(AppContext);
  const selectEnrolledCramSchools =
    isAdministrator || isHeadquarters
      ? SELECT_ENROLLED_CRAM_SCHOOLS
      : [adminUser.enrolled_cram_school];
  const { enrolledCramSchool, setEnrolledCramSchool } = useContext(
    EnrolledCramSchoolContext,
  );

  useEffectOnce(() => {
    setEnrolledCramSchool(
      isAdministrator || isHeadquarters
        ? '校舎指定なし'
        : adminUser.enrolled_cram_school,
    );
  });
  // 校舎セレクトボックスでフィルターをかけるための処理
  useEffect(() => {
    setStaffList(
      allStaffList
        .filter((staff) => {
          if (
            enrolledCramSchool ===
            (SELECT_ENROLLED_CRAM_SCHOOLS[0] as SelectAllEnrolledCramSchools)
          )
            return true;

          return staff.enrolled_cram_school === enrolledCramSchool;
        })
        .map((staff) => staff),
    );
  }, [enrolledCramSchool, setStaffList, allStaffList]);

  const onChange: SelectInputProps['onChange'] = (event) => {
    setEnrolledCramSchool(
      event.target.value as
        | SelectAllEnrolledCramSchools
        | NotesJapanSchool
        | NotesPraiseSchool,
    );
  };

  return (
    <StaffListComponent
      loading={loading}
      staffList={staffList}
      columns={columns}
      toolbarSelectProps={{
        label: '',
        value: enrolledCramSchool,
        options: selectEnrolledCramSchools.map((school) => ({
          label: school,
          value: school,
        })),
        onChange,
      }}
    />
  );
};

export default StaffListContainer;
