import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { Select, MenuItem } from '@material-ui/core';

import {
  ClassTransfer,
  Status,
} from '../../../../../domain/models/class-transfer';
import { StudentBasic } from '../../../../../domain/models/student-basic';
import { classTransferStatus } from '../../../../../data/form-data';
import './style.scss';

export interface Props {
  classTransfer: ClassTransfer | undefined;
  studentBasic: StudentBasic | undefined;
  onChange: (status: Status, classTransfer: ClassTransfer) => void;
}

const Card: FC<Props> = ({ classTransfer, studentBasic, onChange }) => {
  const [status, setStatus] = useState<Status>(
    classTransfer?.status || 'applying',
  );
  if (!classTransfer || !studentBasic) return null;

  return (
    <div className="card">
      <div className="card-content">
        <div className="left-pane">
          <div className="head">
            <div className="enroll-cram-school">
              {classTransfer.enrolled_cram_school}
            </div>
          </div>
          <div className="name">
            {`${studentBasic.last_name} ${studentBasic.first_name}`}
          </div>
        </div>
        <div className="right-pane">
          <Select
            value={status}
            onChange={(event) => {
              if (typeof event.target.value !== 'string') {
                return;
              }
              setStatus(event.target.value as Status);
              onChange(event.target.value as Status, classTransfer);
            }}
            className={clsx('select-status', {
              approval: status === 'approval',
              reject: status === 'reject',
            })}
          >
            {Object.entries(classTransferStatus).map(([key, value]) => (
              <MenuItem value={key}>{value}</MenuItem>
            ))}
          </Select>
          <div className="date">
            <div className="before-date">{`${classTransfer.before_date} ${classTransfer.before_class}`}</div>
            <div className="after-date">{`${classTransfer.after_date} ${classTransfer.after_class}`}</div>
          </div>
          <p className="reason">理由：{classTransfer.transfer_reason || '-'}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
