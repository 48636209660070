import React, { FC, useContext } from 'react';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { Column } from 'react-table';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import Table from '../Table';
import { StaffList } from '../../../../../types/table/staff-list';
import DefaultColumnFilter from '../DefaultColumnFilter';
import useReactTableFilter from '../../../../hooks/useReactTableFilter';
import DotProgress from '../../../atoms/feedback/DotProgress';
import useRole from '../../../../hooks/useRole';
import { FilterContext } from '../../../../context/FilterContext';

export type Props = {
  loading: boolean;
  staffList: StaffList[];
  columns: Column<StaffList>[];
  toolbarSelectProps?: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
};

const StaffListComponent: FC<Props> = ({
  loading,
  staffList,
  columns,
  toolbarSelectProps,
}) => {
  const { isEditor, isViewer } = useRole();
  const history = useHistory();
  const filterTypes = useReactTableFilter();
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const { setFilterKey } = useContext(FilterContext);
  const pageName = 'staff-list';

  useEffectOnce(() => {
    setFilterKey(pageName);
  });

  return (
    <div>
      <div className="is-flex is-justify-content-space-between">
        <h3 className="title">社員マスター</h3>
        <button
          type="button"
          onClick={() => {
            history.push({
              pathname: '/staff/create',
            });
          }}
          disabled={isEditor || isViewer}
          className="button is-primary with-icon btn-primary"
        >
          社員を追加する
        </button>
      </div>
      <Table<StaffList>
        classes={{
          root: clsx('table-container'),
          table: clsx(
            'table',
            'is-fullwidth',
            'is-striped',
            'is-hoverable',
            'list-table',
          ),
        }}
        tableOptions={{
          data: staffList,
          columns,
          filterTypes,
          defaultColumn,
          manualPagination: false,
          initialState: {
            pageSize: 50,
            filters: [
              {
                id: 'is_terminated',
                value: false,
              },
            ],
          },
        }}
        theadThRender={(column) => {
          if (column.id === 'is_terminated') {
            // フィルタのためにhiddenColumnsに指定していないだけなので内容は表示しない
            return false;
          }

          return (
            <th {...column.getHeaderProps()}>
              {
                // Render the header
                column.render('Header')
              }
            </th>
          );
        }}
        tbodyTdRender={(row, cell) => {
          switch (cell.column.id) {
            case 'staff_num':
            case 'name':
            case 'enrolled_cram_school':
            case 'role':
            case 'termination_date': {
              const value = cell.value as string;

              return <td {...cell.getCellProps()}>{value}</td>;
            }
            case 'is_terminated': {
              // フィルタのためにhiddenColumnsに指定していないだけなので内容は表示しない
              return <span key={cell.column.id} />;
            }
            default:
              return (
                <td {...cell.getCellProps()}>
                  {
                    // Render the cell contents
                    cell.render('Cell')
                  }
                </td>
              );
          }
        }}
        onClick={(row) => {
          history.push({
            pathname: '/staff/detail',
            state: { staffId: row.original.staffId },
          });
        }}
        pageName={pageName}
        toolbarSelectProps={toolbarSelectProps}
      />
      {loading && <DotProgress />}
    </div>
  );
};

export default StaffListComponent;
