import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { FaSave, RiArrowGoBackFill } from 'react-icons/all';
import {
  Select as MuiSelect,
  MenuItem,
  TextField,
  Tabs,
  Tab,
} from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { CgCloseO } from 'react-icons/cg';
import { AiFillPlusCircle } from 'react-icons/ai';
import { DateTime } from 'luxon';

import {
  CHECK_ITEM_CATEGORIES,
  dateFormat,
} from '../../../../../data/form-data';
import {
  PeriodicInfo,
  CheckItem,
  EventItem,
} from '../../../../../domain/models/periodic-info';
import useRole from '../../../../hooks/useRole';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import Input from '../../../atoms/form/Input';
import Select from '../../../atoms/form/Select';
import DateField from '../../../atoms/form/DatePicker';
import ErrorMessage from '../../../atoms/form/ErrorMessage';
import Textarea from '../../../atoms/form/Textarea';
import Button from '../../../atoms/button/Button';
import DotProgress from '../../../atoms/feedback/DotProgress';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import Backdrop from '../../../atoms/feedback/Backdrop';
import './style.scss';

export type Props = {
  loading: boolean;
  periodicInfo: PeriodicInfo;
  formMethods: UseFormMethods<PeriodicInfo>;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
  creating: boolean;
  selectProps: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
};

const PeriodicInfoForm: FC<Props> = ({
  loading,
  periodicInfo,
  formMethods,
  onSubmit,
  snackbarState,
  snackbarClose,
  creating,
  selectProps,
}) => {
  const { isViewer } = useRole();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { errors, register, control, watch, setValue, trigger } = formMethods;
  const { fields, append, remove } = useFieldArray<CheckItem>({
    control,
    name: 'checklists',
  });
  const { fields: fields2, append: append2, remove: remove2 } = useFieldArray<
    EventItem
  >({
    control,
    name: 'event_schedule',
  });

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);

    remove();
    remove2();

    setValue('checklists', periodicInfo.checklists);
    setValue('event_schedule', periodicInfo.event_schedule);
  }, [setValue, periodicInfo, remove, remove2]);

  return (
    <div>
      <h3 className="title pb-1">定期情報登録マスター</h3>
      {selectProps ? (
        <div className="table-toolbar is-justify-content-flex-start">
          <MuiSelect
            label={selectProps.label}
            value={selectProps.value}
            onChange={selectProps.onChange}
            className="toolbar-select"
          >
            {selectProps.options.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </MuiSelect>
        </div>
      ) : null}
      {loading ? (
        <DotProgress />
      ) : (
        <form
          onSubmit={onSubmit}
          className="detail-form card p-5 periodic-info"
        >
          <Tabs
            value={tabIndex}
            onChange={(_, newIndex) => setTabIndex(newIndex)}
            aria-label="simple tabs example"
          >
            <Tab label="講師" />
            <Tab label="生徒" />
          </Tabs>
          <div className="tab-content-container">
            <div className={clsx('tab-content', { visible: tabIndex === 0 })}>
              <h4 className="title is-4 pb-2">講師日報</h4>
              <div className="mb-6 pb-5">
                <div className="columns">
                  <p className="label column is-3">カテゴリ</p>
                  <p className="label column is-8">メッセージ</p>
                </div>
                {fields.map((checkItem, index) => (
                  <div className="columns" key={checkItem.id}>
                    <Controller
                      rules={{ required: 'イベントを入力してください' }}
                      control={control}
                      name={`checklists[${index}].category`}
                      defaultValue={periodicInfo?.checklists[index]?.category}
                      as={
                        <Select
                          selectProps={{
                            name: `checklists[${index}].category`,
                            options: CHECK_ITEM_CATEGORIES.map((category) => ({
                              label: category,
                              value: category,
                            })),
                            defaultValue: {
                              label:
                                periodicInfo?.checklists[index]?.category ??
                                '選択してください',
                              value:
                                periodicInfo?.checklists[index]?.category ?? '',
                            },
                            onChange: async (value) => {
                              setValue(
                                `checklists[${index}].category`,
                                value && 'value' in value ? value.value : '',
                              );
                              await trigger(
                                'transportation.default_transportation',
                              );
                            },
                          }}
                          error={errors.checklists?.[index]?.category}
                          className="column is-3 py-0"
                        />
                      }
                    />
                    <Input
                      inputProps={{
                        type: 'string',
                        name: `checklists[${index}].message`,
                        ref: register({
                          required: 'メッセージを入力してください',
                        }),
                        defaultValue: periodicInfo?.checklists[index]?.message,
                      }}
                      error={errors.checklists?.[index]?.message}
                      className="column is-8 py-0"
                    />
                    <div className="field column is-1 py-0 is-flex is-justify-content-flex-start is-align-items-center">
                      <CgCloseO
                        onClick={() => {
                          remove(index);
                          if (periodicInfo?.checklists?.length) {
                            void periodicInfo?.checklists.splice(index, 1);
                          }
                        }}
                        className="remove-btn"
                      />
                    </div>
                  </div>
                ))}
                <div className="columns">
                  <div className="column is-3 btn-add-item has-text-centered">
                    <Button
                      buttonProps={{
                        onClick: () => append({ category: '', message: '' }),
                      }}
                      className="add-btn"
                    >
                      <AiFillPlusCircle />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={clsx('tab-content', { visible: tabIndex === 1 })}>
              <h4 className="title is-4 pb-2">行事予定</h4>
              <div className="mb-6 pb-5">
                <div className="columns">
                  <p className="label column is-3">日付</p>
                  <p className="label column is-8">行事名</p>
                </div>
                {fields2.map((eventItem, index) => (
                  <div className="columns" key={eventItem.id}>
                    <Controller
                      rules={{ required: '日付を選択してください' }}
                      control={control}
                      name={`event_schedule[${index}].date`}
                      defaultValue={
                        periodicInfo?.event_schedule?.[index]?.date || null
                      }
                      as={
                        <div className="column is-3 py-0">
                          <DateField
                            DatePickerProps={{
                              name: `event_schedule[${index}].date`,
                              value:
                                !errors?.event_schedule?.[index]?.date &&
                                watch(`event_schedule[${index}].date`)
                                  ? DateTime.fromFormat(
                                      watch(`event_schedule[${index}].date`) ??
                                        '',
                                      dateFormat,
                                    )
                                  : (eventItem?.date &&
                                      DateTime.fromFormat(
                                        eventItem?.date,
                                        dateFormat,
                                      )) ||
                                    null,
                              error:
                                errors?.event_schedule?.[index]?.date && true,
                              TextFieldComponent: (props) => (
                                <TextField {...props} margin="none" />
                              ),
                              onChange: (v) => {
                                setValue(
                                  `event_schedule[${index}].date`,
                                  v ? v.toFormat(dateFormat) : '',
                                );
                                void trigger(`event_schedule[${index}].date`);
                              },
                              inputVariant: 'outlined',
                              className: 'text-field-outlined',
                            }}
                          />
                          <ErrorMessage
                            message={
                              errors?.event_schedule?.[index]?.date?.message
                            }
                            className="mt-2"
                          />
                        </div>
                      }
                    />
                    <Input
                      inputProps={{
                        type: 'string',
                        name: `event_schedule[${index}].name`,
                        ref: register({ required: '行事名を入力してください' }),
                        defaultValue:
                          periodicInfo?.event_schedule?.[index]?.name,
                      }}
                      error={errors.event_schedule?.[index]?.name}
                      className="column is-8 py-0"
                    />
                    <div className="field column is-1 py-0 is-flex is-justify-content-flex-start is-align-items-center">
                      <CgCloseO
                        onClick={() => {
                          remove2(index);
                          if (periodicInfo?.event_schedule?.length) {
                            void periodicInfo?.event_schedule.splice(index, 1);
                          }
                        }}
                        className="remove-btn"
                      />
                    </div>
                  </div>
                ))}
                <div className="columns">
                  <div className="column is-3 btn-add-item has-text-centered">
                    <Button
                      buttonProps={{
                        onClick: () => append2({ date: '', name: '' }),
                      }}
                      className="add-btn"
                    >
                      <AiFillPlusCircle />
                    </Button>
                  </div>
                </div>
              </div>
              <h4 className="title is-4 pb-5">授業日程表</h4>
              <div className="mt-5 mb-6">
                <div className="columns">
                  <Textarea
                    label="連絡事項"
                    textareaProps={{
                      name: 'information',
                      ref: register(),
                      defaultValue: periodicInfo?.information,
                    }}
                    className="column is-12 py-0"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="btn-area">
            <Button
              buttonProps={{
                type: 'button',
                onClick: () => history.push('/'),
              }}
              className={clsx('with-icon', 'btn-primary')}
            >
              <RiArrowGoBackFill />
              戻る
            </Button>
            <Button
              buttonProps={{ type: 'submit', disabled: isViewer }}
              color="primary"
              className={clsx('with-icon', 'btn-primary')}
            >
              <FaSave />
              保存
            </Button>
          </div>
        </form>
      )}
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default PeriodicInfoForm;
