import React, { FC, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useEffectOnce } from 'react-use';
import { Controller, useFieldArray } from 'react-hook-form';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
  Select as MuiSelect,
  MenuItem,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { FaSave, RiArrowGoBackFill } from 'react-icons/all';

import useRole from '../../../../hooks/useRole';
import {
  AdminUser,
  SelectedStaff,
} from '../../../../../domain/models/admin-user';
import { StudentBasic } from '../../../../../domain/models/student-basic';
import {
  defaultTeacherWork,
  getPreparationCleanUpTime,
  GREETING_TIME,
  IS_NOTES_ELEMENTARY_SCHOOL_LECTURE_TIME,
  IS_NOTES_NORMAL_LECTURE_TIME,
  IS_PRAISE_LECTURE_TIME,
  TeacherWork,
  WorkShift,
} from '../../../../../domain/models/work-shift';
import {
  getLecture,
  getLectureDayKey,
  jaLectureDaysArr,
  LectureDayKey,
} from '../../../../../domain/models/student-lectures';
import DotProgress from '../../../atoms/feedback/DotProgress';
import DateField from '../../../atoms/form/DatePicker';
import { EnrolledCramSchoolContext } from '../../../../context/EnrolledCramSchoolContext';
import { WorkShiftSelectedStaffsContext } from '../../../../context/WorkShiftSelectedStaffsContext';
import {
  NOTES_JAPAN_SCHOOLS,
  NotesJapanClass,
  NotesPraiseClass,
} from '../../../../../data/form-data';
import Button from '../../../atoms/button/Button';
import Backdrop from '../../../atoms/feedback/Backdrop';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import { TableShift } from './TableShift';
import './style.scss';
import Textarea from '../../../atoms/form/Textarea';
import Select from '../../../atoms/form/Select';

export type Props = {
  formMethods: UseFormMethods<WorkShift>;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
  creating: boolean;
  loading: boolean;
  isHoliday: boolean;
  workShift: WorkShift | null;
  staffs: AdminUser[];
  studentBasics: StudentBasic[];
  selectPropsEnrolledCramSchool: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
  onChangeDate: (dateStr: string) => void;
  date: string;
};

const WorkShiftDetailForm: FC<Props> = ({
  formMethods,
  onSubmit,
  snackbarState,
  snackbarClose,
  creating,
  loading,
  isHoliday,
  workShift,
  staffs,
  studentBasics,
  selectPropsEnrolledCramSchool,
  onChangeDate,
  date,
}) => {
  const { isViewer } = useRole();
  const history = useHistory();
  const { enrolledCramSchool } = useContext(EnrolledCramSchoolContext);
  const {
    lecture1SelectedStaffs,
    lecture2SelectedStaffs,
    lecture3SelectedStaffs,
    lecture4SelectedStaffs,
    lecture5SelectedStaffs,
    lectureSpecialSelectedStaffs,
    lecture1SelectedStaffsInitialLoading,
    lecture2SelectedStaffsInitialLoading,
    lecture3SelectedStaffsInitialLoading,
    lecture4SelectedStaffsInitialLoading,
    lecture5SelectedStaffsInitialLoading,
    lectureSpecialSelectedStaffsInitialLoading,
  } = useContext(WorkShiftSelectedStaffsContext);
  const [selectedStaffsAllLecture, setSelectedStaffsAllLecture] = useState<
    SelectedStaff[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, setValue, watch, register, unregister } = formMethods;
  const { append } = useFieldArray<TeacherWork>({
    control,
    name: 'works',
  });
  const [addTargetStaff, setAddTargetStaff] = useState<AdminUser | null>(null);
  const [filteredStaffs, setFilteredStaffs] = useState<AdminUser[]>([]);
  // ※↓useFieldArrayのwatchを参照して自動計算すると不具合が出るので都度都度ここのstateに格納し参照する。
  const [works, setWorks] = useState<TeacherWork[] | null>(null);
  const [isNotesJapan, setIsNotesJapan] = useState<boolean>(false);
  const [lectureDayKey, setLectureDayKey] = useState<LectureDayKey | undefined>(
    undefined,
  );
  const [lecture1Lecture, setLecture1Lecture] = useState<
    NotesJapanClass | NotesPraiseClass | undefined
  >(undefined);
  const [lecture2Lecture, setLecture2Lecture] = useState<
    NotesJapanClass | NotesPraiseClass | undefined
  >(undefined);
  const [lecture3Lecture, setLecture3Lecture] = useState<
    NotesJapanClass | NotesPraiseClass | undefined
  >(undefined);
  const [lecture4Lecture, setLecture4Lecture] = useState<
    NotesJapanClass | NotesPraiseClass | undefined
  >(undefined);
  const [lecture5Lecture, setLecture5Lecture] = useState<
    NotesJapanClass | NotesPraiseClass | undefined
  >(undefined);
  const [initialLoading, setInitialLoading] = useState(true);
  const [transitionLoading, setTransitionLoading] = useState(true);

  useEffectOnce(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  });

  useEffect(() => {
    setTransitionLoading(true);
  }, [date, enrolledCramSchool]);

  useEffect(() => {
    setIsNotesJapan(NOTES_JAPAN_SCHOOLS.some((v) => v === enrolledCramSchool));

    setLectureDayKey(
      getLectureDayKey(jaLectureDaysArr[new Date(date).getDay()]),
    );

    setFilteredStaffs(
      staffs.filter((s) => s.enrolled_cram_school === enrolledCramSchool),
    );

    setAddTargetStaff(null);
  }, [date, enrolledCramSchool, staffs]);

  useEffect(() => {
    if (transitionLoading) {
      // teacher_idが無ければ配列から削除（万が一のバグ対策用）
      setValue('works', workShift?.works.filter((w) => !!w.teacher_id) ?? []);
      setWorks(
        workShift?.works && workShift?.works.length
          ? workShift?.works.filter((w) => !!w.teacher_id)
          : [],
      );
    }

    if (enrolledCramSchool && date && lectureDayKey) {
      setLecture1Lecture(getLecture(lectureDayKey, 'lecture_1', isNotesJapan));
      setLecture2Lecture(getLecture(lectureDayKey, 'lecture_2', isNotesJapan));
      setLecture3Lecture(getLecture(lectureDayKey, 'lecture_3', isNotesJapan));
      setLecture4Lecture(getLecture(lectureDayKey, 'lecture_4', isNotesJapan));
      setLecture5Lecture(getLecture(lectureDayKey, 'lecture_5', isNotesJapan));
    }
    // eslint-disable-next-line
  }, [workShift, date, enrolledCramSchool, lectureDayKey, isNotesJapan]);

  useEffect(() => {
    // 最初にuseFieldArrayの値をリセット
    onUnregisterAll();

    // 値が自動計算・不変のものはここでregisterする
    if (!transitionLoading) {
      if (works && works.length) {
        works.forEach((teacherWork, index) => {
          // teacher_idが無ければ配列から削除（万が一のバグ対策用）
          if (!teacherWork.teacher_id) {
            return;
          }
          onRegister(teacherWork, index);
        });
      }
    } else if (workShift?.works && workShift?.works.length) {
      // 値が自動計算・不変のものはここでregisterする
      void workShift?.works.forEach((teacherWork, index) => {
        // teacher_idが無ければ配列から削除（万が一のバグ対策用）
        if (!teacherWork.teacher_id) {
          return;
        }
        onRegister(teacherWork, index);
      });
    }
    // eslint-disable-next-line
  }, [workShift, lecture1SelectedStaffs, lecture2SelectedStaffs, lecture3SelectedStaffs, lecture4SelectedStaffs, lecture5SelectedStaffs, lectureSpecialSelectedStaffs]);

  useEffect(() => {
    // 1日のうちに選択されている講師の配列を作る+授業時間の計算（重複有り）
    let newSelectedStaffsAllLecture: SelectedStaff[] = _.cloneDeep([
      ...lecture1SelectedStaffs,
      ...lecture2SelectedStaffs,
      ...lecture3SelectedStaffs,
      ...lecture4SelectedStaffs,
      ...lecture5SelectedStaffs,
      ...lectureSpecialSelectedStaffs,
    ]);

    // 重複削除
    newSelectedStaffsAllLecture = newSelectedStaffsAllLecture.filter(
      (s, index, self) => {
        return self.findIndex((s2) => s2.id === s.id) === index;
      },
    );

    if (!_.isEqual(selectedStaffsAllLecture, newSelectedStaffsAllLecture)) {
      setSelectedStaffsAllLecture(newSelectedStaffsAllLecture);
    }

    let sourceWorks: TeacherWork[] = [];
    if (initialLoading) {
      if (works) {
        // worksの値がちゃんと入ってきた場合のみ
        setInitialLoading(false);
        setTransitionLoading(false);
        sourceWorks = _.cloneDeep(works) ?? [];
      }
    } else if (transitionLoading && workShift?.works) {
      setTransitionLoading(false);
      sourceWorks = _.cloneDeep(workShift?.works) ?? [];
    } else {
      sourceWorks = _.cloneDeep(works) ?? [];
    }

    // 既存の勤務状況と1日のうちに選択されている講師を使って、勤務状況を表示させるための勤務状況配列を作成する（コマ時間も上書き）
    const newWorksTmp: TeacherWork[] = sourceWorks ?? [];

    // 既存の勤務状況には無いが、新しく講師選択肢に追加されている講師の勤務状況を追加（授業数が0の講師は除外）
    let newWorksTmp2 = _.cloneDeep(newWorksTmp);
    newSelectedStaffsAllLecture.forEach((staff) => {
      const isNewStaff = newWorksTmp.every((tw) => tw.teacher_id !== staff.id);
      if (isNewStaff && (staff?.shiftNum ?? 0) >= 1) {
        newWorksTmp2.push({ ...defaultTeacherWork, teacher_id: staff.id });
      }
    });

    // 重複削除
    newWorksTmp2 = newWorksTmp2.filter((s, index, self) => {
      return self.findIndex((s2) => s2.teacher_id === s.teacher_id) === index;
    });

    // teacher_idが無ければ配列から削除（万が一のバグ対策用）
    newWorksTmp2 = newWorksTmp2.filter(
      (teacherWork) => !!teacherWork.teacher_id,
    );

    // 時間を格納・計算する
    const newWorks = _.cloneDeep(newWorksTmp2);
    newWorksTmp2.forEach((teacherWork, index) => {
      const selectedStaff = newSelectedStaffsAllLecture.find(
        (s) => s.id === teacherWork.teacher_id,
      );

      let lectureTime = 0;
      if (selectedStaff) {
        lectureTime += getLectureTimeBySelectedStaffs(
          selectedStaff,
          lecture1SelectedStaffs,
        );
        lectureTime += getLectureTimeBySelectedStaffs(
          selectedStaff,
          lecture2SelectedStaffs,
        );
        lectureTime += getLectureTimeBySelectedStaffs(
          selectedStaff,
          lecture3SelectedStaffs,
        );
        lectureTime += getLectureTimeBySelectedStaffs(
          selectedStaff,
          lecture4SelectedStaffs,
        );
        lectureTime += getLectureTimeBySelectedStaffs(
          selectedStaff,
          lecture5SelectedStaffs,
        );
        lectureTime += getLectureTimeBySelectedStaffs(
          selectedStaff,
          lectureSpecialSelectedStaffs,
        );
      }

      const preparationCleanUpTime = teacherWork.preparation_clean_up
        ? getPreparationCleanUpTime(isNotesJapan)
        : 0;

      const greetingTime = teacherWork.greeting ? GREETING_TIME : 0;

      let officeTrainingTime = 0;
      if (Number(watch(`works.${index}.office_training_time`))) {
        officeTrainingTime = Number(
          watch(`works.${index}.office_training_time`),
        );
      } else if (Number(teacherWork.office_training_time)) {
        officeTrainingTime = Number(teacherWork.office_training_time);
      }

      let studyCampTime = 0;
      if (Number(watch(`works.${index}.study_camp_time`))) {
        studyCampTime = Number(watch(`works.${index}.study_camp_time`));
      } else if (Number(teacherWork.study_camp_time)) {
        studyCampTime = Number(teacherWork.study_camp_time);
      }

      let behindTime = 0;
      if (Number(watch(`works.${index}.behind_time`))) {
        behindTime = Number(watch(`works.${index}.behind_time`));
      } else if (Number(teacherWork.behind_time)) {
        behindTime = Number(teacherWork.behind_time);
      }

      let earlyLeaveTime = 0;
      if (Number(watch(`works.${index}.early_leave_time`))) {
        earlyLeaveTime = Number(watch(`works.${index}.early_leave_time`));
      } else if (Number(teacherWork.early_leave_time)) {
        earlyLeaveTime = Number(teacherWork.early_leave_time);
      }

      let sumTime = 0;
      sumTime += lectureTime;
      sumTime += preparationCleanUpTime;
      sumTime += greetingTime;
      sumTime += officeTrainingTime;
      sumTime += studyCampTime;
      sumTime += -behindTime;
      sumTime += -earlyLeaveTime;

      newWorks.splice(index, 1, {
        ...teacherWork,
        lecture_time: lectureTime,
        sum_time: sumTime,
      });
    });

    setWorks(newWorks);
    setValue('works', newWorks);

    // 値が自動計算・不変のものはここでregisterする
    newWorks.forEach((teacherWork, index) => {
      onRegister(teacherWork, index);
    });
    // eslint-disable-next-line
  }, [selectedStaffsAllLecture, lecture1SelectedStaffs, lecture2SelectedStaffs, lecture3SelectedStaffs, lecture4SelectedStaffs, lecture5SelectedStaffs, lectureSpecialSelectedStaffs]);

  const getLectureTimeBySelectedStaffs = (
    staff: SelectedStaff,
    selectedStaffs: SelectedStaff[],
  ) => {
    const targetStaff = selectedStaffs.find((s) => s.id === staff.id);
    if (targetStaff) {
      // もしも授業を持っていない場合は0時間を返す
      if (!targetStaff.shiftNum || targetStaff.shiftNum <= 0) {
        return 0;
      }

      if (isNotesJapan) {
        // 前半or後半のどちらかの授業しか持っていない場合は0.75時間を返す
        if (targetStaff?.isHalfTime) {
          return IS_NOTES_ELEMENTARY_SCHOOL_LECTURE_TIME;
        }

        return IS_NOTES_NORMAL_LECTURE_TIME;
      }

      return IS_PRAISE_LECTURE_TIME;
    }

    return 0;
  };

  const getStaffName = (teacherId: string) => {
    const staff = filteredStaffs.find((s) => s.id === teacherId);
    if (staff) {
      return `${staff.last_name} ${staff.first_name}`;
    }

    return '現在選択している校舎に所属していない講師です';
  };

  // 値が自動計算・不変のもの・checkbox系はここでregisterする
  const onRegister = (work: TeacherWork, index: number) => {
    const teacherIdName = `works.${index}.teacher_id`;
    register(teacherIdName);
    setValue(teacherIdName, work.teacher_id ?? '');

    const lectureTimeName = `works.${index}.lecture_time`;
    register(lectureTimeName);
    setValue(lectureTimeName, work.lecture_time ?? '');

    const sumTimeName = `works.${index}.sum_time`;
    register(sumTimeName);
    setValue(sumTimeName, work.sum_time ?? false);

    // checkbox系だとbooleanではなくarrayで保存されるバグがあるのでここでregisterする
    const preparationCleanUpName = `works.${index}.preparation_clean_up`;
    register(preparationCleanUpName);
    setValue(preparationCleanUpName, work.preparation_clean_up ?? false);

    const greetingName = `works.${index}.greeting`;
    register(greetingName);
    setValue(greetingName, work.greeting ?? false);
  };

  // 最初にuseFieldArrayの値をリセット
  const onUnregisterAll = () => {
    watch(`works`).forEach((tw, index) => {
      unregister(`works.${index}.teacher_id`);
      unregister(`works.${index}.lecture_time`);
      unregister(`works.${index}.preparation_clean_up`);
      unregister(`works.${index}.greeting`);
      unregister(`works.${index}.office_training_time`);
      unregister(`works.${index}.study_camp_time`);
      unregister(`works.${index}.behind_time`);
      unregister(`works.${index}.early_leave_time`);
      unregister(`works.${index}.sum_time`);
      unregister(`works.${index}.remarks`);
    });
  };

  const addWork = () => {
    if (
      !addTargetStaff ||
      watch('works').some((tw) => tw.teacher_id === addTargetStaff?.id)
    ) {
      return;
    }

    const newWork = { ...defaultTeacherWork, teacher_id: addTargetStaff?.id };

    const newWorks = _.cloneDeep(works);
    if (newWorks) {
      // 値が自動計算・不変のものはここでregisterする
      const index = newWorks.length;
      onRegister(newWork, index);

      append(newWork);
      newWorks.push(newWork);
      setWorks(newWorks);
    }
  };

  const removeWork = (index: number) => {
    const newWorks = _.cloneDeep(works);
    if (newWorks) {
      newWorks.splice(index, 1);
      setWorks(newWorks);
      // レンダリング順序の関係でremoveをしてもregisterされてしまうらしいので一つずつunregisterをしてからregisterし直して対応する
      onUnregisterAll();
      newWorks.forEach((teacherWork, idx) => {
        onRegister(teacherWork, idx);
      });
    }
  };

  const onChangeSumTime = (
    index: number,
    changeObj?: {
      preparation_clean_up?: boolean;
      greeting?: boolean;
    },
  ) => {
    const newWorks = _.cloneDeep(works);
    if (newWorks && newWorks[index]) {
      const lectureTime =
        Number(
          watch(`works.${index}.lecture_time`) ?? works?.[index].lecture_time,
        ) ?? 0;
      const officeTrainingTimeName = `works.${index}.office_training_time`;
      const officeTrainingTime = Number(watch(officeTrainingTimeName)) ?? 0;
      const studyCampTimeName = `works.${index}.study_camp_time`;
      const studyCampTime = Number(watch(studyCampTimeName)) ?? 0;

      const behindTimeName = `works.${index}.behind_time`;
      const behindTime = Number(watch(behindTimeName)) ?? 0;
      const earlyLeaveTimeName = `works.${index}.early_leave_time`;
      const earlyLeaveTime = Number(watch(earlyLeaveTimeName)) ?? 0;

      let preparationCleanUpTime =
        watch(`works.${index}.preparation_clean_up`) ??
        works?.[index].preparation_clean_up
          ? getPreparationCleanUpTime(isNotesJapan)
          : 0;
      let greetingTime =
        watch(`works.${index}.greeting`) ?? works?.[index].greeting
          ? GREETING_TIME
          : 0;

      if (changeObj) {
        if ('preparation_clean_up' in changeObj) {
          const preparationCleanUpName = `works.${index}.preparation_clean_up`;
          const isMinus = watch(preparationCleanUpName);
          preparationCleanUpTime = changeObj.preparation_clean_up
            ? getPreparationCleanUpTime(isNotesJapan)
            : 0;
          preparationCleanUpTime = isMinus
            ? -preparationCleanUpTime
            : preparationCleanUpTime;
          const isPreparationCleanUpName =
            changeObj.preparation_clean_up ?? false;
          newWorks[index].preparation_clean_up = isPreparationCleanUpName;
          setValue(preparationCleanUpName, isPreparationCleanUpName);
        }
        if ('greeting' in changeObj) {
          const greetingName = `works.${index}.greeting`;
          const isMinus = watch(greetingName);
          greetingTime = changeObj.greeting ? GREETING_TIME : 0;
          greetingTime = isMinus ? -greetingTime : greetingTime;
          const isGreeting = changeObj.greeting ?? false;
          newWorks[index].greeting = isGreeting;
          setValue(greetingName, isGreeting);
        }

        newWorks[index] = {
          ...newWorks[index],
          ...changeObj,
        };
      }

      const sumTime =
        lectureTime +
        officeTrainingTime +
        studyCampTime +
        preparationCleanUpTime +
        greetingTime +
        -behindTime +
        -earlyLeaveTime;
      const sumTimeName = `works.${index}.sum_time`;

      newWorks[index].office_training_time = officeTrainingTime;
      newWorks[index].study_camp_time = studyCampTime;
      newWorks[index].behind_time = behindTime;
      newWorks[index].early_leave_time = earlyLeaveTime;
      newWorks[index].sum_time = sumTime;
      newWorks.splice(index, 1, newWorks[index]);
      setWorks(newWorks);
      setValue(officeTrainingTimeName, officeTrainingTime);
      setValue(studyCampTimeName, studyCampTime);
      setValue(behindTimeName, behindTime);
      setValue(earlyLeaveTimeName, earlyLeaveTime);
      setValue(sumTimeName, sumTime);
    }
  };

  const isRemovable = (staffId: string) => {
    return (
      selectedStaffsAllLecture.every((s) => s.id !== staffId) ||
      (selectedStaffsAllLecture.find((s) => s.id === staffId)?.shiftNum ?? 0) <=
        0
    );
  };

  return (
    <div className="detail-form work-shift-detail">
      <h3 className="title">シフト管理</h3>
      {selectPropsEnrolledCramSchool ? (
        <div className="is-flex justify-content is-justify-content-flex-start mb-3">
          <div className="w-200px">
            <div className="is-size-7 has-text-grey">校舎</div>
            <MuiSelect
              label={selectPropsEnrolledCramSchool.label}
              value={selectPropsEnrolledCramSchool.value}
              onChange={selectPropsEnrolledCramSchool.onChange}
              className="toolbar-select"
              disabled={
                isViewer ||
                lecture1SelectedStaffsInitialLoading ||
                lecture2SelectedStaffsInitialLoading ||
                lecture3SelectedStaffsInitialLoading ||
                lecture4SelectedStaffsInitialLoading ||
                lecture5SelectedStaffsInitialLoading ||
                lectureSpecialSelectedStaffsInitialLoading
              }
            >
              {selectPropsEnrolledCramSchool.options.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </MuiSelect>
          </div>
          <div className="w-350px ml-2">
            <div className="is-size-7 has-text-grey">日付</div>
            <Controller
              name="date"
              control={control}
              defaultValue={date}
              as={
                <DateField
                  DatePickerProps={{
                    label: '',
                    name: 'date',
                    value: date || null,
                    format: 'yyyy/MM/dd',
                    TextFieldComponent: (props) => (
                      <TextField {...props} margin="none" />
                    ),
                    onChange: (v) => {
                      const dt = v ? v.toFormat('yyyy/MM/dd') : '';
                      onChangeDate(dt);
                      setValue('date', dt ?? '');
                    },
                    disabled:
                      isViewer ||
                      lecture1SelectedStaffsInitialLoading ||
                      lecture2SelectedStaffsInitialLoading ||
                      lecture3SelectedStaffsInitialLoading ||
                      lecture4SelectedStaffsInitialLoading ||
                      lecture5SelectedStaffsInitialLoading ||
                      lectureSpecialSelectedStaffsInitialLoading,
                  }}
                />
              }
            />
          </div>
        </div>
      ) : null}
      {loading ? (
        <DotProgress />
      ) : (
        <>
          {isHoliday ? (
            <p className="my-3">休校日です</p>
          ) : (
            <form
              onSubmit={onSubmit}
              className="detail-form card p-5 work-shift"
            >
              <div>
                <div className="mt-5 mb-6">
                  <h4 className="title is-4">▼{lecture1Lecture}</h4>
                  <div>
                    <TableShift
                      lectureKey="lecture_1"
                      formMethods={formMethods}
                      lectureItemForShifts={workShift?.shift?.lecture_1}
                      staffs={filteredStaffs}
                      studentBasics={studentBasics}
                      date={date}
                      isNotesJapan={isNotesJapan}
                    />
                  </div>
                </div>
                <div className="mt-5 mb-6">
                  <h4 className="title is-4">▼{lecture2Lecture}</h4>
                  <div>
                    <TableShift
                      lectureKey="lecture_2"
                      formMethods={formMethods}
                      lectureItemForShifts={workShift?.shift?.lecture_2}
                      staffs={filteredStaffs}
                      studentBasics={studentBasics}
                      date={date}
                      isNotesJapan={isNotesJapan}
                    />
                  </div>
                </div>
                <div className="mt-5 mb-6">
                  <h4 className="title is-4">▼{lecture3Lecture}</h4>
                  <div>
                    <TableShift
                      lectureKey="lecture_3"
                      formMethods={formMethods}
                      lectureItemForShifts={workShift?.shift?.lecture_3}
                      staffs={filteredStaffs}
                      studentBasics={studentBasics}
                      date={date}
                      isNotesJapan={isNotesJapan}
                    />
                  </div>
                </div>
                <div className="mt-5 mb-6">
                  <h4 className="title is-4">▼{lecture4Lecture}</h4>
                  <div>
                    <TableShift
                      lectureKey="lecture_4"
                      formMethods={formMethods}
                      lectureItemForShifts={workShift?.shift?.lecture_4}
                      staffs={filteredStaffs}
                      studentBasics={studentBasics}
                      date={date}
                      isNotesJapan={isNotesJapan}
                    />
                  </div>
                </div>
                <div className="mt-5 mb-6">
                  <h4 className="title is-4">▼{lecture5Lecture}</h4>
                  <div>
                    <TableShift
                      lectureKey="lecture_5"
                      formMethods={formMethods}
                      lectureItemForShifts={workShift?.shift?.lecture_5}
                      staffs={filteredStaffs}
                      studentBasics={studentBasics}
                      date={date}
                      isNotesJapan={isNotesJapan}
                    />
                  </div>
                </div>
                <div className="mt-5 mb-6">
                  <h4 className="title is-4">▼特別授業</h4>
                  <div>
                    <TableShift
                      lectureKey="lecture_special"
                      formMethods={formMethods}
                      lectureItemForShifts={workShift?.shift?.lecture_special}
                      staffs={filteredStaffs}
                      studentBasics={studentBasics}
                      date={date}
                      isNotesJapan={isNotesJapan}
                    />
                  </div>
                </div>
                <div className="mt-5 mb-6">
                  <h4 className="title is-4">▼勤務状況</h4>
                  <div>
                    <div className="mb-2">
                      <Select
                        selectProps={{
                          options:
                            filteredStaffs && filteredStaffs.length
                              ? filteredStaffs.map((staff) => ({
                                  label: `${staff.last_name} ${staff.first_name}`,
                                  value: staff.id,
                                }))
                              : undefined,
                          value: addTargetStaff
                            ? {
                                label: `${addTargetStaff.last_name} ${addTargetStaff.first_name}`,
                                value: addTargetStaff.id,
                              }
                            : null,
                          onChange: (value) => {
                            if (!value) {
                              setAddTargetStaff(null);
                            } else {
                              setAddTargetStaff(
                                value && 'value' in value
                                  ? filteredStaffs.find(
                                      (staff) => staff.id === value.value,
                                    ) ?? null
                                  : null,
                              );
                            }
                          },
                        }}
                      />
                      <button
                        type="button"
                        onClick={addWork}
                        className="button is-light"
                      >
                        追加
                      </button>
                    </div>
                    <TableContainer>
                      <Table className="works-table">
                        <TableHead>
                          <TableRow>
                            <TableCell>講師名</TableCell>
                            <TableCell>コマ時間</TableCell>
                            <TableCell>準備・片付け</TableCell>
                            <TableCell>夕礼</TableCell>
                            <TableCell>事務・研修</TableCell>
                            <TableCell>勉強合宿</TableCell>
                            <TableCell>遅刻時間</TableCell>
                            <TableCell>早退時間</TableCell>
                            <TableCell>合計</TableCell>
                            <TableCell>備考</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {works &&
                            works.map((work, index) => (
                              <TableRow key={work.teacher_id}>
                                <TableCell>
                                  {getStaffName(work?.teacher_id ?? '')}
                                </TableCell>
                                <TableCell>
                                  {watch(`works.${index}.lecture_time`)
                                    ? watch(`works.${index}.lecture_time`)
                                    : work.lecture_time ?? 0}
                                  時間
                                </TableCell>
                                <TableCell>
                                  <div className="has-text-centered">
                                    <input
                                      type="checkbox"
                                      className="checkbox"
                                      name={`works.${index}.preparation_clean_up`}
                                      checked={
                                        works[index].preparation_clean_up
                                      }
                                      onChange={(e) => {
                                        onChangeSumTime(index, {
                                          preparation_clean_up:
                                            e.target.checked,
                                        });
                                      }}
                                    />
                                    <br />(
                                    {getPreparationCleanUpTime(isNotesJapan)}
                                    時間)
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="has-text-centered">
                                    <input
                                      type="checkbox"
                                      className="checkbox"
                                      name={`works.${index}.greeting`}
                                      checked={works[index].greeting}
                                      onChange={(e) => {
                                        onChangeSumTime(index, {
                                          greeting: e.target.checked,
                                        });
                                      }}
                                    />
                                    <br />({GREETING_TIME}時間)
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <input
                                    type="number"
                                    value={
                                      watch(
                                        `works.${index}.office_training_time`,
                                      ) ??
                                      works[index].office_training_time ??
                                      0
                                    }
                                    name={`works.${index}.office_training_time`}
                                    step="0.01"
                                    min="0"
                                    ref={register()}
                                    onChange={() => {
                                      onChangeSumTime(index);
                                    }}
                                    className="input"
                                  />
                                  <p className="mt-1 has-text-right">時間</p>
                                </TableCell>
                                <TableCell>
                                  <input
                                    type="number"
                                    value={
                                      watch(`works.${index}.study_camp_time`) ??
                                      works[index].study_camp_time ??
                                      0
                                    }
                                    name={`works.${index}.study_camp_time`}
                                    step="0.01"
                                    min="0"
                                    ref={register()}
                                    onChange={() => {
                                      onChangeSumTime(index);
                                    }}
                                    className="input"
                                  />
                                  <p className="mt-1 has-text-right">時間</p>
                                </TableCell>
                                <TableCell>
                                  <input
                                    type="number"
                                    value={
                                      watch(`works.${index}.behind_time`) ??
                                      works[index].behind_time ??
                                      0
                                    }
                                    name={`works.${index}.behind_time`}
                                    step="0.01"
                                    min="0"
                                    ref={register()}
                                    onChange={() => {
                                      onChangeSumTime(index);
                                    }}
                                    className="input"
                                  />
                                  <p className="mt-1 has-text-right">時間</p>
                                </TableCell>
                                <TableCell>
                                  <input
                                    type="number"
                                    value={
                                      watch(
                                        `works.${index}.early_leave_time`,
                                      ) ??
                                      works[index].early_leave_time ??
                                      0
                                    }
                                    name={`works.${index}.early_leave_time`}
                                    step="0.01"
                                    min="0"
                                    ref={register()}
                                    onChange={() => {
                                      onChangeSumTime(index);
                                    }}
                                    className="input"
                                  />
                                  <p className="mt-1 has-text-right">時間</p>
                                </TableCell>
                                <TableCell>
                                  {watch(`works.${index}.sum_time`)
                                    ? watch(`works.${index}.sum_time`)
                                    : work.sum_time ?? 0}
                                  時間
                                </TableCell>
                                <TableCell>
                                  <Textarea
                                    textareaProps={{
                                      value: watch(`works.${index}.remarks`)
                                        ? watch(`works.${index}.remarks`)
                                        : work.remarks,
                                      name: `works.${index}.remarks`,
                                      ref: register(),
                                      onChange: (e) => {
                                        const { value } = e.target;
                                        const newWorks = _.cloneDeep(works);
                                        newWorks[index].remarks = value;
                                        newWorks.splice(
                                          index,
                                          1,
                                          newWorks[index],
                                        );
                                        setWorks(newWorks);
                                        setValue(
                                          `works.${index}.remarks`,
                                          value,
                                        );
                                      },
                                      rows: 1,
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  {isRemovable(work.teacher_id) ? (
                                    <div>
                                      <button
                                        type="button"
                                        onClick={() => removeWork(index)}
                                        className="button is-light"
                                      >
                                        削除
                                      </button>
                                    </div>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
              {workShift ? (
                <div className="btn-area">
                  <Button
                    buttonProps={{
                      type: 'button',
                      onClick: () => history.push('/'),
                    }}
                    className={clsx('with-icon', 'btn-primary')}
                  >
                    <RiArrowGoBackFill />
                    戻る
                  </Button>
                  <Button
                    buttonProps={{ type: 'submit', disabled: isViewer }}
                    color="primary"
                    className={clsx('with-icon', 'btn-primary')}
                  >
                    <FaSave />
                    保存
                  </Button>
                </div>
              ) : null}
            </form>
          )}
        </>
      )}
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default WorkShiftDetailForm;
