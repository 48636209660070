import { useAsync } from 'react-use';
import { Dispatch, SetStateAction, useContext, useState } from 'react';

import getStudentBasic from '../../../domain/services/get-student-basics';
import getMiddleSchoolExam from '../../../domain/services/get-middle-school-exam';
import { StudentBasic } from '../../../domain/models/student-basic';
import { MiddleSchoolExamList } from '../../../types/table/middle-school-exam-list';
import { QueryParams } from '../../../types/firestore-simple';
import {
  defaultMiddleSchoolExamSubjects,
  defaultMiddleSchoolExamValues,
  MiddleSchoolExamKeys,
  middleSchoolExamLabels,
} from '../../../data/form-data';
import {
  getIcon,
  getLibraryCardPoint,
  getPrevKey,
  getSubjectScores,
  getTotalScore,
  MiddleSchoolExam,
} from '../../../domain/models/middle-school-exam';
import { AppContext } from '../../context/AppContext';

export type Result = {
  loading: boolean;
  middleSchoolExamLists: MiddleSchoolExamList[] | undefined;
  error: Error | undefined;
  examKey: MiddleSchoolExamKeys;
  setExamKey: Dispatch<SetStateAction<MiddleSchoolExamKeys>>;
  getLibraryCardIcon: (
    rowIndex: number,
    rowValues: MiddleSchoolExamList,
  ) => JSX.Element | null;
};

const useFetchMiddleSchoolExamLists = (): Result => {
  const { adminUser } = useContext(AppContext);
  const [examKey, setExamKey] = useState<MiddleSchoolExamKeys>(
    (localStorage.getItem('selectedExamKey') as MiddleSchoolExamKeys) ??
      (Object.keys(middleSchoolExamLabels)[0] as MiddleSchoolExamKeys),
  );
  const [exams, setExams] = useState<MiddleSchoolExam[]>([]);

  const { loading, error, value } = useAsync(async () => {
    const defaultQueryParams: QueryParams<StudentBasic> = [
      { fieldPath: 'status', opStr: '==', value: 'enrolled' },
      { fieldPath: 'grade', opStr: 'in', value: ['中1', '中2', '中3'] },
    ];
    if (['editor', 'viewer'].includes(adminUser.role)) {
      defaultQueryParams.push({
        fieldPath: 'enrolled_cram_school',
        opStr: '==',
        value: adminUser.enrolled_cram_school,
      });
    }
    const { studentBasics } = await getStudentBasic({
      queryParams: defaultQueryParams,
    });
    const lists: MiddleSchoolExamList[] = await Promise.all(
      studentBasics.map(
        async (student): Promise<MiddleSchoolExamList> => {
          const { middleSchoolExam } = await getMiddleSchoolExam({
            queryParams: [
              { fieldPath: 'student_id', opStr: '==', value: student.id },
            ],
          });
          setExams((prevState) => {
            return [...prevState, middleSchoolExam[0]];
          });
          const currentScores =
            middleSchoolExam.length && examKey in middleSchoolExam[0]
              ? getSubjectScores(middleSchoolExam[0][examKey])
              : defaultMiddleSchoolExamSubjects;
          const list = {
            icon: null,
            id: middleSchoolExam.length ? middleSchoolExam[0].id : '',
            middleSchoolExam: middleSchoolExam[0],
            studentBasic: student,
            examKey,
            student_id: student.id,
            student_num: student.student_num,
            name: `${student.last_name} ${student.first_name}`,
            grade: student.grade,
            enrolled_cram_school: student.enrolled_cram_school,
            school_name: student.school_name,
            ...currentScores,
            total: Object.values(currentScores).reduce(
              (prev, current) => prev + current,
              0,
            ),
          };
          const prevExamKey = getPrevKey(
            defaultMiddleSchoolExamValues,
            examKey,
          ) as MiddleSchoolExamKeys;

          const prevScores =
            middleSchoolExam.length && prevExamKey in middleSchoolExam[0]
              ? getSubjectScores(middleSchoolExam[0][prevExamKey])
              : undefined;

          const prevTotal =
            middleSchoolExam.length && prevExamKey in middleSchoolExam[0]
              ? getTotalScore(middleSchoolExam[0][prevExamKey])
              : 0;

          return {
            ...list,
            icon: getIcon(currentScores, prevScores),
            prevTotal,
            prevScores,
            libraryCardPoint: getLibraryCardPoint(currentScores, prevScores),
          };
        },
      ),
    );

    return lists;
  }, [examKey]);

  const getLibraryCardIcon = (
    rowIndex: number,
    rowValues: MiddleSchoolExamList,
  ) => {
    if (!value) return null;

    const prevExamKey = getPrevKey(
      defaultMiddleSchoolExamValues,
      examKey,
    ) as MiddleSchoolExamKeys;

    const currentScores =
      exams.length && exams[rowIndex] && examKey in exams[rowIndex]
        ? getSubjectScores({
            japanese: rowValues.japanese,
            math: rowValues.math,
            english: rowValues.english,
            society: rowValues.society,
            science: rowValues.science,
          })
        : defaultMiddleSchoolExamSubjects;
    const prevScores =
      exams.length && exams[rowIndex] && prevExamKey in exams[rowIndex]
        ? getSubjectScores(exams[rowIndex][prevExamKey])
        : undefined;

    return getIcon(currentScores, prevScores);
  };

  return {
    loading,
    error,
    middleSchoolExamLists: value,
    examKey,
    setExamKey,
    getLibraryCardIcon,
  };
};

export default useFetchMiddleSchoolExamLists;
