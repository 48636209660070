import firestoreSimple from '../../lib/firebase-firestore';
import { SystemInformation } from '../models/SystemInformation';

const getSystemInformation = async (): Promise<{
  systemInformation?: SystemInformation[];
}> => {
  const collection = firestoreSimple.collection<SystemInformation>({
    path: 'system_information',
  });
  const docs = await collection
    .orderBy('date', 'desc')
    .fetch()
    .catch((error) => console.error(error));
  const systemInformation = docs instanceof Array ? docs : undefined;

  return { systemInformation };
};

export default getSystemInformation;
