import firestoreSimple from '../../lib/firebase-firestore';
import { WorkShift } from '../models/work-shift';

const bulkCreateWorkShift = async (
  workShifts: WorkShift[],
): Promise<boolean> => {
  try {
    const collection = firestoreSimple.collection<WorkShift>({
      path: 'work_shift',
    });

    const addData = workShifts.filter((workShift) => {
      return !workShift.id;
    });

    await collection.bulkAdd(addData).catch((error) => console.error(error));

    const setData = workShifts.filter((workShift) => {
      return workShift.id;
    });

    await collection.bulkSet(setData).catch((error) => console.error(error));

    return true;
  } catch (e) {
    console.error(e);

    return false;
  }
};

export default bulkCreateWorkShift;
