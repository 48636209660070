import React, {
  PropsWithChildren,
  useCallback,
  useState,
  useContext,
} from 'react';
import { Row, TableInstance } from 'react-table';
import {
  Popper,
  IconButton,
  ClickAwayListener,
  Select,
  MenuItem,
  ButtonProps,
} from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import {
  RiFilter3Fill,
  GrDocumentCsv,
  AiFillCaretLeft,
  AiFillCaretRight,
} from 'react-icons/all';
import { useLocation } from 'react-router-dom';

import Button from '../../../atoms/button/Button';
import { FilterContext } from '../../../../context/FilterContext';
import { YearMonthContext } from '../../../../context/YearMonthContext';
import useRole from '../../../../hooks/useRole';
import BulkChange from './BulkChange';

// eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/explicit-module-boundary-types
const Toolbar = <T extends {}>({
  instance: { columns, setAllFilters, rows },
  selectProps,
  selectProps2,
  yearMonthOnClicks,
  exportCsvHelper,
  pageName,
}: PropsWithChildren<{
  instance: TableInstance<T>;
  selectProps?: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
  selectProps2?: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
  yearMonthOnClicks?: {
    onClickPreviousMonth: ButtonProps['onClick'];
    onClickNextMonth: ButtonProps['onClick'];
  };
  // eslint-disable-next-line no-shadow
  exportCsvHelper?: (rows: Row<T>[], fileName?: string) => void;
  pageName?: string;
}>) => {
  const { pathname } = useLocation();
  const { filters, setFilters, filterKey } = useContext(FilterContext);
  const { isViewer } = useRole();
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLAnchorElement | null
  >(null);
  const { yearMonth } = useContext(YearMonthContext);

  const resetFilters = useCallback(() => {
    setAllFilters([]);
    filters.delete(filterKey);
    setFilters(filters);
  }, [setAllFilters, setFilters, filters, filterKey]);

  let fileNameExportCsv: string | undefined;
  switch (pageName) {
    case 'salary-list': {
      fileNameExportCsv = yearMonth;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div className="table-toolbar">
        <Popper
          open={Boolean(anchorEl)}
          placement="bottom-end"
          anchorEl={anchorEl}
          className="filter-box"
        >
          <div className="table-filters">
            {columns
              .filter((it) => it.isVisible && it.canFilter)
              .map((column) => (
                <div className="field" key={column.id}>
                  {column.render('Filter')}
                </div>
              ))}
          </div>
          <Button
            buttonProps={{ type: 'button', onClick: resetFilters }}
            color="secondary"
          >
            RESET
          </Button>
        </Popper>
        {yearMonthOnClicks ? (
          <div className="year-month-on-clicks">
            <IconButton
              className="select-button"
              onClick={yearMonthOnClicks.onClickPreviousMonth}
            >
              <AiFillCaretLeft />
            </IconButton>
            <div className="month-label">{yearMonth}</div>
            <IconButton
              className="select-button"
              onClick={yearMonthOnClicks.onClickNextMonth}
            >
              <AiFillCaretRight />
            </IconButton>
          </div>
        ) : null}
        {pageName ? <BulkChange pageName={pageName} rows={rows} /> : null}
        {selectProps ? (
          <Select
            label={selectProps.label}
            value={selectProps.value}
            onChange={selectProps.onChange}
            className="toolbar-select"
          >
            {selectProps.options.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        ) : null}
        {selectProps2 ? (
          <Select
            label={selectProps2.label}
            value={selectProps2.value}
            onChange={selectProps2.onChange}
            className="toolbar-select"
          >
            {selectProps2.options.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        ) : null}
        {exportCsvHelper && (
          <IconButton
            area-label="CSV"
            onClick={() =>
              fileNameExportCsv
                ? exportCsvHelper(rows, fileNameExportCsv)
                : exportCsvHelper(rows)
            }
          >
            <GrDocumentCsv />
          </IconButton>
        )}
        <IconButton
          area-label="Filter"
          onClick={(event) =>
            setAnchorEl(anchorEl ? null : event.currentTarget)
          }
          disabled={pathname === '/staff' && isViewer}
        >
          <RiFilter3Fill />
        </IconButton>
      </div>
    </ClickAwayListener>
  );
};

export default Toolbar;
