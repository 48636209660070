import { useState } from 'react';
import useSnackbar, { SnackbarState } from './useSnackbar';
import getStudentBasics from '../../domain/services/get-student-basics';
import getStudentInvoices from '../../domain/services/get-student-invoices';
import createStudentInvoice from '../../domain/services/create-student-invoice';

export type Result = {
  importing: boolean;
  importCsv: (invoiceMonth: string, file: File) => void;
  state: SnackbarState;
  close: () => void;
};

const useImportWithdrawalResultsCsv = (): Result => {
  const [importing, setImporting] = useState(false);
  const { open, close, state } = useSnackbar();

  const importCsv = (invoiceMonth: string, file: File) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async () => {
      setImporting(true);
      // CSVを配列に変換
      // SJIS想定だが現状必要な項目に影響がない為このまま読み込む
      const csv = ((reader.result as string) || '')
        .split(/\r\n|\n/)
        .map((row) => {
          return row.split(',');
        });
      // 引落結果データの抽出(データヘッダ/フッタを取り除く)
      const withdrawalResults = csv.filter((row) => {
        // レコードの先頭がレコード種別だと思われる
        return row[0] === '2';
      });
      // 全生徒情報の取得
      const { studentBasics } = await getStudentBasics({});
      // 請求情報更新Promise
      const updateResult = (
        withdrawalResultColumns: string[],
      ): Promise<void> => {
        return new Promise((resolve, _) => {
          const studentNum = withdrawalResultColumns[10];
          const studentId = studentBasics.find((studentBasic) => {
            return studentNum === studentBasic.student_num;
          })?.id;
          // 該当生徒情報がなければスキップ
          if (!studentId) {
            resolve();

            return;
          }

          void (async () => {
            const { studentInvoices } = await getStudentInvoices({
              queryParams: [
                {
                  fieldPath: 'student_id',
                  opStr: '==',
                  value: studentId,
                },
                {
                  fieldPath: 'invoice_month',
                  opStr: '==',
                  value: invoiceMonth, // 画面上の請求年月
                },
              ],
            });
            const amount = withdrawalResultColumns[8];
            const result = withdrawalResultColumns[11] === '0';
            // 請求データが作成されていない/ステータスが未確定/引落結果が1回でも反映されている/金額に乖離があるのいずれかの場合スキップ
            if (
              !studentInvoices.length ||
              studentInvoices[0].status !== 'publishing' ||
              studentInvoices[0].withdrawal_result !== null ||
              Number(studentInvoices[0].total) !== Number(amount)
            ) {
              resolve();

              return;
            }

            await createStudentInvoice({
              ...studentInvoices[0],
              withdrawal_result: result,
            });

            resolve();
          })();
        });
      };

      const proc = withdrawalResults.map((result) => updateResult(result));

      await Promise.all(proc).finally(() => {
        open('success', 'CSVのインポートに成功しました');
        setImporting(false);
      });
    };
    reader.onerror = () => {
      open(
        'error',
        'CSVのインポートができませんでした。もう一度お試しください。',
      );
      setImporting(false);
    };
  };

  return { importing, importCsv, state, close };
};

export default useImportWithdrawalResultsCsv;
