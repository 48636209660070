import React, { FC, useContext } from 'react';
import { DateTime } from 'luxon';
import twas from 'twas';
import clsx from 'clsx';

import { ParentInfo } from '../../../../../domain/models/parent-info';
import { StudentBasic } from '../../../../../domain/models/student-basic';
import { Room } from '../../../../../domain/models/rooms';
import { ChatContext } from '../../../../context/ChatContext';
import { STUDENT_STATUS_LABELS } from '../../../../../data/form-data';

interface Props {
  room: Room;
  parentInfo: ParentInfo;
  studentBasics: StudentBasic[];
}

const RoomItem: FC<Props> = ({ room, parentInfo, studentBasics }) => {
  const { selectedRoom, setSelectedRoom } = useContext(ChatContext);
  const getTimeDiff = (date: Date | null) => {
    if (!date) return '';

    const now = DateTime.fromJSDate(new Date());
    const datetime = DateTime.fromJSDate(date);
    const diff = datetime.diff(now).toObject().milliseconds || 0;

    return twas(now.toMillis() - -1 * diff);
  };

  return (
    <button
      type="button"
      className={clsx('room-item', { 'is-selected': selectedRoom === room.id })}
      onClick={() => setSelectedRoom(room.id)}
    >
      {parentInfo.id ? (
        <div>
          <div className="room-head">
            <h3 className="title">{parentInfo.name}</h3>
            <div className="time">
              {getTimeDiff(room.update_date && room.update_date.toDate())}
            </div>
          </div>
          <div className="room-meta">
            {studentBasics.length ? (
              <ul>
                {studentBasics.map((student) => (
                  <li key={student.id}>
                    {`${student.student_num} ${
                      STUDENT_STATUS_LABELS[student.status]
                    } ${student.last_name}${student.first_name}`}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
      ) : (
        <div>
          <div className="room-head">
            <h3 className="title">社員チャット</h3>
            <div className="time">
              {getTimeDiff(room.update_date && room.update_date.toDate())}
            </div>
          </div>
        </div>
      )}
    </button>
  );
};

export default RoomItem;
