import { Column } from 'react-table';
import React from 'react';

import { MiddleSchoolExamList } from '../../../../../types/table/middle-school-exam-list';
import SelectColumnFilter from '../SelectColumnFilter';
import sortScoreListByGrade from '../../../../../helpers/sort/sortMiddleSchoolExamByGrade';
import BetweenSliderColumnFilter from '../BetweenSliderColumnFilter';
import EditableCell from './EditableCell';
import TotalCell from './TotalCell';
import CheckboxColumnColumnFilter from '../CheckboxColumnFilter';

const columns: Column<MiddleSchoolExamList>[] = [
  {
    Header: '',
    accessor: 'icon',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: '',
    accessor: 'id',
  },
  {
    Header: '',
    accessor: 'student_id',
  },
  {
    Header: '',
    accessor: 'examKey',
  },
  {
    Header: '',
    accessor: 'middleSchoolExam',
  },
  {
    Header: '',
    accessor: 'studentBasic',
  },
  {
    Header: '生徒番号',
    accessor: 'student_num',
    filter: 'includesStringFilter',
  },
  { Header: '名前', accessor: 'name', filter: 'includesStringFilter' },
  {
    Header: '学年',
    accessor: 'grade',
    filter: 'includeSelectValue',
    Filter: SelectColumnFilter,
    sortType: sortScoreListByGrade,
  },
  {
    Header: '在籍校舎',
    accessor: 'enrolled_cram_school',
    filter: 'includeSelectValue',
    Filter: SelectColumnFilter,
  },
  {
    Header: '在籍学校',
    accessor: 'school_name',
    filter: 'includeSelectValue',
    Filter: SelectColumnFilter,
  },
  {
    Header: '英語',
    accessor: 'english',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '数学',
    accessor: 'math',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '国語',
    accessor: 'japanese',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '理科',
    accessor: 'science',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '社会',
    accessor: 'society',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '合計',
    accessor: 'total',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={500} />
    ),
    Cell: TotalCell,
  },
  {
    Header: '図書カードをもらう生徒のみ',
    accessor: 'libraryCardPoint',
    filter: 'libraryCardPointFilter',
    Filter: (filterProps) => (
      <CheckboxColumnColumnFilter filterProps={filterProps} />
    ),
  },
];

export default columns;
