import { Column } from 'react-table';
import React from 'react';

import { MiddleSchoolReportList } from '../../../../../types/table/middle-school-report-list';
import SelectColumnFilter from '../SelectColumnFilter';
import sortMiddleSchoolReportByGrade from '../../../../../helpers/sort/sortMiddleSchoolReportByGrade';
import BetweenSliderColumnFilter from '../BetweenSliderColumnFilter';
import EditableCell from './EditableCell';
import TotalCell from './TotalCell';

const columns: Column<MiddleSchoolReportList>[] = [
  {
    Header: '',
    accessor: 'id',
  },
  {
    Header: '',
    accessor: 'student_id',
  },
  {
    Header: '',
    accessor: 'reportKey',
  },
  {
    Header: '',
    accessor: 'middleSchoolReport',
  },
  {
    Header: '',
    accessor: 'studentBasic',
  },
  {
    Header: '生徒番号',
    accessor: 'student_num',
    filter: 'includesStringFilter',
  },
  { Header: '名前', accessor: 'name', filter: 'includesStringFilter' },
  {
    Header: '学年',
    accessor: 'grade',
    filter: 'includeSelectValue',
    Filter: SelectColumnFilter,
    sortType: sortMiddleSchoolReportByGrade,
  },
  {
    Header: '在籍校舎',
    accessor: 'enrolled_cram_school',
    filter: 'includeSelectValue',
    Filter: SelectColumnFilter,
  },
  {
    Header: '在籍学校',
    accessor: 'school_name',
    filter: 'includeSelectValue',
    Filter: SelectColumnFilter,
  },
  {
    Header: '国語',
    accessor: 'japanese',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '社会',
    accessor: 'society',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '数学',
    accessor: 'math',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '理科',
    accessor: 'science',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '音楽',
    accessor: 'music',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '美術',
    accessor: 'art',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '保体',
    accessor: 'health_physical',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '技家',
    accessor: 'tech_home',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '英語',
    accessor: 'english',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '合計',
    accessor: 'total',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={45} />
    ),
    Cell: TotalCell,
  },
];

export default columns;
