import { Column } from 'react-table';
import React, { FC, useMemo } from 'react';

import { StudentList } from '../../../../../types/table/student-list';
import SelectColumnFilter from '../../../../components/molecules/table/SelectColumnFilter';
import StudentListComponent from '../../../../components/molecules/table/StudentList';
import useFetchStudentLists from '../../../../hooks/fetch/useFetchStudentLists';

const StudentListContainer: FC = () => {
  const { loading, studentList } = useFetchStudentLists();

  const columns: Column<StudentList>[] = useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'studentId',
      },
      {
        Header: '生徒ステータス',
        accessor: 'status',
        filter: 'includeSelectValue',
        Filter: SelectColumnFilter,
      },
      {
        Header: '生徒番号',
        accessor: 'student_num',
        filter: 'includesStringFilter',
      },
      {
        Header: '名前',
        accessor: 'name',
        filter: 'includesStringFilter',
        disableSortBy: true,
      },
      {
        Header: '学年',
        accessor: 'grade',
        filter: 'includeSelectValue',
        Filter: SelectColumnFilter,
        disableSortBy: true,
      },
      {
        Header: '在籍学校',
        accessor: 'school_name',
        filter: 'includeSelectValue',
        Filter: SelectColumnFilter,
        disableSortBy: true,
      },
      {
        Header: '在籍校舎',
        accessor: 'enrolled_cram_school',
        filter: 'includeSelectValue',
        Filter: SelectColumnFilter,
        disableSortBy: true,
      },
    ];
  }, []);

  return (
    <StudentListComponent
      loading={loading}
      studentList={studentList}
      columns={columns}
    />
  );
};

export default StudentListContainer;
