export default (csv: string, fileName = 'export') => {
  const UTF_8_BOM = '%EF%BB%BF';
  const data = `data:text/csv;charset=utf-8,${UTF_8_BOM}${encodeURIComponent(
    csv,
  )}`;
  const link = document.createElement('a');
  link.href = data;
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
  if (link) {
    link.remove();
  }
};
