import React from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer, Toolbar, List } from '@material-ui/core';

import './style.scss';
import useLinkItems from '../../../hooks/useLinkItems';
import ListItemLink from '../../atoms/utility/ListItemLink';

export default () => {
  const { pathname } = useLocation();
  const { linkItems } = useLinkItems();

  return (
    <Drawer
      variant="permanent"
      className="drawer"
      classes={{ paper: 'drawer-inner' }}
    >
      <Toolbar className="toolbar" />
      <div>
        <List>
          {linkItems.map((item) => (
            <ListItemLink
              key={item.id}
              id={item.id}
              Icon={item.icon}
              text={item.title}
              linkProps={{ to: item.path }}
              selected={pathname === item.path}
            />
          ))}
        </List>
      </div>
    </Drawer>
  );
};
