import React, { FC } from 'react';

import './style.scss';
import { SystemInformation } from '../../../../../domain/models/SystemInformation';
import Information from '../../../../containers/atoms/Information';
import DotProgress from '../../../atoms/feedback/DotProgress';

interface Props {
  systemInformation: SystemInformation[];
  loading: boolean;
  error: Error | undefined;
}

const SystemInformationComponent: FC<Props> = ({
  systemInformation,
  loading,
  error,
}) => {
  let children = (
    <div className="informations">
      {systemInformation.map((info) => (
        <Information
          key={`${info.date.toDate().toDateString()}-${info.category}-${
            info.title
          }`}
          date={info.date}
          category={info.category}
          title={info.title}
        />
      ))}
    </div>
  );
  if (!systemInformation.length) children = <p>システム情報がありません</p>;
  if (error) children = <p>システム情報が取得できませんでした</p>;
  if (loading) children = <DotProgress />;

  return (
    <div className="system-information card">
      <h2 className="title">システム情報</h2>
      {children}
    </div>
  );
};

export default SystemInformationComponent;
