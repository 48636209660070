import React, { FC, ReactNode } from 'react';

import DotProgress from '../feedback/DotProgress';
import './style.scss';

interface Props {
  loading: boolean;
  title: ReactNode;
  href: string;
  number: number;
}

const DataCounter: FC<Props> = ({ loading, title, href, number }) => {
  return (
    <div className="card data-counter">
      <a href={href}>
        <h2 className="title">{title}</h2>
        {loading ? (
          <DotProgress />
        ) : (
          <div className="count">
            <span className="num">{number}</span>
            <span className="util">件</span>
          </div>
        )}
      </a>
    </div>
  );
};

export default DataCounter;
