import React, { FC, useState } from 'react';
import { useAsync } from 'react-use';
import firebase from 'firebase';
import { CircularProgress } from '@material-ui/core';
import { AiOutlineDownload, AiOutlineInfoCircle } from 'react-icons/all';

import clsx from 'clsx';
import { storage } from '../../../../../lib/firebase';
import './style.scss';

interface Props {
  storagePath: string;
  isCurrentUser: boolean;
}

const StorageFile: FC<Props> = ({ storagePath, isCurrentUser }) => {
  const [metadata, setMetadata] = useState<firebase.storage.FullMetadata>();
  const [downloadUrl, setDownloadUrl] = useState('');

  const { loading, error } = useAsync(async () => {
    if (!storagePath) return;

    const storageRef = storage.ref();
    const childRef = storageRef.child(storagePath);
    const meta = (await childRef.getMetadata()) as firebase.storage.FullMetadata;
    const dlUrl = (await childRef.getDownloadURL()) as string;
    setMetadata(meta);
    setDownloadUrl(dlUrl);
  }, [storagePath]);

  if (error) {
    console.error(error);

    return <div>ファイルが読み込めませんでした</div>;
  }

  if (loading) return <CircularProgress />;

  return (
    <div className={clsx('talk-file', { 'current-user': isCurrentUser })}>
      <p className="info-title">
        <AiOutlineInfoCircle />
        <span>ファイルをアップロードしました</span>
      </p>
      {metadata?.contentType?.includes('image') ? (
        <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
          <img src={downloadUrl} alt="" className="image" />
        </a>
      ) : (
        <div className="pdf">
          <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
            <AiOutlineDownload />
            <span>{metadata?.name}</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default StorageFile;
