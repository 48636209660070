import React, { FC } from 'react';
import { Column } from 'react-table';

import { ParentList } from '../../../../../types/table/parent-list';
import ParentListComponent from '../../../../components/molecules/table/ParentList';
import useFetchParents from '../../../../hooks/fetch/useFetchParents';

const columns: Column<ParentList>[] = [
  { Header: '生徒', accessor: 'students', filter: 'studentsFilter' },
  { Header: '保護者', accessor: 'name' },
  {
    Header: '続柄',
    accessor: 'relationship',
    disableFilters: true,
  },
  { Header: '電話番号', accessor: 'tel', disableFilters: true },
  {
    Header: '緊急連絡先',
    accessor: 'emergency_contacts',
    disableFilters: true,
  },
];

const ParentListContainer: FC = () => {
  const { loading, parentList } = useFetchParents();

  return (
    <ParentListComponent
      loading={loading}
      parentList={parentList}
      columns={columns}
    />
  );
};

export default ParentListContainer;
