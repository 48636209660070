import React, { FC } from 'react';
import { Dot as RechartsDot, DotProps } from 'recharts';
import { FaSadTear } from 'react-icons/all';
import { middleSchoolReportLabels } from '../../../../../../data/form-data';
import {
  getPrevKey,
  MiddleSchoolReportScores,
} from '../../../../../../domain/models/middle-school-report';

type Payload = MiddleSchoolReportScores & {
  reportKey: string;
  total: number;
};

interface Props {
  dotProps: { [k: string]: any } & DotProps & {
      payload: Payload;
    };
  data: Payload[];
}

const Dot: FC<Props> = ({ dotProps, data }) => {
  const { cx, cy, payload } = dotProps;
  const x = typeof cx === 'number' ? cx - 10 : 0;
  const y = typeof cy === 'number' ? cy - 10 : 0;
  const dataKey = dotProps.dataKey as keyof MiddleSchoolReportScores | 'total';

  const { reportKey } = payload;
  const lastTimeKey = getPrevKey(middleSchoolReportLabels, reportKey);
  const lastTimeBeforeKey = lastTimeKey
    ? getPrevKey(middleSchoolReportLabels, lastTimeKey)
    : undefined;

  const currentScores = payload;
  const lastTimeScores = lastTimeKey
    ? data.find((d) => d.reportKey === lastTimeKey)
    : undefined;
  const lastTimeBeforeScores = lastTimeBeforeKey
    ? data.find((d) => d.reportKey === lastTimeBeforeKey)
    : undefined;

  switch (dataKey) {
    case 'total': {
      const currentTotal = currentScores.total;
      const lastTimeTotal = lastTimeScores?.total;
      const lastTimeBeforeTotal = lastTimeBeforeScores?.total;
      if (
        typeof lastTimeTotal === 'number' &&
        typeof lastTimeBeforeTotal === 'number' &&
        currentTotal > 0 &&
        currentTotal - lastTimeTotal < 0 &&
        lastTimeTotal - lastTimeBeforeTotal < 0
      ) {
        return (
          <FaSadTear
            x={x}
            y={y}
            fontSize={40}
            fill="red"
            viewBox="0 0 1024 1024"
          />
        );
      }
      break;
    }
    default: {
      const currentScore = currentScores[dataKey];
      const lastTimeScore = lastTimeScores
        ? lastTimeScores[dataKey]
        : undefined;
      const lastTimeBeforeScore = lastTimeBeforeScores
        ? lastTimeBeforeScores[dataKey]
        : undefined;

      if (
        typeof lastTimeScore === 'number' &&
        typeof lastTimeBeforeScore === 'number' &&
        currentScores.total > 0 &&
        currentScore - lastTimeScore < 0 &&
        lastTimeScore - lastTimeBeforeScore < 0
      ) {
        return (
          <FaSadTear
            x={x}
            y={y}
            fontSize={40}
            fill="red"
            viewBox="0 0 1024 1024"
          />
        );
      }
    }
  }

  return <RechartsDot {...dotProps} />;
};

export default Dot;
