import React, { FC, PropsWithChildren, useContext } from 'react';
import {
  Cell,
  CellValue,
  ColumnInstance,
  Row,
  TableInstance,
} from 'react-table';
import { useDebounce } from 'react-use';

import { MiddleSchoolExam } from '../../../../../domain/models/middle-school-exam';
import { MiddleSchoolExamList } from '../../../../../types/table/middle-school-exam-list';
import { defaultMiddleSchoolExamValues } from '../../../../../data/form-data';
import { ScoreExamContext } from '../../../../context/ScoreExamContext';
import useRole from '../../../../hooks/useRole';

const MAX = 100;
const MIN = 0;

const getNewValue = (value: string | number) => {
  let newValue = Number(value);
  if (Number.isNaN(newValue)) {
    return MIN;
  }

  if (newValue > MAX) {
    newValue = MAX;
  } else if (newValue < MIN) {
    newValue = MIN;
  }

  return newValue;
};

const getNewTableData = (
  cell: Cell<MiddleSchoolExamList>,
  value: number,
): MiddleSchoolExamList => {
  let newTable: MiddleSchoolExamList = cell.row.values as MiddleSchoolExamList;
  switch (cell.column.id) {
    case 'japanese': {
      newTable = { ...newTable, japanese: value };
      break;
    }
    case 'math': {
      newTable = { ...newTable, math: value };
      break;
    }
    case 'english': {
      newTable = { ...newTable, english: value };
      break;
    }
    case 'science': {
      newTable = { ...newTable, science: value };
      break;
    }
    case 'society': {
      newTable = { ...newTable, society: value };
      break;
    }
    default: {
      break;
    }
  }

  return newTable;
};

const getNewExamData = (newTableData: MiddleSchoolExamList) => {
  const newExamData: MiddleSchoolExam = {
    ...defaultMiddleSchoolExamValues,
    ...newTableData.middleSchoolExam,
    id: newTableData.id,
    student_id: newTableData.student_id,
    [newTableData.examKey]: {
      japanese: newTableData.japanese,
      math: newTableData.math,
      english: newTableData.english,
      science: newTableData.science,
      society: newTableData.society,
    },
  };

  return newExamData;
};

const EditableCell: FC<PropsWithChildren<
  TableInstance<MiddleSchoolExamList> & {
    cell: Cell<MiddleSchoolExamList, number>;
    column: ColumnInstance<MiddleSchoolExamList>;
    row: Row<MiddleSchoolExamList>;
    value: CellValue<number>;
    updateTableData: (
      rowIndex: number,
      columnId: string,
      score: number,
      id?: string,
    ) => void;
  }
>> = ({
  cell,
  value: initialValue,
  row: { index },
  column: { id },
  updateTableData,
}) => {
  const { create } = useContext(ScoreExamContext);
  const [value, setValue] = React.useState(initialValue);
  const { isViewer } = useRole();

  useDebounce(
    async () => {
      if (initialValue === value) return;

      const newTableData = getNewTableData(cell, value);
      const newExamData = getNewExamData(newTableData);
      const examId = create ? await create(newExamData) : '';
      updateTableData(index, id, value, examId);
    },
    500,
    [value],
  );

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      type="number"
      className="input"
      onChange={(event) => {
        const newValue = getNewValue(event.target.value);
        setValue(newValue);
      }}
      onClick={(event) => event.stopPropagation()}
      value={value}
      max={MAX}
      min={MIN}
      disabled={isViewer}
    />
  );
};

export default EditableCell;
