import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import queryString from 'query-string';
import {
  defaultStudentLectures,
  StudentLectures,
} from '../../../../../domain/models/student-lectures';
import StudentLecturesForm from '../../../../components/molecules/form/StudentLecturesForm';
import useFetchStudentLectures from '../../../../hooks/fetch/useFetchStudentLectures';
import useCreateStudentLectures from '../../../../hooks/create/useCreateStudentLectures';
import useFetchParentInfo from '../../../../hooks/fetch/useFetchParentInfo';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const [updatedStudentLecture, setUpdatedStudentLecture] = useState<
    StudentLectures | undefined
  >(undefined);
  const location = useLocation<{ studentId: string | undefined }>();
  const queryStringId = queryString.parse(location.search).id;

  if (!location.state && !queryStringId) return null;

  const studentId = location.state?.studentId ?? (queryStringId as string);

  const { loading, studentBasics, studentLectures } = useFetchStudentLectures({
    id: studentId,
    queryParams: [{ fieldPath: 'student_id', opStr: '==', value: studentId }],
  });
  const { parents } = useFetchParentInfo({ id: studentBasics[0]?.parent_id });
  const { creating, create, close, state } = useCreateStudentLectures();
  const formMethods = useForm<StudentLectures>({
    defaultValues: studentLectures[0],
  });
  const onSubmit = formMethods.handleSubmit(async (data) => {
    const newData = {
      ...defaultStudentLectures,
      ...data,
    };
    const [id, newStudentLecture] = await create(newData);
    if (id && !data.id && typeof id === 'string') {
      formMethods.setValue('id', id || '');
    }
    if (newStudentLecture && typeof newStudentLecture !== 'string') {
      setUpdatedStudentLecture(newStudentLecture);
    }
  });

  return (
    <StudentLecturesForm
      loading={loading}
      studentBasic={studentBasics[0]}
      studentLectures={updatedStudentLecture ?? studentLectures[0]}
      parentInfo={parents[0]}
      formMethods={formMethods}
      onSubmit={onSubmit}
      snackbarState={state}
      snackbarClose={close}
      creating={creating}
    />
  );
};
