import { createContext } from 'react';

import { MiddleSchoolReportsList } from '../../types/table/middle-school-reports-list';
import { MiddleSchoolReport } from '../../domain/models/middle-school-report';
import { SnackbarState } from '../hooks/useSnackbar';

export type ScoreReportContextType = {
  data: MiddleSchoolReportsList[];
  updateTableData: (
    rowIndex: number,
    columnId: string,
    score: number,
    id?: string,
  ) => void;
  creating?: boolean;
  create?: (
    middleSchoolReport: MiddleSchoolReport,
  ) => Promise<string | undefined>;
  snackbarState?: SnackbarState;
  closeSnackbar?: () => void;
};

export const ScoreReportContext = createContext<ScoreReportContextType>({
  data: [],
  updateTableData: () => null,
});
