import React, { FC, useContext, useState } from 'react';
import {
  LineChart,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  TooltipPayload,
} from 'recharts';
import { FormControlLabel, Switch } from '@material-ui/core';

import VerticalText from './VerticalText';
import './style.scss';
import {
  middleSchoolReportSubjects,
  subjectColors,
} from '../../../../../../data/form-data';
import Dot from './Dot';
import TooltipContent from './TooltipContent';
import { ScoreReportContext } from '../../../../../context/ScoreReportContext';
import { MiddleSchoolReportScores } from '../../../../../../domain/models/middle-school-report';

const ReportChart: FC = () => {
  const [tooltip, setTooltip] = useState(true);
  const { data } = useContext(ScoreReportContext);
  const chartData = data.map((d): {
    reportKey: string;
    total: number;
  } & MiddleSchoolReportScores => ({
    reportKey: d.reportKey,
    japanese: d.japanese,
    math: d.math,
    english: d.english,
    society: d.society,
    science: d.science,
    art: d.art,
    music: d.music,
    health_physical: d.health_physical,
    tech_home: d.tech_home,
    total: d.total,
  }));

  return (
    <div className="chart-root">
      <FormControlLabel
        control={
          <Switch checked={tooltip} onChange={() => setTooltip(!tooltip)} />
        }
        label="マウスホバーでデータを表示する"
      />

      <div className="chart-container">
        <ResponsiveContainer>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="reportKey"
              tick={VerticalText}
              height={100}
              tickCount={9}
              interval={0}
            />
            <YAxis width={36} domain={[0, 5]} yAxisId="left" />
            <YAxis domain={[0, 45]} yAxisId="right" orientation="right" />
            {tooltip ? (
              <Tooltip
                content={(props: TooltipPayload) => {
                  return <TooltipContent {...props} />;
                }}
              />
            ) : null}
            {Object.keys(middleSchoolReportSubjects).map((subject) => (
              <Line
                key={subject}
                yAxisId="left"
                dataKey={subject}
                stroke={
                  subjectColors[subject as keyof MiddleSchoolReportScores]
                }
                dot={(props) => <Dot dotProps={props} data={data} />}
              />
            ))}
            <Line
              dataKey="total"
              yAxisId="right"
              stroke="#000"
              dot={(props) => <Dot dotProps={props} data={data} />}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ReportChart;
