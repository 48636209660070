import { useState } from 'react';

import { TaskDocumentRequest } from '../../../domain/models/task';
import createTaskDocumentRequest from '../../../domain/services/create-task-document-request';
import useSnackbar, { SnackbarState } from '../useSnackbar';

export type Result = {
  creating: boolean;
  create: (
    taskDocumentRequest: TaskDocumentRequest,
  ) => Promise<string | undefined>;
  state: SnackbarState;
  close: () => void;
};

const useCreateTaskDocumentRequest = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (taskDocumentRequest: TaskDocumentRequest) => {
    setCreating(true);
    const result = await createTaskDocumentRequest(taskDocumentRequest);
    open(
      result.id ? 'success' : 'error',
      result.id
        ? '資料請求タスクの更新に成功しました'
        : '資料請求タスクの更新ができませんでした。もう一度お試しください。',
    );
    setCreating(false);

    return result.id;
  };

  return { creating, create, close, state };
};

export default useCreateTaskDocumentRequest;
