import { createContext, Dispatch, SetStateAction } from 'react';
import { Filters } from 'react-table';

export type FilterContextType = {
  filters: Map<string, Filters<any>>;
  setFilters: Dispatch<SetStateAction<Map<string, Filters<any>>>>;
  filterKey: string;
  setFilterKey: Dispatch<SetStateAction<string>>;
};

export const FilterContext = createContext<FilterContextType>({
  filters: new Map([['', []]]),
  setFilters: () => '',
  filterKey: '',
  setFilterKey: () => '',
});
