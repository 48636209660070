import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Tab, Tabs } from '@material-ui/core';

import AdminWrapper from '../../components/layouts/AdminWrapper';
import Container from '../../components/layouts/Container';
import { PAGE_TITLE } from '../../../data/app-basic';
import TaskDocumentRequestListContainer from '../../containers/molecules/table/TaskDocumentRequestListContainer';
import TaskTrialListContainer from '../../containers/molecules/table/TaskTrialListContainer';
import TaskEnrollListContainer from '../../containers/molecules/table/TaskEnrollListContainer';

export default () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('タスク一覧')}</title>
      </Helmet>
      <Container>
        <Tabs
          value={tabIndex}
          onChange={(_, newIndex) => setTabIndex(newIndex)}
        >
          <Tab label="資料請求タスク" />
          <Tab label="体験入塾タスク" />
          <Tab label="本入塾タスク" />
        </Tabs>
        {tabIndex === 0 ? <TaskDocumentRequestListContainer /> : null}
        {tabIndex === 1 ? <TaskTrialListContainer /> : null}
        {tabIndex === 2 ? <TaskEnrollListContainer /> : null}
      </Container>
    </AdminWrapper>
  );
};
