import React, { FC, useContext } from 'react';

import { ChatContext } from '../../../../context/ChatContext';
import { ParentInfo } from '../../../../../domain/models/parent-info';
import './style.scss';
import useScrollBottom from '../../../../hooks/useScrollBottom';
import DateTalk from '../DateTalk';

interface Props {
  parentInfo: ParentInfo | undefined;
}

const TalkArea: FC<Props> = ({ parentInfo }) => {
  const { dateTalks } = useContext(ChatContext);
  const { targetRef } = useScrollBottom(dateTalks);
  const talks = Object.entries(dateTalks);

  return (
    <div className="talk-area" ref={targetRef}>
      {talks.length ? (
        talks.map(([key, value]) => {
          return (
            <DateTalk
              key={key}
              date={key}
              talks={value}
              parentInfo={parentInfo}
            />
          );
        })
      ) : (
        <p className="not-found">トークがありません</p>
      )}
    </div>
  );
};

export default TalkArea;
