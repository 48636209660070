import React, { FC } from 'react';
import { useAsync } from 'react-use';

import SystemInformationComponent from '../../../../components/pages/top/SystemInformation';
import getSystemInformation from '../../../../../domain/services/get-system-information';

const SystemInformation: FC = () => {
  const { loading, error, value } = useAsync(async () => {
    const { systemInformation } = await getSystemInformation();

    return systemInformation;
  }, []);
  if (error) console.error(error);

  return (
    <SystemInformationComponent
      systemInformation={value || []}
      loading={loading}
      error={error}
    />
  );
};

export default SystemInformation;
