import React, { Dispatch, SetStateAction, useState } from 'react';
import { Modal, Tab, Tabs } from '@material-ui/core';

import TaskDocumentRequest from '../../../../containers/molecules/form/TaskDocumentRequestForm';
import TaskTrialForm from '../../../../containers/molecules/form/TaskTrialForm';
import TaskEnrollForm from '../../../../containers/molecules/form/TaskEnrollForm';
import './style.scss';

export type Props = {
  opening: boolean;
  setOpening: Dispatch<SetStateAction<boolean>>;
};

export default ({ opening, setOpening }: Props) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Modal open={opening} onClose={() => setOpening(false)}>
      <div className="modal-box">
        <Tabs
          value={tabIndex}
          onChange={(_, newIndex) => setTabIndex(newIndex)}
          aria-label="simple tabs example"
          className="tab-wrapper"
          variant="fullWidth"
        >
          <Tab label="資料請求タスク" className="aaaa" />
          <Tab label="体験入塾タスク" className="" />
          <Tab label="本入塾タスク" className="" />
        </Tabs>
        <div className="modal-inner">
          {tabIndex === 0 ? <TaskDocumentRequest /> : null}
          {tabIndex === 1 ? <TaskTrialForm /> : null}
          {tabIndex === 2 ? <TaskEnrollForm /> : null}
        </div>
      </div>
    </Modal>
  );
};
