import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Modal } from '@material-ui/core';
import { Row } from 'react-table';
import InvoiceBulkChangeForm from '../../../../containers/molecules/form/InvoiceBulkChangeForm';
import { InvoiceList } from '../../../../../types/table/invoice-list';
import { InvoiceContext } from '../../../../context/InvoiceContext';

export type Props = {
  opening: boolean;
  setOpening: Dispatch<SetStateAction<boolean>>;
  invoiceMonth: string;
  rows: Row<InvoiceList>[];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ModalBulkChange = ({
  opening,
  setOpening,
  invoiceMonth,
  rows,
}: Props) => {
  const { setBulkChanged } = useContext(InvoiceContext);
  const [changed, setChanged] = useState<boolean>(false);

  return (
    <Modal
      open={opening}
      onClose={() => {
        setOpening(false);
        if (setBulkChanged) setBulkChanged(changed);
      }}
    >
      <InvoiceBulkChangeForm
        invoiceMonth={invoiceMonth}
        rows={rows}
        setBulkChanged={setChanged}
      />
    </Modal>
  );
};

export default ModalBulkChange;
