import clsx from 'clsx';
import React, { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/all';
import { Column } from 'react-table';
import { InvoiceList } from '../../../../../types/table/invoice-list';
import InvoiceListTable from '../InvoiceListTable';
import DefaultColumnFilter from '../DefaultColumnFilter';
import useReactTableFilter from '../../../../hooks/useReactTableFilter';
import DotProgress from '../../../atoms/feedback/DotProgress';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import Backdrop from '../../../atoms/feedback/Backdrop';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import useImportWithdrawalResultsCsv from '../../../../hooks/useImportWithdrawalResultsCsv';
import { InvoiceContext } from '../../../../context/InvoiceContext';
import './style.scss';
import {
  PAYMENT_METHOD_LABELS,
  PaymentMethod,
  STUDENT_STATUS_LABELS,
  StudentStatus,
} from '../../../../../data/form-data';
import { InvoiceItem } from '../../../../../domain/models/student-invoice';

export type Props = {
  loading: boolean;
  data: InvoiceList[];
  updateTableData: (rowIndex: number, value: InvoiceList) => void;
  columns: Column<InvoiceList>[];
  snackbarState: SnackbarState;
  snackbarClose: () => void;
};

const InvoiceListComponent: FC<Props> = ({
  loading,
  data,
  updateTableData,
  columns,
  snackbarState,
  snackbarClose,
}) => {
  const history = useHistory();
  const filterTypes = useReactTableFilter();
  const { invoiceMonth, creating, setBulkChanged } = useContext(InvoiceContext);
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    importing,
    importCsv,
    state,
    close,
  } = useImportWithdrawalResultsCsv();

  const priority = ['enrolled', 'waiting', 'trial', 'waiting_trial', 'recess'];

  useEffect(() => {
    if (state.isOpening) {
      window.setTimeout(() => {
        if (setBulkChanged) setBulkChanged(true);
      }, 2000);
    }
  }, [setBulkChanged, state.isOpening]);

  return (
    <div>
      <InvoiceListTable<InvoiceList>
        classes={{
          root: clsx('table-container'),
          table: clsx(
            'table',
            'is-fullwidth',
            'is-striped',
            'is-hoverable',
            'list-table',
          ),
        }}
        tableOptions={{
          data,
          columns,
          filterTypes,
          defaultColumn,
          manualPagination: false,
          maxMultiSortColCount: 3,
          orderByFn: (rows, _) => {
            return rows.slice().sort((a, b) => {
              // ステータス順に並び替え
              if (a.original.student_status !== b.original.student_status)
                return (
                  priority.indexOf(a.original.student_status) -
                  priority.indexOf(b.original.student_status)
                );

              // 生徒番号順に並び替え
              return a.original.student_num < b.original.student_num ? -1 : 1;
            });
          },
          initialState: {
            sortBy: [{ id: 'student_num' }],
            hiddenColumns: [],
            pageSize: 50,
          },
          updateTableData,
        }}
        theadThRender={(column) => {
          if (column.id === 'student_id') {
            // フィルタまたは一括変更のためにhiddenColumnsに指定していないだけなので内容は表示しない
            return <span key={column.id} />;
          }

          return (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              className={`column-${column.id}`}
            >
              <span>{column.render('Header')}</span>
              {column.isSorted &&
                (column.isSortedDesc ? (
                  <AiFillCaretDown fontSize="small" />
                ) : (
                  <AiFillCaretUp fontSize="small" />
                ))}
            </th>
          );
        }}
        tbodyTdRender={(row, cell) => {
          switch (cell.column.id) {
            case 'student_status': {
              const value = cell.value as StudentStatus;

              return (
                <td {...cell.getCellProps()}>{STUDENT_STATUS_LABELS[value]}</td>
              );
            }
            case 'payment_method': {
              const value = cell.value as PaymentMethod;

              if (value === null) {
                return <td {...cell.getCellProps()} />;
              }

              return (
                <td {...cell.getCellProps()}>{PAYMENT_METHOD_LABELS[value]}</td>
              );
            }
            case 'total': {
              const value = cell.value as number;

              if (value === null) {
                return <td {...cell.getCellProps()} />;
              }

              return (
                <td {...cell.getCellProps()}>{`¥${value.toLocaleString()}`}</td>
              );
            }
            case 'withdrawal_result': {
              const value = cell.value as boolean;
              if (value === null) {
                return <td {...cell.getCellProps()} />;
              }

              return (
                <td {...cell.getCellProps()}>{value ? '成功' : '失敗'}</td>
              );
            }
            case 'invoice_items': {
              const value = cell.value as InvoiceItem[];

              return (
                <td {...cell.getCellProps()}>
                  {value.map((v) => {
                    return (
                      <>
                        {v.label}
                        <br />
                      </>
                    );
                  })}
                </td>
              );
            }
            case 'is_transfer': {
              if (
                row.original.withdrawal_result === false ||
                row.original.payment_method === 'postal_transfer'
              ) {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={`column-${cell.column.id}`}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              }

              return <td {...cell.getCellProps()} />;
            }
            case 'student_id': {
              // フィルタまたは一括変更のためにhiddenColumnsに指定していないだけなので内容は表示しない
              return <span key={cell.column.id} />;
            }
            default:
              return (
                <td
                  {...cell.getCellProps()}
                  className={`column-${cell.column.id}`}
                >
                  {cell.render('Cell')}
                </td>
              );
          }
        }}
        onClick={(row, orderedStudentIds) => {
          const studentId =
            row?.values?.student_id &&
            typeof row?.values?.student_id === 'string'
              ? row.values.student_id
              : undefined;
          history.push('/invoice/detail', {
            studentId,
            invoiceMonth,
            orderedStudentIds,
          });
        }}
        importCsv={importCsv}
      />
      {loading && <DotProgress />}

      <Backdrop open={creating || importing} />
      <SnackbarWrapper
        message={snackbarState?.message || ''}
        isOpening={snackbarState?.isOpening || false}
        variant={snackbarState?.variant || 'success'}
        onClose={snackbarClose}
      />
      <SnackbarWrapper
        message={state.message}
        isOpening={state.isOpening}
        variant={state.variant}
        onClose={close}
      />
    </div>
  );
};

export default InvoiceListComponent;
