import { useState, Dispatch, SetStateAction } from 'react';

import { AdminUser } from '../../../domain/models/admin-user';
import createAdminUser from '../../../domain/services/create-admin-user';
import useSnackbar, { SnackbarState, VariantKey } from '../useSnackbar';

export type Result = {
  creating: boolean;
  create: (
    adminUser: AdminUser,
    isCreate?: boolean,
  ) => Promise<string | undefined>;
  state: SnackbarState;
  close: () => void;
  setCreating: Dispatch<SetStateAction<boolean>>;
  open: (variant: VariantKey, message: string) => void;
};

const useCreateStaff = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (
    adminUser: AdminUser,
    isCreate = false,
  ): Promise<string | undefined> => {
    setCreating(true);

    const result = await createAdminUser(adminUser);

    // 新規作成の場合はデータ作成後detailページに遷移するので、Snackbarは表示させない&Backdropを表示させ続けるためにcreatingはfalseにしない
    if (!isCreate) {
      open(
        result.id ? 'success' : 'error',
        result.id
          ? '社員の更新に成功しました'
          : '社員の更新ができませんでした。もう一度お試しください。',
      );
      setCreating(false);
    }

    return result.id;
  };

  return { creating, create, close, state, setCreating, open };
};

export default useCreateStaff;
