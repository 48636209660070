import React, { FC } from 'react';
import clsx from 'clsx';

import './style.scss';

export type Props = {
  className?: string;
  message?: string;
};

const ErrorMessage: FC<Props> = ({ className, message }) => {
  return message ? (
    <p className={clsx('error-message', [className])}>{message}</p>
  ) : null;
};

export default ErrorMessage;
