import { useEffect, useState } from 'react';

import { AdminUser } from '../../../domain/models/admin-user';
import getAdminUsers from '../../../domain/services/get-admin-users';
import { QueryParams } from '../../../types/firestore-simple';

export type Result = {
  loading: boolean;
  staffs: AdminUser[];
};

const useFetchStaff = ({
  id,
  queryParams,
}: {
  id?: string;
  queryParams?: QueryParams<AdminUser>;
}): Result => {
  const [loading, setLoading] = useState(false);
  const [staffs, setStaffs] = useState<AdminUser[]>([]);

  useEffect(() => {
    void (async () => {
      setLoading(true);

      const { adminUsers } = await getAdminUsers({ id, queryParams });
      if (adminUsers.length) {
        setStaffs(adminUsers);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [id]);

  return { loading, staffs };
};

export default useFetchStaff;
