import { Row } from 'react-table';
import exportCsv from '../utils/array/exportCsv';
import arrayToCsv from '../utils/array/arrayToCsv';
import { MiddleSchoolReportsList } from '../types/table/middle-school-reports-list';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (rows: Row<MiddleSchoolReportsList>[]) => {
  const rowArrays = rows.map((row) => {
    const rowValues = Object.entries(row.values).filter((v) => {
      return !['id', 'reportKey'].includes(v[0]);
    });

    return rowValues.map((rowValue): string => {
      if (typeof rowValue[1] === 'number') {
        return String(rowValue[1]);
      }

      return typeof rowValue[1] === 'string' ? rowValue[1] : '';
    });
  });

  return exportCsv(arrayToCsv(rowArrays));
};
