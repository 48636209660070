import firestoreSimple from '../../lib/firebase-firestore';
import { AdminUser } from '../models/admin-user';

const getAdminUser = async (
  uid: string,
  message: string,
): Promise<{ message: string; adminUser?: AdminUser }> => {
  let msg = message;
  const adminUserCollection = firestoreSimple.collection<AdminUser>({
    path: 'admin_user',
  });
  const docs = await adminUserCollection
    .where('uid', '==', uid)
    .fetch()
    .catch((error) => {
      console.error(error);
      msg = 'ログインに失敗しました';
    });
  const adminUser = docs instanceof Array ? docs[0] : undefined;
  msg = adminUser ? 'ログインに成功しました' : 'ログインに失敗しました';

  return { message: msg, adminUser };
};

export default getAdminUser;
