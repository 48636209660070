import { HasId } from '@firestore-simple/web/dist/types';
import {
  defaultSeparatedLecture,
  LectureKey,
  SeparatedLecture,
} from './student-lectures';

export type LectureItemForShift = {
  teacher_id: SeparatedLecture | string;
  default_seat: SeparatedLecture | string;
  subject: SeparatedLecture | string;
  student_id: string;
  grade: string;
  remarks: string;
};

export type LectureShiftItem = {
  [key in LectureKey]?: LectureItemForShift[];
};

export type TeacherWork = {
  teacher_id: string;
  lecture_time: number;
  preparation_clean_up: boolean;
  greeting: boolean;
  office_training_time: number;
  study_camp_time: number;
  behind_time: number;
  early_leave_time: number;
  sum_time: number;
  remarks: string;
};

export type WorkShift = HasId & {
  date: string;
  school_name: string;
  shift: LectureShiftItem;
  works: TeacherWork[];
};

export const defaultLectureItemForShiftNotesElementarySchool: LectureItemForShift = {
  teacher_id: defaultSeparatedLecture,
  default_seat: defaultSeparatedLecture,
  subject: defaultSeparatedLecture,
  student_id: '',
  grade: '',
  remarks: '',
};

export const defaultLectureItemForShift: LectureItemForShift = {
  teacher_id: '',
  default_seat: '',
  subject: '',
  student_id: '',
  grade: '',
  remarks: '',
};

export const defaultTeacherWork: TeacherWork = {
  teacher_id: '',
  lecture_time: 0,
  preparation_clean_up: false,
  greeting: false,
  office_training_time: 0,
  study_camp_time: 0,
  behind_time: 0,
  early_leave_time: 0,
  sum_time: 0,
  remarks: '',
};

export const defaultWorkShift: WorkShift = {
  id: '',
  date: '',
  school_name: '',
  shift: {
    lecture_1: [],
    lecture_2: [],
    lecture_3: [],
    lecture_4: [],
    lecture_5: [],
    lecture_special: [],
  },
  works: [],
};

export const getPreparationCleanUpTime = (isNotesJapan: boolean) => {
  return isNotesJapan ? 0.5 : 0.75;
};
export const GREETING_TIME = 0.5;

export const IS_NOTES_ELEMENTARY_SCHOOL_LECTURE_TIME = 0.75;
export const IS_NOTES_NORMAL_LECTURE_TIME = 1.5;
export const IS_PRAISE_LECTURE_TIME = 1;
