import clsx from 'clsx';
import React, { FC } from 'react';
import _ from 'lodash';

import './style.scss';

export type Props = {
  yearMonth: string;
  holidays: string[];
  onClickDate: (fullDate: string) => void;
};

type CalendarItem = {
  fullDate: string;
  date: number;
  className: string;
};

type CalendarItems = CalendarItem[];

type CalendarType = CalendarItems[];

export const Calendar: FC<Props> = ({ yearMonth, holidays, onClickDate }) => {
  // 表示したい月をセット
  const currentMonthDate = new Date(yearMonth);
  // 月末の日付（number）
  const lastDay = new Date(
    currentMonthDate.getFullYear(),
    currentMonthDate.getMonth() + 1,
    0,
  ).getDate();

  // 先月末（Date）
  const prevMonthDate = new Date(
    currentMonthDate.getFullYear(),
    currentMonthDate.getMonth(),
    0,
  );
  // 先月末の日付（number）
  const prevLastDay = prevMonthDate.getDate();

  // 翌月（Date）
  const nextMonthDate = new Date(
    currentMonthDate.getFullYear(),
    currentMonthDate.getMonth() + 1,
  );

  // 月初の曜日のindex。0 ~ 6 = 日曜 ~ 土曜
  const firstDayIndex = currentMonthDate.getDay();

  // 月末の曜日のindex。
  const lastDayIndex = new Date(
    currentMonthDate.getFullYear(),
    currentMonthDate.getMonth() + 1,
    0,
  ).getDay();

  // 先月の表示させなきゃいけない日数分(firstDayIndex分の数があるのでそのまま代入。)
  const prevDays = firstDayIndex;

  // 翌月の表示させなきゃいけない日数分
  const nextDays = 7 - lastDayIndex - 1;

  // 一週間ごとに日付を格納していく
  const calendar: CalendarType = [];

  const getFullDate = (monthDate: Date, date: number) => {
    return `${monthDate.getFullYear()}/${(monthDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.toString().padStart(2, '0')}`;
  };

  _.times(prevDays + lastDay + nextDays, (index) => {
    // 日にちに換算
    const number = index + 1;

    const calendarIndex = Math.floor(index / 7);

    if (!calendar[calendarIndex]) {
      calendar[calendarIndex] = [];
    }

    if (number <= prevDays) {
      // 先月分の日付
      const date = prevLastDay - prevDays + number;
      const fullDate = getFullDate(prevMonthDate, date);
      calendar[calendarIndex].push({ fullDate, date, className: 'prev-date' });
    } else if (number > lastDay + prevDays) {
      // 翌月分の日付
      const date = number - lastDay - prevDays;
      const fullDate = getFullDate(nextMonthDate, date);
      calendar[calendarIndex].push({ fullDate, date, className: 'next-date' });
    } else {
      // 今月分の日付
      const date = number - prevDays;
      const fullDate = getFullDate(currentMonthDate, date);
      calendar[calendarIndex].push({
        fullDate,
        date,
        className: 'normal-date',
      });
    }
  });

  const currentMonthStr = currentMonthDate.getMonth() + 1;

  return (
    <div className="calendar-container">
      <h5 className="is-size-5 has-text-weight-bold mb-2">{`${currentMonthStr}月`}</h5>
      <table className="school-calendar">
        <thead>
          <tr>
            <th className="has-text-danger">日</th>
            <th>月</th>
            <th>火</th>
            <th>水</th>
            <th>木</th>
            <th>金</th>
            <th className="has-text-link">土</th>
          </tr>
        </thead>
        <tbody>
          {calendar.map((items) => (
            <tr
              key={`${currentMonthStr}/${Math.random() * new Date().getTime()}`}
            >
              {items.map((item, index) => (
                <td key={item.date}>
                  <div
                    className={clsx(
                      'date-container',
                      item.className,
                      {
                        red: holidays.some(
                          (holiday) => holiday === item.fullDate,
                        ),
                      },
                      {
                        'has-text-danger': index === 0,
                      },
                      {
                        'has-text-link': index === 6,
                      },
                    )}
                    onClick={() => {
                      if (item.className === 'normal-date')
                        onClickDate(item.fullDate);
                    }}
                    aria-hidden="true"
                  >
                    {item.date}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
