import React, { FC, useContext } from 'react';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/all';
import clsx from 'clsx';
import { Column } from 'react-table';

import { TaskTrial } from '../../../../../domain/models/task';
import { TaskTrialList } from '../../../../../types/table/task-trial-list';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import Input from './Input';
import Table from '../Table';
import DefaultColumnFilter from '../DefaultColumnFilter';
import useReactTableFilter from '../../../../hooks/useReactTableFilter';
import DotProgress from '../../../atoms/feedback/DotProgress';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import Backdrop from '../../../atoms/feedback/Backdrop';
import './style.scss';
import exportTaskCsv from '../../../../../helpers/exportTaskCsv';
import { FilterContext } from '../../../../context/FilterContext';

export type Props = {
  loading: boolean;
  data: TaskTrialList[];
  updateTableData: (rowIndex: number, value: TaskTrialList) => void;
  columns: Column<TaskTrialList>[];
  creating: boolean;
  create: (taskTrial: TaskTrial) => Promise<string | undefined>;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
};

const TaskTrialListComponent: FC<Props> = ({
  loading,
  data,
  updateTableData,
  columns,
  creating,
  create,
  snackbarState,
  snackbarClose,
}) => {
  const history = useHistory();
  const filterTypes = useReactTableFilter();
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const { setFilterKey } = useContext(FilterContext);
  const pageName = 'task-trial-list';

  useEffectOnce(() => {
    setFilterKey(pageName);
  });

  return (
    <div>
      <Table<TaskTrialList>
        classes={{
          root: clsx('table-container'),
          table: clsx(
            'table',
            'is-fullwidth',
            'is-striped',
            'is-hoverable',
            'list-table',
          ),
        }}
        tableOptions={{
          data,
          columns,
          filterTypes,
          defaultColumn,
          manualPagination: false,
          initialState: {
            pageSize: 50,
            sortBy: [{ id: 'enrolled_cram_school' }],
            hiddenColumns: ['id', 'student_id'],
          },
        }}
        theadThRender={(column) => {
          const widthStyle = column.minWidth
            ? { minWidth: column.minWidth }
            : { minWidth: 'initial' };

          return (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              className={`column-task-trial-${column.id}`}
              style={{ ...widthStyle }}
            >
              <span>{column.render('Header')}</span>
              {column.isSorted &&
                (column.isSortedDesc ? (
                  <AiFillCaretDown fontSize="small" />
                ) : (
                  <AiFillCaretUp fontSize="small" />
                ))}
            </th>
          );
        }}
        tbodyTdRender={(row, cell) => {
          switch (cell.column.id) {
            case 'icon': {
              return (
                <td
                  {...cell.getCellProps()}
                  className={`column-task-trial-${cell.column.id}`}
                >
                  <div className="icon">{cell.value}</div>
                </td>
              );
            }
            case 'id':
            case 'student_id':
            case 'student_name':
            case 'enrolled_cram_school': {
              return (
                <td
                  {...cell.getCellProps()}
                  className={`column-task-trial-${cell.column.id}`}
                >
                  {cell.render('Cell')}
                </td>
              );
            }
            default:
              return (
                <td {...cell.getCellProps()} className="column-task">
                  <div className="form">
                    <Input
                      cell={cell}
                      create={create}
                      updateTableData={updateTableData}
                    />
                  </div>
                </td>
              );
          }
        }}
        onClick={(row) => {
          history.push({
            pathname: '/student/detail',
            state: { studentId: row.original.student_id },
          });
        }}
        pageName={pageName}
        exportCsvHelper={exportTaskCsv}
      />
      {loading && <DotProgress />}
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default TaskTrialListComponent;
