import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ParentInfo } from '../../../../../domain/models/parent-info';
import { StudentBasic } from '../../../../../domain/models/student-basic';

interface Props {
  parentInfo: ParentInfo | undefined;
  studentBasics: StudentBasic[];
}

const RoomHeader: FC<Props> = ({ parentInfo, studentBasics }) => {
  const history = useHistory();
  if (!parentInfo?.id)
    return (
      <div className="room-header">
        <div className="parent-wrap">
          <h2 className="parent">社員チャット</h2>
        </div>
      </div>
    );

  return (
    <div className="room-header">
      <div className="parent-wrap">
        <h2 className="parent">{parentInfo?.name}</h2>
        <button
          type="button"
          className="button is-primary"
          onClick={() => {
            history.push({
              pathname: '/parent/detail',
              state: { parentId: parentInfo?.id },
            });
          }}
        >
          保護者詳細ページへ
        </button>
      </div>
      <div className="students">
        {studentBasics.map((student) => {
          return (
            <div key={student.id} className="student">
              <div className="student-info">{`${student.student_num}：${student.last_name} ${student.first_name}`}</div>
              <button
                type="button"
                className="button is-primary"
                onClick={() => {
                  history.push({
                    pathname: '/student/detail',
                    state: { studentId: student?.id },
                  });
                }}
              >
                生徒詳細ページへ
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RoomHeader;
