import firestoreSimple from '../../lib/firebase-firestore';
import { TaskTrial } from '../models/task';

export type Result = {
  id?: string;
};

const createTaskTrial = async (taskTrial: TaskTrial): Promise<Result> => {
  const collection = firestoreSimple.collection<TaskTrial>({
    path: 'task_trial',
  });

  const docId = taskTrial.id
    ? await collection.set(taskTrial).catch((error) => {
        console.error(error);
      })
    : await collection.add(taskTrial).catch((error) => console.error(error));

  return { id: docId || undefined };
};

export default createTaskTrial;
