import React, { FC, useContext } from 'react';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { ButtonProps } from '@material-ui/core';
import { Column } from 'react-table';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import Table from '../Table';
import { SalaryList } from '../../../../../types/table/salary-list';
import DefaultColumnFilter from '../DefaultColumnFilter';
import useReactTableFilter from '../../../../hooks/useReactTableFilter';
import DotProgress from '../../../atoms/feedback/DotProgress';
import exportSalaryListsCsv from '../../../../../helpers/exportSalaryListsCsv';
import { FilterContext } from '../../../../context/FilterContext';

export type Props = {
  loading: boolean;
  salaryList: SalaryList[];
  columns: Column<SalaryList>[];
  toolbarSelectProps?: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
  onClickPreviousMonth: ButtonProps['onClick'];
  onClickNextMonth: ButtonProps['onClick'];
};

const SalaryListComponent: FC<Props> = ({
  loading,
  salaryList,
  columns,
  toolbarSelectProps,
  onClickPreviousMonth,
  onClickNextMonth,
}) => {
  const history = useHistory();
  const filterTypes = useReactTableFilter();
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const { setFilterKey } = useContext(FilterContext);
  const pageName = 'salary-list';

  useEffectOnce(() => {
    setFilterKey(pageName);
  });

  return (
    <>
      {loading ? (
        <DotProgress />
      ) : (
        <div>
          <div className="is-flex is-justify-content-space-between">
            <h3 className="title">給与データ</h3>
          </div>
          <Table<SalaryList>
            classes={{
              root: clsx('table-container'),
              table: clsx(
                'table',
                'is-fullwidth',
                'is-striped',
                'is-hoverable',
                'list-table',
              ),
            }}
            tableOptions={{
              data: salaryList,
              columns,
              filterTypes,
              defaultColumn,
              manualPagination: false,
              initialState: {
                pageSize: 50,
              },
            }}
            theadThRender={(column) => {
              return (
                <th {...column.getHeaderProps()}>
                  {
                    // Render the header
                    column.render('Header')
                  }
                </th>
              );
            }}
            tbodyTdRender={(row, cell) => {
              switch (cell.column.id) {
                case 'staff_num':
                case 'name':
                case 'enrolled_cram_school':
                case 'employment_status':
                case 'worked_days':
                case 'lecture_time':
                case 'office_training_time':
                case 'behind_num':
                case 'behind_time':
                case 'early_leave_num':
                case 'early_leave_time': {
                  const value = cell.value as string;

                  return <td {...cell.getCellProps()}>{value}</td>;
                }
                default:
                  return (
                    <td {...cell.getCellProps()}>
                      {
                        // Render the cell contents
                        cell.render('Cell')
                      }
                    </td>
                  );
              }
            }}
            onClick={(row) => {
              history.push({
                pathname: '/staff/detail',
                state: { staffId: row.original.staffId },
              });
            }}
            pageName={pageName}
            toolbarSelectProps={toolbarSelectProps}
            toolbarYearMonthOnClicks={{
              onClickPreviousMonth,
              onClickNextMonth,
            }}
            exportCsvHelper={exportSalaryListsCsv}
          />
        </div>
      )}
    </>
  );
};

export default SalaryListComponent;
