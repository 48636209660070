import React, { FC, useContext } from 'react';
import useFetchClassTransfers from '../../../hooks/fetch/useFetchClassTransfers';
import DotProgress from '../../atoms/feedback/DotProgress';
import Card from './Card';
import useCreateClassTransfer from '../../../hooks/create/useCreateClassTransfer';
import SnackbarWrapper from '../../atoms/feedback/Snackbar';
import Backdrop from '../../atoms/feedback/Backdrop';
import {
  ClassTransfer as ClassTransferType,
  Status,
} from '../../../../domain/models/class-transfer';
import { AppContext } from '../../../context/AppContext';

const ClassTransfer: FC = () => {
  const {
    loading,
    classTransfers,
    studentBasics,
    error,
  } = useFetchClassTransfers();
  const { adminUser } = useContext(AppContext);
  const { creating, create, close, state } = useCreateClassTransfer();

  const onChange = (status: Status, classTransfer: ClassTransferType) => {
    const newValue: ClassTransferType = {
      ...classTransfer,
      status,
    };
    create(newValue, adminUser);
  };

  if (loading) return <DotProgress />;

  if (error) {
    console.error(error);

    return <p>授業振替申請の取得に失敗しました</p>;
  }

  if (!classTransfers?.length) return <p>授業振替申請が0件です</p>;

  return (
    <div>
      <div className="columns is-multiline">
        {classTransfers?.map((transfers) => (
          <div className="column is-full" key={transfers.id}>
            <Card
              classTransfer={transfers}
              studentBasic={studentBasics?.find((basic) =>
                basic ? basic.id === transfers.student_id : false,
              )}
              onChange={onChange}
            />
          </div>
        ))}
      </div>
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={state.message}
        isOpening={state.isOpening}
        variant={state.variant}
        onClose={close}
      />
    </div>
  );
};

export default ClassTransfer;
