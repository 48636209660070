import React from 'react';
import { useForm } from 'react-hook-form';

import { LoginData } from '../../../../../types/form/login-data';
import LoginForm from '../../../../components/molecules/form/LoginForm';
import useSignIn from '../../../../hooks/useSignIn';

export default () => {
  // TODO: https://github.com/react-hook-form/react-hook-form/issues/2887
  // eslint-disable-next-line
  const { handleSubmit, register, errors } = useForm<LoginData>();
  const { onSubmit, loading, snackbarState } = useSignIn({
    handleSubmit,
  });

  return (
    <LoginForm
      onSubmit={onSubmit}
      loading={loading}
      register={register}
      errors={errors}
      snackbarState={snackbarState}
    />
  );
};
