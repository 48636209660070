import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Tab, Tabs } from '@material-ui/core';

import { PAGE_TITLE } from '../../../data/app-basic';
import Container from '../../components/layouts/Container';
import AdminWrapper from '../../components/layouts/AdminWrapper';
import ExamTab from '../../containers/pages/score/detail/ExamTab';
import ReportTab from '../../containers/pages/score/detail/ReportTab';
import Meta from '../../components/pages/score/detail/Meta';

const ScoreDetail: FC = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('成績情報詳細')}</title>
      </Helmet>
      <Container>
        <Meta />
        <Tabs
          value={tabIndex}
          onChange={(_, newIndex) => setTabIndex(newIndex)}
        >
          <Tab label="定期テスト" />
          <Tab label="通知表" />
        </Tabs>
        {tabIndex === 0 ? <ExamTab /> : null}
        {tabIndex === 1 ? <ReportTab /> : null}
      </Container>
    </AdminWrapper>
  );
};

export default ScoreDetail;
