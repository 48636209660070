import { useContext } from 'react';
import { useAsync } from 'react-use';

import { AppContext } from '../../context/AppContext';
import { ChatContext } from '../../context/ChatContext';
import getAdminUsers from '../../../domain/services/get-admin-users';
import getRooms from '../../../domain/services/get-rooms';
import getParents from '../../../domain/services/get-parent-info';
import getStudentBasics from '../../../domain/services/get-student-basics';
import onSnapshotRooms from '../../../domain/services/snapshot-rooms';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const { adminUser } = useContext(AppContext);
  const { setRooms, setParents, setStudentBasics, setAdminUsers } = useContext(
    ChatContext,
  );

  const { loading, error, value } = useAsync(async () => {
    const { adminUsers } = await getAdminUsers({});
    if (adminUsers) setAdminUsers(adminUsers);

    onSnapshotRooms({
      queryParams: [
        {
          fieldPath: 'room_users',
          opStr: 'array-contains',
          value: adminUser.id,
        },
      ],
      callback: (room) => {
        setRooms((prevState) => {
          return prevState.map((r) => (r.id === room.id ? room : r));
        });
      },
    });

    const { rooms } = await getRooms({
      queryParams: [
        {
          fieldPath: 'room_users',
          opStr: 'array-contains',
          value: adminUser.id,
        },
      ],
    });

    const { parents } = await getParents({});
    const { studentBasics } = await getStudentBasics({});

    setParents(() => parents);
    setStudentBasics(() => studentBasics);

    setRooms(
      rooms.sort((a, b) => {
        if (!a.update_date && !b.update_date) return 0;
        if (!a.update_date) return 1;
        if (!b.update_date) return -1;

        return a.update_date < b.update_date ? 1 : -1;
      }),
    );

    return rooms;
  });

  return { loading, error, value };
};
