import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { UseFormMethods } from 'react-hook-form/dist/types';
import _ from 'lodash';
import {
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Popper,
  Box,
  ClickAwayListener,
} from '@material-ui/core';

import {
  LectureItemForShift,
  WorkShift,
} from '../../../../../domain/models/work-shift';
import { StudentBasic } from '../../../../../domain/models/student-basic';
import {
  AdminUser,
  SelectedStaff,
} from '../../../../../domain/models/admin-user';
import { TableRowShift } from './TableRowShift';
import { LectureKey } from '../../../../../domain/models/student-lectures';
import { EnrolledCramSchoolContext } from '../../../../context/EnrolledCramSchoolContext';
import { WorkShiftSelectedStaffsContext } from '../../../../context/WorkShiftSelectedStaffsContext';
import { storage } from '../../../../../lib/firebase';
import DotProgress from '../../../atoms/feedback/DotProgress';
import Select from '../../../atoms/form/Select';
import {
  SETTLEMENT_SUBJECTS,
  SettlementSubject,
  UNDERSTANDING_SUBJECTS,
  UnderstandingSubject,
} from '../../../../../data/form-data';

export type Props = {
  lectureKey: LectureKey;
  formMethods: UseFormMethods<WorkShift>;
  lectureItemForShifts: LectureItemForShift[] | undefined;
  staffs: AdminUser[];
  studentBasics: StudentBasic[];
  date: string;
  isNotesJapan: boolean;
};

/* eslint-disable */
export const TableShift = ({
  lectureKey,
  formMethods,
  lectureItemForShifts,
  staffs,
  studentBasics,
  date,
  isNotesJapan,
}: Props) => {
  const defaultStaffImageSrc = `${process.env.PUBLIC_URL}/staff_image.png`;
  const { enrolledCramSchool } = useContext(
    EnrolledCramSchoolContext,
  );
  const {
    lecture1SelectedStaffs,
    lecture2SelectedStaffs,
    lecture3SelectedStaffs,
    lecture4SelectedStaffs,
    lecture5SelectedStaffs,
    lectureSpecialSelectedStaffs,
    setLecture1SelectedStaffs,
    setLecture2SelectedStaffs,
    setLecture3SelectedStaffs,
    setLecture4SelectedStaffs,
    setLecture5SelectedStaffs,
    setLectureSpecialSelectedStaffs,
    setLecture1SelectedStaffsInitialLoading,
    setLecture2SelectedStaffsInitialLoading,
    setLecture3SelectedStaffsInitialLoading,
    setLecture4SelectedStaffsInitialLoading,
    setLecture5SelectedStaffsInitialLoading,
    setLectureSpecialSelectedStaffsInitialLoading,
  } = useContext(
    WorkShiftSelectedStaffsContext,
  );
  const fieldArrayName = `shift.${lectureKey}`;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, setValue, watch, register, unregister } = formMethods;
  const { fields } = useFieldArray<LectureItemForShift>({
    control,
    name: fieldArrayName,
  });
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedStaffs, setSelectedStaffs] = useState<SelectedStaff[]>([]);
  const [addTargetStaff, setAddTargetStaff] = useState<AdminUser | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement[]>([]);
  const [open, setOpen] = useState<boolean[]>([]);

  useEffect(() => {
    setSelectedStaffs([]);
  }, [enrolledCramSchool, date, staffs]);

  useEffect(() => {
    // 最初にuseFieldArrayの値をリセット
    (watch(fieldArrayName) as LectureItemForShift[]).forEach((lifs, index) => {
      const indexStr = String(index);
      unregister(`${fieldArrayName}.${indexStr}.student_id`);
      unregister(`${fieldArrayName}.${indexStr}.grade`);
      unregister(`${fieldArrayName}.${indexStr}.remarks`);
      unregister(`${fieldArrayName}.${indexStr}.subject.first`);
      unregister(`${fieldArrayName}.${indexStr}.subject.latter`);
      unregister(`${fieldArrayName}.${indexStr}.subject`);
    });

    // 値が不変のものはこちらで先にregisterしておく
    lectureItemForShifts?.forEach((lifs, index) => {
      const studentIdName = `${fieldArrayName}.${index}.student_id`
      register(studentIdName);
      setValue(studentIdName, lifs?.student_id ?? '');
      const gradeName = `${fieldArrayName}.${index}.grade`
      register(gradeName);
      setValue(gradeName, lifs?.grade ?? '');
      const remarksName = `${fieldArrayName}.${index}.remarks`
      register(remarksName);
      setValue(remarksName, lifs?.remarks ?? '');
      if (typeof lifs.subject !== 'string') {
        const subjectFirstName = `${fieldArrayName}.${index}.subject.first`
        register(subjectFirstName);
        setValue(subjectFirstName, lifs.subject?.first ?? '');
        const subjectLatterName = `${fieldArrayName}.${index}.subject.latter`
        register(subjectLatterName);
        setValue(subjectLatterName, lifs.subject?.latter ?? '');
      } else {
        const subjectName = `${fieldArrayName}.${index}.subject`
        register(subjectName);
        setValue(subjectName, lifs.subject);
      }
    });

    if (lectureItemForShifts) {
      setValue(fieldArrayName, _.cloneDeep(lectureItemForShifts));
    }
    // eslint-disable-next-line
  }, [lectureItemForShifts]);

  useEffect( () => {
    // 授業数管理
    init();
    // eslint-disable-next-line
  }, [lectureItemForShifts, staffs]);

  useEffect(() => {
    switch (lectureKey) {
      case 'lecture_1': {
        if (!_.isEqual(lecture1SelectedStaffs, selectedStaffs)) {
          setLecture1SelectedStaffs(selectedStaffs);
        }
        break;
      }
      case 'lecture_2': {
        if (!_.isEqual(lecture2SelectedStaffs, selectedStaffs)) {
          setLecture2SelectedStaffs(selectedStaffs);
        }
        break;
      }
      case 'lecture_3': {
        if (!_.isEqual(lecture3SelectedStaffs, selectedStaffs)) {
          setLecture3SelectedStaffs(selectedStaffs);
        }
        break;
      }
      case 'lecture_4': {
        if (!_.isEqual(lecture4SelectedStaffs, selectedStaffs)) {
          setLecture4SelectedStaffs(selectedStaffs);
        }
        break;
      }
      case 'lecture_5': {
        if (!_.isEqual(lecture5SelectedStaffs, selectedStaffs)) {
          setLecture5SelectedStaffs(selectedStaffs);
        }
        break;
      }
      case 'lecture_special': {
        if (!_.isEqual(lectureSpecialSelectedStaffs, selectedStaffs)) {
          setLectureSpecialSelectedStaffs(selectedStaffs);
        }
        break;
      }
      default: {
        break;
      }
    }
    // eslint-disable-next-line
  }, [selectedStaffs]);

  useEffect(() => {
    switch (lectureKey) {
      case 'lecture_1': {
        setLecture1SelectedStaffsInitialLoading(initialLoading);
        break;
      }
      case 'lecture_2': {
        setLecture2SelectedStaffsInitialLoading(initialLoading);
        break;
      }
      case 'lecture_3': {
        setLecture3SelectedStaffsInitialLoading(initialLoading);
        break;
      }
      case 'lecture_4': {
        setLecture4SelectedStaffsInitialLoading(initialLoading);
        break;
      }
      case 'lecture_5': {
        setLecture5SelectedStaffsInitialLoading(initialLoading);
        break;
      }
      case 'lecture_special': {
        setLectureSpecialSelectedStaffsInitialLoading(initialLoading);
        break;
      }
      default: {
        break;
      }
    }
    // eslint-disable-next-line
  }, [initialLoading]);

  const init = () => {
    setInitialLoading(true);

    let newSelectedStaffsTmp = formatSelectedStaffs(lectureItemForShifts ?? [], true)

    // 各コマに対する講師の持つ授業数・image_pathを元にしたイメージ画像のURLを設定
    void (async () => {
      if (newSelectedStaffsTmp && newSelectedStaffsTmp.length) {
        let newSelectedStaffs = _.cloneDeep(newSelectedStaffsTmp);

        const promises = newSelectedStaffsTmp.map(async (staff, index) => {
          const staffTmp = staff;
          staffTmp.imageUrl = await getDownloadUrl(staff.image_path);
          newSelectedStaffs.splice(index, 1, staffTmp);
        });

        await Promise.all(promises);

        setSelectedStaffs(newSelectedStaffs);

        setInitialLoading(false);
      } else {
        setSelectedStaffs([]);

        setInitialLoading(false);
      }
    })();
  }

  // 講師選択肢表示させるためのデータを整形するための前準備。授業を持つ講師の配列を作成する。
  const getSelectedStaffs = (lectureItemForShiftsForUse: LectureItemForShift[], isInitial: boolean) => {
    let selectedStaffsTmp: SelectedStaff[] = [];

    lectureItemForShiftsForUse?.forEach((lifs) => {
      let staff;
      if (typeof lifs.teacher_id !== 'string' && (lifs.teacher_id?.first || lifs.teacher_id?.latter)) {
        const teacherIdFirst = lifs.teacher_id?.first;
        const teacherIdLatter = lifs.teacher_id?.latter;

        staff = staffs.find((s) => s.id === teacherIdFirst);
        if (staff) {
          selectedStaffsTmp.push(staff);
        }

        staff = staffs.find((s) => s.id === teacherIdLatter);
        if (staff) {
          selectedStaffsTmp.push(staff);
        }
      } else {
        staff = staffs.find((s) => s.id === lifs.teacher_id);
        if (staff) {
          selectedStaffsTmp.push(staff);
        }
      }
    });

    // 講師の重複があれば無くす
    let newSelectedStaffs = selectedStaffsTmp.filter((s, index, self) => {
      return self.findIndex((s2) =>s2.id === s.id) === index
    });

    if (!isInitial) {
      // 講師の授業数が0のものでも選択肢に残るようにする（※講師セレクトボックスを変更した際の処理）
      const notIncludedStaffs = selectedStaffs.filter((s2) => !newSelectedStaffs.map((s) => s.id).includes(s2.id));
      if (notIncludedStaffs) {
        notIncludedStaffs.forEach((staff) => {
          const sourceSelectedStaff = newSelectedStaffs.find((s) => s.id === staff.id);
          const findIndex = newSelectedStaffs.findIndex((s) => s.id === staff.id);
          newSelectedStaffs.splice(findIndex, 1, {...staff, imageUrl: sourceSelectedStaff?.imageUrl ?? defaultStaffImageSrc, shiftNum: 0});
        });
      }

      // imageUrl・shiftNumなどが格納されていればそれらも仕込む
      if (selectedStaffs && selectedStaffs.length) {
        newSelectedStaffs = selectedStaffs.map((s2) => {
          const targetStaff = newSelectedStaffs.find((s) => s.id === s2.id);

          if (targetStaff) {
            return {...targetStaff, imageUrl: s2.imageUrl, shiftNum: s2.shiftNum}
          }

          return {...s2}
        });
      }
    }

    return newSelectedStaffs;
  }

  // 授業数・定着授業の有無・理解授業の数を求め格納する
  const includeSubjectNumSelectedStaffs = (selectedStaffsForUse: SelectedStaff[], lectureItemForShiftsForUse: LectureItemForShift[]) => {
    return selectedStaffsForUse.map((s) => {
      let shiftNum = 0;
      let hasSettlementSubject = false;
      let understandingSubjectNum = 0;
      let isHalfTime = false;
      let firstNum = 0;
      let latterNum = 0;
      let normalNum = 0; // 前半or後半に分かれない授業の数
      lectureItemForShiftsForUse?.forEach((lifs) => {
        if (typeof lifs.subject !== 'string' && typeof lifs.teacher_id !== 'string') {
          // 前半・後半に分かれている場合
          if (lifs.teacher_id?.first === s.id) {
            // 授業数の追加
            shiftNum += 1;
            firstNum += 1;

            // 定着授業の有無
            if (SETTLEMENT_SUBJECTS.includes(lifs.subject?.first as SettlementSubject)) {
              hasSettlementSubject = true;
            }

            // 理解授業の数を追加
            if (UNDERSTANDING_SUBJECTS.includes(lifs.subject?.first as UnderstandingSubject)) {
              understandingSubjectNum += 1;
            }
          }

          if (lifs.teacher_id?.latter === s.id) {
            // 授業数の追加
            shiftNum += 1;
            latterNum += 1;

            // 定着授業の有無
            if (SETTLEMENT_SUBJECTS.includes(lifs.subject?.latter as SettlementSubject)) {
              hasSettlementSubject = true;
            }

            // 理解授業の数を追加
            if (UNDERSTANDING_SUBJECTS.includes(lifs.subject?.latter as UnderstandingSubject)) {
              understandingSubjectNum += 1;
            }
          }
        } else if (lifs.teacher_id === s.id) {
          // 前半・後半に分かれていない場合
          // 授業数の追加
          shiftNum += 1;
          normalNum += 1;

          // 定着授業の有無
          if (SETTLEMENT_SUBJECTS.includes(lifs.subject as SettlementSubject)) {
            hasSettlementSubject = true;
          }

          // 理解授業の数を追加
          if (UNDERSTANDING_SUBJECTS.includes(lifs.subject as UnderstandingSubject)) {
            understandingSubjectNum += 1;
          }
        }
      });

      // 前半or後半のどちらかしかないかどうかを判定
      isHalfTime = normalNum <= 0 && ((firstNum >= 1 && latterNum <= 0) || (firstNum <= 0 && latterNum >= 1))

      return {
        ...s,
        shiftNum,
        isHalfTime,
        hasSettlementSubject,
        understandingSubjectNum,
      }
    })
  }

  // 講師選択肢表示させるためのstateを整形する（授業数も計算）
  const formatSelectedStaffs = (lectureItemForShiftsForUse: LectureItemForShift[], isInitial: boolean) => {
    let newSelectedStaffs = getSelectedStaffs(lectureItemForShiftsForUse, isInitial);
    newSelectedStaffs = includeSubjectNumSelectedStaffs(newSelectedStaffs, lectureItemForShiftsForUse);
    return newSelectedStaffs;
  }

  const getDownloadUrl = async (imagePath: string | undefined) => {
    if (!imagePath) {
      return defaultStaffImageSrc;
    }
    const storageRef = storage.ref(imagePath);
    const dlImageUrl = (await storageRef.getDownloadURL()) as string;
    return dlImageUrl ? dlImageUrl : defaultStaffImageSrc;
  }

  // 講師選択肢に追加する
  const addStaff = async () => {
    const staff: SelectedStaff | undefined = staffs.find((s) => s.id === addTargetStaff?.id)
    if (staff && !selectedStaffs.map((s) => s.id).includes(staff.id)) {
      let selectedStaffsTmp = _.cloneDeep(selectedStaffs);
      staff.imageUrl = await getDownloadUrl(staff.image_path);
      staff.shiftNum = 0;
      staff.hasSettlementSubject = false;
      staff.understandingSubjectNum = 0;
      selectedStaffsTmp.push(staff);
      setSelectedStaffs(selectedStaffsTmp);
      setAddTargetStaff(null);
    }
  }

  // 講師選択肢と全ての紐付けから削除する
  const removeStaff = (staff: AdminUser) => {
    const staffId = staff.id;
    setSelectedStaffs(selectedStaffs.filter((s) => s.id !== staffId));
    (watch(fieldArrayName) as LectureItemForShift[])?.forEach((lifs, index) => {
      const indexStr = String(index);
      const teacherId = lifs.teacher_id;
      if (typeof teacherId !== 'string') {
        const teacherIdFirst = teacherId?.first;
        const teacherIdLatter = teacherId?.latter;

        if (teacherIdFirst === staffId) {
          setValue(`${fieldArrayName}.${indexStr}.teacher_id.first`, '');
        }

        if (teacherIdLatter === staffId) {
          setValue(`${fieldArrayName}.${indexStr}.teacher_id.latter`, '');
        }
      } else {
        if (teacherId === staffId) {
          setValue(`${fieldArrayName}.${indexStr}.teacher_id`, '');
        }
      }
    });
  }

  // 削除Popperを表示させる
  const showRemoveStaffPopper = (event: React.MouseEvent<HTMLElement>, index: number) => {
    const newAnchorEl = [
      ...anchorEl,
    ];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
    const newOpen = [
      ...open,
    ];
    newOpen[index] = !open[index];
    setOpen(newOpen);
  }

  // 削除Popperを非表示にさせる
  const hiddenRemoveStaffPopper = (index: number) => {
    const newOpen = [
      ...open,
    ];
    newOpen[index] = false;
    setOpen(newOpen);
  };

  const onChangeCallback = () => {
    let newSelectedStaffs = formatSelectedStaffs((watch(fieldArrayName) as LectureItemForShift[]), false)

    setSelectedStaffs(newSelectedStaffs);
  }

  return (
    lectureItemForShifts && lectureItemForShifts.length ?
      <>
        <div className="is-flex justify-content is-justify-content-flex-start is-flex-wrap-wrap">
          {!initialLoading ? <>{selectedStaffs?.map((staff, index) => (
            <div key={staff.id} className="staff-image-container">
              <ClickAwayListener onClickAway={() => hiddenRemoveStaffPopper(index)}>
                <div className="staff-image">
                  <Popper
                    id={staff.id}
                    key={staff.id}
                    open={open[index] || false}
                    anchorEl={anchorEl[index] || null}
                    placement="top">
                    <Box className="mb-1" style={{ backgroundColor: '#777777', border: '1px solid white', padding: '3px 5px', borderRadius: '5px' }}>
                      <button type="button" onClick={() => removeStaff(staff)} className="button is-small is-light">
                        削除
                      </button>
                    </Box>
                  </Popper>
                  <button type="button" onClick={(event) => showRemoveStaffPopper(event, index)}>
                    <img
                      src={staff.imageUrl}
                      alt="社員画像"
                      className="image"
                      aria-describedby={staff.id}
                    />
                  </button>
                  <span className="shift-number">{staff.shiftNum ?? 0}</span>
                </div>
              </ClickAwayListener>
              <p className="staff-name"><small>{staff.last_name} {staff.first_name}</small></p>
            </div>
          ))}
            <div className="ml-3">
              <Select
                selectProps={{
                  options: staffs && staffs.length ? staffs.map((staff) => ({
                    label: `${staff.last_name} ${staff.first_name}`,
                    value: staff.id,
                  })) : undefined,
                  value: addTargetStaff ? {
                    label: `${addTargetStaff.last_name} ${addTargetStaff.first_name}`,
                    value: addTargetStaff.id,
                  } : null,
                  onChange: (value) => {
                    if (!value) {
                      setAddTargetStaff(null)
                    } else {
                      setAddTargetStaff(value && 'value' in value ? (staffs.find((staff) => staff.id === value.value) ?? null) : null)
                    }
                  },
                  className: 'select-add-teacher'
                }}
              />
              <button type="button" onClick={addStaff} className="button is-light">
                追加
              </button>
            </div>
          </> : <DotProgress/>}
        </div>
        <TableContainer>
          <Table className="shift-table">
            <TableHead>
              <TableRow>
                <TableCell>生徒番号</TableCell>
                <TableCell>氏名</TableCell>
                <TableCell>学年</TableCell>
                <TableCell>教科</TableCell>
                <TableCell>座席</TableCell>
                <TableCell>講師</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                fields.map((field, index) => {
                  const studentBasic = studentBasics.find(
                    (sb) => sb.id === field.student_id,
                  );

                  return (studentBasic
                    ? <TableRowShift
                      key={field.id}
                      index={index}
                      fieldArrayName={fieldArrayName}
                      formMethods={formMethods}
                      lectureItemForShift={field}
                      staffs={selectedStaffs}
                      studentBasic={studentBasic}
                      onChangeCallback={onChangeCallback}
                      isNotesJapan={isNotesJapan}
                    />
                    : null)
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </> : <p>このコマの授業はありません。</p>
  );
};
