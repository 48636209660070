import React, { PropsWithChildren, useState } from 'react';
import { Row } from 'react-table';
import { Button as MaterialButton } from '@material-ui/core';

import ModalBulkChangeTimetable from '../../modal/Timetable/bulk-change';
import ModalBulkChangeWorkShift from '../../modal/WorkShift/bulk-change';

// eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/explicit-module-boundary-types
const BulkChange = <T extends {}>({
  pageName,
  rows,
}: PropsWithChildren<{
  pageName: string;
  rows: Row<T>[];
}>) => {
  const isStudentListPage = pageName === 'student-list';
  const isWeekDefaultListPage = pageName === 'week-default-list';
  const [opening, setOpening] = useState(false);

  return (
    <>
      {isStudentListPage ? (
        <>
          <MaterialButton variant="text" onClick={() => setOpening(!opening)}>
            一括変更
          </MaterialButton>
          <ModalBulkChangeTimetable
            opening={opening}
            setOpening={setOpening}
            rows={rows as Row<any>[]}
          />
        </>
      ) : null}
      {isWeekDefaultListPage ? (
        <>
          <MaterialButton variant="text" onClick={() => setOpening(!opening)}>
            日程表へ反映
          </MaterialButton>
          <ModalBulkChangeWorkShift
            opening={opening}
            setOpening={setOpening}
            rows={rows as Row<any>[]}
          />
        </>
      ) : null}
    </>
  );
};

export default BulkChange;
