import React, { FC } from 'react';
import { Text, TextProps, TooltipPayload } from 'recharts';
import {
  MiddleSchoolExamKeys,
  middleSchoolExamLabels,
} from '../../../../../../data/form-data';

const VerticalText: FC<TextProps & { payload: TooltipPayload }> = ({
  x,
  y,
  dx,
  dy,
  payload,
}) => {
  return (
    <Text
      x={x}
      y={y}
      dx={dx}
      dy={dy}
      textAnchor="start"
      verticalAnchor="middle"
      width={20}
      height={180}
      angle={90}
    >
      {middleSchoolExamLabels[payload.value as MiddleSchoolExamKeys]}
    </Text>
  );
};

export default VerticalText;
