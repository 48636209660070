import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { TaskDocumentRequest } from '../../../domain/models/task';
import { QueryParams } from '../../../types/firestore-simple';
import getTaskDocumentRequest from '../../../domain/services/get-task-document-request';

export type Result = {
  loading: boolean;
  taskItems: TaskDocumentRequest | undefined;
};

const useFetchTaskDocumentRequest = ({
  id,
  queryParams,
}: {
  id?: string;
  queryParams?: QueryParams<TaskDocumentRequest>;
}): Result => {
  const [loading, setLoading] = useState(false);
  const [taskDocumentRequests, setTaskDocumentRequests] = useState<
    TaskDocumentRequest[]
  >([]);

  useEffectOnce(() => {
    void (async () => {
      setLoading(true);

      const result = await getTaskDocumentRequest({ id, queryParams });

      if (result.taskDocumentRequests.length) {
        setTaskDocumentRequests(result.taskDocumentRequests);
      }

      setLoading(false);
    })();
  });

  return {
    loading,
    taskItems: taskDocumentRequests.length
      ? taskDocumentRequests[0]
      : undefined,
  };
};

export default useFetchTaskDocumentRequest;
