import { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { StudentBasic } from '../../../domain/models/student-basic';
import { TaskEnrollList } from '../../../types/table/task-enroll-list';
import getStudentBasic from '../../../domain/services/get-student-basics';
import getTaskEnroll from '../../../domain/services/get-task-enroll';
import { enrollTaskItems } from '../../../domain/models/task';
import { AppContext } from '../../context/AppContext';

export type Result = {
  loading: boolean;
  enrollTaskList: TaskEnrollList[];
};

const useFetchTaskEnrollLists = (): Result => {
  const { adminUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [, setStudentBasic] = useState<StudentBasic[]>([]);
  const [enrollTaskList, setEnrollTaskList] = useState<TaskEnrollList[]>([]);
  const defaultQuery = [
    {
      fieldPath: 'status',
      opStr: '==',
      value: 'waiting',
    },
  ];
  const queryParams = ['editor', 'viewer'].includes(adminUser.role)
    ? [
        ...defaultQuery,
        {
          fieldPath: 'enrolled_cram_school',
          opStr: '==',
          value: adminUser.enrolled_cram_school,
        },
      ]
    : [...defaultQuery];

  useEffectOnce(() => {
    void (async () => {
      setLoading(true);

      const { studentBasics } = await getStudentBasic({ queryParams });
      setStudentBasic(studentBasics);

      const { taskEnrolls } = await getTaskEnroll({});

      setEnrollTaskList(
        studentBasics.map(
          (studentBasic): TaskEnrollList => {
            const studentTask = taskEnrolls.find(
              (value) => value.student_id === studentBasic.id,
            );

            return {
              icon: null,
              id: studentTask?.id || '',
              student_id: studentBasic.id,
              student_num: studentBasic.student_num,
              student_name: `${studentBasic.last_name}${studentBasic.first_name}`,
              enrolled_cram_school: studentBasic.enrolled_cram_school,
              input_invoice_data:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'input_invoice_data',
                    ) || enrollTaskItems[0]
                  : enrollTaskItems[0],
              input_class_schedule:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'input_class_schedule',
                    ) || enrollTaskItems[1]
                  : enrollTaskItems[1],
              create_student_file:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'create_student_file',
                    ) || enrollTaskItems[2]
                  : enrollTaskItems[2],
              create_report_curriculum:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'create_report_curriculum',
                    ) || enrollTaskItems[3]
                  : enrollTaskItems[3],
              order_text_book:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'order_text_book',
                    ) || enrollTaskItems[4]
                  : enrollTaskItems[4],
              distribute_text_book:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'distribute_text_book',
                    ) || enrollTaskItems[5]
                  : enrollTaskItems[5],
              distribute_bank_account_print:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'distribute_bank_account_print',
                    ) || enrollTaskItems[6]
                  : enrollTaskItems[6],
              receive_bank_account_print:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'receive_bank_account_print',
                    ) || enrollTaskItems[7]
                  : enrollTaskItems[7],
              process_bank_account_print:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'process_bank_account_print',
                    ) || enrollTaskItems[8]
                  : enrollTaskItems[8],
              distribute_privilege:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'distribute_privilege',
                    ) || enrollTaskItems[9]
                  : enrollTaskItems[9],
              collection_result:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'collection_result',
                    ) || enrollTaskItems[10]
                  : enrollTaskItems[10],
              deadline:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'deadline',
                    ) || enrollTaskItems[11]
                  : enrollTaskItems[11],
            };
          },
        ),
      );

      setLoading(false);
    })();
  });

  return { loading, enrollTaskList };
};

export default useFetchTaskEnrollLists;
