import clsx from 'clsx';
import _ from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { FaSave, RiArrowGoBackFill } from 'react-icons/all';
import {
  MenuItem,
  Select as MuiSelect,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { TableRowCalendar } from './TableRowCalendar';

import {
  NOTES_JAPAN_CLASSES,
  ElementarySchoolSubject,
  MiddleSchoolSubject,
  HighSchoolSubject,
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_WEEKDAYS_CLASSES,
  NOTES_PRAISE_HOLIDAY_CLASSES,
  NotesJapanClass,
  NotesPraiseClass,
  ELEMENTARY_SCHOOL_GRADES,
} from '../../../../../data/form-data';
import DotProgress from '../../../atoms/feedback/DotProgress';
import Button from '../../../atoms/button/Button';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import Backdrop from '../../../atoms/feedback/Backdrop';
import {
  getSubjects,
  StudentBasic,
} from '../../../../../domain/models/student-basic';
import {
  formatSchedule,
  ScheduleItem,
  Timetable,
} from '../../../../../domain/models/timetable';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import './style.scss';
import { ParentInfo } from '../../../../../domain/models/parent-info';
import { YearMonthContext } from '../../../../context/YearMonthContext';

export type Props = {
  loading: boolean;
  studentBasic: StudentBasic;
  timetable: Timetable;
  parentInfo: ParentInfo;
  formMethods: UseFormMethods<Timetable>;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
  creating: boolean;
  selectProps: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
};

const TimetableForm: FC<Props> = ({
  loading,
  studentBasic,
  timetable,
  parentInfo,
  formMethods,
  onSubmit,
  snackbarState,
  snackbarClose,
  creating,
  selectProps,
}) => {
  const { yearMonth } = useContext(YearMonthContext);
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, watch, setValue, register } = formMethods;
  const { fields } = useFieldArray<ScheduleItem>({
    control,
    name: 'schedule',
  });

  const [isNotesJapan, setIsNotesJapan] = useState(false);
  const [subjects, setSubjects] = useState<
    (ElementarySchoolSubject | MiddleSchoolSubject | HighSchoolSubject)[]
  >([]);
  const [classes, setClasses] = useState<
    NotesJapanClass[] | NotesPraiseClass[]
  >([]);
  const [lastDay, setLastDay] = useState<number | undefined>(undefined);
  const [isNotesElementarySchool, setIsNotesElementarySchool] = useState(false);

  useEffect(() => {
    setLastDay(
      new Date(
        Number(yearMonth.split('/')[0]),
        Number(yearMonth.split('/')[1]),
        0,
      ).getDate(),
    );
  }, [yearMonth]);

  useEffect(() => {
    setSubjects(getSubjects(studentBasic?.grade));

    setIsNotesJapan(
      NOTES_JAPAN_SCHOOLS.some((v) => v === studentBasic?.enrolled_cram_school),
    );

    setClasses(
      isNotesJapan ? NOTES_JAPAN_CLASSES : [...NOTES_PRAISE_WEEKDAYS_CLASSES],
    );

    setIsNotesElementarySchool(
      isNotesJapan &&
        ELEMENTARY_SCHOOL_GRADES.some((v) => v === studentBasic?.grade),
    );
  }, [
    studentBasic,
    setSubjects,
    setIsNotesJapan,
    setClasses,
    isNotesJapan,
    setIsNotesElementarySchool,
  ]);

  useEffect(() => {
    const schedule = formatSchedule(
      lastDay,
      yearMonth,
      timetable,
      isNotesJapan,
      isNotesElementarySchool,
      classes,
    );
    setValue('schedule', schedule);
  }, [
    studentBasic,
    timetable,
    classes,
    isNotesJapan,
    isNotesElementarySchool,
    lastDay,
    setValue,
    yearMonth,
  ]);

  useEffectOnce(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  });

  return loading ? (
    <DotProgress />
  ) : (
    <div className="detail-form timetable-form">
      <form onSubmit={onSubmit} className="detail-form card p-5">
        <div className="student-meta">
          <h3 className="student-name">{`${studentBasic?.last_name} ${studentBasic?.first_name}`}</h3>
          <button
            type="button"
            className="button is-primary"
            onClick={() => {
              history.push({
                pathname: '/student-score/detail',
                state: { studentBasic },
              });
            }}
          >
            成績情報ページへ
          </button>
          <div className="parent-info">
            <h4 className="parent-name">{parentInfo?.name}</h4>
            <button
              type="button"
              className="button is-primary"
              onClick={() => {
                history.push({
                  pathname: '/parent/detail',
                  state: { parentId: parentInfo?.id },
                });
              }}
            >
              保護者詳細ページへ
            </button>
          </div>
        </div>
        <input
          type="hidden"
          name="id"
          defaultValue={timetable?.id || watch('id')}
          ref={register}
        />
        <input
          type="hidden"
          name="student_id"
          defaultValue={studentBasic?.id}
          ref={register}
        />
        <input
          type="hidden"
          name="year_month"
          defaultValue={yearMonth}
          ref={register}
        />
        {selectProps ? (
          <div className="table-toolbar is-justify-content-flex-start">
            <MuiSelect
              label={selectProps.label}
              value={selectProps.value}
              onChange={selectProps.onChange}
              className="toolbar-select"
            >
              {selectProps.options.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </MuiSelect>
          </div>
        ) : null}
        <Table className="mb-5">
          <TableHead>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={clsx(
                  'has-text-centered',
                  'has-background-grey-lighter',
                  'has-text-weight-bold',
                )}
              >
                日付
              </TableCell>
              <TableCell
                component="th"
                className={clsx(
                  'has-text-centered',
                  'has-background-grey-lighter',
                )}
              >
                {isNotesJapan ? 'N1' : 'P1/P4'}
              </TableCell>
              <TableCell
                component="th"
                className={clsx(
                  'has-text-centered',
                  'has-background-grey-lighter',
                )}
              >
                {isNotesJapan ? 'N2' : 'P2/P5'}
              </TableCell>
              <TableCell
                component="th"
                className={clsx(
                  'has-text-centered',
                  'has-background-grey-lighter',
                )}
              >
                {isNotesJapan ? 'N3' : 'P3/P6'}
              </TableCell>
              <TableCell
                component="th"
                className={clsx(
                  'has-text-centered',
                  'has-background-grey-lighter',
                )}
              >
                {isNotesJapan ? 'N4' : 'P4/P7'}
              </TableCell>
              <TableCell
                component="th"
                className={clsx(
                  'has-text-centered',
                  'has-background-grey-lighter',
                )}
              >
                {isNotesJapan ? 'N5' : 'P5/P8'}
              </TableCell>
              <TableCell
                component="th"
                className={clsx(
                  'has-text-centered',
                  'has-background-grey-lighter',
                )}
              >
                特別授業
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((scheduleItem, index) => (
              <TableRowCalendar
                key={scheduleItem.id}
                formMethods={formMethods}
                scheduleItem={scheduleItem}
                index={index}
                subjects={subjects}
                isNotesElementarySchool={isNotesElementarySchool}
              />
            ))}
          </TableBody>
        </Table>
        <div className="btn-area">
          <Button
            buttonProps={{
              type: 'button',
              onClick: () => history.push('/student'),
            }}
            className={clsx('with-icon', 'btn-primary')}
          >
            <RiArrowGoBackFill />
            戻る
          </Button>
          <Button
            buttonProps={{ type: 'submit' }}
            color="primary"
            className={clsx('with-icon', 'btn-primary')}
          >
            <FaSave />
            保存
          </Button>
        </div>
      </form>
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default TimetableForm;
