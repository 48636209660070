import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

interface Props {
  variant?: 'error' | 'warning' | 'success';
  message: string;
  isOpening: boolean;
  onClose?: () => void;
}

const SnackbarWrapper: React.FC<Props> = ({
  variant = 'success',
  message,
  isOpening,
  onClose,
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isOpening}
      autoHideDuration={6000}
    >
      <Alert severity={variant} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarWrapper;
