import { HasId } from '@firestore-simple/web/dist/types';

export type CheckItem = {
  category: string;
  message: string;
};

export type EventItem = {
  date: string;
  name: string;
};

export type PeriodicInfo = HasId & {
  school_name: string;
  checklists: CheckItem[];
  event_schedule: EventItem[];
  information: string;
};

export const defaultPeriodicInfo: PeriodicInfo = {
  id: '',
  school_name: '',
  checklists: [],
  event_schedule: [],
  information: '',
};
