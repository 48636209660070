import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Cell,
  CellValue,
  ColumnInstance,
  Row,
  TableInstance,
} from 'react-table';
import { OptionsType, OptionTypeBase } from 'react-select/src/types';
import { useDebounce } from 'react-use';
import Select from 'react-select';
import useRole from '../../../../hooks/useRole';
import { InvoiceList } from '../../../../../types/table/invoice-list';
import {
  INVOICE_STATUS,
  INVOICE_STATUS_LABELS,
  InvoiceStatus,
  WithdrawalBank,
} from '../../../../../data/form-data';
import { StudentInvoice } from '../../../../../domain/models/student-invoice';
import { InvoiceContext } from '../../../../context/InvoiceContext';

const SelectInvoiceStatusCell: FC<PropsWithChildren<
  TableInstance<InvoiceList> & {
    cell: Cell<InvoiceList>;
    column: ColumnInstance<InvoiceList>;
    row: Row<InvoiceList>;
    value: CellValue<InvoiceStatus | null>;
    updateTableData: (rowIndex: number, value: InvoiceList) => void;
  }
>> = ({ cell, value: initialValue, updateTableData }) => {
  const { create } = useContext(InvoiceContext);
  const [value, setValue] = useState(initialValue);
  const { isViewer } = useRole();

  useDebounce(
    async () => {
      if (initialValue === value) return;

      const newStudentInvoice: StudentInvoice = {
        id: cell.row.original.id,
        student_id: cell.row.original.student_id,
        status: value as InvoiceStatus,
        invoice_month: cell.row.original.invoice_month,
        invoice_items: cell.row.original.invoice_items,
        withdrawal_date: cell.row.original.withdrawal_date,
        withdrawal_bank:
          (cell.row.original.withdrawal_bank as WithdrawalBank) || '',
        withdrawal_result: cell.row.original.withdrawal_result,
        consumption_tax: cell.row.original.consumption_tax || 0,
        subtotal: cell.row.original.subtotal || 0,
        total: cell.row.original.total || 0,
        payment_method: cell.row.original.payment_method,
        message: cell.row.original.message,
        remarks: cell.row.original.remarks,
        is_transfer: cell.row.original.is_transfer,
      };

      const newInvoiceList: InvoiceList = {
        id: cell.row.original.id,
        student_id: cell.row.original.student_id,
        student_num: cell.row.original.student_num,
        student_name: cell.row.original.student_name,
        student_status: cell.row.original.student_status,
        grade: cell.row.original.grade,
        enrolled_cram_school: cell.row.original.enrolled_cram_school,
        invoice_status: value as InvoiceStatus,
        invoice_month: cell.row.original.invoice_month,
        withdrawal_date: cell.row.original.withdrawal_date,
        withdrawal_bank: cell.row.original.withdrawal_bank,
        total: cell.row.original.total,
        withdrawal_result: cell.row.original.withdrawal_result,
        invoice_items: cell.row.original.invoice_items,
        consumption_tax: cell.row.original.consumption_tax,
        subtotal: cell.row.original.subtotal,
        payment_method: cell.row.original.payment_method,
        message: cell.row.original.message,
        remarks: cell.row.original.remarks,
        is_transfer: cell.row.original.is_transfer,
      };

      const studentInvoiceId = create ? await create(newStudentInvoice) : '';
      if (studentInvoiceId) {
        newInvoiceList.id = studentInvoiceId;
      }

      updateTableData(cell.row.index, newInvoiceList);
    },
    500,
    [value],
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const options: OptionsType<OptionTypeBase> = INVOICE_STATUS.sort((a, b) => {
    const original = INVOICE_STATUS;

    return original.findIndex((v) => v === a) >
      original.findIndex((v) => v === b)
      ? 1
      : -1;
  }).map((v) => ({ label: INVOICE_STATUS_LABELS[v], value: v }));

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    <div onClick={(event) => event.stopPropagation()}>
      <Select
        options={options}
        value={options.filter((option) => option.value === value)}
        isDisabled={isViewer}
        onChange={(v) => {
          const selectedInvoiceStatus = (v as OptionTypeBase)
            .value as InvoiceStatus;
          setValue(selectedInvoiceStatus);
        }}
      />
    </div>
  );
};

export default SelectInvoiceStatusCell;
