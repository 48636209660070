import { createContext, Dispatch, SetStateAction } from 'react';
import { Dictionary } from 'lodash';

import { Room } from '../../domain/models/rooms';
import { ParentInfo } from '../../domain/models/parent-info';
import { StudentBasic } from '../../domain/models/student-basic';
import { Talk } from '../../domain/models/talk';
import { AdminUser } from '../../domain/models/admin-user';

export type ChatContextType = {
  selectedRoom: string | undefined;
  adminUsers: AdminUser[];
  rooms: Room[];
  filteredRooms: Room[];
  parents: ParentInfo[];
  studentBasics: StudentBasic[];
  dateTalks: Dictionary<Talk[]>;
  setAdminUsers: Dispatch<SetStateAction<AdminUser[]>>;
  setSelectedRoom: Dispatch<SetStateAction<string>>;
  setRooms: Dispatch<SetStateAction<Room[]>>;
  setFilteredRooms: Dispatch<SetStateAction<Room[]>>;
  setParents: Dispatch<SetStateAction<ParentInfo[]>>;
  setStudentBasics: Dispatch<SetStateAction<StudentBasic[]>>;
  setDateTalks: Dispatch<SetStateAction<Dictionary<Talk[]>>>;
};

export const ChatContext = createContext<ChatContextType>({
  selectedRoom: undefined,
  adminUsers: [],
  rooms: [],
  filteredRooms: [],
  parents: [],
  studentBasics: [],
  dateTalks: {},
  setAdminUsers: () => '',
  setSelectedRoom: () => '',
  setRooms: () => '',
  setFilteredRooms: () => '',
  setParents: () => '',
  setStudentBasics: () => '',
  setDateTalks: () => '',
});
