import React, { FC } from 'react';
import { useEffectOnce } from 'react-use';
import {
  Select as MuiSelect,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { ValueType } from 'react-select/src/types';

import { AdminUser } from '../../../../../domain/models/admin-user';
import { PeriodicInfo } from '../../../../../domain/models/periodic-info';
import { StudentBasic } from '../../../../../domain/models/student-basic';
import { LectureShiftItem } from '../../../../../domain/models/work-shift';
import Select from '../../../atoms/form/Select';
import DotProgress from '../../../atoms/feedback/DotProgress';
import Textarea from '../../../atoms/form/Textarea';
import { TableRowShift } from './TableRowShift';
import './style.scss';

export type Props = {
  loading: boolean;
  staff: AdminUser | null;
  periodicInfo: PeriodicInfo | null;
  shift: LectureShiftItem | null;
  studentBasics: StudentBasic[];
  selectPropsEnrolledCramSchool: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
  selectPropsStaff: {
    value: ValueType<{ label: string; value: string }> | null;
    options: { label: string; value: string }[];
    onChange: (value: ValueType<{ label: string; value: string }>) => void;
    isDisabled: boolean;
    placeholder: string;
  };
};

const DailyReportDetailForm: FC<Props> = ({
  loading,
  staff,
  periodicInfo,
  shift,
  studentBasics,
  selectPropsEnrolledCramSchool,
  selectPropsStaff,
}) => {
  useEffectOnce(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  });

  return (
    <div className="detail-form daily-report-detail">
      <h3 className="title">講師日報</h3>
      {selectPropsEnrolledCramSchool ? (
        <div className="table-toolbar is-justify-content-flex-start mb-6">
          <div className="table-toolbar-item w-200px">
            <div className="is-size-7 has-text-grey">校舎</div>
            <MuiSelect
              label={selectPropsEnrolledCramSchool.label}
              value={selectPropsEnrolledCramSchool.value}
              onChange={selectPropsEnrolledCramSchool.onChange}
              className="toolbar-select"
            >
              {selectPropsEnrolledCramSchool.options.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </MuiSelect>
          </div>
          <div className="table-toolbar-item w-350px">
            <div className="is-size-7 has-text-grey mb-2">講師名</div>
            <Select selectProps={selectPropsStaff} className="toolbar-select" />
          </div>
        </div>
      ) : null}
      {loading ? (
        <DotProgress />
      ) : (
        <div>
          <div className="mt-5 mb-6">
            <h4 className="title is-4">確認事項</h4>
            <div>
              {periodicInfo && periodicInfo.checklists.length ? (
                periodicInfo.checklists.map((checklist) => (
                  <div
                    key={`${Math.random() * new Date().getTime()}`}
                    className="is-flex justify-content is-justify-content-flex-start mb-2"
                  >
                    <div className="grey-box">{checklist.category}</div>
                    <p className="grey-box-neighbor">{checklist.message}</p>
                  </div>
                ))
              ) : (
                <div className="white-box">確認事項はありません</div>
              )}
            </div>
          </div>
          <div className="my-5 mb-6">
            <h4 className="title is-4">始業時業務チェック</h4>
            <div>
              <div className="is-flex justify-content is-justify-content-flex-start is-flex-wrap-wrap">
                <div className="is-flex justify-content is-justify-content-flex-start is-align-items-center mb-4">
                  <div className="white-checkbox" />
                  <p>身だしなみ、笑顔、元気の確認</p>
                </div>
                <div className="is-flex justify-content is-justify-content-flex-start is-align-items-center mb-4">
                  <div className="white-checkbox" />
                  <p>ミーティングノート、ボード、リストの確認</p>
                </div>
                <div className="is-flex justify-content is-justify-content-flex-start is-align-items-center mb-4">
                  <div className="white-checkbox" />
                  <p>勤務予定表記入</p>
                </div>
              </div>
              <div className="is-flex justify-content is-justify-content-flex-start is-flex-wrap-wrap">
                <div className="is-flex justify-content is-justify-content-flex-start is-align-items-center mb-4">
                  <div className="white-checkbox" />
                  <p>座席配置、配布物の確認</p>
                </div>
                <div className="is-flex justify-content is-justify-content-flex-start is-align-items-center mb-4">
                  <div className="white-checkbox" />
                  <p>生徒対応、指導法予習、シュミレーション</p>
                </div>
                <div className="is-flex justify-content is-justify-content-flex-start is-align-items-center mb-4">
                  <div className="white-checkbox" />
                  <p>夕礼準備（発言準備、掃除場所確認）</p>
                </div>
              </div>
            </div>
          </div>
          <div className="my-5 mb-6">
            <h4 className="title is-4">本日の目標</h4>
            <Textarea
              textareaProps={{
                rows: 1,
              }}
            />
          </div>
          <div className="my-5 mb-6">
            <h4 className="title is-4">終業時業務チェック</h4>
            <div className="my-2">
              <div className="is-flex justify-content is-justify-content-flex-start is-align-items-center mb-4">
                <div className="white-checkbox" />
                <p>講師日報の記入</p>
              </div>
              <div className="is-flex justify-content is-justify-content-flex-start is-align-items-center mb-4">
                <div className="white-checkbox" />
                <p>ボード、リストへの記入</p>
              </div>
              <div className="is-flex justify-content is-justify-content-flex-start is-align-items-center mb-4">
                <div className="white-checkbox" />
                <p>気になる生徒の報告</p>
              </div>
              <div className="is-flex justify-content is-justify-content-flex-start is-align-items-center mb-4">
                <div className="white-checkbox" />
                <p>担当場所の整理整頓</p>
              </div>
              <div className="is-flex justify-content is-justify-content-flex-start is-align-items-center mb-4">
                <div className="white-checkbox" />
                <p>労いの言葉を掛ける（最低２人）</p>
              </div>
            </div>
          </div>
          <div className="my-5 mb-6">
            <h4 className="title is-4">本日の担当生徒</h4>
            <TableContainer>
              <Table className="today-students-table">
                <TableHead>
                  <TableRow>
                    <TableCell>生徒番号</TableCell>
                    <TableCell>氏名</TableCell>
                    <TableCell>学校名</TableCell>
                    <TableCell>学年</TableCell>
                    <TableCell>教科</TableCell>
                    <TableCell>備考・報告（相談・報告は青字）</TableCell>
                    <TableCell>宿題忘れ</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shift?.lecture_1?.map((li) => (
                    <TableRowShift
                      key={`lecture_1/${Math.random() * new Date().getTime()}`}
                      staff={staff}
                      lectureItemForShift={li}
                      studentBasics={studentBasics}
                    />
                  ))}
                  {shift?.lecture_2?.map((li) => (
                    <TableRowShift
                      key={`lecture_2/${Math.random() * new Date().getTime()}`}
                      staff={staff}
                      lectureItemForShift={li}
                      studentBasics={studentBasics}
                    />
                  ))}
                  {shift?.lecture_3?.map((li) => (
                    <TableRowShift
                      key={`lecture_3/${Math.random() * new Date().getTime()}`}
                      staff={staff}
                      lectureItemForShift={li}
                      studentBasics={studentBasics}
                    />
                  ))}
                  {shift?.lecture_4?.map((li) => (
                    <TableRowShift
                      key={`lecture_4/${Math.random() * new Date().getTime()}`}
                      staff={staff}
                      lectureItemForShift={li}
                      studentBasics={studentBasics}
                    />
                  ))}
                  {shift?.lecture_5?.map((li) => (
                    <TableRowShift
                      key={`lecture_5/${Math.random() * new Date().getTime()}`}
                      staff={staff}
                      lectureItemForShift={li}
                      studentBasics={studentBasics}
                    />
                  ))}
                  {shift?.lecture_special?.map((li) => (
                    <TableRowShift
                      key={`lecture_special/${
                        Math.random() * new Date().getTime()
                      }`}
                      staff={staff}
                      lectureItemForShift={li}
                      studentBasics={studentBasics}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default DailyReportDetailForm;
