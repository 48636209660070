import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useAsync } from 'react-use';
import { DateTime } from 'luxon';
import { StudentBasic } from '../../../domain/models/student-basic';
import { InvoiceList } from '../../../types/table/invoice-list';
import getStudentBasic from '../../../domain/services/get-student-basics';
import getStudentInvoice from '../../../domain/services/get-student-invoices';
import { AppContext } from '../../context/AppContext';

export type Result = {
  loading: boolean;
  invoiceList: InvoiceList[];
  invoiceMonth: string;
  setInvoiceMonth: Dispatch<SetStateAction<string>>;
  setReacquire: Dispatch<SetStateAction<boolean>>;
};

const useFetchStudentInvoiceLists = (): Result => {
  const { adminUser } = useContext(AppContext);
  const [invoiceMonth, setInvoiceMonth] = useState<string>(
    DateTime.fromJSDate(new Date()).toFormat('yyyy/MM'),
  );
  const [, setStudentBasic] = useState<StudentBasic[]>([]);
  const [invoiceList, setStudentInvoiceList] = useState<InvoiceList[]>([]);
  const [reacquire, setReacquire] = useState<boolean>(true);

  const { loading } = useAsync(async () => {
    if (!reacquire) return;

    const defaultQuery = [
      {
        fieldPath: 'status',
        opStr: 'in',
        value: ['enrolled', 'waiting', 'trial', 'waiting_trial', 'recess'],
      },
    ];

    const studentQueryParams = ['editor', 'viewer'].includes(adminUser.role)
      ? [
          ...defaultQuery,
          {
            fieldPath: 'enrolled_cram_school',
            opStr: '==',
            value: adminUser.enrolled_cram_school,
          },
        ]
      : [...defaultQuery];

    const { studentBasics } = await getStudentBasic({
      queryParams: studentQueryParams,
    });

    setStudentBasic(studentBasics);

    const { studentInvoices } = await getStudentInvoice({
      queryParams: [
        {
          fieldPath: 'invoice_month',
          opStr: '==',
          value: invoiceMonth,
        },
      ],
    });

    setStudentInvoiceList(
      studentBasics.map(
        (studentBasic): InvoiceList => {
          const studentInvoice = studentInvoices.find(
            (invoice) => invoice.student_id === studentBasic.id,
          );

          return {
            id: studentInvoice?.id ?? '',
            student_id: studentBasic.id,
            student_num: studentBasic.student_num,
            student_name: `${studentBasic.last_name ?? ''}
                ${studentBasic.first_name ?? ''}`,
            student_status: studentBasic.status,
            grade: studentBasic.grade ?? '',
            enrolled_cram_school: studentBasic.enrolled_cram_school ?? '',
            invoice_status: studentInvoice?.status ?? '',
            invoice_month: studentInvoice?.invoice_month ?? '',
            withdrawal_date: studentInvoice?.withdrawal_date ?? '',
            withdrawal_bank: studentInvoice?.withdrawal_bank ?? '',
            total: studentInvoice?.total ?? null,
            withdrawal_result: studentInvoice?.withdrawal_result ?? null,
            invoice_items: studentInvoice?.invoice_items ?? [],
            consumption_tax: studentInvoice?.consumption_tax ?? null,
            subtotal: studentInvoice?.subtotal ?? null,
            payment_method: studentInvoice?.payment_method ?? null,
            message: studentInvoice?.message ?? '',
            remarks: studentInvoice?.remarks ?? '',
            is_transfer: studentInvoice?.is_transfer ?? null,
          };
        },
      ),
    );
    setReacquire(false);
  }, [invoiceMonth, reacquire]);

  return { loading, invoiceList, invoiceMonth, setInvoiceMonth, setReacquire };
};

export default useFetchStudentInvoiceLists;
