import firestoreSimple from '../../lib/firebase-firestore';
import { ClassTransfer } from '../models/class-transfer';

export type Result = {
  id?: string;
};

const createClassTransfer = async (
  classTransfer: ClassTransfer,
): Promise<Result> => {
  const collection = firestoreSimple.collection<ClassTransfer>({
    path: 'class_transfer',
  });

  const docId = classTransfer.id
    ? await collection.set(classTransfer).catch((error) => {
        console.error(error);
      })
    : await collection
        .add(classTransfer)
        .catch((error) => console.error(error));

  return { id: docId || undefined };
};

export default createClassTransfer;
