import React, { FC } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { StudentBasic } from '../../../../../../domain/models/student-basic';
import './style.scss';

const Meta: FC = () => {
  const {
    state: { studentBasic },
  } = useLocation<{ studentBasic: StudentBasic }>();
  const history = useHistory();

  return (
    <div className="score-meta">
      <div>
        <p className="score-number">{`生徒番号：${studentBasic.student_num}`}</p>
        <p className="score-name">{`${studentBasic.last_name} ${studentBasic.first_name}`}</p>
      </div>
      <button
        type="button"
        className="button is-primary"
        onClick={() =>
          history.push({
            pathname: '/student/detail',
            state: { studentId: studentBasic.id },
          })
        }
      >
        生徒詳細ページへ
      </button>
    </div>
  );
};

export default Meta;
