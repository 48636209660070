import React, { Dispatch, SetStateAction } from 'react';
import { Modal } from '@material-ui/core';
import { Row } from 'react-table';
import WorkShiftBulkChangeForm from '../../../../containers/molecules/form/WorkShiftBulkChangeForm';
import { StudentList } from '../../../../../types/table/student-list';

export type Props = {
  opening: boolean;
  setOpening: Dispatch<SetStateAction<boolean>>;
  rows: Row<StudentList>[];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ModalBulkChange = ({ opening, setOpening }: Props) => {
  return (
    <Modal
      open={opening}
      onClose={() => {
        setOpening(false);
      }}
    >
      <WorkShiftBulkChangeForm />
    </Modal>
  );
};

export default ModalBulkChange;
