import React, { useContext } from 'react';
import { useDebounce } from 'react-use';
import Select from 'react-select';
import { FilterProps } from 'react-table';
import { OptionsType, OptionTypeBase } from 'react-select/src/types';

import { FilterContext } from '../../../../context/FilterContext';

import {
  GRADES,
  INVOICE_STATUS,
  INVOICE_STATUS_LABELS,
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_SCHOOLS,
  RELATIONSHIPS,
  STUDENT_STATUS,
  STUDENT_STATUS_LABELS,
  WITHDRAWAL_BANKS,
} from '../../../../../data/form-data';

// eslint-disable-next-line @typescript-eslint/ban-types
const SelectColumnFilter = <T extends {}>({ column }: FilterProps<T>) => {
  const { filters, setFilters, filterKey } = useContext(FilterContext);
  const { render, setFilter, id, preFilteredRows } = column;
  let options: OptionsType<OptionTypeBase>;
  // 配列の順番が変わってしまうためsortメソッドで対応、時間があるときに調査
  switch (id) {
    case 'relationship': {
      options = RELATIONSHIPS.sort((a, b) => {
        const original = RELATIONSHIPS;

        return original.findIndex((v) => v === a) >
          original.findIndex((v) => v === b)
          ? 1
          : -1;
      }).map((v) => ({ label: v, value: v }));
      break;
    }
    case 'status':
    case 'student_status': {
      options = STUDENT_STATUS.sort((a, b) => {
        const original = STUDENT_STATUS;

        return original.findIndex((v) => v === a) >
          original.findIndex((v) => v === b)
          ? 1
          : -1;
      }).map((v) => ({ label: STUDENT_STATUS_LABELS[v], value: v }));
      break;
    }
    case 'grade': {
      options = GRADES.sort((a, b) => {
        const original = GRADES;

        return original.findIndex((v) => v === a) >
          original.findIndex((v) => v === b)
          ? 1
          : -1;
      }).map((v) => ({ label: v, value: v }));
      break;
    }
    case 'enrolled_cram_school': {
      options = [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS]
        .sort((a, b) => {
          const original = [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS];

          return original.findIndex((v) => v === a) >
            original.findIndex((v) => v === b)
            ? 1
            : -1;
        })
        .map((v) => ({
          label: v,
          value: v,
        }));
      break;
    }
    case 'invoice_status': {
      options = INVOICE_STATUS.sort((a, b) => {
        const original = INVOICE_STATUS;

        return original.findIndex((v) => v === a) >
          original.findIndex((v) => v === b)
          ? 1
          : -1;
      }).map((v) => ({ label: INVOICE_STATUS_LABELS[v], value: v }));
      break;
    }
    case 'withdrawal_bank': {
      options = WITHDRAWAL_BANKS.sort((a, b) => {
        const original = WITHDRAWAL_BANKS;

        return original.findIndex((v) => v === a) >
          original.findIndex((v) => v === b)
          ? 1
          : -1;
      }).map((v) => ({ label: v, value: v }));
      break;
    }
    case 'withdrawal_result': {
      options = [true, false].map((v) => ({
        label: v ? '成功' : '失敗',
        value: v,
      }));
      break;
    }
    default: {
      const opts = new Map();
      preFilteredRows.forEach((v) => {
        opts.set(
          v && 'values' in v ? v.values[id] : undefined,
          v && 'values' in v ? v.values[id] : undefined,
        );
      });
      options = [];
      opts.forEach((v) => {
        if (typeof v === 'string') {
          options = options.concat({ label: v, value: v });
        }
      });
      break;
    }
  }
  const filterValue: any[] =
    column.filterValue instanceof Array ? column.filterValue : [];
  useDebounce(
    () => {
      if (filterValue !== undefined) {
        setFilters((prevState) => {
          if (prevState && prevState.has(filterKey)) {
            filters.set(filterKey, [
              ...prevState.get(filterKey)!.filter((v) => v.id !== column.id),
              { id: column.id, value: filterValue },
            ]);

            return filters;
          }
          filters.set(filterKey, [{ id: column.id, value: filterValue }]);

          return filters;
        });
      } else {
        setFilters((prevState) => {
          if (prevState && prevState.has(filterKey)) {
            filters.set(filterKey, [
              ...prevState.get(filterKey)!.filter((v) => v.id !== column.id),
            ]);

            return filters;
          }

          return filters;
        });
      }
    },
    300,
    [filterValue],
  );

  return (
    <div>
      <div className="label">{render('Header')}</div>
      <div className="control">
        <Select
          onChange={(value) => {
            setFilter(value instanceof Array && value.length ? value : null);
          }}
          options={options}
          value={filterValue}
          isMulti
        />
      </div>
    </div>
  );
};

export default SelectColumnFilter;
