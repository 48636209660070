import React from 'react';
import { Column } from 'react-table';

import BetweenSliderColumnFilter from '../BetweenSliderColumnFilter';
import EditableCell from './EditableCell';
import { MiddleSchoolExamsList } from '../../../../../types/table/middle-school-exams-list';
import TotalCell from './TotalCell';

const columns: Column<MiddleSchoolExamsList>[] = [
  {
    Header: '',
    accessor: 'id',
  },
  {
    Header: '',
    accessor: 'icon',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'テスト名',
    accessor: 'examKey',
  },
  {
    Header: '英語',
    accessor: 'english',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '数学',
    accessor: 'math',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '国語',
    accessor: 'japanese',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '理科',
    accessor: 'science',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '社会',
    accessor: 'society',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '合計',
    accessor: 'total',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={500} />
    ),
    Cell: TotalCell,
  },
];

export default columns;
