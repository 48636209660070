import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';

export class ExtendLuxonUtils extends LuxonUtils {
  getCalendarHeaderText(date: DateTime): string {
    return this.format(date, 'yyyy年MM月');
  }

  getDatePickerHeaderText(date: DateTime): string {
    return this.format(date, 'MM月dd日(EEE)');
  }

  getYearText(date: DateTime): string {
    return this.format(date, 'yyyy年');
  }
}

export default ExtendLuxonUtils;
