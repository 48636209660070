import { HasId } from '@firestore-simple/web/dist/types';
import _ from 'lodash';
import {
  AdmissionRoute,
  ELEMENTARY_SCHOOL_SUBJECTS,
  MIDDLE_SCHOOL_SUBJECTS,
  HIGH_SCHOOL_SUBJECTS,
  NOTES_JAPAN_SCHOOLS,
  ELEMENTARY_SCHOOL_GRADES,
  Gender,
  Grade,
  NotesJapanSchool,
  NotesPraiseSchool,
  StudentStatus,
} from '../../data/form-data';

export type StudentBasic = HasId & {
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  gender: Gender;
  birthday: string;
  school_name: string;
  grade: Grade;
  student_num: string;
  status: StudentStatus;
  admission_date: string;
  admission_route: AdmissionRoute[];
  enrolled_cram_school: NotesJapanSchool | NotesPraiseSchool;
  memo: string;
  parent_id: string;
};

export const defaultStudentBasic: StudentBasic = {
  id: '',
  last_name: '',
  first_name: '',
  last_name_kana: '',
  first_name_kana: '',
  gender: 'その他',
  birthday: '',
  school_name: '',
  grade: '小1',
  student_num: '',
  status: 'waiting_trial',
  admission_date: '',
  admission_route: [],
  enrolled_cram_school: 'ノートスいしやま',
  memo: '',
  parent_id: '',
};

export const getSubjects = (grade: Grade) => {
  switch (grade) {
    case '小1':
    case '小2':
    case '小3':
    case '小4':
    case '小5':
    case '小6': {
      return ELEMENTARY_SCHOOL_SUBJECTS;
    }
    case '中1':
    case '中2':
    case '中3': {
      return MIDDLE_SCHOOL_SUBJECTS;
    }
    case '高1':
    case '高2':
    case '高3': {
      return HIGH_SCHOOL_SUBJECTS;
    }
    case '既卒':
    default: {
      return _.uniq([
        ...ELEMENTARY_SCHOOL_SUBJECTS,
        ...MIDDLE_SCHOOL_SUBJECTS,
        ...HIGH_SCHOOL_SUBJECTS,
      ]);
    }
  }
};

export const isNotesElementarySchool = (studentBasic: StudentBasic) => {
  return (
    NOTES_JAPAN_SCHOOLS.some((v) => v === studentBasic.enrolled_cram_school) &&
    ELEMENTARY_SCHOOL_GRADES.some((v) => v === studentBasic?.grade)
  );
};
