import React, { FC } from 'react';
import { TooltipProps } from 'recharts';
import {
  MiddleSchoolExamKeys,
  middleSchoolExamLabels,
  middleSchoolExamSubjects,
  subjectColors,
} from '../../../../../../data/form-data';
import { MiddleSchoolExamScores } from '../../../../../../domain/models/middle-school-exam';

const TooltipContent: FC<TooltipProps> = (props) => {
  const { label, payload } = props;

  return (
    <div className="card p-2 chart-tooltip">
      <h2 className="title is-6 mb-2">
        {middleSchoolExamLabels[label as MiddleSchoolExamKeys]}
      </h2>
      <ul>
        {payload
          ? payload.map((value) => {
              const subjectKey = value.dataKey as keyof MiddleSchoolExamScores;
              const color = subjectColors[subjectKey];
              const itemTtl = middleSchoolExamSubjects[subjectKey] || '合計';

              return (
                <li
                  key={typeof value.dataKey === 'string' ? value.dataKey : ''}
                  style={{
                    color,
                    fontWeight: 'bold',
                  }}
                >
                  {itemTtl}：{value.value}
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
};

export default TooltipContent;
