import React, { FC, useEffect, useState } from 'react';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import useFetchMiddleSchoolExamLists from '../../../../hooks/fetch/useFetchMiddleSchoolExamLists';
import DotProgress from '../../../../components/atoms/feedback/DotProgress';
import MiddleSchoolExamListComponent from '../../../../components/molecules/table/MiddleSchoolExamList';
import { MiddleSchoolExamList } from '../../../../../types/table/middle-school-exam-list';
import useCreateMiddleSchoolExam from '../../../../hooks/create/useCreateMiddleSchoolExam';
import {
  MiddleSchoolExamKeys,
  middleSchoolExamLabels,
} from '../../../../../data/form-data';
import { ScoreExamContext } from '../../../../context/ScoreExamContext';
import { getSubjectScores } from '../../../../../domain/models/middle-school-exam';

const MiddleSchoolExamListContainer: FC = () => {
  const {
    loading,
    error,
    middleSchoolExamLists,
    examKey,
    setExamKey,
    getLibraryCardIcon,
  } = useFetchMiddleSchoolExamLists();
  const { create, close, state } = useCreateMiddleSchoolExam();
  const [data, setData] = useState<MiddleSchoolExamList[]>([]);

  useEffect(() => {
    if (middleSchoolExamLists) {
      setData(middleSchoolExamLists);
    }
  }, [middleSchoolExamLists]);

  const updateTableData = (
    rowIndex: number,
    columnId: string,
    score: number,
    id?: string,
  ) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          const newData = {
            ...old[rowIndex],
            [columnId]: score,
            id: id || '',
          };
          const subjectScores = getSubjectScores(newData);

          return {
            ...newData,
            total: Object.values(subjectScores).reduce(
              (prev, current) => prev + current,
              0,
            ),
          };
        }

        return row;
      }),
    );
  };

  if (loading) return <DotProgress />;

  if (error) {
    console.error(error);

    return <p>成績情報の取得に失敗しました</p>;
  }

  if (!middleSchoolExamLists?.length) return <p>成績情報が0件です</p>;

  const onChange: SelectInputProps['onChange'] = (event) => {
    const selectedExamKey = event.target.value as MiddleSchoolExamKeys;
    setExamKey(selectedExamKey);
    localStorage.setItem('selectedExamKey', selectedExamKey);
  };

  return (
    <ScoreExamContext.Provider
      value={{
        data: [],
        updateTableData: () => null,
        getLibraryCardIcon: () => null,
        create,
      }}
    >
      <MiddleSchoolExamListComponent
        data={data}
        updateTableData={updateTableData}
        snackbarClose={close}
        snackbarState={state}
        getLibraryCardIcon={getLibraryCardIcon}
        toolbarSelectProps={{
          label: '',
          value: examKey,
          options: Object.entries(middleSchoolExamLabels).map(
            ([key, value]) => ({
              label: value,
              value: key,
            }),
          ),
          onChange,
        }}
      />
    </ScoreExamContext.Provider>
  );
};

export default MiddleSchoolExamListContainer;
