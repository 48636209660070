import React from 'react';
import { Helmet } from 'react-helmet';

import { PAGE_TITLE } from '../../../data/app-basic';
import AdminWrapper from '../../components/layouts/AdminWrapper';
import Container from '../../components/layouts/Container';
import InvoiceListContainer from '../../containers/molecules/table/InvoiceList';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('請求情報一覧')}</title>
      </Helmet>
      <Container>
        <InvoiceListContainer />
      </Container>
    </AdminWrapper>
  );
};
