import clsx from 'clsx';
import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { IconType } from 'react-icons';

import './style.scss';

export type Props = {
  id: string;
  Icon: IconType;
  text: string;
  linkProps: LinkProps;
  selected: boolean;
};

const ListItemLink: FC<Props> = ({ id, Icon, text, linkProps, selected }) => {
  return (
    <li className="menu-item">
      <ListItem
        button
        component={Link}
        {...linkProps}
        classes={{ button: clsx('menu-link', { 'is-selected': selected }) }}
      >
        <ListItemIcon className={clsx('menu-icon', id)}>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} classes={{ primary: 'menu-text' }} />
      </ListItem>
    </li>
  );
};

export default ListItemLink;
