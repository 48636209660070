import React, {
  PropsWithChildren,
  useCallback,
  useState,
  useContext,
} from 'react';
import { Row, TableInstance } from 'react-table';
import {
  Popper,
  IconButton,
  ClickAwayListener,
  Button as MaterialButton,
} from '@material-ui/core';
import {
  RiFilter3Fill,
  CgImport,
  CgExport,
  AiFillCaretLeft,
  AiFillCaretRight,
} from 'react-icons/all';
import FileReaderInput, { Result } from 'react-file-reader-input';
import _ from 'lodash';
import Button from '../../../atoms/button/Button';
import { FilterContext } from '../../../../context/FilterContext';
import exportInvoiceListsCsv from '../../../../../helpers/exportInvoiceListsCsv';
import ModalBulkChange from '../../modal/Invoice/bulk-change';
import { InvoiceContext } from '../../../../context/InvoiceContext';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-types
const Toolbar = <T extends {}>({
  instance: { columns, setAllFilters, rows },
  importCsv,
}: PropsWithChildren<{
  instance: TableInstance<T>;
  importCsv: (invoiceMonth: string, file: File) => void;
}>) => {
  const { filters, setFilters, filterKey } = useContext(FilterContext);
  const { invoiceMonth, onClickPreviousMonth, onClickNextMonth } = useContext(
    InvoiceContext,
  );
  const [opening, setOpening] = useState(false);
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLAnchorElement | null
  >(null);

  const resetFilters = useCallback(() => {
    setAllFilters([]);
    filters.delete(filterKey);
    setFilters(filters);
  }, [setAllFilters, setFilters, filters, filterKey]);

  const handleChangeCsv = (
    event: React.ChangeEvent<HTMLInputElement>,
    results: Result[],
  ) => {
    const [, file] = results[0];
    importCsv(invoiceMonth, file);
  };

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div className="table-toolbar">
        <Popper
          open={Boolean(anchorEl)}
          placement="bottom-end"
          anchorEl={anchorEl}
          className="filter-box"
        >
          <div className="table-filters">
            {columns
              .filter((it) => it.isVisible && it.canFilter)
              .map((column) => (
                <div className="field" key={column.id}>
                  {column.render('Filter')}
                </div>
              ))}
          </div>
          <Button
            buttonProps={{ type: 'button', onClick: resetFilters }}
            color="secondary"
          >
            RESET
          </Button>
        </Popper>
        <div className="select-invoice-month">
          <IconButton className="select-button" onClick={onClickPreviousMonth}>
            <AiFillCaretLeft />
          </IconButton>
          <div className="month-label">{invoiceMonth}</div>
          <IconButton className="select-button" onClick={onClickNextMonth}>
            <AiFillCaretRight />
          </IconButton>
        </div>
        <MaterialButton variant="text" onClick={() => setOpening(!opening)}>
          一括変更
        </MaterialButton>
        <ModalBulkChange
          opening={opening}
          setOpening={setOpening}
          invoiceMonth={invoiceMonth}
          rows={rows as Row<any>[]}
        />
        <FileReaderInput as="text" accept=".csv" onChange={handleChangeCsv}>
          <IconButton area-label="Import" classes={{ label: 'button-label' }}>
            <CgImport />
            <div>import</div>
          </IconButton>
        </FileReaderInput>
        <IconButton
          area-label="Export"
          classes={{ label: 'button-label' }}
          onClick={() => {
            let isContainsPrivate = false;
            rows.forEach((row) => {
              const values = _.filter(row.values, (__, key) => {
                return ['invoice_status'].includes(key);
              });
              const rowValues = Object.entries(values);

              if (rowValues[0][1] !== 'publishing') {
                isContainsPrivate = true;
              }
            });

            if (isContainsPrivate) {
              alert('未確定のデータが含まれる為エクスポートできません。');
            } else {
              exportInvoiceListsCsv(invoiceMonth, rows as Row<any>[]);
            }
          }}
        >
          <CgExport />
          <div>export</div>
        </IconButton>
        <IconButton
          area-label="Filter"
          onClick={(event) =>
            setAnchorEl(anchorEl ? null : event.currentTarget)
          }
        >
          <RiFilter3Fill />
        </IconButton>
      </div>
    </ClickAwayListener>
  );
};

export default Toolbar;
