import React, { FC } from 'react';

import './style.scss';

interface Props {
  date: string;
  category: string;
  title: string;
}

const InformationComponent: FC<Props> = ({ date, category, title }) => {
  return (
    <div className="information">
      <span className="date">{date}</span>
      <span className="category">{category}</span>
      <h3 className="title">{title}</h3>
    </div>
  );
};

export default InformationComponent;
