import React, { useContext } from 'react';

import { ChatContext } from '../../../../context/ChatContext';
import DotProgress from '../../../../components/atoms/feedback/DotProgress';
import RoomHeader from '../../../../components/pages/chat/RoomHeader';
import TalkArea from '../../../../components/pages/chat/TalkArea';
import useFetchTalks from '../../../../hooks/fetch/useFetchTalks';
import TextArea from '../TextArea';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const { selectedRoom, rooms, parents, studentBasics } = useContext(
    ChatContext,
  );
  const room = rooms.find((r) => r.id === selectedRoom);
  const roomParentInfo = parents.find((p) => room?.room_users.includes(p.id));
  const roomStudents = studentBasics
    .filter((s) => room?.room_users.includes(s.parent_id))
    .sort((a, b) => {
      return Number(a.student_num) > Number(b.student_num) ? 1 : -1;
    });
  const { loading, error } = useFetchTalks();

  if (loading)
    return (
      <div className="not-found">
        <DotProgress />
      </div>
    );

  if (error) {
    console.error(error);

    return <p className="not-found">トークの取得に失敗しました</p>;
  }

  if (!room) return <p className="not-found">ルームが選択されていません</p>;

  return (
    <div className="room-container">
      <RoomHeader parentInfo={roomParentInfo} studentBasics={roomStudents} />
      <TalkArea parentInfo={roomParentInfo} />
      <TextArea />
    </div>
  );
};
