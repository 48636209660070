import React from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useFetchTaskEnroll from '../../../../hooks/fetch/useFetchTaskEnroll';
import useCreateTaskEnroll from '../../../../hooks/create/useCreateTaskEnroll';
import TaskEnrollForm from '../../../../components/molecules/form/TaskEnrollForm';
import { TaskEnroll } from '../../../../../domain/models/task';

export default () => {
  const location = useLocation<{ studentId: string | undefined }>();
  if (!location.state) return null;

  const studentId =
    'studentId' in location.state ? location.state.studentId : undefined;

  const { loading, taskItems } = useFetchTaskEnroll({
    queryParams: [{ fieldPath: 'student_id', opStr: '==', value: studentId }],
  });

  const { create, state, close } = useCreateTaskEnroll();

  const formMethods = useForm<TaskEnroll>({
    defaultValues: taskItems,
  });
  const onSubmit = formMethods.handleSubmit(async (data) => {
    const id = await create({
      ...taskItems,
      ...data,
      student_id: studentId || '',
    });
    if (id) formMethods.setValue('id', id);
  });

  return (
    <TaskEnrollForm
      loading={loading}
      taskItems={taskItems}
      formMethods={formMethods}
      onSubmit={onSubmit}
      snackbarClose={close}
      snackbarState={state}
    />
  );
};
