import React, { FC, useEffect, useState } from 'react';
import { Column } from 'react-table';

import TaskEnrollListComponent from '../../../../components/molecules/table/TaskEnrollListComponent';
import useFetchTaskEnrollLists from '../../../../hooks/fetch/useFetchTaskEnrollList';
import useCreateTaskEnroll from '../../../../hooks/create/useCreateTaskEnroll';
import { TaskEnrollList } from '../../../../../types/table/task-enroll-list';
import SelectColumnFilter from '../../../../components/molecules/table/SelectColumnFilter';

const columns: Column<TaskEnrollList>[] = [
  {
    Header: '',
    accessor: 'icon',
    minWidth: 60,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: '',
    accessor: 'id',
  },
  {
    Header: '',
    accessor: 'student_id',
  },
  {
    Header: '生徒番号',
    accessor: 'student_num',
  },
  {
    Header: '生徒名',
    accessor: 'student_name',
  },
  {
    Header: '在籍校舎',
    accessor: 'enrolled_cram_school',
    filter: 'includeSelectValue',
    Filter: SelectColumnFilter,
  },
  {
    accessor: 'input_invoice_data',
    Header: '請求書データ入力',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'input_class_schedule',
    Header: '授業日程管理データ入力',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'create_student_file',
    Header: '生徒・定着ファイルの作成',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'create_report_curriculum',
    Header: '報告書、カリキュラムの作成',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'order_text_book',
    Header: 'テキスト発注',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'distribute_text_book',
    Header: 'テキスト配布',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'distribute_bank_account_print',
    Header: '引き落とし銀行口座用紙配布',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'receive_bank_account_print',
    Header: '引き落とし銀行口座用紙受け取り',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'process_bank_account_print',
    Header: '引き落とし銀行口座用紙手続き',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'distribute_privilege',
    Header: '特典配布',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'collection_result',
    Header: '成績・点数回収',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'deadline',
    Header: 'タスク完了期限',
    disableSortBy: true,
    disableFilters: true,
  },
];

const TaskEnrollListContainer: FC = () => {
  const { loading, enrollTaskList } = useFetchTaskEnrollLists();
  const { creating, create, state, close } = useCreateTaskEnroll();
  const [data, setData] = useState<TaskEnrollList[]>([]);

  useEffect(() => {
    setData(enrollTaskList);
  }, [enrollTaskList]);

  const updateTableData = (rowIndex: number, value: TaskEnrollList) => {
    setData((old) => {
      return old.map((row, index) => {
        if (rowIndex === index) {
          return {
            ...old[rowIndex],
            ...value,
          };
        }

        return row;
      });
    });
  };

  return (
    <TaskEnrollListComponent
      loading={loading}
      data={data}
      updateTableData={updateTableData}
      columns={columns}
      create={create}
      creating={creating}
      snackbarState={state}
      snackbarClose={close}
    />
  );
};

export default TaskEnrollListContainer;
