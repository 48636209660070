import { AdminUser } from '../../domain/models/admin-user';
import {
  IS_NOTES_ELEMENTARY_SCHOOL_LECTURE_TIME,
  IS_NOTES_NORMAL_LECTURE_TIME,
  IS_PRAISE_LECTURE_TIME,
  WorkShift,
} from '../../domain/models/work-shift';
import { LECTURE_KEYS } from '../../domain/models/student-lectures';
import { NOTES_JAPAN_SCHOOLS } from '../../data/form-data';

export type SalaryList = {
  staffId: string;
  staff_num?: string;
  name: string;
  enrolled_cram_school: string;
  employment_status: string;
  worked_days: number;
  lecture_time: number;
  office_training_time: number;
  behind_num: number;
  behind_time: number;
  early_leave_num: number;
  early_leave_time: number;
};

export const getWorkedDaysAndLectureTimeWorkedDays = (
  staff: AdminUser,
  workShifts: WorkShift[],
) => {
  const teacherId = staff.id;

  const isNotesJapan = NOTES_JAPAN_SCHOOLS.some(
    (v) => v === staff.enrolled_cram_school,
  );

  let lectureTime = 0;
  let workedDays = 0;

  workShifts.forEach((ws) => {
    let isWorked = false;
    const maxLectureTime = isNotesJapan
      ? IS_NOTES_NORMAL_LECTURE_TIME
      : IS_PRAISE_LECTURE_TIME; // 1コマの最大時間
    let lectureTimeForEachLecture = 0;

    LECTURE_KEYS.forEach((lectureKey) => {
      lectureTimeForEachLecture = 0;

      void ws.shift[lectureKey]?.forEach((lifs) => {
        if (lectureTimeForEachLecture >= maxLectureTime) {
          return;
        }

        if (
          typeof lifs.subject !== 'string' &&
          typeof lifs.teacher_id !== 'string'
        ) {
          if (lifs.subject.first && lifs.teacher_id.first === teacherId) {
            isWorked = true;
            lectureTimeForEachLecture += IS_NOTES_ELEMENTARY_SCHOOL_LECTURE_TIME;
          }

          if (lifs.subject.latter && lifs.teacher_id.latter === teacherId) {
            isWorked = true;
            lectureTimeForEachLecture += IS_NOTES_ELEMENTARY_SCHOOL_LECTURE_TIME;
          }
        } else if (lifs.subject && lifs.teacher_id === teacherId) {
          isWorked = true;
          lectureTimeForEachLecture = isNotesJapan
            ? IS_NOTES_NORMAL_LECTURE_TIME
            : IS_PRAISE_LECTURE_TIME;
        }
      });

      lectureTime += lectureTimeForEachLecture;
    });

    // 勤務状況にて合計時間が1分以上の場合は出勤日数にカウントすること（「コマの登録はなくてもその他の稼働だけ登録するケース」もあるため）(＝合計時間が0分以下だった場合は出勤日数をそのまま0カウント)
    if (
      ws.works.find(
        (tw) => tw.teacher_id === teacherId && Number(tw.sum_time) >= 1,
      )
    ) {
      isWorked = true;
    }

    if (isWorked) {
      workedDays += 1;
    }
  });

  return [workedDays, lectureTime];
};

export const getNumAndTimeForWorks = (
  staff: AdminUser,
  workShifts: WorkShift[],
) => {
  const teacherId = staff.id;

  let officeTrainingTime = 0;
  let behindNum = 0;
  let behindTime = 0;
  let earlyLeaveNum = 0;
  let earlyLeaveTime = 0;

  workShifts.forEach((ws) => {
    const targetWorks = ws.works.filter(
      (work) => work.teacher_id === teacherId,
    );

    targetWorks.forEach((work) => {
      officeTrainingTime += work?.office_training_time
        ? Number(work?.office_training_time)
        : 0;
      behindNum += Number(work?.behind_time ?? 0) > 0 ? 1 : 0;
      behindTime += work?.behind_time ? Number(work?.behind_time) : 0;
      earlyLeaveNum += Number(work?.early_leave_time ?? 0) > 0 ? 1 : 0;
      earlyLeaveTime += work?.early_leave_time
        ? Number(work?.early_leave_time)
        : 0;
    });
  });

  return [
    officeTrainingTime,
    behindNum,
    behindTime,
    earlyLeaveNum,
    earlyLeaveTime,
  ];
};
