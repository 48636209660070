import { useState } from 'react';

export type VariantKey = 'error' | 'warning' | 'success';

export type SnackbarState = {
  isOpening: boolean;
  variant: VariantKey;
  message: string;
};

export default () => {
  const initialState: SnackbarState = {
    isOpening: false,
    variant: 'error',
    message: '',
  };
  const [state, setSnackbar] = useState(initialState);

  const close = () => {
    setSnackbar(initialState);
  };

  const open = (variant: VariantKey, message: string) => {
    setSnackbar({
      isOpening: true,
      variant,
      message,
    });
  };

  return {
    state,
    close,
    open,
  };
};
