import React, { FC, useEffect, useState } from 'react';
import { Column } from 'react-table';

import TaskTrialListComponent from '../../../../components/molecules/table/TaskTrialListComponent';
import useFetchTaskTrialLists from '../../../../hooks/fetch/useFetchTaskTrialLists';
import useCreateTaskTrial from '../../../../hooks/create/useCreateTaskTrial';
import { TaskTrialList } from '../../../../../types/table/task-trial-list';
import SelectColumnFilter from '../../../../components/molecules/table/SelectColumnFilter';

const columns: Column<TaskTrialList>[] = [
  {
    Header: '',
    accessor: 'icon',
    minWidth: 60,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: '',
    accessor: 'id',
  },
  {
    Header: '',
    accessor: 'student_id',
  },
  {
    Header: '生徒名',
    accessor: 'student_name',
  },
  {
    Header: '在籍校舎',
    accessor: 'enrolled_cram_school',
    filter: 'includeSelectValue',
    Filter: SelectColumnFilter,
  },
  {
    Header: '教室へ面談予約済み',
    accessor: 'reserve_interview',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: '請求書データ入力',
    accessor: 'input_invoice_data',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: '授業日程管理データ入力',
    accessor: 'input_class_schedule',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: '報告書、カリキュラムの作成',
    accessor: 'create_report_curriculum',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: '授業・宿題用プリント作成',
    accessor: 'create_homework_print',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'タスク完了期限',
    accessor: 'deadline',
    disableSortBy: true,
    disableFilters: true,
  },
];

const TaskTrialListContainer: FC = () => {
  const { loading, trialTaskList } = useFetchTaskTrialLists();
  const { creating, create, state, close } = useCreateTaskTrial();
  const [data, setData] = useState<TaskTrialList[]>([]);

  useEffect(() => {
    setData(trialTaskList);
  }, [trialTaskList]);

  const updateTableData = (rowIndex: number, value: TaskTrialList) => {
    setData((old) => {
      return old.map((row, index) => {
        if (rowIndex === index) {
          return {
            ...old[rowIndex],
            ...value,
          };
        }

        return row;
      });
    });
  };

  return (
    <TaskTrialListComponent
      loading={loading}
      data={data}
      updateTableData={updateTableData}
      columns={columns}
      create={create}
      creating={creating}
      snackbarState={state}
      snackbarClose={close}
    />
  );
};

export default TaskTrialListContainer;
