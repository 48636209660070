import { createContext } from 'react';

import { MiddleSchoolExamsList } from '../../types/table/middle-school-exams-list';
import { MiddleSchoolExam } from '../../domain/models/middle-school-exam';
import { SnackbarState } from '../hooks/useSnackbar';

export type ScoreExamContextType = {
  data: MiddleSchoolExamsList[];
  updateTableData: (
    rowIndex: number,
    columnId: string,
    score: number,
    id?: string,
  ) => void;
  getLibraryCardIcon: (
    rowIndex: number,
    rowValues: MiddleSchoolExamsList,
  ) => JSX.Element | null;
  creating?: boolean;
  create?: (middleSchoolExam: MiddleSchoolExam) => Promise<string | undefined>;
  snackbarState?: SnackbarState;
  closeSnackbar?: () => void;
};

export const ScoreExamContext = createContext<ScoreExamContextType>({
  data: [],
  updateTableData: () => null,
  getLibraryCardIcon: () => null,
  creating: false,
});
