import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import queryString from 'query-string';
import {
  defaultTimetable,
  Timetable,
} from '../../../../../domain/models/timetable';
import TimetableForm from '../../../../components/molecules/form/TimetableForm';
import useFetchTimetable from '../../../../hooks/fetch/useFetchTimetables';
import useCreateTimetable from '../../../../hooks/create/useCreateTimetable';
import useFetchParentInfo from '../../../../hooks/fetch/useFetchParentInfo';
import { YearMonthContext } from '../../../../context/YearMonthContext';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const { yearMonth, setYearMonth } = useContext(YearMonthContext);
  const location = useLocation<{ studentId: string | undefined }>();
  const queryStringId = queryString.parse(location.search).id;

  if (!location.state && !queryStringId) return null;

  const studentId = location.state?.studentId ?? (queryStringId as string);

  const { loading, studentBasics, timetables } = useFetchTimetable({
    id: studentId,
    queryParams: [
      { fieldPath: 'student_id', opStr: '==', value: studentId },
      { fieldPath: 'year_month', opStr: '==', value: yearMonth },
    ],
  });
  const { parents } = useFetchParentInfo({ id: studentBasics[0]?.parent_id });
  const { creating, create, close, state } = useCreateTimetable();
  const formMethods = useForm<Timetable>({
    defaultValues: timetables[0],
  });

  const onChange: SelectInputProps['onChange'] = (event) => {
    setYearMonth(event.target.value as string);
  };

  const onSubmit = formMethods.handleSubmit(async (data) => {
    const newData = {
      ...defaultTimetable,
      ...data,
    };
    const id = await create(newData);
    if (id && !data.id) formMethods.setValue('id', id || '');
  });

  const today = new Date();
  const thisYear = today.getFullYear();
  const nextYear = today.getFullYear() + 1;
  const selectableOptions = Array.from(Array(24).keys())
    .map((i) => {
      const o = i + 1;

      return o;
    })
    .map((month) => {
      const useYear = String(month <= 12 ? thisYear : nextYear);
      let useMonth: string | number = month <= 12 ? month : month - 12;
      useMonth = useMonth <= 9 ? `0${String(useMonth)}` : String(useMonth);

      return DateTime.fromISO(`${useYear}-${useMonth}`).toFormat('yyyy/MM');
    });

  return (
    <TimetableForm
      loading={loading}
      studentBasic={studentBasics[0]}
      timetable={timetables[0]}
      parentInfo={parents[0]}
      formMethods={formMethods}
      onSubmit={onSubmit}
      snackbarState={state}
      snackbarClose={close}
      creating={creating}
      selectProps={{
        label: '',
        value: yearMonth,
        options: selectableOptions.map((ym) => ({
          label: ym,
          value: ym,
        })),
        onChange,
      }}
    />
  );
};
