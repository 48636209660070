import clsx from 'clsx';
import React, { FC, useContext, useEffect } from 'react';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/all';
import { Column } from 'react-table';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { WeekDefaultList } from '../../../../../types/table/week-default-list';
import Table from '../Table';
import DefaultColumnFilter from '../DefaultColumnFilter';
import useReactTableFilter from '../../../../hooks/useReactTableFilter';
import DotProgress from '../../../atoms/feedback/DotProgress';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import Backdrop from '../../../atoms/feedback/Backdrop';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import useImportWithdrawalResultsCsv from '../../../../hooks/useImportWithdrawalResultsCsv';
import { WeekDefaultContext } from '../../../../context/WeekDefaultContext';
import { FilterContext } from '../../../../context/FilterContext';
import './style.scss';

export type Props = {
  loading: boolean;
  data: WeekDefaultList[];
  updateTableData: (rowIndex: number, value: WeekDefaultList) => void;
  columns: Column<WeekDefaultList>[];
  snackbarState: SnackbarState;
  snackbarClose: () => void;
  toolbarSelectProps?: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
  toolbarSelectProps2?: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
};

const WeekDefaultListComponent: FC<Props> = ({
  loading,
  data,
  updateTableData,
  columns,
  snackbarState,
  snackbarClose,
  toolbarSelectProps,
  toolbarSelectProps2,
}) => {
  const history = useHistory();
  const filterTypes = useReactTableFilter();
  const { creating, setBulkChanged } = useContext(WeekDefaultContext);
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const { setFilterKey } = useContext(FilterContext);
  const pageName = 'week-default-list';

  useEffectOnce(() => {
    setFilterKey(pageName);
  });

  const { importing, state, close } = useImportWithdrawalResultsCsv();

  useEffect(() => {
    if (state.isOpening) {
      window.setTimeout(() => {
        if (setBulkChanged) setBulkChanged(true);
      }, 2000);
    }
  }, [setBulkChanged, state.isOpening]);

  return (
    <div>
      <h3 className="title">曜日マスター</h3>
      <Table<WeekDefaultList>
        classes={{
          root: clsx('table-container'),
          table: clsx(
            'table',
            'is-fullwidth',
            'is-striped',
            'is-hoverable',
            'list-table',
          ),
        }}
        tableOptions={{
          data,
          columns,
          filterTypes,
          defaultColumn,
          manualPagination: false,
          maxMultiSortColCount: 3,
          orderByFn: (rows) => {
            return rows.slice().sort((a, b) => {
              const aNum = a.original.student_num;
              const bNum = b.original.student_num;
              if (aNum !== bNum) {
                return aNum > bNum ? 1 : -1;
              }

              return 0;
            });
          },
          initialState: {
            sortBy: [{ id: 'student_num' }],
            hiddenColumns: [],
            pageSize: 50,
          },
          updateTableData,
        }}
        theadThRender={(column) => {
          if (column.id === 'student_id') {
            // フィルタまたは一括変更のためにhiddenColumnsに指定していないだけなので内容は表示しない
            return <span key={column.id} />;
          }

          return (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              className={`column-${column.id}`}
            >
              <span>{column.render('Header')}</span>
              {column.isSorted &&
                (column.isSortedDesc ? (
                  <AiFillCaretDown fontSize="small" />
                ) : (
                  <AiFillCaretUp fontSize="small" />
                ))}
            </th>
          );
        }}
        tbodyTdRender={(row, cell) => {
          switch (cell.column.id) {
            case 'student_id': {
              // フィルタまたは一括変更のためにhiddenColumnsに指定していないだけなので内容は表示しない
              return <span key={cell.column.id} />;
            }
            default:
              return (
                <td
                  {...cell.getCellProps()}
                  className={`column-${cell.column.id}`}
                >
                  {cell.render('Cell')}
                </td>
              );
          }
        }}
        toolbarSelectProps={toolbarSelectProps}
        toolbarSelectProps2={toolbarSelectProps2}
        pageName={pageName}
        onClick={(row) => {
          const studentId =
            row?.values?.student_id &&
            typeof row?.values?.student_id === 'string'
              ? row.values.student_id
              : undefined;
          history.push('/student/detail', { studentId });
        }}
      />
      {loading && <DotProgress />}

      <Backdrop open={creating || importing} />
      <SnackbarWrapper
        message={snackbarState?.message || ''}
        isOpening={snackbarState?.isOpening || false}
        variant={snackbarState?.variant || 'success'}
        onClose={snackbarClose}
      />
      <SnackbarWrapper
        message={state.message}
        isOpening={state.isOpening}
        variant={state.variant}
        onClose={close}
      />
    </div>
  );
};

export default WeekDefaultListComponent;
