import { UseFormMethods } from 'react-hook-form/dist/types';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { DateTime } from 'luxon';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Select from 'react-select';
import clsx from 'clsx';
import { FaSave } from 'react-icons/all';
import useRole from '../../../../hooks/useRole';
import { BulkChangeTimetable } from '../../../../../types/form/bulk-change-timetable';
import Button from '../../../atoms/button/Button';
import DateField from '../../../atoms/form/DatePicker';
import {
  GRADES,
  NOTES_JAPAN_CLASSES,
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_SCHOOLS,
  NOTES_PRAISE_HOLIDAY_CLASSES,
  NOTES_PRAISE_WEEKDAYS_CLASSES,
  NotesJapanClass,
  NotesPraiseClass,
  ElementarySchoolSubject,
  MiddleSchoolSubject,
  HighSchoolSubject,
  ELEMENTARY_SCHOOL_GRADES,
  SPECIAL_SUBJECTS,
  SpecialSubject,
} from '../../../../../data/form-data';
import ErrorMessage from '../../../atoms/form/ErrorMessage';
import Backdrop from '../../../atoms/feedback/Backdrop';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import { getSubjects } from '../../../../../domain/models/student-basic';

export type Props = {
  formMethods: UseFormMethods<BulkChangeTimetable>;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  creating: boolean;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
  isDialog: boolean;
  setIsDialog: Dispatch<SetStateAction<boolean>>;
  isValidateThrough: boolean;
  setIsValidateThrough: Dispatch<SetStateAction<boolean>>;
  errorMessage: string;
};

const TimetableBulkChangeForm: FC<Props> = ({
  formMethods,
  onSubmit,
  creating,
  snackbarState,
  snackbarClose,
  isDialog,
  setIsDialog,
  isValidateThrough,
  setIsValidateThrough,
  errorMessage,
}) => {
  const { isEditor, isViewer, enrolledCramSchool } = useRole();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { errors, control, watch, setValue, trigger } = formMethods;
  const [classes, setClasses] = useState<
    (NotesJapanClass | NotesPraiseClass)[]
  >([]);
  const [subjects, setSubjects] = useState<
    (
      | ElementarySchoolSubject
      | MiddleSchoolSubject
      | HighSchoolSubject
      | SpecialSubject
    )[]
  >([]);
  const [isNotesJapan, setIsNotesJapan] = useState(false);
  const [isSeparatedLecture, setIsSeparatedLecture] = useState(false);

  useEffect(() => {
    // 選択肢のリセット
    setValue('lecture', null);
    setValue('subject', { first: '', latter: '' });
    setValue('subject', null);

    // 授業時間の制御
    const enrolled_cram_school = watch('enrolled_cram_school');
    setIsNotesJapan(
      NOTES_JAPAN_SCHOOLS.some((v) => v === enrolled_cram_school),
    );
    if (isNotesJapan) {
      setClasses(NOTES_JAPAN_CLASSES);
    } else {
      const date = watch('date');
      if (date) {
        const day = new Date(date).getDay();
        if (day === 0 || day === 6) {
          setClasses(NOTES_PRAISE_HOLIDAY_CLASSES);
        } else {
          setClasses(NOTES_PRAISE_WEEKDAYS_CLASSES);
        }
      }
    }
    // eslint-disable-next-line
  }, [watch('enrolled_cram_school'), watch('date'), setIsNotesJapan, isNotesJapan, setClasses, setValue]);

  useEffect(() => {
    // 教科の制御
    const grade = watch('grade');
    const isSpecialLecture = watch('lecture') === '特別授業';
    if (grade) {
      if (isSpecialLecture) {
        setSubjects(SPECIAL_SUBJECTS);
      } else {
        setSubjects(getSubjects(grade));
      }
    }
    setIsSeparatedLecture(
      isNotesJapan &&
        ELEMENTARY_SCHOOL_GRADES.some((v) => v === grade) &&
        !isSpecialLecture,
    );
    // eslint-disable-next-line
  }, [watch('enrolled_cram_school'), watch('grade'), watch('lecture'), setSubjects, setIsSeparatedLecture, isNotesJapan]);

  useEffect(() => {
    if (isValidateThrough) {
      void onSubmit();
    }
  }, [isValidateThrough, onSubmit]);

  return (
    <div className="modal-box">
      <div className="modal-inner">
        <form onSubmit={onSubmit}>
          <div>
            <div className="field">
              <div className="field">
                <p className="label">日付</p>
                <Controller
                  name="date"
                  control={control}
                  defaultValue={null}
                  rules={{ required: '日付を選択してください' }}
                  as={
                    <DateField
                      DatePickerProps={{
                        label: '',
                        name: 'date',
                        value: watch('date')
                          ? DateTime.fromFormat(
                              watch('date') ?? '',
                              'yyyy/MM/dd',
                            )
                          : null,
                        format: 'yyyy/MM/dd',
                        TextFieldComponent: (props) => (
                          <TextField {...props} margin="none" />
                        ),
                        onChange: (v) => {
                          const date = v ? v.toFormat('yyyy/MM/dd') : null;
                          setValue('date', date ?? '');
                        },
                        error: errors?.date && true,
                        inputVariant: 'outlined',
                        className: 'text-field-outlined',
                      }}
                    />
                  }
                />
                <ErrorMessage
                  message={errors?.date?.message}
                  className="my-2"
                />
              </div>
              <p className="label">所属校舎</p>
              <Controller
                name="enrolled_cram_school"
                control={control}
                defaultValue={null}
                rules={{ required: '所属校舎を選択してください' }}
                as={
                  <div>
                    <Select
                      name="enrolled_cram_school"
                      options={
                        isEditor || isViewer
                          ? [enrolledCramSchool].map((school) => ({
                              label: school,
                              value: school,
                            }))
                          : [
                              ...NOTES_JAPAN_SCHOOLS,
                              ...NOTES_PRAISE_SCHOOLS,
                            ].map((school) => ({
                              label: school,
                              value: school,
                            }))
                      }
                      value={
                        watch('enrolled_cram_school')
                          ? {
                              label: watch('enrolled_cram_school'),
                              value: watch('enrolled_cram_school'),
                            }
                          : null
                      }
                      onChange={async (value) => {
                        setValue(
                          'enrolled_cram_school',
                          value && 'value' in value ? value.value : '',
                        );
                        await trigger('enrolled_cram_school');
                      }}
                      error={errors?.enrolled_cram_school}
                      isDisabled={!watch('date')}
                      placeholder={
                        !watch('date')
                          ? '日付から先に選択してください'
                          : '所属校舎を選択してください'
                      }
                    />
                    <ErrorMessage
                      message={errors?.enrolled_cram_school?.message}
                      className="my-2"
                    />
                  </div>
                }
              />
            </div>
            <div className="field">
              <p className="label">学年</p>
              <Controller
                name="grade"
                control={control}
                defaultValue={null}
                rules={{ required: '学年を選択してください' }}
                as={
                  <div>
                    <Select
                      name="grade"
                      options={GRADES.map((grade) => ({
                        label: grade,
                        value: grade,
                      }))}
                      value={
                        watch('grade')
                          ? {
                              label: watch('grade'),
                              value: watch('grade'),
                            }
                          : null
                      }
                      onChange={async (value) => {
                        setValue(
                          'grade',
                          value && 'value' in value ? value.value : '',
                        );
                        await trigger('grade');
                      }}
                      error={errors?.grade}
                      isDisabled={!watch('enrolled_cram_school')}
                      placeholder={
                        !watch('enrolled_cram_school')
                          ? '所属校舎から先に選択してください'
                          : '学年を選択してください'
                      }
                    />
                    <ErrorMessage
                      message={errors?.grade?.message}
                      className="my-2"
                    />
                  </div>
                }
              />
            </div>
            <div className="field">
              <p className="label">授業時間</p>
              <Controller
                name="lecture"
                control={control}
                defaultValue={null}
                rules={{ required: '授業時間を選択してください' }}
                as={
                  <div>
                    <Select
                      name="lecture"
                      options={[...classes, '特別授業'].map((lecture) => ({
                        label: lecture,
                        value: lecture,
                      }))}
                      value={
                        watch('lecture')
                          ? {
                              label: watch('lecture') as
                                | NotesJapanClass
                                | NotesPraiseClass
                                | '特別授業',
                              value: watch('lecture') as
                                | NotesJapanClass
                                | NotesPraiseClass
                                | '特別授業',
                            }
                          : null
                      }
                      onChange={async (value) => {
                        setValue(
                          'lecture',
                          value && 'value' in value ? value.value : '',
                        );
                        await trigger('lecture');
                      }}
                      error={errors?.lecture}
                      isDisabled={!watch('grade')}
                      placeholder={
                        !watch('grade')
                          ? '学年から先に選択してください'
                          : '授業時間を選択してください'
                      }
                    />
                    <ErrorMessage
                      message={errors?.lecture?.message}
                      className="my-2"
                    />
                  </div>
                }
              />
            </div>
            <div className="field">
              <p className="label">教科</p>
              {!isSeparatedLecture ? (
                <Controller
                  name="subject"
                  control={control}
                  defaultValue={null}
                  rules={{ required: '教科を選択してください' }}
                  as={
                    <div>
                      <Select
                        name="subject"
                        options={subjects.map((subject) => ({
                          label: subject,
                          value: subject,
                        }))}
                        value={
                          typeof watch('subject') === 'string' &&
                          watch('subject')
                            ? {
                                label: watch('subject'),
                                value: watch('subject'),
                              }
                            : null
                        }
                        onChange={async (value) => {
                          setValue(
                            'subject',
                            value && 'value' in value ? value.value : '',
                          );
                          await trigger('subject');
                        }}
                        error={errors?.subject}
                        isDisabled={!watch('lecture')}
                        placeholder={
                          !watch('lecture')
                            ? '授業時間から先に選択してください'
                            : '教科を選択してください'
                        }
                      />
                      <ErrorMessage
                        message={errors?.subject?.message}
                        className="my-2"
                      />
                    </div>
                  }
                />
              ) : (
                <>
                  <div>前半</div>
                  <Controller
                    name="subject.first"
                    control={control}
                    defaultValue={null}
                    rules={{ required: '教科(前半)を選択してください' }}
                    as={
                      <div>
                        <Select
                          name="subject.first"
                          options={subjects.map((subject) => ({
                            label: subject,
                            value: subject,
                          }))}
                          value={
                            watch('subject.first')
                              ? {
                                  label: watch('subject.first'), // eslint-disable-line
                                  value: watch('subject.first'), // eslint-disable-line
                                }
                              : null
                          }
                          onChange={async (value) => {
                            setValue(
                              'subject.first',
                              value && 'value' in value ? value.value : '',
                            );
                            await trigger('subject.first');
                          }}
                          error={(errors?.subject as any)?.first} // eslint-disable-line
                          isDisabled={!watch('lecture')}
                          placeholder={
                            !watch('lecture')
                              ? '授業時間から先に選択してください'
                              : '教科を選択してください'
                          }
                        />
                        <ErrorMessage
                          message={(errors?.subject as any)?.first?.message} // eslint-disable-line
                          className="my-2"
                        />
                      </div>
                    }
                  />
                  <div>後半</div>
                  <Controller
                    name="subject.latter"
                    control={control}
                    defaultValue={null}
                    rules={{ required: '教科(後半)を選択してください' }}
                    as={
                      <div>
                        <Select
                          name="subject.latter"
                          options={subjects.map((subject) => ({
                            label: subject,
                            value: subject,
                          }))}
                          value={
                            watch('subject.latter')
                              ? {
                                label: watch('subject.latter'), // eslint-disable-line
                                value: watch('subject.latter'), // eslint-disable-line
                                }
                              : null
                          }
                          onChange={async (value) => {
                            setValue(
                              'subject.latter',
                              value && 'value' in value ? value.value : '',
                            );
                            await trigger('subject.latter');
                          }}
                          error={(errors?.subject as any)?.latter} // eslint-disable-line
                          isDisabled={!watch('lecture')}
                          placeholder={
                            !watch('lecture')
                              ? '授業時間から先に選択してください'
                              : '教科を選択してください'
                          }
                        />
                        <ErrorMessage
                          message={(errors?.subject as any)?.latter?.message} // eslint-disable-line
                          className="my-2"
                        />
                      </div>
                    }
                  />
                </>
              )}
            </div>
            <div className="btn-area">
              {!!errorMessage && (
                <ErrorMessage message={errorMessage} className="mt-6" />
              )}
              <Button
                buttonProps={{ type: 'submit' }}
                color="primary"
                className={clsx('with-icon', 'btn-primary')}
              >
                <FaSave />
                保存
              </Button>
            </div>
          </div>
          <Dialog open={isDialog}>
            <DialogContent>
              <DialogContentText>
                既に授業が設定されている生徒が含まれています。
                <br />
                上書きしてよろしいですか？
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                buttonProps={{
                  type: 'button',
                  onClick: () => {
                    setIsDialog(false);
                  },
                  autoFocus: true,
                }}
              >
                いいえ
              </Button>
              <Button
                buttonProps={{
                  type: 'submit',
                  onClick: () => {
                    setIsValidateThrough(true);
                  },
                }}
              >
                はい
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </div>
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default TimetableBulkChangeForm;
