import React from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import queryString from 'query-string';
import {
  defaultStudentBasic,
  StudentBasic,
} from '../../../../../domain/models/student-basic';
import useFetchStudentBasics from '../../../../hooks/fetch/useFetchStudentBasics';
import StudentBasicForm from '../../../../components/molecules/form/StudentBasicForm';
import useCreateStudentBasic from '../../../../hooks/create/useCreateStudentBasic';
import useFetchParentInfo from '../../../../hooks/fetch/useFetchParentInfo';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const location = useLocation<{ studentId: string | undefined }>();
  const queryStringId = queryString.parse(location.search).id;

  if (!location.state && !queryStringId) return null;

  const studentId = location.state?.studentId ?? (queryStringId as string);

  const { loading, studentBasics } = useFetchStudentBasics({
    id: studentId,
  });
  const studentBasic = studentBasics[0];
  const { parents } = useFetchParentInfo({ id: studentBasic?.parent_id });
  const { creating, create, close, state } = useCreateStudentBasic();
  const formMethods = useForm<StudentBasic>({
    defaultValues: studentBasics[0],
  });
  const onSubmit = formMethods.handleSubmit((data) => {
    create({
      ...defaultStudentBasic,
      ...data,
      id: studentBasics[0].id,
      parent_id: studentBasics[0].parent_id,
    });
  });

  return (
    <StudentBasicForm
      loading={loading}
      parentInfo={parents[0]}
      studentBasic={studentBasic}
      formMethods={formMethods}
      onSubmit={onSubmit}
      snackbarState={state}
      snackbarClose={close}
      creating={creating}
    />
  );
};
