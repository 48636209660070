import React, { FC, useContext } from 'react';
import { useDebounce } from 'react-use';
import { FilterProps } from 'react-table';

import CheckBox from '@material-ui/core/Checkbox';
import { FilterContext } from '../../../../context/FilterContext';

/* eslint-disable @typescript-eslint/no-explicit-any */
const CheckboxColumnColumnFilter: FC<{
  filterProps: FilterProps<any>;
}> = ({ filterProps: { column } }) => {
  const { filters, setFilters, filterKey } = useContext(FilterContext);
  const { render, setFilter } = column;
  const filterValue: boolean | undefined =
    typeof column.filterValue === 'boolean' ? column.filterValue : false;

  useDebounce(
    () => {
      setFilter(filterValue);
      if (filterValue !== undefined) {
        setFilters((prevState) => {
          if (prevState && prevState.has(filterKey)) {
            filters.set(filterKey, [
              ...prevState.get(filterKey)!.filter((v) => v.id !== column.id),
              { id: column.id, value: filterValue },
            ]);

            return filters;
          }
          filters.set(filterKey, [{ id: column.id, value: filterValue }]);

          return filters;
        });
      } else {
        setFilters((prevState) => {
          if (prevState && prevState.has(filterKey)) {
            filters.set(filterKey, [
              ...prevState.get(filterKey)!.filter((v) => v.id !== column.id),
            ]);

            return filters;
          }

          return filters;
        });
      }
    },
    300,
    [filterValue],
  );

  return (
    <div>
      <div className="label">{render('Header')}</div>
      <div className="control">
        <CheckBox
          checked={filterValue}
          onChange={(event, newValue) => {
            setFilter(newValue);
          }}
        />
      </div>
    </div>
  );
};

export default CheckboxColumnColumnFilter;
