import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { useEffectOnce } from 'react-use';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import SchoolCalendarForm from '../../../../components/molecules/form/SchoolCalendarForm';
import useRole from '../../../../hooks/useRole';
import { AppContext } from '../../../../context/AppContext';
import { EnrolledCramSchoolContext } from '../../../../context/EnrolledCramSchoolContext';
import { YearContext } from '../../../../context/YearContext';
import {
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_SCHOOLS,
  NotesJapanSchool,
  NotesPraiseSchool,
} from '../../../../../data/form-data';
import useFetchSchoolCalendar from '../../../../hooks/fetch/useFetchSchoolCalendar';
import useCreateSchoolCalendar from '../../../../hooks/create/useCreateSchoolCalendar';
import {
  defaultSchoolCalendar,
  SchoolCalendar,
} from '../../../../../domain/models/school-calendar';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const { isAdministrator, isHeadquarters } = useRole();
  const { adminUser } = useContext(AppContext);
  const selectEnrolledCramSchools =
    isAdministrator || isHeadquarters
      ? [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS]
      : [adminUser.enrolled_cram_school];
  const { enrolledCramSchool, setEnrolledCramSchool } = useContext(
    EnrolledCramSchoolContext,
  );
  const { year, setYear } = useContext(YearContext);
  const thisYear = new Date().getFullYear();

  useEffectOnce(() => {
    setEnrolledCramSchool(
      isAdministrator || isHeadquarters
        ? [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS][0]
        : adminUser.enrolled_cram_school,
    );
  });

  const queryParams = [
    {
      fieldPath: 'school_name',
      opStr: '==',
      value: enrolledCramSchool,
    },
    {
      fieldPath: 'year',
      opStr: '==',
      value: year,
    },
  ];

  const {
    loading,
    schoolCalendars,
    setSchoolCalendars,
  } = useFetchSchoolCalendar({
    queryParams,
  });
  const { creating, create, close, state } = useCreateSchoolCalendar();
  const formMethods = useForm<SchoolCalendar>({
    defaultValues: schoolCalendars[0],
  });

  const onChange: SelectInputProps['onChange'] = (event) => {
    setEnrolledCramSchool(
      event.target.value as NotesJapanSchool | NotesPraiseSchool,
    );
  };
  const onChangeYear: SelectInputProps['onChange'] = (event) => {
    setYear(event.target.value as string);
  };

  const onSubmit = formMethods.handleSubmit(async (data) => {
    // 登録・編集用データの元
    const createValue = {
      ...defaultSchoolCalendar,
      ...data,
      id: schoolCalendars[0]?.id,
      school_name: schoolCalendars[0]?.school_name
        ? schoolCalendars[0]?.school_name
        : enrolledCramSchool,
    };

    const id = await create(createValue);

    if (id) {
      setSchoolCalendars([
        {
          ...createValue,
          id,
          school_name: enrolledCramSchool,
        },
      ]);
    }
  });

  return (
    <SchoolCalendarForm
      loading={loading}
      schoolCalendar={schoolCalendars[0] ?? defaultSchoolCalendar}
      formMethods={formMethods}
      onSubmit={onSubmit}
      creating={creating}
      snackbarState={state}
      snackbarClose={close}
      selectProps={{
        label: '',
        value: enrolledCramSchool,
        options: selectEnrolledCramSchools.map((school) => ({
          label: school,
          value: school,
        })),
        onChange,
      }}
      selectPropsYear={{
        label: '',
        value: year,
        options: [
          String(thisYear),
          String(thisYear + 1),
          String(thisYear + 2),
          String(thisYear + 3),
        ].map((y) => ({
          label: y,
          value: y,
        })),
        onChange: onChangeYear,
      }}
    />
  );
};
