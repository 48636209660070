import { createContext, Dispatch, SetStateAction } from 'react';
import { SelectedStaff } from '../../domain/models/admin-user';

export type WorkShiftSelectedStaffsContextType = {
  lecture1SelectedStaffs: SelectedStaff[];
  lecture2SelectedStaffs: SelectedStaff[];
  lecture3SelectedStaffs: SelectedStaff[];
  lecture4SelectedStaffs: SelectedStaff[];
  lecture5SelectedStaffs: SelectedStaff[];
  lectureSpecialSelectedStaffs: SelectedStaff[];
  setLecture1SelectedStaffs: Dispatch<SetStateAction<SelectedStaff[]>>;
  setLecture2SelectedStaffs: Dispatch<SetStateAction<SelectedStaff[]>>;
  setLecture3SelectedStaffs: Dispatch<SetStateAction<SelectedStaff[]>>;
  setLecture4SelectedStaffs: Dispatch<SetStateAction<SelectedStaff[]>>;
  setLecture5SelectedStaffs: Dispatch<SetStateAction<SelectedStaff[]>>;
  setLectureSpecialSelectedStaffs: Dispatch<SetStateAction<SelectedStaff[]>>;
  lecture1SelectedStaffsInitialLoading: boolean;
  lecture2SelectedStaffsInitialLoading: boolean;
  lecture3SelectedStaffsInitialLoading: boolean;
  lecture4SelectedStaffsInitialLoading: boolean;
  lecture5SelectedStaffsInitialLoading: boolean;
  lectureSpecialSelectedStaffsInitialLoading: boolean;
  setLecture1SelectedStaffsInitialLoading: Dispatch<SetStateAction<boolean>>;
  setLecture2SelectedStaffsInitialLoading: Dispatch<SetStateAction<boolean>>;
  setLecture3SelectedStaffsInitialLoading: Dispatch<SetStateAction<boolean>>;
  setLecture4SelectedStaffsInitialLoading: Dispatch<SetStateAction<boolean>>;
  setLecture5SelectedStaffsInitialLoading: Dispatch<SetStateAction<boolean>>;
  setLectureSpecialSelectedStaffsInitialLoading: Dispatch<
    SetStateAction<boolean>
  >;
};

export const WorkShiftSelectedStaffsContext = createContext<
  WorkShiftSelectedStaffsContextType
>({
  lecture1SelectedStaffs: [],
  lecture2SelectedStaffs: [],
  lecture3SelectedStaffs: [],
  lecture4SelectedStaffs: [],
  lecture5SelectedStaffs: [],
  lectureSpecialSelectedStaffs: [],
  setLecture1SelectedStaffs: () => null,
  setLecture2SelectedStaffs: () => null,
  setLecture3SelectedStaffs: () => null,
  setLecture4SelectedStaffs: () => null,
  setLecture5SelectedStaffs: () => null,
  setLectureSpecialSelectedStaffs: () => null,
  lecture1SelectedStaffsInitialLoading: true,
  lecture2SelectedStaffsInitialLoading: true,
  lecture3SelectedStaffsInitialLoading: true,
  lecture4SelectedStaffsInitialLoading: true,
  lecture5SelectedStaffsInitialLoading: true,
  lectureSpecialSelectedStaffsInitialLoading: true,
  setLecture1SelectedStaffsInitialLoading: () => null,
  setLecture2SelectedStaffsInitialLoading: () => null,
  setLecture3SelectedStaffsInitialLoading: () => null,
  setLecture4SelectedStaffsInitialLoading: () => null,
  setLecture5SelectedStaffsInitialLoading: () => null,
  setLectureSpecialSelectedStaffsInitialLoading: () => null,
});
