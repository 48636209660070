import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';

import AdminWrapper from '../../components/layouts/AdminWrapper';
import Container from '../../components/layouts/Container';
import SchoolCalendarForm from '../../containers/molecules/form/SchoolCalendarForm';
import { PAGE_TITLE } from '../../../data/app-basic';
import { EnrolledCramSchoolContext } from '../../context/EnrolledCramSchoolContext';
import { YearContext } from '../../context/YearContext';

const SchoolCalendarDetail: FC = () => {
  const [enrolledCramSchool, setEnrolledCramSchool] = useState<string>('');
  const thisYear = new Date().getFullYear();
  const [year, setYear] = useState<string>(String(thisYear));

  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('校舎マスター')}</title>
      </Helmet>
      <Container>
        <EnrolledCramSchoolContext.Provider
          value={{
            enrolledCramSchool,
            setEnrolledCramSchool,
          }}
        >
          <YearContext.Provider
            value={{
              year,
              setYear,
            }}
          >
            <SchoolCalendarForm />
          </YearContext.Provider>
        </EnrolledCramSchoolContext.Provider>
      </Container>
    </AdminWrapper>
  );
};

export default SchoolCalendarDetail;
