import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { FC, useEffect } from 'react';
import { OptionTypeBase, ValueType } from 'react-select';
import { Motion, spring } from 'react-motion';
import { useEffectOnce } from 'react-use';
import { Controller, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { FaSave, RiArrowGoBackFill } from 'react-icons/all';
import { TextField } from '@material-ui/core';
import { CgCloseO } from 'react-icons/cg';
import _ from 'lodash';

import {
  dateFormat,
  LECTURE_COUNTS,
  LECTURE_TIMES,
  NOTES_JAPAN_CLASSES,
  ElementarySchoolSubject,
  MiddleSchoolSubject,
  HighSchoolSubject,
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_WEEKDAYS_CLASSES,
  NOTES_PRAISE_HOLIDAY_CLASSES,
  NOTES_JAPAN_COURSES,
  NOTES_PRAISE_COURSES,
  ELEMENTARY_SCHOOL_GRADES,
} from '../../../../../data/form-data';
import DotProgress from '../../../atoms/feedback/DotProgress';
import Select from '../../../atoms/form/Select';
import Button from '../../../atoms/button/Button';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import Backdrop from '../../../atoms/feedback/Backdrop';
import DateField from '../../../atoms/form/DatePicker';
import ErrorMessage from '../../../atoms/form/ErrorMessage';
import {
  getSubjects,
  StudentBasic,
} from '../../../../../domain/models/student-basic';
import {
  BASE_LECTURE_KEYS,
  LECTURE_DAY_KEYS,
  jaLectureDays,
  SeparatedLecture,
  TrialLecture,
  StudentLectures,
  defaultSeparatedLecture,
  getLecture,
} from '../../../../../domain/models/student-lectures';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import './style.scss';
import useRole from '../../../../hooks/useRole';
import { ParentInfo } from '../../../../../domain/models/parent-info';
import { SelectSubject } from '../utils/SelectSubject';

export type Props = {
  loading: boolean;
  studentBasic: StudentBasic;
  studentLectures: StudentLectures;
  parentInfo: ParentInfo;
  formMethods: UseFormMethods<StudentLectures>;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
  creating: boolean;
};

const StudentLecturesForm: FC<Props> = ({
  loading,
  studentBasic,
  studentLectures,
  parentInfo,
  formMethods,
  onSubmit,
  snackbarState,
  snackbarClose,
  creating,
}) => {
  const { isViewer } = useRole();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { errors, control, watch, setValue, trigger, register } = formMethods;
  const { fields, append, remove } = useFieldArray<TrialLecture>({
    control,
    name: 'trial_lectures',
  });

  const isNotesJapan = NOTES_JAPAN_SCHOOLS.some(
    (v) => v === studentBasic?.enrolled_cram_school,
  );
  const isNotesElementarySchool =
    isNotesJapan &&
    ELEMENTARY_SCHOOL_GRADES.some((v) => v === studentBasic?.grade);
  const classes: any = isNotesJapan
    ? NOTES_JAPAN_CLASSES
    : [...NOTES_PRAISE_WEEKDAYS_CLASSES, ...NOTES_PRAISE_HOLIDAY_CLASSES];
  const subjects: (
    | ElementarySchoolSubject
    | MiddleSchoolSubject
    | HighSchoolSubject
  )[] = getSubjects(studentBasic?.grade);

  useEffect(() => {
    if (studentLectures?.trial_lectures.length) {
      append(studentLectures.trial_lectures);
    }

    LECTURE_DAY_KEYS.forEach((lectureDayKey) => {
      BASE_LECTURE_KEYS.forEach((baseLectureKey) => {
        const teacherIdName = `${lectureDayKey}.${baseLectureKey}.teacher_id`;
        const defaultSeatName = `${lectureDayKey}.${baseLectureKey}.default_seat`;

        let defaultValueSubject;
        let defaultValueTeacherId;
        let defaultValueDefaultSeat;
        if (isNotesElementarySchool) {
          // ノートス小学生の場合かつ通常の授業の場合は前半・後半に分ける
          defaultValueSubject =
            studentLectures?.[lectureDayKey]?.[baseLectureKey]?.subject ??
            _.cloneDeep(defaultSeparatedLecture);
          defaultValueTeacherId =
            studentLectures?.[lectureDayKey]?.[baseLectureKey]?.teacher_id ??
            _.cloneDeep(defaultSeparatedLecture);
          defaultValueDefaultSeat =
            studentLectures?.[lectureDayKey]?.[baseLectureKey]?.default_seat ??
            _.cloneDeep(defaultSeparatedLecture);

          // もしも、中学校->小学校のグレードに戻っていた場合は、初期値をdefaultSeparatedLectureにする。
          if (typeof defaultValueSubject === 'string') {
            defaultValueTeacherId = _.cloneDeep(defaultSeparatedLecture);
            defaultValueDefaultSeat = _.cloneDeep(defaultSeparatedLecture);
          }

          register(`${teacherIdName}.first`);
          setValue(
            `${teacherIdName}.first`,
            typeof defaultValueTeacherId !== 'string'
              ? defaultValueTeacherId.first
              : '',
          );
          register(`${teacherIdName}.latter`);
          setValue(
            `${teacherIdName}.latter`,
            typeof defaultValueTeacherId !== 'string'
              ? defaultValueTeacherId.latter
              : '',
          );
          register(`${defaultSeatName}.first`);
          setValue(
            `${defaultSeatName}.first`,
            typeof defaultValueDefaultSeat !== 'string'
              ? defaultValueDefaultSeat.first
              : '',
          );
          register(`${defaultSeatName}.latter`);
          setValue(
            `${defaultSeatName}.latter`,
            typeof defaultValueDefaultSeat !== 'string'
              ? defaultValueDefaultSeat.latter
              : '',
          );
        } else {
          // ノートス小学生以外の場合かつ特別授業の場合は前半・後半に分けない
          defaultValueSubject =
            studentLectures?.[lectureDayKey]?.[baseLectureKey]?.subject ?? '';
          defaultValueTeacherId =
            studentLectures?.[lectureDayKey]?.[baseLectureKey]?.teacher_id ??
            '';
          defaultValueDefaultSeat =
            studentLectures?.[lectureDayKey]?.[baseLectureKey]?.default_seat ??
            '';

          // もしも、小学校->中学校のグレードに上がっていた場合は、初期値をstring（空文字）にする。
          if (typeof defaultValueSubject !== 'string') {
            defaultValueTeacherId = '';
            defaultValueDefaultSeat = '';
          }

          register(teacherIdName);
          setValue(teacherIdName, defaultValueTeacherId);
          register(defaultSeatName);
          setValue(defaultSeatName, defaultValueDefaultSeat);
        }
      });
    });
  }, [append, studentLectures, isNotesElementarySchool, register, setValue]);

  useEffectOnce(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  });

  return loading ? (
    <DotProgress />
  ) : (
    <div>
      <form onSubmit={onSubmit} className="detail-form card p-5">
        <div className="student-meta">
          <h3 className="student-name">{`${studentBasic?.last_name} ${studentBasic?.first_name}`}</h3>
          <button
            type="button"
            className="button is-primary"
            onClick={() => {
              history.push({
                pathname: '/student-score/detail',
                state: { studentBasic },
              });
            }}
          >
            成績情報ページへ
          </button>
          <div className="parent-info">
            <h4 className="parent-name">{parentInfo?.name}</h4>
            <button
              type="button"
              className="button is-primary"
              onClick={() => {
                history.push({
                  pathname: '/parent/detail',
                  state: { parentId: parentInfo?.id },
                });
              }}
            >
              保護者詳細ページへ
            </button>
          </div>
        </div>

        <input
          type="hidden"
          name="id"
          defaultValue={studentLectures?.id || watch('id')}
          ref={register}
        />
        <input
          type="hidden"
          name="student_id"
          defaultValue={studentBasic?.id}
          ref={register}
        />
        <Controller
          control={control}
          name="course"
          defaultValue={studentLectures?.course || []}
          as={
            <Select
              label="コース"
              selectProps={{
                name: 'course',
                options: isNotesJapan
                  ? NOTES_JAPAN_COURSES.map((v) => ({ label: v, value: v }))
                  : NOTES_PRAISE_COURSES.map((v) => ({ label: v, value: v })),
                defaultValue:
                  studentLectures?.course?.map((v) => ({
                    label: v,
                    value: v,
                  })) || [],
                onChange: async (v) => {
                  let newValue: string[];
                  if (v && v instanceof Array) {
                    newValue = v.reduce<string[]>(
                      (prev: string[], current: ValueType<OptionTypeBase>) => {
                        return current && 'value' in current
                          ? prev.concat(current.value as string)
                          : prev;
                      },
                      [],
                    );
                  } else {
                    newValue = [];
                  }
                  setValue('course', newValue);
                  await trigger('course');
                },
                isDisabled: isViewer,
                isMulti: true,
              }}
            />
          }
        />
        <Controller
          control={control}
          name="lecture_count"
          defaultValue={studentLectures?.lecture_count || ''}
          as={
            <Select
              label="授業回数(週)"
              selectProps={{
                name: 'lecture_count',
                options: LECTURE_COUNTS.map((v) => ({ label: v, value: v })),
                defaultValue: {
                  label: studentLectures?.lecture_count,
                  value: studentLectures?.lecture_count,
                },
                onChange: async (value) => {
                  setValue(
                    'lecture_count',
                    value && 'value' in value ? value.value : '',
                  );
                  await trigger('lecture_count');
                },
                isDisabled: isViewer,
              }}
              error={errors.lecture_count}
            />
          }
        />
        <Controller
          control={control}
          name="lecture_time"
          defaultValue={studentLectures?.lecture_time || ''}
          as={
            <Select
              label="授業時間"
              selectProps={{
                name: 'lecture_time',
                options: LECTURE_TIMES.map((v) => ({ label: v, value: v })),
                defaultValue: {
                  label: studentLectures?.lecture_time,
                  value: studentLectures?.lecture_time,
                },
                onChange: async (value) => {
                  setValue(
                    'lecture_time',
                    value && 'value' in value ? value.value : '',
                  );
                  await trigger('lecture_time');
                },
                isDisabled: isViewer,
              }}
              error={errors.lecture_time}
            />
          }
        />
        {LECTURE_DAY_KEYS.map((lectureDayKey) => (
          <div className="field">
            <div className="label">{jaLectureDays[lectureDayKey]}曜日</div>
            <div className="columns is-multiline">
              {BASE_LECTURE_KEYS.map((lectureKey) => {
                const lectureName = `${lectureDayKey}.${lectureKey}.lecture`;
                const subjectName = `${lectureDayKey}.${lectureKey}.subject`;
                const defaultValueLecture = getLecture(
                  lectureDayKey,
                  lectureKey,
                  isNotesJapan,
                );

                let defaultValueSubject: SeparatedLecture | string;
                if (isNotesElementarySchool) {
                  // ノートス小学生の場合かつ通常の授業の場合は前半・後半に分ける
                  defaultValueSubject =
                    studentLectures?.[lectureDayKey]?.[lectureKey]?.subject ??
                    _.cloneDeep(defaultSeparatedLecture);

                  // もしも、中学校->小学校のグレードに戻っていた場合は、初期値をdefaultSeparatedLectureにする。
                  if (typeof defaultValueSubject === 'string') {
                    defaultValueSubject = _.cloneDeep(defaultSeparatedLecture);
                  }
                } else {
                  // ノートス小学生以外の場合かつ特別授業の場合は前半・後半に分けない
                  defaultValueSubject =
                    studentLectures?.[lectureDayKey]?.[lectureKey]?.subject ??
                    '';

                  // もしも、小学校->中学校のグレードに上がっていた場合は、初期値をstring（空文字）にする。
                  if (typeof defaultValueSubject !== 'string') {
                    defaultValueSubject = '';
                  }
                }

                return (
                  <>
                    <input
                      type="hidden"
                      name={lectureName}
                      defaultValue={defaultValueLecture}
                      ref={register}
                    />
                    {typeof defaultValueSubject === 'string' ? (
                      <div className="column is-one-fifth">
                        <div className="is-size-7 has-text-grey mb-2">
                          {defaultValueLecture}
                        </div>
                        <SelectSubject
                          formMethods={formMethods}
                          subjects={subjects}
                          subjectName={subjectName}
                          defaultValueSubject={defaultValueSubject}
                        />
                      </div>
                    ) : (
                      <div className="column is-one-fifth">
                        <div className="is-size-7 has-text-grey mb-1">
                          {defaultValueLecture}
                        </div>
                        <div className="mb-2">前半</div>
                        <SelectSubject
                          formMethods={formMethods}
                          subjects={subjects}
                          subjectName={`${subjectName}.first`}
                          defaultValueSubject={defaultValueSubject.first}
                          selectPropsClassName="mb-5"
                        />
                        <div className="mb-2">後半</div>
                        <SelectSubject
                          formMethods={formMethods}
                          subjects={subjects}
                          subjectName={`${subjectName}.latter`}
                          defaultValueSubject={defaultValueSubject.latter}
                          selectPropsClassName="mb-5"
                        />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        ))}
        <div className="field">
          <label className="label" htmlFor="first_date">
            初回授業日
          </label>
          <Controller
            name="first_date"
            control={control}
            defaultValue={studentLectures?.first_date || null}
            as={
              <DateField
                DatePickerProps={{
                  name: 'first_date',
                  value:
                    !errors?.first_date && watch('first_date')
                      ? DateTime.fromFormat(watch('first_date'), dateFormat)
                      : (studentLectures?.first_date &&
                          DateTime.fromFormat(
                            studentLectures?.first_date,
                            dateFormat,
                          )) ||
                        null,
                  error: errors.first_date && true,
                  TextFieldComponent: (props) => (
                    <TextField {...props} margin="none" />
                  ),
                  onChange: async (v) => {
                    setValue('first_date', v ? v.toFormat(dateFormat) : '');
                    await trigger('first_date');
                  },
                  disabled: isViewer,
                }}
              />
            }
          />
          <ErrorMessage
            message={errors?.first_date?.message}
            className="mt-2"
          />
        </div>
        <Controller
          control={control}
          name="first_class"
          defaultValue={studentLectures?.first_class || ''}
          as={
            <Select
              label="初回コマ"
              selectProps={{
                name: 'first_class',
                options:
                  classes instanceof Array
                    ? classes.map((v: string) => ({ label: v, value: v }))
                    : [],
                defaultValue: {
                  label: studentLectures?.first_class,
                  value: studentLectures?.first_class,
                },
                onChange: async (value) => {
                  setValue(
                    'first_class',
                    value && 'value' in value ? value.value : '',
                  );
                  await trigger('first_class');
                },
                isDisabled: isViewer,
              }}
              error={errors.first_class}
            />
          }
        />
        <Controller
          control={control}
          name="first_subject"
          defaultValue={studentLectures?.first_subject || ''}
          as={
            <Select
              label="初回教科"
              selectProps={{
                name: 'first_subject',
                options: subjects.map((v) => ({
                  label: v,
                  value: v,
                })),
                defaultValue: {
                  label: studentLectures?.first_subject,
                  value: studentLectures?.first_subject,
                },
                onChange: async (value) => {
                  setValue(
                    'first_subject',
                    value && 'value' in value ? value.value : '',
                  );
                  await trigger('first_subject');
                },
                isDisabled: isViewer,
              }}
              error={errors.first_subject}
            />
          }
        />
        <div className="field">
          <p className="label">体験授業</p>
          {fields.map((field, index) => {
            const errorTrialLectures =
              errors?.trial_lectures && errors.trial_lectures[index];
            const namePrefix = `trial_lectures[${index}]`;
            const fieldNames = {
              trial_date: `${namePrefix}.trial_date`,
              trial_class: `${namePrefix}.trial_class`,
              trial_subject: `${namePrefix}.trial_subject`,
            };

            return (
              <Motion
                defaultStyle={{ scale: 0.9, opacity: 0 }}
                style={{ scale: spring(1), opacity: 1 }}
                key={field.id}
              >
                {(style) => (
                  <div className="fields-wrap">
                    <div
                      className="columns"
                      style={{
                        transform: `scale(${style.scale})`,
                        opacity: style.opacity,
                      }}
                    >
                      <div className="column is-one-third">
                        <label className="label" htmlFor="first_date">
                          体験日
                        </label>
                        <Controller
                          name={fieldNames.trial_date}
                          control={control}
                          defaultValue={
                            studentLectures?.trial_lectures[index]
                              ?.trial_date || null
                          }
                          as={
                            <DateField
                              DatePickerProps={{
                                name: fieldNames.trial_date,
                                value:
                                  !errorTrialLectures?.trial_date &&
                                  watch(fieldNames.trial_date)
                                    ? DateTime.fromFormat(
                                        watch(fieldNames.trial_date),
                                        dateFormat,
                                      )
                                    : (studentLectures?.trial_lectures[index]
                                        ?.trial_date &&
                                        DateTime.fromFormat(
                                          studentLectures?.trial_lectures[index]
                                            .trial_date,
                                          dateFormat,
                                        )) ||
                                      null,
                                error: errorTrialLectures?.trial_date && true,
                                TextFieldComponent: (props) => (
                                  <TextField {...props} margin="none" />
                                ),
                                onChange: async (v) => {
                                  setValue(
                                    fieldNames.trial_date,
                                    v ? v.toFormat(dateFormat) : '',
                                  );
                                  await trigger(fieldNames.trial_date);
                                },
                                disabled: isViewer,
                              }}
                            />
                          }
                        />
                        <ErrorMessage
                          message={errorTrialLectures?.trial_date?.message}
                          className="mt-2"
                        />
                      </div>
                      <div className="column is-one-third">
                        <Controller
                          control={control}
                          name={fieldNames.trial_class}
                          defaultValue={
                            studentLectures?.trial_lectures[index]
                              ?.trial_class || ''
                          }
                          as={
                            <Select
                              label="体験コマ"
                              selectProps={{
                                name: fieldNames.trial_class,
                                options:
                                  classes instanceof Array
                                    ? classes.map((v: string) => ({
                                        label: v,
                                        value: v,
                                      }))
                                    : [],
                                defaultValue: {
                                  label:
                                    studentLectures?.trial_lectures[index]
                                      ?.trial_class,
                                  value:
                                    studentLectures?.trial_lectures[index]
                                      ?.trial_class,
                                },
                                onChange: async (value) => {
                                  setValue(
                                    fieldNames.trial_class,
                                    value && 'value' in value
                                      ? value.value
                                      : '',
                                  );
                                  await trigger(fieldNames.trial_class);
                                },
                                disabled: isViewer,
                              }}
                              error={errorTrialLectures?.trial_class}
                            />
                          }
                        />
                      </div>
                      <div className="column is-one-third">
                        <Controller
                          control={control}
                          name={fieldNames.trial_subject}
                          defaultValue={
                            studentLectures?.trial_lectures[index]
                              ?.trial_subject || ''
                          }
                          as={
                            <Select
                              label="体験教科"
                              selectProps={{
                                name: fieldNames.trial_subject,
                                options: subjects.map((v) => ({
                                  label: v,
                                  value: v,
                                })),
                                defaultValue: {
                                  label:
                                    studentLectures?.trial_lectures[index]
                                      ?.trial_subject,
                                  value:
                                    studentLectures?.trial_lectures[index]
                                      ?.trial_subject,
                                },
                                onChange: async (value) => {
                                  setValue(
                                    fieldNames.trial_subject,
                                    value && 'value' in value
                                      ? value.value
                                      : '',
                                  );
                                  await trigger(fieldNames.trial_subject);
                                },
                                isDisabled: isViewer,
                              }}
                              error={errorTrialLectures?.trial_subject}
                            />
                          }
                        />
                      </div>
                    </div>
                    <CgCloseO onClick={() => remove(index)} />
                  </div>
                )}
              </Motion>
            );
          })}
          <Button
            buttonProps={{ onClick: () => append({}), disabled: isViewer }}
          >
            体験授業を追加
          </Button>
        </div>
        <div className="btn-area">
          <Button
            buttonProps={{
              type: 'button',
              onClick: () => history.push('/student'),
            }}
            className={clsx('with-icon', 'btn-primary')}
          >
            <RiArrowGoBackFill />
            戻る
          </Button>
          <Button
            buttonProps={{ type: 'submit' }}
            color="primary"
            className={clsx('with-icon', 'btn-primary')}
          >
            <FaSave />
            保存
          </Button>
        </div>
      </form>
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default StudentLecturesForm;
