import { useRef, useEffect } from 'react';

export default (trigger?: any) => {
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!targetRef.current) return;

    targetRef.current.scrollTop = targetRef.current.scrollHeight;
  }, [targetRef, trigger]);

  return { targetRef };
};
