import { useContext } from 'react';

import { AppContext } from '../context/AppContext';
import { ROLES } from '../../data/form-data';
import { QueryParams } from '../../types/firestore-simple';
import { StudentBasic } from '../../domain/models/student-basic';

export default () => {
  const { adminUser } = useContext(AppContext);
  const isAdministrator = ROLES[0] === adminUser.role;
  const isHeadquarters = ROLES[1] === adminUser.role;
  const isEditor = ROLES[2] === adminUser.role;
  const isViewer = ROLES[3] === adminUser.role;
  const enrolledCramSchool = adminUser.enrolled_cram_school;
  const enrolledCramSchoolQueries: QueryParams<StudentBasic> = [
    {
      fieldPath: 'enrolled_cram_school',
      opStr: '==',
      value: enrolledCramSchool,
    },
  ];

  return {
    isAdministrator,
    isHeadquarters,
    isEditor,
    isViewer,
    enrolledCramSchool,
    enrolledCramSchoolQueries,
  };
};
