import firestoreSimple from '../../lib/firebase-firestore';
import { Talk } from '../models/talk';

const deleteTalk = async (talkId: string): Promise<void> => {
  const collection = firestoreSimple.collection<Talk>({
    path: 'talks',
  });

  await collection.delete(talkId).catch((error) => {
    console.error(error);
  });
};

export default deleteTalk;
