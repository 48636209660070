import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Tabs, Tab, IconButton } from '@material-ui/core';
import { FaTasks } from 'react-icons/all';
import { DateTime } from 'luxon';

import AdminWrapper from '../../components/layouts/AdminWrapper';
import Container from '../../components/layouts/Container';
import StudentLecturesForm from '../../containers/molecules/form/StudentLectuesForm';
import StudentBasicForm from '../../containers/molecules/form/StudentBasicForm';
import TimetableForm from '../../containers/molecules/form/TimetableForm';
import Task from '../../components/molecules/modal/Task';
import { PAGE_TITLE } from '../../../data/app-basic';
import { YearMonthContext } from '../../context/YearMonthContext';
import './style.scss';

const StudentDetail: FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [opening, setOpening] = useState(false);
  const [yearMonth, setYearMonth] = useState<string>(
    DateTime.fromJSDate(new Date()).toFormat('yyyy/MM'),
  );

  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('生徒情報編集')}</title>
      </Helmet>
      <Container>
        <YearMonthContext.Provider
          value={{
            yearMonth,
            setYearMonth,
          }}
        >
          <IconButton
            className="button-task"
            onClick={() => setOpening(!opening)}
          >
            <FaTasks />
          </IconButton>
          <Task opening={opening} setOpening={setOpening} />
          <Tabs
            value={tabIndex}
            onChange={(_, newIndex) => setTabIndex(newIndex)}
            aria-label="simple tabs example"
          >
            <Tab label="基本情報" />
            <Tab label="通塾情報" />
            <Tab label="授業日程" />
          </Tabs>
          {tabIndex === 0 ? <StudentBasicForm /> : null}
          {tabIndex === 1 ? <StudentLecturesForm /> : null}
          {tabIndex === 2 ? <TimetableForm /> : null}
        </YearMonthContext.Provider>
      </Container>
    </AdminWrapper>
  );
};

export default StudentDetail;
