export const dateFormat = 'yyyy年M月d日';
export const timeFormat = '';

export type Role = 'administrator' | 'headquarters' | 'editor' | 'viewer';
export const ROLES: Role[] = [
  'administrator',
  'headquarters',
  'editor',
  'viewer',
];
export type RoleLabel = '全体管理者' | '本部' | '校舎管理' | 'スタッフ';
export const ROLE_LABELS: { [key in Role]: RoleLabel } = {
  administrator: '全体管理者',
  headquarters: '本部',
  editor: '校舎管理',
  viewer: 'スタッフ',
};

export type EmploymentStatus =
  | '役員'
  | '正社員'
  | '契約社員'
  | '嘱託社員'
  | 'パート(専任講師)'
  | 'アルバイト(講師)';
export const EMPLOYMENT_STATUS: EmploymentStatus[] = [
  '役員',
  '正社員',
  '契約社員',
  '嘱託社員',
  'パート(専任講師)',
  'アルバイト(講師)',
];

export type Rank = 'SSS' | 'SS' | 'S' | 'A' | 'B';
export const RANKS: Rank[] = ['SSS', 'SS', 'S', 'A', 'B'];

export type Transportation = '電車' | 'バス' | '自家用車';
export const TRANSPORTATIONS: Transportation[] = ['電車', 'バス', '自家用車'];

export type Gender = '男' | '女' | 'その他';
export const GENDERS: Gender[] = ['男', '女', 'その他'];

export type Relationship = '父' | '母' | '祖父' | '祖母' | 'その他';
export const RELATIONSHIPS: Relationship[] = [
  '父',
  '母',
  '祖父',
  '祖母',
  'その他',
];

export type StudentStatus =
  | 'enrolled'
  | 'waiting'
  | 'trial'
  | 'waiting_trial'
  | 'withdrawal'
  | 'recess'
  | 'not_enrolled'
  | 'document_request';
export const STUDENT_STATUS: StudentStatus[] = [
  'document_request',
  'waiting_trial',
  'trial',
  'waiting',
  'enrolled',
  'recess',
  'withdrawal',
  'not_enrolled',
];
export const STUDENT_STATUS_LABELS: { [key in StudentStatus]: string } = {
  document_request: '資料請求',
  waiting_trial: '体験入塾待ち',
  trial: '体験入塾',
  waiting: '本入塾待ち',
  enrolled: '本入塾',
  recess: '休会中',
  withdrawal: '退塾済み',
  not_enrolled: '未入塾',
};
export type SelectAllStatus = 'all';
export const SELECT_ALL_STATUS: SelectAllStatus = 'all';
export type SelectAllStatusLabel = '指定なし';
export const SELECT_ALL_STATUS_LABEL: SelectAllStatusLabel = '指定なし';
export type SelectStudentStatus =
  | SelectAllStatus
  | 'enrolled'
  | 'waiting'
  | 'trial'
  | 'waiting_trial';
export const SELECT_STUDENT_STATUS: SelectStudentStatus[] = [
  SELECT_ALL_STATUS,
  'waiting_trial',
  'trial',
  'waiting',
  'enrolled',
];
export const SELECT_STUDENT_STATUS_LABELS: {
  [key in SelectStudentStatus]: string;
} = {
  all: SELECT_ALL_STATUS_LABEL,
  waiting_trial: '体験入塾待ち',
  trial: '体験入塾',
  waiting: '本入塾待ち',
  enrolled: '本入塾',
};

export type ElementarySchoolGrade =
  | '小1'
  | '小2'
  | '小3'
  | '小4'
  | '小5'
  | '小6';
export const ELEMENTARY_SCHOOL_GRADES: ElementarySchoolGrade[] = [
  '小1',
  '小2',
  '小3',
  '小4',
  '小5',
  '小6',
];
export type MiddleSchoolGrade = '中1' | '中2' | '中3';
export const MIDDLE_SCHOOL_GRADES: MiddleSchoolGrade[] = ['中1', '中2', '中3'];
export type HighSchoolGrade = '高1' | '高2' | '高3';
export const HIGH_SCHOOL_GRADES: HighSchoolGrade[] = ['高1', '高2', '高3'];
export type AlreadyGraduatedGrade = '既卒';
export const ALREADY_GRADUATED_GRADES: AlreadyGraduatedGrade[] = ['既卒'];
export type Grade =
  | ElementarySchoolGrade
  | MiddleSchoolGrade
  | HighSchoolGrade
  | AlreadyGraduatedGrade;
export const GRADES: Grade[] = [
  ...ELEMENTARY_SCHOOL_GRADES,
  ...MIDDLE_SCHOOL_GRADES,
  ...HIGH_SCHOOL_GRADES,
  ...ALREADY_GRADUATED_GRADES,
];

export type AdmissionRoute =
  | 'Webサイト'
  | 'Webサイトフォーム'
  | '折込チラシ'
  | 'ポスティング'
  | '新潟情報'
  | '手配りチラシ'
  | 'SNS'
  | '友人・親戚の紹介'
  | 'その他';
export const ADMISSION_ROUTES: AdmissionRoute[] = [
  'Webサイト',
  'Webサイトフォーム',
  '折込チラシ',
  'ポスティング',
  '新潟情報',
  '手配りチラシ',
  'SNS',
  '友人・親戚の紹介',
  'その他',
];

export type Prefecture =
  | '北海道'
  | '青森県'
  | '岩手県'
  | '宮城県'
  | '秋田県'
  | '山形県'
  | '福島県'
  | '茨城県'
  | '栃木県'
  | '群馬県'
  | '埼玉県'
  | '千葉県'
  | '東京都'
  | '神奈川県'
  | '新潟県'
  | '富山県'
  | '石川県'
  | '福井県'
  | '山梨県'
  | '長野県'
  | '岐阜県'
  | '静岡県'
  | '愛知県'
  | '三重県'
  | '滋賀県'
  | '京都府'
  | '大阪府'
  | '兵庫県'
  | '奈良県'
  | '和歌山県'
  | '鳥取県'
  | '島根県'
  | '岡山県'
  | '広島県'
  | '山口県'
  | '徳島県'
  | '香川県'
  | '愛媛県'
  | '高知県'
  | '福岡県'
  | '佐賀県'
  | '長崎県'
  | '熊本県'
  | '大分県'
  | '宮崎県'
  | '鹿児島県'
  | '沖縄県';
export const PREFECTURES: Prefecture[] = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

export type PhoneRelationship =
  | '自宅'
  | '父携帯'
  | '母携帯'
  | '祖父携帯'
  | '祖母携帯'
  | '本人携帯'
  | 'その他';
export const PHONE_RELATIONSHIPS: PhoneRelationship[] = [
  '自宅',
  '父携帯',
  '母携帯',
  '祖父携帯',
  '祖母携帯',
  '本人携帯',
  'その他',
];

export type WithdrawalBank = 'ゆうちょ銀行' | '第四北越銀行';
export const WITHDRAWAL_BANKS: WithdrawalBank[] = [
  'ゆうちょ銀行',
  '第四北越銀行',
];

export type NotesJapanSchool =
  | 'ノートスいしやま'
  | 'ノートスさつき野駅前'
  | 'ノートスかめだ'
  | 'ノートスあおやま'
  | 'ノートスにいつ';
export const NOTES_JAPAN_SCHOOLS: NotesJapanSchool[] = [
  'ノートスあおやま',
  'ノートスいしやま',
  'ノートスかめだ',
  'ノートスさつき野駅前',
  'ノートスにいつ',
];

export type NotesPraiseSchool = 'プレイズ女池' | 'プレイズ荻川';
export const NOTES_PRAISE_SCHOOLS: NotesPraiseSchool[] = [
  'プレイズ女池',
  'プレイズ荻川',
];

export type NotesHeadquarters = '本部';
export const NOTES_HEADQUARTERS: NotesHeadquarters = '本部';

export type SelectAllEnrolledCramSchools = '校舎指定なし';
export type SelectAllEnrolledCramSchools2 = '全校舎';
export const selectAllEnrolledCramSchools2: SelectAllEnrolledCramSchools2 =
  '全校舎';
export const SELECT_ENROLLED_CRAM_SCHOOLS: (
  | SelectAllEnrolledCramSchools
  | NotesHeadquarters
  | NotesJapanSchool
  | NotesPraiseSchool
)[] = ['校舎指定なし', '本部', ...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS];

export type LectureCount = '1' | '2' | '3' | '4' | '5' | '6';
export const LECTURE_COUNTS: LectureCount[] = ['1', '2', '3', '4', '5', '6'];

export type LectureTime = '40分' | '55分' | '80分';
export const LECTURE_TIMES: LectureTime[] = ['40分', '55分', '80分'];

export type NotesJapanClass = 'N1' | 'N2' | 'N3' | 'N4' | 'N5';
export const NOTES_JAPAN_CLASSES: NotesJapanClass[] = [
  'N1',
  'N2',
  'N3',
  'N4',
  'N5',
];

export type NotesPraiseClass =
  | 'P1'
  | 'P2'
  | 'P3'
  | 'P4'
  | 'P5'
  | 'P6'
  | 'P7'
  | 'P8';
export const NOTES_PRAISE_WEEKDAYS_CLASSES: NotesPraiseClass[] = [
  'P4',
  'P5',
  'P6',
  'P7',
  'P8',
];
export const NOTES_PRAISE_HOLIDAY_CLASSES: NotesPraiseClass[] = [
  'P1',
  'P2',
  'P3',
  'P4',
  'P5',
];

export type StandardSubject =
  | '国語'
  | '数学'
  | '算数'
  | '英語'
  | '社会'
  | '理科'
  | '国発'
  | '国基'
  | '社発'
  | '社基'
  | '理発'
  | '理基'
  | '英会話'
  | '実践'
  | '強化'
  | '宿題'
  | '自習'
  | '模試'
  | '受験'
  | '筆答'
  | '面接'
  | 'リス'
  | 'テ対'
  | '勉合'
  | '国(追)'
  | '数(追)'
  | '算(追)'
  | '英(追)'
  | '社(追)'
  | '理(追)'
  | '定(追)'
  | '基(追)';

export const STANDARD_SUBJECTS: StandardSubject[] = [
  '国語',
  '数学',
  '算数',
  '英語',
  '社会',
  '理科',
  '国発',
  '国基',
  '社発',
  '社基',
  '理発',
  '理基',
  '英会話',
  '実践',
  '強化',
  '宿題',
  '自習',
  '模試',
  '受験',
  '筆答',
  '面接',
  'リス',
  'テ対',
  '勉合',
  '国(追)',
  '数(追)',
  '算(追)',
  '英(追)',
  '社(追)',
  '理(追)',
  '定(追)',
  '基(追)',
];

// 定着授業
export type SettlementSubject =
  | '定着'
  | '基定'
  | '国(定)'
  | '数(定)'
  | '算(定)'
  | '英(定)'
  | '社(定)'
  | '理(定)';

export const SETTLEMENT_SUBJECTS: SettlementSubject[] = [
  '定着',
  '基定',
  '国(定)',
  '数(定)',
  '算(定)',
  '英(定)',
  '社(定)',
  '理(定)',
];

export type Subject = StandardSubject | SettlementSubject;

export const SUBJECTS: Subject[] = [
  ...STANDARD_SUBJECTS,
  ...SETTLEMENT_SUBJECTS,
];

export type SpecialSubject =
  | '勉強合宿'
  | '模試'
  | '実践'
  | '指導会'
  | '激励会'
  | '夏期講習'
  | '冬季講習'
  | '春期講習';

export const SPECIAL_SUBJECTS: SpecialSubject[] = [
  '勉強合宿',
  '模試',
  '実践',
  '指導会',
  '激励会',
  '夏期講習',
  '冬季講習',
  '春期講習',
];

// 理解授業（特別授業含め「定着」以外の全ての授業のこと）
export type UnderstandingSubject = StandardSubject | SpecialSubject;

export const UNDERSTANDING_SUBJECTS: UnderstandingSubject[] = [
  ...STANDARD_SUBJECTS,
  ...SPECIAL_SUBJECTS,
];

export type ElementarySchoolSubject = Subject;
export const ELEMENTARY_SCHOOL_SUBJECTS: ElementarySchoolSubject[] = SUBJECTS;

export type MiddleSchoolSubject = Subject;
export const MIDDLE_SCHOOL_SUBJECTS: MiddleSchoolSubject[] = SUBJECTS;

export type HighSchoolSubject = Subject;
export const HIGH_SCHOOL_SUBJECTS: HighSchoolSubject[] = SUBJECTS;

export const classTransferStatus = {
  applying: '申請待ち',
  approval: '承認',
  reject: '非承認',
};

export const middleSchoolExamLabels = {
  '1st_grader_exam_1': `1年生 定期テスト${String.fromCharCode(8544)}`,
  '1st_grader_exam_2': `1年生 定期テスト${String.fromCharCode(8545)}`,
  '1st_grader_exam_3': `1年生 定期テスト${String.fromCharCode(8546)}`,
  '1st_grader_exam_4': `1年生 定期テスト${String.fromCharCode(8547)}`,
  '2nd_grader_exam_1': `2年生 定期テスト${String.fromCharCode(8544)}`,
  '2nd_grader_exam_2': `2年生 定期テスト${String.fromCharCode(8545)}`,
  '2nd_grader_exam_3': `2年生 定期テスト${String.fromCharCode(8546)}`,
  '2nd_grader_exam_4': `2年生 定期テスト${String.fromCharCode(8547)}`,
  '3rd_grader_exam_1': `3年生 定期テスト${String.fromCharCode(8544)}`,
  '3rd_grader_exam_2': `3年生 定期テスト${String.fromCharCode(8545)}`,
  '3rd_grader_exam_3': `3年生 定期テスト${String.fromCharCode(8546)}`,
  '3rd_grader_exam_4': `3年生 定期テスト${String.fromCharCode(8547)}`,
};

export type MiddleSchoolExamKeys =
  | '1st_grader_exam_1'
  | '1st_grader_exam_2'
  | '1st_grader_exam_3'
  | '1st_grader_exam_4'
  | '2nd_grader_exam_1'
  | '2nd_grader_exam_2'
  | '2nd_grader_exam_3'
  | '2nd_grader_exam_4'
  | '3rd_grader_exam_1'
  | '3rd_grader_exam_2'
  | '3rd_grader_exam_3'
  | '3rd_grader_exam_4';

export const middleSchoolExamSubjects = {
  english: '英語',
  math: '数学',
  japanese: '国語',
  science: '理科',
  society: '社会',
};

export const defaultMiddleSchoolExamSubjects = {
  japanese: 0,
  math: 0,
  english: 0,
  society: 0,
  science: 0,
};

export const defaultMiddleSchoolExamValues = {
  '1st_grader_exam_1': defaultMiddleSchoolExamSubjects,
  '1st_grader_exam_2': defaultMiddleSchoolExamSubjects,
  '1st_grader_exam_3': defaultMiddleSchoolExamSubjects,
  '1st_grader_exam_4': defaultMiddleSchoolExamSubjects,
  '2nd_grader_exam_1': defaultMiddleSchoolExamSubjects,
  '2nd_grader_exam_2': defaultMiddleSchoolExamSubjects,
  '2nd_grader_exam_3': defaultMiddleSchoolExamSubjects,
  '2nd_grader_exam_4': defaultMiddleSchoolExamSubjects,
  '3rd_grader_exam_1': defaultMiddleSchoolExamSubjects,
  '3rd_grader_exam_2': defaultMiddleSchoolExamSubjects,
  '3rd_grader_exam_3': defaultMiddleSchoolExamSubjects,
  '3rd_grader_exam_4': defaultMiddleSchoolExamSubjects,
};

export const middleSchoolReportLabels = {
  '1st_grader_report_1': '1年生 前期（1学期）',
  '1st_grader_report_2': '1年生 後期（2学期）',
  '1st_grader_report_3': '1年生 3学期',
  '2nd_grader_report_1': '2年生 前期（1学期）',
  '2nd_grader_report_2': '2年生 後期（2学期）',
  '2nd_grader_report_3': '2年生 3学期',
  '3rd_grader_report_1': '3年生 前期（1学期）',
  '3rd_grader_report_2': '3年生 後期（2学期）',
  '3rd_grader_report_3': '3年生 3学期',
};

export type MiddleSchoolReportKeys =
  | '1st_grader_report_1'
  | '1st_grader_report_2'
  | '1st_grader_report_3'
  | '2nd_grader_report_1'
  | '2nd_grader_report_2'
  | '2nd_grader_report_3'
  | '3rd_grader_report_1'
  | '3rd_grader_report_2'
  | '3rd_grader_report_3';

export type NotesJapanCourse =
  | '小学生コース'
  | '中学生コース'
  | '高校生コース'
  | 'Human英会話コース'
  | 'ロボット教室コース';

export const NOTES_JAPAN_COURSES: NotesJapanCourse[] = [
  '小学生コース',
  '中学生コース',
  '高校生コース',
  'Human英会話コース',
  'ロボット教室コース',
];

export type NotesPraiseCourse =
  | '小学生コース（通常）'
  | '小学生コース（週２日）'
  | '小学生コース（中学受験）'
  | '中学生コース（通常）'
  | '中学生コース（週２日）'
  | '高校生コース'
  | 'Human英会話コース'
  | 'ロボット教室コース';

export const NOTES_PRAISE_COURSES: NotesPraiseCourse[] = [
  '小学生コース（通常）',
  '小学生コース（週２日）',
  '小学生コース（中学受験）',
  '中学生コース（通常）',
  '中学生コース（週２日）',
  '高校生コース',
  'Human英会話コース',
  'ロボット教室コース',
];

export const middleSchoolReportSubjects = {
  japanese: '国語',
  society: '社会',
  math: '数学',
  science: '理科',
  music: '音楽',
  art: '美術',
  health_physical: '保体',
  tech_home: '技家',
  english: '英語',
};

export const defaultMiddleSchoolReportSubjects = {
  japanese: 0,
  society: 0,
  math: 0,
  science: 0,
  music: 0,
  art: 0,
  health_physical: 0,
  tech_home: 0,
  english: 0,
};

export const defaultMiddleSchoolReportValues = {
  '1st_grader_report_1': defaultMiddleSchoolReportSubjects,
  '1st_grader_report_2': defaultMiddleSchoolReportSubjects,
  '1st_grader_report_3': defaultMiddleSchoolReportSubjects,
  '2nd_grader_report_1': defaultMiddleSchoolReportSubjects,
  '2nd_grader_report_2': defaultMiddleSchoolReportSubjects,
  '2nd_grader_report_3': defaultMiddleSchoolReportSubjects,
  '3rd_grader_report_1': defaultMiddleSchoolReportSubjects,
  '3rd_grader_report_2': defaultMiddleSchoolReportSubjects,
  '3rd_grader_report_3': defaultMiddleSchoolReportSubjects,
};

export const subjectColors = {
  japanese: '#f00',
  math: '#87ceeb',
  english: '#fc0',
  science: '#008000',
  society: '#ffa500',
  music: '#10a',
  art: '#ffc0cb',
  health_physical: '#800080',
  tech_home: '#a52a2a',
};

export type InvoiceStatus = 'private' | 'publishing';
export const INVOICE_STATUS: InvoiceStatus[] = ['private', 'publishing'];
export const INVOICE_STATUS_LABELS: { [key in InvoiceStatus]: string } = {
  private: '未確定',
  publishing: '確定',
};

export const TAX_RATE = 0.1;

export type PaymentMethod = 'postal_transfer' | 'account_withdrawal';
export const PAYMENT_METHODS: PaymentMethod[] = [
  'postal_transfer',
  'account_withdrawal',
];
export const PAYMENT_METHOD_LABELS: { [key in PaymentMethod]: string } = {
  postal_transfer: '郵便振込',
  account_withdrawal: '口座引落',
};

export type CheckItemCategory = 'イベント' | '講師への連絡' | '生徒への連絡';
export const CHECK_ITEM_CATEGORIES: CheckItemCategory[] = [
  'イベント',
  '講師への連絡',
  '生徒への連絡',
];

export const DEFAULT_SEATS = [
  'A-1',
  'A-2',
  'A-3',
  'A-4',
  'B-1',
  'B-2',
  'B-3',
  'B-4',
  'C-1',
  'C-2',
  'C-3',
  'C-4',
  'D-1',
  'D-2',
  'D-3',
  'D-4',
  'E-1',
  'E-2',
  'E-3',
  'E-4',
  'F-1',
  'F-2',
  'F-3',
  'F-4',
  'G-1',
  'G-2',
  'G-3',
  'G-4',
  'H-1',
  'H-2',
  'H-3',
  'H-4',
  'I-1',
  'I-2',
  'I-3',
  'I-4',
  'J-1',
  'J-2',
  'J-3',
  'J-4',
  'K-1',
  'K-2',
  'K-3',
  'K-4',
  'L-1',
  'L-2',
  'L-3',
  'L-4',
  'M-1',
  'M-2',
  'M-3',
  'M-4',
  'N-1',
  'N-2',
  'N-3',
  'N-4',
  'O-1',
  'O-2',
  'O-3',
  'O-4',
  'P-1',
  'P-2',
  'P-3',
  'P-4',
  'Q-1',
  'Q-2',
  'Q-3',
  'Q-4',
  'R-1',
  'R-2',
  'R-3',
  'R-4',
  'S-1',
  'S-2',
  'S-3',
  'S-4',
  'T-1',
  'T-2',
  'T-3',
  'T-4',
  'U-1',
  'U-2',
  'U-3',
  'U-4',
  'V-1',
  'V-2',
  'V-3',
  'V-4',
  'W-1',
  'W-2',
  'W-3',
  'W-4',
  'X-1',
  'X-2',
  'X-3',
  'X-4',
  'Y-1',
  'Y-2',
  'Y-3',
  'Y-4',
  'Z-1',
  'Z-2',
  'Z-3',
  'Z-4',
];

export const DEFAULT_SEATS_FOR_PRAISE = [
  'A-1',
  'A-2',
  'A-3',
  'A-4',
  'A-5',
  'A-6',
  'A-7',
  'A-8',
  'A-9',
  'A-10',
  'A-11',
  'A-12',
  'B-1',
  'B-2',
  'B-3',
  'B-4',
  'B-5',
  'B-6',
  'B-7',
  'B-8',
  'B-9',
  'B-10',
  'B-11',
  'B-12',
  'C-1',
  'C-2',
  'C-3',
  'C-4',
  'C-5',
  'C-6',
  'C-7',
  'C-8',
  'C-9',
  'C-10',
  'C-11',
  'C-12',
  'D-1',
  'D-2',
  'D-3',
  'D-4',
  'D-5',
  'D-6',
  'D-7',
  'D-8',
  'D-9',
  'D-10',
  'D-11',
  'D-12',
  'E-1',
  'E-2',
  'E-3',
  'E-4',
  'E-5',
  'E-6',
  'E-7',
  'E-8',
  'E-9',
  'E-10',
  'E-11',
  'E-12',
  'F-1',
  'F-2',
  'F-3',
  'F-4',
  'F-5',
  'F-6',
  'F-7',
  'F-8',
  'F-9',
  'F-10',
  'F-11',
  'F-12',
  'G-1',
  'G-2',
  'G-3',
  'G-4',
  'G-5',
  'G-6',
  'G-7',
  'G-8',
  'G-9',
  'G-10',
  'G-11',
  'G-12',
  'H-1',
  'H-2',
  'H-3',
  'H-4',
  'H-5',
  'H-6',
  'H-7',
  'H-8',
  'H-9',
  'H-10',
  'H-11',
  'H-12',
  'I-1',
  'I-2',
  'I-3',
  'I-4',
  'I-5',
  'I-6',
  'I-7',
  'I-8',
  'I-9',
  'I-10',
  'I-11',
  'I-12',
  'J-1',
  'J-2',
  'J-3',
  'J-4',
  'J-5',
  'J-6',
  'J-7',
  'J-8',
  'J-9',
  'J-10',
  'J-11',
  'J-12',
  'K-1',
  'K-2',
  'K-3',
  'K-4',
  'K-5',
  'K-6',
  'K-7',
  'K-8',
  'K-9',
  'K-10',
  'K-11',
  'K-12',
  'L-1',
  'L-2',
  'L-3',
  'L-4',
  'L-5',
  'L-6',
  'L-7',
  'L-8',
  'L-9',
  'L-10',
  'L-11',
  'L-12',
  'M-1',
  'M-2',
  'M-3',
  'M-4',
  'M-5',
  'M-6',
  'M-7',
  'M-8',
  'M-9',
  'M-10',
  'M-11',
  'M-12',
  'N-1',
  'N-2',
  'N-3',
  'N-4',
  'N-5',
  'N-6',
  'N-7',
  'N-8',
  'N-9',
  'N-10',
  'N-11',
  'N-12',
  'O-1',
  'O-2',
  'O-3',
  'O-4',
  'O-5',
  'O-6',
  'O-7',
  'O-8',
  'O-9',
  'O-10',
  'O-11',
  'O-12',
  'P-1',
  'P-2',
  'P-3',
  'P-4',
  'P-5',
  'P-6',
  'P-7',
  'P-8',
  'P-9',
  'P-10',
  'P-11',
  'P-12',
  'Q-1',
  'Q-2',
  'Q-3',
  'Q-4',
  'Q-5',
  'Q-6',
  'Q-7',
  'Q-8',
  'Q-9',
  'Q-10',
  'Q-11',
  'Q-12',
  'R-1',
  'R-2',
  'R-3',
  'R-4',
  'R-5',
  'R-6',
  'R-7',
  'R-8',
  'R-9',
  'R-10',
  'R-11',
  'R-12',
  'S-1',
  'S-2',
  'S-3',
  'S-4',
  'S-5',
  'S-6',
  'S-7',
  'S-8',
  'S-9',
  'S-10',
  'S-11',
  'S-12',
  'T-1',
  'T-2',
  'T-3',
  'T-4',
  'T-5',
  'T-6',
  'T-7',
  'T-8',
  'T-9',
  'T-10',
  'T-11',
  'T-12',
  'U-1',
  'U-2',
  'U-3',
  'U-4',
  'U-5',
  'U-6',
  'U-7',
  'U-8',
  'U-9',
  'U-10',
  'U-11',
  'U-12',
  'V-1',
  'V-2',
  'V-3',
  'V-4',
  'V-5',
  'V-6',
  'V-7',
  'V-8',
  'V-9',
  'V-10',
  'V-11',
  'V-12',
  'W-1',
  'W-2',
  'W-3',
  'W-4',
  'W-5',
  'W-6',
  'W-7',
  'W-8',
  'W-9',
  'W-10',
  'W-11',
  'W-12',
  'X-1',
  'X-2',
  'X-3',
  'X-4',
  'X-5',
  'X-6',
  'X-7',
  'X-8',
  'X-9',
  'X-10',
  'X-11',
  'X-12',
  'Y-1',
  'Y-2',
  'Y-3',
  'Y-4',
  'Y-5',
  'Y-6',
  'Y-7',
  'Y-8',
  'Y-9',
  'Y-10',
  'Y-11',
  'Y-12',
  'Z-1',
  'Z-2',
  'Z-3',
  'Z-4',
  'Z-5',
  'Z-6',
  'Z-7',
  'Z-8',
  'Z-9',
  'Z-10',
  'Z-11',
  'Z-12',
];
