import React, { useContext } from 'react';
import { useDebounce } from 'react-use';
import { FilterProps } from 'react-table';

import FilterInput from '../../../atoms/form/FilterInput';
import { FilterContext } from '../../../../context/FilterContext';

// eslint-disable-next-line @typescript-eslint/ban-types
const DefaultColumnFilter = <T extends {}>({ column }: FilterProps<T>) => {
  const { filters, setFilters, filterKey } = useContext(FilterContext);
  const { id, render, setFilter } = column;
  const filterValue: string | undefined =
    typeof column.filterValue === 'string' ? column.filterValue : undefined;
  useDebounce(
    () => {
      setFilter(filterValue);
      if (filterValue !== undefined) {
        setFilters((prevState) => {
          if (prevState && prevState.has(filterKey)) {
            filters.set(filterKey, [
              ...prevState.get(filterKey)!.filter((v) => v.id !== column.id),
              { id: column.id, value: filterValue },
            ]);

            return filters;
          }
          filters.set(filterKey, [{ id: column.id, value: filterValue }]);

          return filters;
        });
      } else {
        setFilters((prevState) => {
          if (prevState && prevState.has(filterKey)) {
            filters.set(filterKey, [
              ...prevState.get(filterKey)!.filter((v) => v.id !== column.id),
            ]);

            return filters;
          }

          return filters;
        });
      }
    },
    300,
    [filterValue],
  );

  return (
    <div>
      <div className="label">{render('Header')}</div>
      <div className="control">
        <FilterInput
          name={id}
          value={filterValue || ''}
          onChange={(event) => {
            setFilter(event.target.value || undefined);
          }}
        />
      </div>
    </div>
  );
};

export default DefaultColumnFilter;
