import firebase from 'firebase';
import { firebaseAuth } from '../../lib/firebase';

export type SignInResponse = {
  message: string;
  userCredential?: firebase.auth.UserCredential;
};

const signInFirebaseAuth = async (
  email: string,
  password: string,
): Promise<SignInResponse> => {
  let message = '';
  const userCredential = await firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .catch((error: firebase.auth.AuthError) => {
      console.error(error);
      switch (error.code) {
        case 'auth/invalid-email': {
          message = 'メールアドレスが有効ではありません';
          break;
        }
        case 'auth/user-disabled': {
          message = 'ユーザーが無効です';
          break;
        }
        case 'auth/user-not-found': {
          message = 'ユーザーが見つかりません';
          break;
        }
        case 'auth/wrong-password': {
          message = 'パスワードが無効です';
          break;
        }
        default: {
          message = 'ログインに失敗しました。';
          break;
        }
      }
    });

  return { message, userCredential: userCredential || undefined };
};

export default signInFirebaseAuth;
