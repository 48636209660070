import React, { FC } from 'react';
import clsx from 'clsx';

import './style.scss';

export type Props = {
  buttonProps: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;
  className?: string;
  color?: 'primary' | 'secondary' | 'invert';
};

const Button: FC<Props> = ({ children, buttonProps, className, color }) => {
  let colorClassName = '';
  switch (color) {
    case 'primary':
      colorClassName = 'is-primary';
      break;
    case 'secondary':
      colorClassName = 'is-secondary';
      break;
    case 'invert':
      colorClassName = 'is-invert';
      break;
    default:
      break;
  }

  return (
    <button
      className={clsx('button', colorClassName, className)}
      type="button"
      {...buttonProps}
    >
      {children}
    </button>
  );
};

export default Button;
