import { HasId } from '@firestore-simple/web/dist/types';
import firebase from 'firebase';

export type Room = HasId & {
  update_date: firebase.firestore.Timestamp | null;
  room_users: string[];
};

export const defaultRoom: Room = {
  id: '',
  update_date: null,
  room_users: [],
};
