import { HasId } from '@firestore-simple/web/dist/types';
import _ from 'lodash';
import {
  NOTES_JAPAN_CLASSES,
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_HOLIDAY_CLASSES,
  NOTES_PRAISE_WEEKDAYS_CLASSES,
  NotesJapanClass,
  NotesPraiseClass,
} from '../../data/form-data';
import {
  LectureItem,
  LectureKey,
  LECTURE_KEYS,
  defaultSeparatedLecture,
} from './student-lectures';

export type ScheduleItem = {
  date: string;
} & {
  [key in LectureKey]?: LectureItem;
};

export type Timetable = HasId & {
  student_id: string;
  year_month: string;
  schedule: ScheduleItem[];
};

export const defaultTimetable: Timetable = {
  id: '',
  student_id: '',
  year_month: '',
  schedule: [],
};

// lectureからlectureKeyを探す
export const getLectureKey = (
  lecture: string,
  enrolled_cram_school: string,
  date: string,
) => {
  const isNotesJapan = NOTES_JAPAN_SCHOOLS.some(
    (v) => v === enrolled_cram_school,
  );
  let lectureKey: LectureKey | undefined;
  const [n1, n2, n3, n4, n5] = NOTES_JAPAN_CLASSES;
  const [p1H, p2H, p3H, p4H, p5H] = NOTES_PRAISE_HOLIDAY_CLASSES;
  const [p4W, p5W, p6W, p7W, p8W] = NOTES_PRAISE_WEEKDAYS_CLASSES;
  const [
    lecture1,
    lecture2,
    lecture3,
    lecture4,
    lecture5,
    lectureSpecial,
  ] = LECTURE_KEYS;
  if (isNotesJapan) {
    switch (lecture) {
      case n1: {
        lectureKey = lecture1;
        break;
      }
      case n2: {
        lectureKey = lecture2;
        break;
      }
      case n3: {
        lectureKey = lecture3;
        break;
      }
      case n4: {
        lectureKey = lecture4;
        break;
      }
      case n5: {
        lectureKey = lecture5;
        break;
      }
      case '特別授業': {
        lectureKey = lectureSpecial;
        break;
      }
      default: {
        break;
      }
    }
  } else {
    const day = new Date(date).getDay();
    if (day === 0 || day === 6) {
      // 土日の場合
      switch (lecture) {
        case p1H: {
          lectureKey = lecture1;
          break;
        }
        case p2H: {
          lectureKey = lecture2;
          break;
        }
        case p3H: {
          lectureKey = lecture3;
          break;
        }
        case p4H: {
          lectureKey = lecture4;
          break;
        }
        case p5H: {
          lectureKey = lecture5;
          break;
        }
        case '特別授業': {
          lectureKey = lectureSpecial;
          break;
        }
        default: {
          break;
        }
      }
    } else {
      // 平日の場合
      switch (lecture) {
        case p4W: {
          lectureKey = lecture1;
          break;
        }
        case p5W: {
          lectureKey = lecture2;
          break;
        }
        case p6W: {
          lectureKey = lecture3;
          break;
        }
        case p7W: {
          lectureKey = lecture4;
          break;
        }
        case p8W: {
          lectureKey = lecture5;
          break;
        }
        case '特別授業': {
          lectureKey = lectureSpecial;
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  return lectureKey;
};

export const formatSchedule = (
  lastDay: number | undefined,
  yearMonth: string,
  timetable: Timetable | null,
  isNotesJapan: boolean,
  isNotesElementarySchool: boolean,
  classes: NotesJapanClass[] | NotesPraiseClass[],
) => {
  const schedule: ScheduleItem[] = [];

  if (!lastDay) return schedule;

  _.times(lastDay, (number) => {
    const dateNumStr =
      number <= 8 ? `0${String(number + 1)}` : String(number + 1);
    const date = `${yearMonth}/${dateNumStr}`;

    const isHasSchedule =
      timetable?.schedule?.length &&
      timetable?.schedule?.some((si) => si.date === date);

    const day = new Date(date).getDay();
    let coreClasses:
      | (NotesJapanClass | '特別授業')[]
      | (NotesPraiseClass | '特別授業')[] = _.cloneDeep(classes);
    if (!isNotesJapan && (day === 0 || day === 6)) {
      // プレイズの土日の場合
      coreClasses = NOTES_PRAISE_HOLIDAY_CLASSES;
    }
    coreClasses.push('特別授業');
    const subject = isNotesElementarySchool
      ? _.cloneDeep(defaultSeparatedLecture)
      : '';

    if (isHasSchedule) {
      const scheduleItem = timetable?.schedule?.find((si) => si.date === date);
      if (scheduleItem) {
        LECTURE_KEYS.forEach((lectureKey, index) => {
          scheduleItem[lectureKey] = {
            lecture: coreClasses[index],
            subject: scheduleItem[lectureKey]?.subject ?? subject,
          };
        });

        schedule.push(scheduleItem);
      }
    } else {
      const scheduleItem: any = {};
      LECTURE_KEYS.forEach((lectureKey, index) => {
        scheduleItem[lectureKey] = { // eslint-disable-line
          lecture: coreClasses[index],
          subject: lectureKey === 'lecture_special' ? '' : subject,
        };
      });

      schedule.push({
        date,
        ...scheduleItem,
      });
    }
  });

  return schedule;
};
