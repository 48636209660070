import { HasId } from '@firestore-simple/web/dist/types';

export type SchoolCalendar = HasId & {
  school_name: string;
  year: string;
  holidays: string[];
};

export const defaultSchoolCalendar: SchoolCalendar = {
  id: '',
  school_name: '',
  year: '',
  holidays: [],
};
