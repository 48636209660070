import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import queryString from 'query-string';
import { extension } from 'mime-types';
import { nanoid } from 'nanoid';
import useFetchStaff from '../../../../hooks/fetch/useFetchStaff';
import {
  defaultAdminUser,
  AdminUser,
} from '../../../../../domain/models/admin-user';
import StaffForm from '../../../../components/molecules/form/StaffForm';
import useCreateStaff from '../../../../hooks/create/useCreateStaff';
import { storage, functions } from '../../../../../lib/firebase';

export type Props = {
  isCreate?: boolean;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default ({ isCreate = false }: Props) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [createdDocId, setCreatedDocId] = useState<string | undefined>(
    undefined,
  );
  const history = useHistory();
  const location = useLocation<{
    staffId: string;
    isCreate?: boolean | undefined;
  }>();
  const queryStringId = queryString.parse(location.search).id;
  const {
    creating,
    create,
    close,
    state,
    setCreating,
    open,
  } = useCreateStaff();
  const [isCreatedSnackbar, setIsCreatedSnackbar] = useState(false); // 「社員の登録に成功しました」のスナックバーを出すときに無限ループさせないためのstate値

  let id;
  if (!isCreate) {
    if (!location.state && !queryStringId) return null;

    id = location.state?.staffId ?? (queryStringId as string);
  }
  const { loading, staffs } = useFetchStaff(isCreate ? {} : { id });
  const formMethods = useForm<AdminUser>({ defaultValues: staffs[0] });

  useEffect(() => {
    // 社員新規登録ページから社員を新規登録後、編集ページに遷移した場合はスナックバーを表示させる（すでにスナックバーを表示させる処理が終わっていた場合は処理しない）
    if (staffs.length && location.state?.isCreate && !isCreatedSnackbar) {
      open(
        staffs[0].id ? 'success' : 'error',
        staffs[0].id
          ? '社員の登録に成功しました'
          : '社員の登録後、データの取得ができませんでした。もう一度ページを更新してください',
      );
      setIsCreatedSnackbar(true);
    }
  }, [staffs, location.state, isCreatedSnackbar, open]);

  // 社員画像のアップロードが成功すればDetailページに遷移する（新規社員データ登録後の処理）
  useEffect(() => {
    if (createdDocId) {
      history.push({
        pathname: '/staff/detail',
        state: { staffId: createdDocId, isCreate: true },
      });
    }
  }, [history, createdDocId]);

  const onChangeImageFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setImageFile(file);
  };

  const removeImageFile = () => {
    setImageFile(null);
  };

  // Firebase Storageに社員画像をアップロードするための登録・編集用共通関数
  const uploadImageFile = (
    file: File,
    value: AdminUser,
    callBack: (() => void) | undefined = undefined,
  ) => {
    const docId = value.id;
    let image_path = '';

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const ext = extension(file.type) || '';
      if (!ext) return;

      if (docId) {
        const storagePath = `employee/${docId}`;
        const fileName = `${nanoid(12)}.${ext}`;
        const storageRef = storage.ref(storagePath);
        const fileRef = storageRef.child(fileName);
        await fileRef.put(file);

        image_path = `${storagePath}/${fileName}`;
      }

      await create(
        {
          ...value,
          image_path,
        },
        isCreate,
      );

      if (isCreate && callBack) {
        callBack();
      }
    };
  };

  type ApiResponse = {
    id?: string;
    isSuccess: boolean;
    properties?: {
      uid: string;
    };
  };

  const onSubmit = formMethods.handleSubmit(async (data) => {
    // 登録・編集用データの元
    let createValue: AdminUser = {
      ...defaultAdminUser,
      ...data,
      id: isCreate ? '' : staffs[0].id,
      uid: isCreate ? '' : staffs[0].uid,
      transportation: {
        cost_per_day: Number(data.transportation?.cost_per_day),
        default_transportation:
          data.transportation?.default_transportation ?? '',
      },
    };

    if (isCreate) {
      setCreating(true);

      // 新規登録ページの場合（最初にAuthentication&ドキュメントデータを作成する）
      const createNewAdminUser = functions.httpsCallable('createNewAdminUser');
      const {
        id: docId,
        isSuccess,
        uid,
      }: {
        id?: string;
        isSuccess: boolean;
        uid?: string;
      } = await createNewAdminUser({ newAdminUser: createValue }).then(
        ({ data: apiResponse }: { data: ApiResponse }) => {
          return {
            id: apiResponse.id,
            isSuccess: apiResponse.isSuccess,
            uid: apiResponse.properties?.uid,
          };
        },
      );

      if (!isSuccess) {
        setCreating(false);
        open('error', '社員の登録ができませんでした。もう一度お試しください。');
      } else if (imageFile && docId && uid) {
        createValue = {
          ...createValue,
          id: docId,
          uid,
        };

        // 新規登録ページ&&画像の更新がある場合：画像のアップロード&先ほど新規登録したデータのimage_pathの更新をし、編集ページに遷移させる
        uploadImageFile(imageFile, createValue, () => {
          // 新規登録ページの場合はimage_pathが更新できた後に編集ページに遷移させる
          setCreatedDocId(docId);
        });
      } else {
        // 新規登録ページ&&画像の更新がない場合：編集ページに遷移だけさせる
        setCreatedDocId(docId);
      }
    } else if (!isCreate && imageFile) {
      // 編集ページ&&画像の更新がある場合：画像のアップロード&データの更新をする
      setCreating(true);
      uploadImageFile(imageFile, createValue);
    } else {
      // 編集ページ&&画像の更新がない場合：データの更新だけする
      await create(createValue);
    }
  });

  return (
    <StaffForm
      loading={isCreate ? false : loading}
      staff={isCreate ? null : staffs[0]}
      formMethods={formMethods}
      onSubmit={onSubmit}
      creating={creating}
      snackbarState={state}
      snackbarClose={close}
      onChangeImageFile={onChangeImageFile}
      removeImageFile={removeImageFile}
    />
  );
};
