import { useState } from 'react';

import { ParentInfo } from '../../../domain/models/parent-info';
import createParentInfo from '../../../domain/services/create-parent-info';
import useSnackbar, { SnackbarState } from '../useSnackbar';

export type Result = {
  creating: boolean;
  create: (parentInfo: ParentInfo) => void;
  state: SnackbarState;
  close: () => void;
};

const useCreateParentInfo = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (parentInfo: ParentInfo) => {
    setCreating(true);
    const result = await createParentInfo(parentInfo);
    open(
      result.id ? 'success' : 'error',
      result.id
        ? '保護者の更新に成功しました'
        : '保護者の更新ができませんでした。もう一度お試しください。',
    );
    setCreating(false);
  };

  return { creating, create, close, state };
};

export default useCreateParentInfo;
