import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { defaultWorkShift, WorkShift } from '../../../domain/models/work-shift';
import getWorkShifts from '../../../domain/services/get-work-shifts';
import { QueryParams } from '../../../types/firestore-simple';
import { EnrolledCramSchoolContext } from '../../context/EnrolledCramSchoolContext';

export type Result = {
  loading: boolean;
  workShifts: WorkShift[];
  setWorkShifts: Dispatch<SetStateAction<WorkShift[]>>;
};

const useFetchWorkShift = ({
  queryParams,
}: {
  queryParams?: QueryParams<WorkShift>;
}): Result => {
  const [loading, setLoading] = useState(false);
  const [workShifts, setWorkShifts] = useState<WorkShift[]>([]);
  const { enrolledCramSchool } = useContext(EnrolledCramSchoolContext);

  useEffect(() => {
    void (async () => {
      setLoading(true);

      const result = await getWorkShifts({ queryParams });
      if (result.workShifts.length) {
        setWorkShifts(result.workShifts);
      } else {
        setWorkShifts([defaultWorkShift]);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [enrolledCramSchool]);

  return { loading, workShifts, setWorkShifts };
};

export default useFetchWorkShift;
