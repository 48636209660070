import firestoreSimple from '../../lib/firebase-firestore';
import { WorkShift } from '../models/work-shift';

const bulkDeleteWorkShift = async (
  workShiftIds: string[],
): Promise<boolean> => {
  try {
    const collection = firestoreSimple.collection<WorkShift>({
      path: 'work_shift',
    });

    await collection.bulkDelete(workShiftIds).catch((error) => {
      console.error(error);
    });

    return true;
  } catch (e) {
    console.error(e);

    return false;
  }
};

export default bulkDeleteWorkShift;
