import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { ArrayField } from 'react-hook-form';
import { TableCell, TableRow } from '@material-ui/core';
import { DateTime } from 'luxon';

import { dateFormat } from '../../../../../data/form-data';
import {
  LECTURE_KEYS,
  defaultSeparatedLecture,
  SeparatedLecture,
} from '../../../../../domain/models/student-lectures';
import { ScheduleItem } from '../../../../../domain/models/timetable';
import { Subject } from './Subject';
import {
  EventItem,
  PeriodicInfo,
} from '../../../../../domain/models/periodic-info';

export type Props = {
  scheduleItem: Partial<ArrayField<ScheduleItem>>;
  index: number;
  isNotesElementarySchool: boolean;
  periodicInfo: PeriodicInfo | null;
};

export const TableRowCalendar = ({
  scheduleItem,
  index,
  isNotesElementarySchool,
  periodicInfo,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const day = new Date(scheduleItem.date as string).getDay();
  const jaDays = ['日', '月', '火', '水', '木', '金', '土'];
  let dateColor;
  if (day === 0) {
    dateColor = 'has-text-danger';
  } else if (day === 6) {
    dateColor = 'has-text-link';
  } else {
    dateColor = 'has-text-grey-dark';
  }
  const date = index + 1;

  // 行事予定を探し出す
  let formattedDate = '';
  if (scheduleItem.date) {
    formattedDate = DateTime.fromJSDate(new Date(scheduleItem.date)).toFormat(
      dateFormat,
    );
  }
  let eventItems: EventItem[] = [];
  if (periodicInfo) {
    eventItems = periodicInfo.event_schedule.filter(
      (eventItem) => eventItem.date === formattedDate,
    );
  }

  return (
    <TableRow>
      <TableCell component="th" scope="row" className="has-text-centered">
        <div>
          <div className={clsx('is-size-4', 'has-text-weight-bold', dateColor)}>
            {date}
          </div>
          <div>{jaDays[day]}曜日</div>
        </div>
      </TableCell>
      {LECTURE_KEYS.map((lectureKey) => {
        const isLectureSpecial = lectureKey === 'lecture_special';

        let defaultValueSubject: SeparatedLecture | string;
        if (isNotesElementarySchool && !isLectureSpecial) {
          // ノートス小学生の場合かつ通常の授業の場合は前半・後半に分ける
          defaultValueSubject =
            scheduleItem?.[lectureKey]?.subject ??
            _.cloneDeep(defaultSeparatedLecture);

          // もしも、中学校->小学校のグレードに戻っていた場合は、初期値をdefaultSeparatedLectureにする。
          if (typeof defaultValueSubject === 'string') {
            defaultValueSubject = _.cloneDeep(defaultSeparatedLecture);
          }
        } else {
          // ノートス小学生以外の場合かつ特別授業の場合は前半・後半に分けない
          defaultValueSubject = scheduleItem?.[lectureKey]?.subject ?? '';

          // もしも、小学校->中学校のグレードに上がっていた場合は、初期値をstring（空文字）にする。
          if (typeof defaultValueSubject !== 'string') {
            defaultValueSubject = '';
          }
        }

        return (
          <TableCell className="has-text-centered">
            {typeof defaultValueSubject === 'string' ? (
              <Subject defaultValueSubject={defaultValueSubject} />
            ) : (
              <>
                <div className="mt-2 mb-5 h-55px">
                  <div className="is-size-7 has-text-grey mb-1">前半</div>
                  <Subject defaultValueSubject={defaultValueSubject.first} />
                </div>
                <div className="mt-2 mb-3 h-55px">
                  <div className="is-size-7 has-text-grey mb-1">後半</div>
                  <Subject defaultValueSubject={defaultValueSubject.latter} />
                </div>
              </>
            )}
          </TableCell>
        );
      })}
      <TableCell>
        {eventItems.length ? (
          <div>
            <ul>
              {eventItems.map((eventItem) => (
                <li>{eventItem.name}</li>
              ))}
            </ul>
          </div>
        ) : null}
      </TableCell>
    </TableRow>
  );
};
