/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-unsafe-return */

import { FilterValue, IdType, Row } from 'react-table';
import { useMemo } from 'react';

import { OptionsType, OptionTypeBase } from 'react-select';
import { TelInfo } from '../../domain/models/parent-info';
import { StudentBasic } from '../../domain/models/student-basic';
import { InvoiceItem } from '../../domain/models/student-invoice';

export const includesStringFilter = (
  rows: Row[],
  columnIds: IdType<any>[],
  filterValue: FilterValue,
) => {
  return rows.filter((row) => {
    const rowValue: string =
      typeof row.values[columnIds[0]] === 'string'
        ? String(row.values[columnIds[0]])
        : '';

    return rowValue ? rowValue.includes(filterValue) : false;
  });
};

export const includeSelectValueFilter = (
  rows: Row[],
  columnIds: IdType<any>[],
  filterValue: OptionsType<OptionTypeBase>,
) => {
  return rows.filter((row) => {
    return filterValue && filterValue.length
      ? filterValue.some((v) => v.value === row.values[columnIds[0]])
      : true;
  });
};

export const telFilter = (
  rows: Row[],
  columnIds: IdType<any>[],
  filterValue: FilterValue,
) => {
  return rows.filter((row) => {
    const telInfo = row.values[columnIds[0]] as TelInfo;
    if (!telInfo.num || !telInfo.relationship) {
      return false;
    }

    return `${telInfo.num}${telInfo.relationship}`.includes(filterValue);
  });
};

export const emergencyContactsFilter = (
  rows: Row[],
  columnIds: IdType<any>[],
  filterValue: FilterValue,
) => {
  return rows.filter((row) => {
    const values: TelInfo[] =
      row.values[columnIds[0]] instanceof Array
        ? (row.values[columnIds[0]] as TelInfo[])
        : [];

    return values.some((telInfo) =>
      `${telInfo.num}${telInfo.relationship}`.includes(filterValue),
    );
  });
};

export const studentsFilter = (
  rows: Row[],
  columnIds: IdType<any>[],
  filterValue: FilterValue,
) => {
  return rows.filter((row) => {
    const values: StudentBasic[] =
      row.values[columnIds[0]] instanceof Array
        ? (row.values[columnIds[0]] as StudentBasic[])
        : [];

    return values.some((studentBasic) =>
      `${studentBasic.student_num}${studentBasic.first_name}${studentBasic.last_name}${studentBasic.grade}${studentBasic.school_name}${studentBasic.enrolled_cram_school}`.includes(
        filterValue,
      ),
    );
  });
};

export const invoiceItemsFilter = (
  rows: Row[],
  columnIds: IdType<any>[],
  filterValue: FilterValue,
) => {
  return rows.filter((row) => {
    const values: InvoiceItem[] =
      row.values[columnIds[0]] instanceof Array
        ? (row.values[columnIds[0]] as InvoiceItem[])
        : [];

    return values.some((invoiceItem) =>
      invoiceItem.label.includes(filterValue),
    );
  });
};

export const libraryCardPointFilter = (
  rows: Row[],
  columnIds: IdType<any>[],
  filterValue: FilterValue,
) => {
  return rows.filter((row) => {
    const libraryCardPoint = row.values[columnIds[0]] as number;
    const checked: boolean = filterValue as boolean;

    return checked ? libraryCardPoint > 0 : true;
  });
};

export const isTerminatedFilter = (
  rows: Row[],
  columnIds: IdType<any>[],
  filterValue: FilterValue,
) => {
  return rows.filter((row) => {
    const checked: boolean = filterValue as boolean;
    const termination_date: string | undefined = row.values[columnIds[0]] as
      | string
      | undefined;

    return checked ? true : !termination_date || false;
  });
};

export default () => {
  return useMemo(
    () => ({
      includesStringFilter,
      includeSelectValue: includeSelectValueFilter,
      telFilter,
      emergencyContactsFilter,
      studentsFilter,
      invoiceItemsFilter,
      libraryCardPointFilter,
      isTerminatedFilter,
    }),
    [],
  );
};
