import { useAsync } from 'react-use';
import { useEffect, useState } from 'react';

import { StudentBasic } from '../../../domain/models/student-basic';
import { MiddleSchoolReportsList } from '../../../types/table/middle-school-reports-list';
import {
  getSubjectScores,
  MiddleSchoolReportScores,
} from '../../../domain/models/middle-school-report';
import { defaultMiddleSchoolReportValues } from '../../../data/form-data';
import getMiddleSchoolReport from '../../../domain/services/get-middle-school-report';

export type Result = {
  loading: boolean;
  middleSchoolReportsLists: MiddleSchoolReportsList[];
  error: Error | undefined;
  updateTableData: (
    rowIndex: number,
    columnId: string,
    score: number,
    id?: string,
  ) => void;
};

const useFetchMiddleSchoolReportsLists = (
  studentBasic: StudentBasic,
): Result => {
  const [data, setData] = useState<MiddleSchoolReportsList[]>([]);
  const { loading, error, value } = useAsync(async () => {
    const { middleSchoolReport } = await getMiddleSchoolReport({
      queryParams: [
        { fieldPath: 'student_id', opStr: '==', value: studentBasic.id },
      ],
    });
    const report = middleSchoolReport[0] || {
      ...defaultMiddleSchoolReportValues,
    };
    const defaultExam = { icon: null, id: report.id };
    const getTotal = (middleSchoolReportScores: MiddleSchoolReportScores) =>
      Object.values(middleSchoolReportScores).reduce(
        (prev, current) => prev + current,
        0,
      );
    const middleSchoolReportsList: MiddleSchoolReportsList[] = [
      {
        ...defaultExam,
        ...report['1st_grader_report_1'],
        reportKey: '1st_grader_report_1',
        total: getTotal(report['1st_grader_report_1']),
      },
      {
        ...defaultExam,
        ...report['1st_grader_report_2'],
        reportKey: '1st_grader_report_2',
        total: getTotal(report['1st_grader_report_2']),
      },
      {
        ...defaultExam,
        ...report['1st_grader_report_3'],
        reportKey: '1st_grader_report_3',
        total: getTotal(report['1st_grader_report_3']),
      },
      {
        ...defaultExam,
        ...report['2nd_grader_report_1'],
        reportKey: '2nd_grader_report_1',
        total: getTotal(report['2nd_grader_report_1']),
      },
      {
        ...defaultExam,
        ...report['2nd_grader_report_2'],
        reportKey: '2nd_grader_report_2',
        total: getTotal(report['2nd_grader_report_2']),
      },
      {
        ...defaultExam,
        ...report['2nd_grader_report_3'],
        reportKey: '2nd_grader_report_3',
        total: getTotal(report['2nd_grader_report_3']),
      },
      {
        ...defaultExam,
        ...report['3rd_grader_report_1'],
        reportKey: '3rd_grader_report_1',
        total: getTotal(report['3rd_grader_report_1']),
      },
      {
        ...defaultExam,
        ...report['3rd_grader_report_2'],
        reportKey: '3rd_grader_report_2',
        total: getTotal(report['3rd_grader_report_2']),
      },
      {
        ...defaultExam,
        ...report['3rd_grader_report_3'],
        reportKey: '3rd_grader_report_3',
        total: getTotal(report['3rd_grader_report_3']),
      },
    ];

    return middleSchoolReportsList;
  }, []);

  useEffect(() => {
    if (value) setData(value);
  }, [value]);

  const updateTableData = (
    rowIndex: number,
    columnId: string,
    score: number,
    id?: string,
  ) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          const newData = {
            ...old[rowIndex],
            [columnId]: score,
            id: id || '',
          };
          const subjectScores = getSubjectScores(newData);

          return {
            ...newData,
            total: Object.values(subjectScores).reduce(
              (prev, current) => prev + current,
              0,
            ),
          };
        }

        return row;
      }),
    );
  };

  return {
    loading,
    error,
    middleSchoolReportsLists: data,
    updateTableData,
  };
};

export default useFetchMiddleSchoolReportsLists;
