import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import {
  SubmitErrorHandler,
  SubmitHandler,
} from 'react-hook-form/dist/types/form';

import { LoginData } from '../../types/form/login-data';
import signInFirebaseAuth from '../../domain/services/sign-in-firebase-auth';
import getAdminUser from '../../domain/services/get-admin-user';
import useSnackbar from './useSnackbar';
import { AppContext } from '../context/AppContext';

export default ({
  handleSubmit,
}: {
  handleSubmit: <TSubmitFieldValues extends FieldValues = LoginData>(
    onValid: SubmitHandler<TSubmitFieldValues>,
    onInvalid?: SubmitErrorHandler<LoginData>,
  ) => (e?: React.BaseSyntheticEvent) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const { setAdminUser } = useContext(AppContext);
  const [, setMessage] = useState('');
  const history = useHistory();
  const { state, open, close } = useSnackbar();

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const { mail_address, password } = data;
    const resultAuth = await signInFirebaseAuth(mail_address, password);
    const resultFirestore = await getAdminUser(
      resultAuth?.userCredential?.user?.uid || '',
      resultAuth.message,
    );
    setMessage(resultFirestore.message);
    if (resultFirestore.adminUser) {
      setAdminUser(resultFirestore.adminUser);
    }
    open(
      resultFirestore.adminUser ? 'success' : 'error',
      resultFirestore.message,
    );
    setLoading(false);
    if (resultFirestore.adminUser) history.push('');
    setTimeout(() => close(), 3000);
  });

  return {
    onSubmit,
    loading,
    snackbarState: state,
  };
};
