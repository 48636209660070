import { HasId } from '@firestore-simple/web/dist/types';
import {
  InvoiceStatus,
  PaymentMethod,
  WithdrawalBank,
} from '../../data/form-data';

export type InvoiceItem = {
  label: string;
  tax_included: boolean;
  amounts: number;
  order: number;
};

export type StudentInvoice = HasId & {
  student_id: string;
  status: InvoiceStatus;
  invoice_month: string;
  withdrawal_date: string;
  withdrawal_bank: WithdrawalBank | null;
  withdrawal_result: boolean | null;
  invoice_items: InvoiceItem[];
  consumption_tax: number;
  subtotal: number;
  total: number;
  payment_method: PaymentMethod | null;
  message: string;
  remarks: string;
  is_transfer: boolean | null;
};

export const defaultStudentInvoice: StudentInvoice = {
  id: '',
  student_id: '',
  status: 'private',
  invoice_month: '',
  withdrawal_date: '',
  withdrawal_bank: null,
  withdrawal_result: null,
  consumption_tax: 0,
  invoice_items: [],
  subtotal: 0,
  total: 0,
  payment_method: null,
  message: '',
  remarks: '',
  is_transfer: null,
};
