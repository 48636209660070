import { useAsync } from 'react-use';
import { Dispatch, SetStateAction, useContext, useState } from 'react';

import getStudentBasic from '../../../domain/services/get-student-basics';
import getMiddleSchoolReport from '../../../domain/services/get-middle-school-report';
import { StudentBasic } from '../../../domain/models/student-basic';
import { MiddleSchoolReportList } from '../../../types/table/middle-school-report-list';
import { QueryParams } from '../../../types/firestore-simple';
import {
  defaultMiddleSchoolReportSubjects,
  MiddleSchoolReportKeys,
  middleSchoolReportLabels,
} from '../../../data/form-data';
import { getSubjectScores } from '../../../domain/models/middle-school-report';
import { AppContext } from '../../context/AppContext';

export type Result = {
  loading: boolean;
  middleSchoolReportLists: MiddleSchoolReportList[] | undefined;
  error: Error | undefined;
  reportKey: MiddleSchoolReportKeys;
  setReportKey: Dispatch<SetStateAction<MiddleSchoolReportKeys>>;
};

const useFetchMiddleSchoolReportLists = (): Result => {
  const { adminUser } = useContext(AppContext);
  const [reportKey, setReportKey] = useState<MiddleSchoolReportKeys>(
    (localStorage.getItem('selectedReportKey') as MiddleSchoolReportKeys) ??
      (Object.keys(middleSchoolReportLabels)[0] as MiddleSchoolReportKeys),
  );

  const { loading, error, value } = useAsync(async () => {
    const defaultQueryParams: QueryParams<StudentBasic> = [
      { fieldPath: 'status', opStr: '==', value: 'enrolled' },
      { fieldPath: 'grade', opStr: 'in', value: ['中1', '中2', '中3'] },
    ];
    if (['editor', 'viewer'].includes(adminUser.role)) {
      defaultQueryParams.push({
        fieldPath: 'enrolled_cram_school',
        opStr: '==',
        value: adminUser.enrolled_cram_school,
      });
    }
    const { studentBasics } = await getStudentBasic({
      queryParams: defaultQueryParams,
    });
    const lists: MiddleSchoolReportList[] = await Promise.all(
      studentBasics.map(
        async (student): Promise<MiddleSchoolReportList> => {
          const { middleSchoolReport } = await getMiddleSchoolReport({
            queryParams: [
              { fieldPath: 'student_id', opStr: '==', value: student.id },
            ],
          });
          const currentScores =
            middleSchoolReport.length && reportKey in middleSchoolReport[0]
              ? getSubjectScores(middleSchoolReport[0][reportKey])
              : defaultMiddleSchoolReportSubjects;

          return {
            id: middleSchoolReport.length ? middleSchoolReport[0].id : '',
            middleSchoolReport: middleSchoolReport[0],
            studentBasic: student,
            reportKey,
            student_id: student.id,
            student_num: student.student_num,
            name: `${student.last_name} ${student.first_name}`,
            grade: student.grade,
            enrolled_cram_school: student.enrolled_cram_school,
            school_name: student.school_name,
            ...currentScores,
            total: Object.values(currentScores).reduce(
              (prev, current) => prev + current,
              0,
            ),
          };
        },
      ),
    );

    return lists;
  }, [reportKey]);

  return {
    loading,
    error,
    middleSchoolReportLists: value,
    reportKey,
    setReportKey,
  };
};

export default useFetchMiddleSchoolReportLists;
