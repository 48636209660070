import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { TaskEnroll } from '../../../domain/models/task';
import { QueryParams } from '../../../types/firestore-simple';
import getTaskEnroll from '../../../domain/services/get-task-enroll';

export type Result = {
  loading: boolean;
  taskItems: TaskEnroll | undefined;
};

const useFetchTaskEnroll = ({
  id,
  queryParams,
}: {
  id?: string;
  queryParams?: QueryParams<TaskEnroll>;
}): Result => {
  const [loading, setLoading] = useState(false);
  const [taskEnrolls, setTaskEnrolls] = useState<TaskEnroll[]>([]);

  useEffectOnce(() => {
    void (async () => {
      setLoading(true);

      const result = await getTaskEnroll({ id, queryParams });

      if (result.taskEnrolls.length) {
        setTaskEnrolls(result.taskEnrolls);
      }

      setLoading(false);
    })();
  });

  return {
    loading,
    taskItems: taskEnrolls.length ? taskEnrolls[0] : undefined,
  };
};

export default useFetchTaskEnroll;
