import React, { FC, PropsWithChildren } from 'react';
import {
  Cell,
  CellValue,
  ColumnInstance,
  Row,
  TableInstance,
} from 'react-table';

import { MiddleSchoolExamList } from '../../../../../types/table/middle-school-exam-list';

const TotalCell: FC<PropsWithChildren<
  TableInstance<MiddleSchoolExamList> & {
    cell: Cell<MiddleSchoolExamList, number>;
    column: ColumnInstance<MiddleSchoolExamList>;
    row: Row<MiddleSchoolExamList>;
    value: CellValue<number>;
    updateTableData: (
      rowIndex: number,
      columnId: string,
      score: number,
      id?: string,
    ) => void;
  }
>> = ({ cell }) => {
  return <span>{cell.value}</span>;
};

export default TotalCell;
