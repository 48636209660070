import { OmitId } from '@firestore-simple/web/dist/types';
import { Collection } from '@firestore-simple/web/dist/collection';
import { Query } from '@firestore-simple/web';

import firestoreSimple from '../../lib/firebase-firestore';
import { SchoolCalendar } from '../models/school-calendar';
import { QueryParams } from '../../types/firestore-simple';

export type CollectionOrQuery =
  | Collection<SchoolCalendar, OmitId<SchoolCalendar>>
  | Query<SchoolCalendar, OmitId<SchoolCalendar>>;

const getSchoolCalendars = async ({
  queryParams,
}: {
  queryParams?: QueryParams<SchoolCalendar>;
}): Promise<{ schoolCalendars: SchoolCalendar[] }> => {
  const collection = firestoreSimple.collection<SchoolCalendar>({
    path: 'school_calendar',
  });
  const query =
    queryParams && queryParams.length
      ? queryParams.reduce((prev: CollectionOrQuery, current) => {
          return prev.where(current.fieldPath, current.opStr, current.value);
        }, collection)
      : collection;
  let docs: SchoolCalendar[] | undefined;
  if ('query' in query && 'startAt' in query) {
    docs =
      (await query.fetch().catch((error) => console.error(error))) || undefined;
  } else {
    docs = undefined;
  }
  const schoolCalendars = docs instanceof Array ? docs : [];

  return { schoolCalendars };
};

export default getSchoolCalendars;
