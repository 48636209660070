import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Cell } from 'react-table';
import { TextField } from '@material-ui/core';

import { dateFormat } from '../../../../../data/form-data';
import { TaskDocumentRequestList } from '../../../../../types/table/task-document-request-list';
import {
  DocumentRequestTaskItems,
  TaskDocumentRequest,
  TaskItem,
} from '../../../../../domain/models/task';
import DateField from '../../../atoms/form/DatePicker';
import getIcon from '../../../../../helpers/table/getIcon';
import useRole from '../../../../hooks/useRole';

interface Props {
  cell: Cell<TaskDocumentRequestList>;
  create: (task: TaskDocumentRequest) => Promise<string | undefined>;
  updateTableData: (rowIndex: number, value: TaskDocumentRequestList) => void;
}

export default ({ cell, create, updateTableData }: Props) => {
  const { isViewer } = useRole();
  const value = cell.value as TaskItem;
  const [checked, setCheck] = useState<boolean>(value.done);
  const [datetime, setDatetime] = useState<DateTime | null>(
    value.date ? DateTime.fromFormat(value.date, dateFormat) : null,
  );
  const dateLabel = cell.column.id === 'send_document' ? '資料発送日' : '';

  const getNewTasks = ({
    done,
    date,
  }: {
    done?: boolean;
    date?: string | null;
  }) => {
    const tasks = [
      { ...cell.row.values.send_document },
      { ...cell.row.values.call_after_send_document },
      { ...cell.row.values.deadline },
    ] as TaskItem[];
    const newTasks: TaskItem[] = tasks.map((taskVal: TaskItem) => {
      if (taskVal.name !== cell.column.id) {
        return taskVal;
      }

      if (typeof done !== 'undefined') {
        return { ...taskVal, done };
      }
      if (typeof date !== 'undefined') {
        return { ...taskVal, date };
      }

      return taskVal;
    }) as DocumentRequestTaskItems;

    return newTasks;
  };

  const getNewValues = (newTasks: TaskItem[]) => {
    return {
      id: cell.row.original.id,
      student_id: cell.row.original.student_id,
      tasks: newTasks,
    } as TaskDocumentRequest;
  };

  /* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
  return (
    <div className="form" onClick={(event) => event.stopPropagation()}>
      <input
        type="checkbox"
        name={cell.column.id}
        defaultChecked={checked}
        className="checkbox"
        onChange={async (e) => {
          setCheck(e.target.checked);
          const newTasks = getNewTasks({ done: e.target.checked });
          const newValues = getNewValues(newTasks);
          const newTaskList = {
            icon: null,
            id: cell.row.original.id ? cell.row.original.id : '',
            student_id: cell.row.original.student_id || '',
            student_name: cell.row.original.student_name || '',
            enrolled_cram_school: cell.row.original.enrolled_cram_school || '',
            send_document: newTasks[0],
            call_after_send_document: newTasks[1],
            deadline: newTasks[2],
          };
          const id = await create(newValues);
          if (id) {
            newTaskList.id = id;
          }
          updateTableData(cell.row.index, {
            ...newTaskList,
            icon: getIcon(newTaskList),
          });
        }}
        disabled={isViewer}
      />
      {['send_document', 'deadline'].includes(cell.column.id) ? (
        <DateField
          DatePickerProps={{
            label: dateLabel,
            name: cell.column.id,
            value: datetime,
            TextFieldComponent: (props) => (
              <TextField {...props} margin="none" />
            ),
            onChange: async (v) => {
              const date = v ? v.toFormat(dateFormat) : null;
              setDatetime(date ? DateTime.fromFormat(date, dateFormat) : null);
              const newTasks: TaskItem[] = getNewTasks({ date });
              const newValues = getNewValues(newTasks);
              const newTaskList = {
                icon: null,
                id: cell.row.original.id || '',
                student_id: cell.row.original.student_id || '',
                student_name: cell.row.original.student_name || '',
                enrolled_cram_school:
                  cell.row.original.enrolled_cram_school || '',
                send_document: newTasks[0],
                call_after_send_document: newTasks[1],
                deadline: newTasks[2],
              };
              const id = await create(newValues);
              if (id) {
                newTaskList.id = id;
              }
              updateTableData(cell.row.index, {
                ...newTaskList,
                icon: getIcon(newTaskList),
              });
            },
            disabled: isViewer,
            className: 'datetime',
          }}
        />
      ) : null}
    </div>
  );
};
