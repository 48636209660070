import { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { StudentBasic } from '../../../domain/models/student-basic';
import { TaskTrialList } from '../../../types/table/task-trial-list';
import getStudentBasic from '../../../domain/services/get-student-basics';
import getTaskTrial from '../../../domain/services/get-task-trial';
import getIcon from '../../../helpers/table/getIcon';
import { trialTaskItems } from '../../../domain/models/task';
import { AppContext } from '../../context/AppContext';

export type Result = {
  loading: boolean;
  trialTaskList: TaskTrialList[];
};

const useFetchTaskTrialLists = (): Result => {
  const { adminUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [, setStudentBasic] = useState<StudentBasic[]>([]);
  const [trialTaskList, setTrialTaskList] = useState<TaskTrialList[]>([]);
  const defaultQuery = [
    {
      fieldPath: 'status',
      opStr: '==',
      value: 'waiting_trial',
    },
  ];
  const queryParams = ['editor', 'viewer'].includes(adminUser.role)
    ? [
        ...defaultQuery,
        {
          fieldPath: 'enrolled_cram_school',
          opStr: '==',
          value: adminUser.enrolled_cram_school,
        },
      ]
    : [...defaultQuery];

  useEffectOnce(() => {
    void (async () => {
      setLoading(true);

      const { studentBasics } = await getStudentBasic({ queryParams });
      setStudentBasic(studentBasics);

      const { taskTrials } = await getTaskTrial({});

      setTrialTaskList(
        studentBasics.map(
          (studentBasic): TaskTrialList => {
            const studentTask = taskTrials.find(
              (value) => value.student_id === studentBasic.id,
            );
            const tasks: TaskTrialList = {
              icon: null,
              id: studentTask?.id || '',
              student_id: studentBasic.id,
              student_name: `${studentBasic.last_name}${studentBasic.first_name}`,
              enrolled_cram_school: studentBasic.enrolled_cram_school,
              input_invoice_data:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'input_invoice_data',
                    ) || trialTaskItems[0]
                  : trialTaskItems[0],
              input_class_schedule:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'input_class_schedule',
                    ) || trialTaskItems[1]
                  : trialTaskItems[1],
              create_report_curriculum:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'create_report_curriculum',
                    ) || trialTaskItems[2]
                  : trialTaskItems[2],
              create_homework_print:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'create_homework_print',
                    ) || trialTaskItems[3]
                  : trialTaskItems[3],
              reserve_interview:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'reserve_interview',
                    ) || trialTaskItems[4]
                  : trialTaskItems[4],
              deadline:
                studentTask && studentTask.tasks?.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'deadline',
                    ) || trialTaskItems[5]
                  : trialTaskItems[5],
            };

            return {
              ...tasks,
              icon: getIcon(tasks),
            };
          },
        ),
      );

      setLoading(false);
    })();
  });

  return { loading, trialTaskList };
};

export default useFetchTaskTrialLists;
