import React, { FC, PropsWithChildren, useContext } from 'react';
import {
  Cell,
  CellValue,
  ColumnInstance,
  Row,
  TableInstance,
} from 'react-table';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { StudentBasic } from '../../../../../domain/models/student-basic';
import { defaultMiddleSchoolReportValues } from '../../../../../data/form-data';
import { ScoreReportContext } from '../../../../context/ScoreReportContext';
import { MiddleSchoolReportsList } from '../../../../../types/table/middle-school-reports-list';
import { MiddleSchoolReport } from '../../../../../domain/models/middle-school-report';
import useRole from '../../../../hooks/useRole';

const MAX = 5;
const MIN = 0;

const getNewValue = (value: string | number) => {
  let newValue = Number(value);
  if (Number.isNaN(newValue)) {
    return 0;
  }

  if (newValue > MAX) {
    newValue = MAX;
  } else if (newValue < MIN) {
    newValue = MIN;
  }

  return newValue;
};

const getNewTableData = (
  cell: Cell<MiddleSchoolReportsList>,
  value: number,
): MiddleSchoolReportsList => {
  let newTable: MiddleSchoolReportsList = cell.row
    .values as MiddleSchoolReportsList;
  switch (cell.column.id) {
    case 'japanese': {
      newTable = { ...newTable, japanese: value };
      break;
    }
    case 'math': {
      newTable = { ...newTable, math: value };
      break;
    }
    case 'english': {
      newTable = { ...newTable, english: value };
      break;
    }
    case 'science': {
      newTable = { ...newTable, science: value };
      break;
    }
    case 'music': {
      newTable = { ...newTable, music: value };
      break;
    }
    case 'society': {
      newTable = { ...newTable, society: value };
      break;
    }
    case 'art': {
      newTable = { ...newTable, art: value };
      break;
    }
    case 'health_physical': {
      newTable = { ...newTable, health_physical: value };
      break;
    }
    case 'tech_home': {
      newTable = { ...newTable, tech_home: value };
      break;
    }
    default: {
      break;
    }
  }

  return newTable;
};

const getNewReportData = (
  studentId: string,
  currentData: MiddleSchoolReport,
  newTableData: MiddleSchoolReportsList,
) => {
  const newReportData: MiddleSchoolReport = {
    ...defaultMiddleSchoolReportValues,
    ...currentData,
    id: newTableData.id,
    student_id: studentId,
    [newTableData.reportKey]: {
      japanese: newTableData.japanese,
      society: newTableData.society,
      math: newTableData.math,
      science: newTableData.science,
      music: newTableData.music,
      art: newTableData.art,
      health_physical: newTableData.health_physical,
      tech_home: newTableData.tech_home,
      english: newTableData.english,
    },
  };

  return newReportData;
};

const getReportData = (data: MiddleSchoolReportsList[]): MiddleSchoolReport => {
  return {
    id: '',
    student_id: '',
    '1st_grader_report_1': {
      japanese: data[0].japanese,
      math: data[0].math,
      english: data[0].english,
      society: data[0].society,
      science: data[0].science,
      music: data[0].music,
      art: data[0].art,
      health_physical: data[0].health_physical,
      tech_home: data[0].tech_home,
    },
    '1st_grader_report_2': {
      japanese: data[1].japanese,
      math: data[1].math,
      english: data[1].english,
      society: data[1].society,
      science: data[1].science,
      music: data[1].music,
      art: data[1].art,
      health_physical: data[1].health_physical,
      tech_home: data[1].tech_home,
    },
    '1st_grader_report_3': {
      japanese: data[2].japanese,
      math: data[2].math,
      english: data[2].english,
      society: data[2].society,
      science: data[2].science,
      music: data[2].music,
      art: data[2].art,
      health_physical: data[2].health_physical,
      tech_home: data[2].tech_home,
    },
    '2nd_grader_report_1': {
      japanese: data[3].japanese,
      math: data[3].math,
      english: data[3].english,
      society: data[3].society,
      science: data[3].science,
      music: data[3].music,
      art: data[3].art,
      health_physical: data[3].health_physical,
      tech_home: data[3].tech_home,
    },
    '2nd_grader_report_2': {
      japanese: data[4].japanese,
      math: data[4].math,
      english: data[4].english,
      society: data[4].society,
      science: data[4].science,
      music: data[4].music,
      art: data[4].art,
      health_physical: data[4].health_physical,
      tech_home: data[4].tech_home,
    },
    '2nd_grader_report_3': {
      japanese: data[5].japanese,
      math: data[5].math,
      english: data[5].english,
      society: data[5].society,
      science: data[5].science,
      music: data[5].music,
      art: data[5].art,
      health_physical: data[5].health_physical,
      tech_home: data[5].tech_home,
    },
    '3rd_grader_report_1': {
      japanese: data[6].japanese,
      math: data[6].math,
      english: data[6].english,
      society: data[6].society,
      science: data[6].science,
      music: data[6].music,
      art: data[6].art,
      health_physical: data[6].health_physical,
      tech_home: data[6].tech_home,
    },
    '3rd_grader_report_2': {
      japanese: data[7].japanese,
      math: data[7].math,
      english: data[7].english,
      society: data[7].society,
      science: data[7].science,
      music: data[7].music,
      art: data[7].art,
      health_physical: data[7].health_physical,
      tech_home: data[7].tech_home,
    },
    '3rd_grader_report_3': {
      japanese: data[8].japanese,
      math: data[8].math,
      english: data[8].english,
      society: data[8].society,
      science: data[8].science,
      music: data[8].music,
      art: data[8].art,
      health_physical: data[8].health_physical,
      tech_home: data[8].tech_home,
    },
  };
};

const EditableCell: FC<PropsWithChildren<
  TableInstance<MiddleSchoolReportsList> & {
    cell: Cell<MiddleSchoolReportsList, number>;
    column: ColumnInstance<MiddleSchoolReportsList>;
    row: Row<MiddleSchoolReportsList>;
    value: CellValue<number>;
    updateTableData: (
      rowIndex: number,
      columnId: string,
      score: number,
      id?: string,
    ) => void;
  }
>> = ({
  data,
  cell,
  value: initialValue,
  row: { index },
  column: { id },
  updateTableData,
}) => {
  const {
    state: { studentBasic },
  } = useLocation<{ studentBasic: StudentBasic }>();
  const { create } = useContext(ScoreReportContext);
  const [value, setValue] = React.useState(initialValue);
  const { isViewer } = useRole();

  useDebounce(
    async () => {
      if (initialValue === value) return;

      const newTableData = getNewTableData(cell, value);
      const newReportData = getNewReportData(
        studentBasic.id,
        getReportData(data),
        newTableData,
      );
      const reportId = create ? await create(newReportData) : '';
      updateTableData(index, id, value, reportId);
    },
    500,
    [value],
  );

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      type="number"
      className="input"
      onChange={(event) => {
        const newValue = getNewValue(event.target.value);
        setValue(newValue);
      }}
      value={value}
      max={MAX}
      min={MIN}
      disabled={isViewer}
    />
  );
};

export default EditableCell;
