import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Toolbar } from '@material-ui/core';

import AppBar from '../../../containers/layouts/AppBar';
import Drawer from '../Drawer';
import './style.scss';

const AdminWrapper: FC = ({ children }) => {
  const { pathname } = useLocation();
  const pageId =
    pathname === '/' ? 'top' : pathname.replace(/^\//, '').replace('/', '-');

  useEffect(() => {
    void document.querySelector('body')?.classList.add(pageId);
  }, [pageId]);

  return (
    <div className="admin-wrapper">
      <Helmet>
        <style type="text/css">{`
          html {
              background-color: #fafafa;
          }
        `}</style>
      </Helmet>
      <AppBar />
      <Drawer />
      <main id="content-wrapper">
        <Toolbar className="header-block" />
        {children}
      </main>
    </div>
  );
};

export default AdminWrapper;
