import { useState } from 'react';
import { DateTime } from 'luxon';
import _ from 'lodash';
import {
  defaultLectureItemForShift,
  defaultLectureItemForShiftNotesElementarySchool,
  defaultWorkShift,
  WorkShift,
} from '../../../domain/models/work-shift';
import {
  defaultSeparatedLecture,
  getLectureDayKey,
  jaLectureDaysArr,
  LECTURE_KEYS,
} from '../../../domain/models/student-lectures';
import { isNotesElementarySchool } from '../../../domain/models/student-basic';
import bulkCreateWorkShift from '../../../domain/services/bulk-create-work-shift';
import getWorkShifts from '../../../domain/services/get-work-shifts';
import getStudentBasics from '../../../domain/services/get-student-basics';
import getSchoolCalendars from '../../../domain/services/get-school-calendar';
import getTimetables from '../../../domain/services/get-timetables';
import getStudentLectures from '../../../domain/services/get-student-lectures';
import { STUDENT_STATUS } from '../../../data/form-data';
import useSnackbar, { SnackbarState } from '../useSnackbar';

export type Result = {
  creating: boolean;
  bulkCreate: (
    fromDate: string,
    toDate: string,
    enrolledCramSchool: string,
  ) => void;
  state: SnackbarState;
  close: () => void;
};

const useBulkCreateWorkShift = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const bulkCreate = async (
    fromDate: string,
    toDate: string,
    enrolledCramSchool: string,
  ) => {
    setCreating(true);

    // 日付範囲（文字列）から各年毎の年度が格納された配列を作成する
    const startYear = new Date(fromDate).getFullYear();
    const endYear = new Date(toDate).getFullYear();
    const yearList: string[] = [];
    for (let year = startYear; year <= endYear; year += 1) {
      yearList.push(String(year));
    }

    const { schoolCalendars: allSchoolCalendars } = await getSchoolCalendars({
      queryParams: [
        {
          fieldPath: 'school_name',
          opStr: '==',
          value: enrolledCramSchool,
        },
      ],
    });
    const schoolCalendars = allSchoolCalendars.filter(
      (sc) =>
        sc.school_name === enrolledCramSchool && yearList.includes(sc.year),
    );

    const [, , , , enrolled] = STUDENT_STATUS;
    const { studentBasics } = await getStudentBasics({
      queryParams: [
        {
          fieldPath: 'status',
          opStr: '==',
          value: enrolled,
        },
        {
          fieldPath: 'enrolled_cram_school',
          opStr: '==',
          value: enrolledCramSchool,
        },
      ],
    });

    // ※timetableはループ内でfindする
    const { timetables: allTimetables } = await getTimetables({});

    const { studentLectures: allStudentLectures } = await getStudentLectures(
      {},
    );
    const studentLectures = allStudentLectures.filter((sl) =>
      studentBasics.map((sb) => sb.id).includes(sl.student_id),
    );

    const { workShifts } = await getWorkShifts({
      queryParams: [
        {
          fieldPath: 'school_name',
          opStr: '==',
          value: enrolledCramSchool,
        },
      ],
    });

    // 日付範囲（文字列）から各1日毎の日付（文字列）が格納された配列を作成する
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    const dateList = [];
    for (const sd = startDate; sd <= endDate; sd.setDate(sd.getDate() + 1)) {
      const formattedYear = sd.getFullYear();
      const formattedMonth = DateTime.fromJSDate(sd).toFormat('MM');
      const formattedDate = DateTime.fromJSDate(sd).toFormat('dd');
      const formattedFullDate = `${formattedYear}/${formattedMonth}/${formattedDate}`;
      dateList.push(formattedFullDate);
    }

    const createData: WorkShift[] = [];
    dateList.forEach((date) => {
      // 校舎マスタにて休みに設定されている日付の場合はシフト登録しない
      if (schoolCalendars.some((sc) => sc.holidays.includes(date))) return;

      const dateTypeDate = new Date(date);
      const yearMonth = DateTime.fromJSDate(dateTypeDate).toFormat('yyyy/MM');

      // 日付と校舎名を元にベースとなるシフトのデータを探すor無ければ作成
      const workShift = workShifts.find(
        (ws) => ws.date === date && ws.school_name === enrolledCramSchool,
      ) ?? {
        ..._.cloneDeep(defaultWorkShift),
        date,
        school_name: enrolledCramSchool,
      };

      studentBasics.forEach((studentBasic) => {
        const isNotesElementarySchoolTmp = isNotesElementarySchool(
          studentBasic,
        );

        const studentId = studentBasic.id;

        // 授業日程（ScheduleItem）をベースにシフトデータのデータ整形をする
        const timetable = allTimetables.find(
          (t) => t.year_month === yearMonth && t.student_id === studentId,
        );

        // 授業日程が登録されていなければ次の日付のループへ
        if (!timetable) return;

        // ベースとなる授業日程（ScheduleItem）を探す
        const scheduleItem = timetable.schedule.find((si) => si.date === date);

        // 授業日程（ScheduleItem）が登録されていなければ次の日付のループへ
        if (!scheduleItem) return;

        // 1限目〜特別授業までの授業日程を、create用データ配列にpush
        LECTURE_KEYS.forEach((lectureKey) => {
          // 特別授業の場合はノートス小学生でも教科が前半・後半に分かれないのでその判定用
          const isBaseLectureKey = !(lectureKey === 'lecture_special');

          // ノートス小学生<->ノートス小学生以外で学年を途中で切り替えたが、拾ってきた授業日程のデータ構造が切り替え後のデータ構造に切り替わっていない場合は次の日付のループへ
          // （※バグ対策用 例.ノートス小学生->ノートス中学生に学年を切り替えたが、その後に授業日程の保存ボタンを押さず授業日程データの更新をしていない時に起こりうる）
          if (
            isNotesElementarySchoolTmp &&
            typeof scheduleItem?.[lectureKey]?.subject === 'string' &&
            isBaseLectureKey
          )
            return;
          if (
            !isNotesElementarySchoolTmp &&
            typeof scheduleItem?.[lectureKey]?.subject !== 'string' &&
            isBaseLectureKey
          )
            return;

          // ※念のためのバグ対策&TypeScriptエラー用（lecture_Xが無かった場合等）
          if (!workShift?.shift?.[lectureKey]) workShift.shift[lectureKey] = [];
          if (!workShift?.shift?.[lectureKey]) return;

          // 教科が登録されていなければ次のコマのループへ
          if (
            scheduleItem &&
            typeof scheduleItem[lectureKey]?.subject === 'string' &&
            !scheduleItem[lectureKey]?.subject
          )
            return;
          if (
            scheduleItem &&
            typeof scheduleItem[lectureKey]?.subject !== 'string' &&
            !scheduleItem[lectureKey]?.subject?.first &&
            !scheduleItem[lectureKey]?.subject?.latter
          )
            return;

          // 生徒IDを元にベースとなるシフトの各限毎の授業データを探すor無ければ作成
          let lectureItemForShift =
            workShift?.shift?.[lectureKey]?.find(
              (lifs) => lifs.student_id === studentId,
            ) ??
            (isNotesElementarySchoolTmp && isBaseLectureKey
              ? _.cloneDeep(defaultLectureItemForShiftNotesElementarySchool)
              : _.cloneDeep(defaultLectureItemForShift));
          const lectureItemForShiftIndex = workShift?.shift?.[
            lectureKey
          ]?.findIndex((lifs) => lifs.student_id === studentId);
          lectureItemForShift = {
            ...lectureItemForShift,
            subject:
              scheduleItem?.[lectureKey]?.subject ??
              (isNotesElementarySchoolTmp && isBaseLectureKey
                ? defaultSeparatedLecture
                : ''),
            student_id: studentId,
            grade: studentBasic.grade,
          };

          // 授業日程と同じ曜日、コマ、教科の通塾情報がある場合はその座席と講師を設定する
          const studentLecture = studentLectures.find(
            (sl) => sl.student_id === studentId,
          );
          const lectureDayKey = getLectureDayKey(
            jaLectureDaysArr[dateTypeDate.getDay()],
          );
          if (studentLecture && lectureDayKey && scheduleItem) {
            const lectureItemForLecture = studentLecture[lectureDayKey];

            // ノートス小学生の場合
            if (
              lectureItemForLecture &&
              lectureItemForLecture[lectureKey]?.lecture ===
                scheduleItem[lectureKey]?.lecture &&
              typeof lectureItemForLecture[lectureKey]?.subject !== 'string'
            ) {
              let teacherIdFirst = '';
              let teacherIdLatter = '';
              let defaultSeatFirst = '';
              let defaultSeatLatter = '';

              // 前半のチェック
              if (
                typeof scheduleItem[lectureKey]?.subject !== 'string' &&
                typeof lectureItemForLecture[lectureKey]?.teacher_id !==
                  'string' &&
                typeof lectureItemForLecture[lectureKey]?.default_seat !==
                  'string' &&
                lectureItemForLecture[lectureKey]?.subject.first ===
                  scheduleItem[lectureKey]?.subject.first
              ) {
                teacherIdFirst =
                  lectureItemForLecture[lectureKey]?.teacher_id?.first ?? '';
                defaultSeatFirst =
                  lectureItemForLecture[lectureKey]?.default_seat?.first ?? '';
              }

              // 後半のチェック
              if (
                typeof scheduleItem[lectureKey]?.subject !== 'string' &&
                typeof lectureItemForLecture[lectureKey]?.teacher_id !==
                  'string' &&
                typeof lectureItemForLecture[lectureKey]?.default_seat !==
                  'string' &&
                lectureItemForLecture[lectureKey]?.subject.latter ===
                  scheduleItem[lectureKey]?.subject.latter
              ) {
                teacherIdLatter =
                  lectureItemForLecture[lectureKey]?.teacher_id?.latter ?? '';
                defaultSeatLatter =
                  lectureItemForLecture[lectureKey]?.default_seat?.latter ?? '';
              }

              lectureItemForShift = {
                ...lectureItemForShift,
                teacher_id: {
                  first: teacherIdFirst,
                  latter: teacherIdLatter,
                },
                default_seat: {
                  first: defaultSeatFirst,
                  latter: defaultSeatLatter,
                },
              };
            }

            // ノートス小学生以外の場合
            if (
              lectureItemForLecture &&
              lectureItemForLecture[lectureKey]?.lecture ===
                scheduleItem[lectureKey]?.lecture &&
              typeof lectureItemForLecture[lectureKey]?.subject === 'string' &&
              lectureItemForLecture[lectureKey]?.subject ===
                scheduleItem[lectureKey]?.subject
            ) {
              lectureItemForShift = {
                ...lectureItemForShift,
                teacher_id:
                  typeof lectureItemForLecture[lectureKey]?.teacher_id ===
                  'string'
                    ? lectureItemForLecture[lectureKey]?.teacher_id ?? ''
                    : '',
                default_seat:
                  typeof lectureItemForLecture[lectureKey]?.default_seat ===
                  'string'
                    ? lectureItemForLecture[lectureKey]?.default_seat ?? ''
                    : '',
              };
            }
          }

          // シフトデータ（1限毎）が登録されていなかったらそのままデータ配列にpush、登録されていればindexを元にデータを差し替え
          if (lectureItemForShiftIndex <= -1) {
            void workShift?.shift?.[lectureKey].push(lectureItemForShift);

            return;
          }
          void workShift?.shift?.[lectureKey].splice(
            lectureItemForShiftIndex,
            1,
            lectureItemForShift,
          );
        });
      });

      // 1日のうち全てのコマで授業日程が一つも登録されていなければデータ作成はしない
      if (
        LECTURE_KEYS.map((lectureKey) => workShift.shift[lectureKey]).every(
          (lectureItemForShifts) =>
            !lectureItemForShifts || lectureItemForShifts.length <= 0,
        )
      )
        return;

      // create用データ配列にpush
      createData.push(workShift);
    });

    const isSuccess = await bulkCreateWorkShift(createData);

    open(
      isSuccess ? 'success' : 'error',
      isSuccess
        ? 'シフトの一括作成が成功しました'
        : 'シフトの一括作成ができませんでした。もう一度お試しください。',
    );
    setCreating(false);
  };

  return { creating, bulkCreate, state, close };
};

export default useBulkCreateWorkShift;
