import { useState } from 'react';
import _ from 'lodash';

import useSnackbar, { SnackbarState } from '../useSnackbar';
import createStudentLectures from '../../../domain/services/create-student-lectfures';
import {
  BASE_LECTURE_KEYS,
  LECTURE_DAY_KEYS,
  StudentLectures,
} from '../../../domain/models/student-lectures';
import getStudentLectures from '../../../domain/services/get-student-lectures';

export type Result = {
  creating: boolean;
  create: (
    studentLectures: StudentLectures,
  ) => Promise<(string | StudentLectures | undefined)[]>;
  state: SnackbarState;
  close: () => void;
};

const useCreateStudentLectures = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (studentLecture: StudentLectures) => {
    setCreating(true);

    // 通塾情報が更新された際に変更された箇所を変更&クリアする処理
    const { studentLectures: oldStudentLectures } = await getStudentLectures({
      queryParams: [
        {
          fieldPath: 'student_id',
          opStr: '==',
          value: studentLecture.student_id,
        },
      ],
    });
    const oldStudentLecture = oldStudentLectures?.[0];

    const newStudentLecture = _.cloneDeep(studentLecture);
    if (oldStudentLecture) {
      LECTURE_DAY_KEYS.forEach((lectureDayKey) => {
        BASE_LECTURE_KEYS.forEach((baseLectureKey) => {
          let lectureItem = newStudentLecture[lectureDayKey][baseLectureKey];
          const oldLectureItem =
            oldStudentLecture[lectureDayKey][baseLectureKey];
          if (lectureItem && oldLectureItem) {
            // 教科が更新された際にor削除された際に、担当講師&座席表をクリア
            if (
              typeof lectureItem.subject !== 'string' &&
              typeof oldLectureItem.subject !== 'string' &&
              typeof lectureItem?.teacher_id !== 'string' &&
              typeof lectureItem?.default_seat !== 'string'
            ) {
              // ノートス小学生の場合
              if (!_.isEqual(lectureItem.subject, oldLectureItem.subject)) {
                const isClearFirst =
                  lectureItem.subject.first !== oldLectureItem.subject.first ||
                  !lectureItem.subject.first;
                const isClearLatter =
                  lectureItem.subject.latter !==
                    oldLectureItem.subject.latter ||
                  !lectureItem.subject.latter;

                lectureItem = {
                  ...lectureItem,
                  teacher_id: {
                    first: isClearFirst
                      ? ''
                      : lectureItem?.teacher_id?.first ?? '',
                    latter: isClearLatter
                      ? ''
                      : lectureItem?.teacher_id?.latter ?? '',
                  },
                  default_seat: {
                    first: isClearFirst
                      ? ''
                      : lectureItem?.default_seat?.first ?? '',
                    latter: isClearLatter
                      ? ''
                      : lectureItem?.default_seat?.latter ?? '',
                  },
                };
              }
            } else {
              // ノートス小学生以外
              const isClear =
                lectureItem.subject !== oldLectureItem.subject ||
                !lectureItem.subject;

              lectureItem = {
                ...lectureItem,
                teacher_id: isClear ? '' : lectureItem?.teacher_id ?? '',
                default_seat: isClear ? '' : lectureItem?.default_seat ?? '',
              };
            }
          }

          newStudentLecture[lectureDayKey][baseLectureKey] = lectureItem;
        });
      });
    }

    const result = await createStudentLectures(newStudentLecture);

    open(
      result.id ? 'success' : 'error',
      result.id
        ? '生徒情報の更新に成功しました'
        : '生徒情報の更新ができませんでした。もう一度お試しください。',
    );
    setCreating(false);

    return [result.id, newStudentLecture];
  };

  return { creating, create, close, state };
};

export default useCreateStudentLectures;
