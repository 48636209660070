import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { APP_TITLE, PAGE_TITLE } from '../../../data/app-basic';
import ForgetPasswordForm from '../../containers/molecules/form/ForgetPasswordForm';
import Logo from '../../../assets/images/logo_type.svg';
import './style.scss';

const ForgetPassword = () => {
  return (
    <div>
      <Helmet>
        <style type="text/css">{`
          html {
              background-color: #fafafa;
          }
        `}</style>
        <title>{PAGE_TITLE('パスワードを忘れた方へ')}</title>
      </Helmet>
      <div className="form-wrapper">
        <img src={Logo} alt={APP_TITLE} className="logo" />
        <ForgetPasswordForm />
        <p className="login-note">
          <Link to="/login">ログインページへ</Link>
        </p>
      </div>
    </div>
  );
};

export default ForgetPassword;
