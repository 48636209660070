import firestoreSimple from '../../lib/firebase-firestore';
import { ParentInfo } from '../models/parent-info';

export type Result = {
  id?: string;
};

const createParentInfo = async (parentInfo: ParentInfo): Promise<Result> => {
  const collection = firestoreSimple.collection<ParentInfo>({
    path: 'parent_info',
  });

  const docId = parentInfo.id
    ? await collection.set(parentInfo).catch((error) => {
        console.error(error);
      })
    : await collection.add(parentInfo).catch((error) => console.error(error));

  return { id: docId || undefined };
};

export default createParentInfo;
