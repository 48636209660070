import React, { FC, useContext } from 'react';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/all';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import {
  isScoreUp,
  isTotalScoreUp,
  MiddleSchoolExamList,
} from '../../../../../types/table/middle-school-exam-list';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import Table from '../Table';
import useReactTableFilter from '../../../../hooks/useReactTableFilter';
import DefaultColumnFilter from '../DefaultColumnFilter';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import './style.scss';
import columns from './columns';
import exportMiddleSchoolExamListCsv from '../../../../../helpers/exportMiddleSchoolExamListCsv';
import { FilterContext } from '../../../../context/FilterContext';

interface Props {
  data: MiddleSchoolExamList[];
  updateTableData: (
    rowIndex: number,
    columnId: string,
    score: number,
    id?: string,
  ) => void;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
  getLibraryCardIcon: (
    rowIndex: number,
    rowValues: MiddleSchoolExamList,
  ) => JSX.Element | null;
  toolbarSelectProps?: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
}

const MiddleSchoolExamListComponent: FC<Props> = ({
  data,
  updateTableData,
  snackbarState,
  snackbarClose,
  getLibraryCardIcon,
  toolbarSelectProps,
}) => {
  const history = useHistory();
  const filterTypes = useReactTableFilter();
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const { setFilterKey } = useContext(FilterContext);
  const pageName = 'middle-school-exam-list';

  useEffectOnce(() => {
    setFilterKey(pageName);
  });

  return (
    <div>
      <Table<MiddleSchoolExamList>
        classes={{
          root: clsx('table-container'),
          table: clsx(
            'table',
            'is-fullwidth',
            'is-striped',
            'is-hoverable',
            'list-table',
          ),
        }}
        tableOptions={{
          data,
          columns,
          filterTypes,
          defaultColumn,
          manualPagination: false,
          initialState: {
            pageSize: 50,
            sortBy: [{ id: 'enrolled_cram_school' }],
            hiddenColumns: [
              'id',
              'student_id',
              'examKey',
              'middleSchoolExam',
              'studentBasic',
            ],
          },
          updateTableData,
        }}
        theadThRender={(column) => {
          if (column.id === 'libraryCardPoint') {
            // フィルタのためにhiddenColumnsに指定していないだけなので内容は表示しない
            return <span key={column.id} />;
          }

          return (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              className={`column-exam-${column.id}`}
            >
              <span>{column.render('Header')}</span>
              {column.isSorted &&
                (column.isSortedDesc ? (
                  <AiFillCaretDown fontSize="small" />
                ) : (
                  <AiFillCaretUp fontSize="small" />
                ))}
            </th>
          );
        }}
        tbodyTdRender={(row, cell) => {
          switch (cell.column.id) {
            case 'icon': {
              return (
                <td
                  {...cell.getCellProps()}
                  className={clsx(`column-exam-${cell.column.id}`, {
                    'td-score-up-total': isTotalScoreUp(row.original),
                  })}
                >
                  {getLibraryCardIcon(row.index, row.original)}
                </td>
              );
            }
            case 'libraryCardPoint': {
              // フィルタのためにhiddenColumnsに指定していないだけなので内容は表示しない
              return <span key={cell.column.id} />;
            }
            default:
              return (
                <td
                  {...cell.getCellProps()}
                  className={clsx(
                    `column-exam-${cell.column.id}`,
                    {
                      'td-score-up-total': isTotalScoreUp(row.original),
                    },
                    {
                      'td-score-up-subject': isScoreUp(
                        row.original,
                        cell.column.id,
                      ),
                    },
                  )}
                >
                  {cell.render('Cell')}
                </td>
              );
          }
        }}
        onClick={(row) =>
          history.push({
            pathname: '/student-score/detail',
            state: { studentBasic: row.original.studentBasic },
          })
        }
        toolbarSelectProps={toolbarSelectProps}
        pageName={pageName}
        exportCsvHelper={exportMiddleSchoolExamListCsv}
      />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default MiddleSchoolExamListComponent;
