import React, { FC, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Filters } from 'react-table';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useSessionStorage } from 'react-use';
import LuxonUtils from '@date-io/luxon';

import { AppContext } from './presentation/context/AppContext';
import { FilterContext } from './presentation/context/FilterContext';
import { AdminUser, defaultAdminUser } from './domain/models/admin-user';
import PrivateRoute from './presentation/components/atoms/utility/PrivateRoute';
import PrivateNotViewerRoute from './presentation/components/atoms/utility/PrivateNotViewerRoute';
import Login from './presentation/view/Login';
import ForgetPassword from './presentation/view/ForgetPassword';
import Top from './presentation/view/Top';
import Parent from './presentation/view/Parent';
import ParentDetail from './presentation/view/Parent/Detail';
import Student from './presentation/view/Student';
import StudentDetail from './presentation/view/Student/Detail';
import Task from './presentation/view/Task';
import Score from './presentation/view/Score';
import ScoreDetail from './presentation/view/Score/Detail';
import ClassTransfer from './presentation/view/ClassTransfer';
import Chat from './presentation/view/Chat';
import Invoice from './presentation/view/Invoice';
import InvoiceDetail from './presentation/view/Invoice/Detail';
import Staff from './presentation/view/Staff';
import StaffDetail from './presentation/view/Staff/Detail';
import StaffCreate from './presentation/view/Staff/Create';
import PeriodicInfoDetail from './presentation/view/PeriodicInfo/Detail';
import TimetableDetail from './presentation/view/Timetable/Detail';
import SchoolCalendarDetail from './presentation/view/SchoolCalendar/Detail';
import WeekDefault from './presentation/view/WeekDefault';
import DailyReportDetail from './presentation/view/DailyReport/Detail';
import WorkShiftDetail from './presentation/view/WorkShift/Detail';
import Salary from './presentation/view/Salary';
import NotFound from './presentation/view/NotFound';

const App: FC = () => {
  const [adminUser, setAdminUser] = useSessionStorage<AdminUser>(
    'adminUser',
    defaultAdminUser,
  );
  const [filters, setFilters] = useState<Map<string, Filters<any>>>(new Map());
  const [filterKey, setFilterKey] = useState('');

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils} locale="ja">
      <AppContext.Provider value={{ adminUser, setAdminUser }}>
        <FilterContext.Provider
          value={{ filters, setFilters, filterKey, setFilterKey }}
        >
          <BrowserRouter>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/forget-password">
                <ForgetPassword />
              </Route>
              <PrivateRoute path="/" exact>
                <Top />
              </PrivateRoute>
              <PrivateRoute path="/parent" exact>
                <Parent />
              </PrivateRoute>
              <PrivateRoute path="/parent/detail" exact>
                <ParentDetail />
              </PrivateRoute>
              <PrivateRoute path="/student" exact>
                <Student />
              </PrivateRoute>
              <PrivateRoute path="/student/detail" exact>
                <StudentDetail />
              </PrivateRoute>
              <PrivateRoute path="/student-task" exact>
                <Task />
              </PrivateRoute>
              <PrivateRoute path="/student-score" exact>
                <Score />
              </PrivateRoute>
              <PrivateRoute path="/student-score/detail" exact>
                <ScoreDetail />
              </PrivateRoute>
              <PrivateRoute path="/class-transfer" exact>
                <ClassTransfer />
              </PrivateRoute>
              <PrivateRoute path="/chat" exact>
                <Chat />
              </PrivateRoute>
              <PrivateRoute path="/invoice" exact>
                <Invoice />
              </PrivateRoute>
              <PrivateRoute path="/invoice/detail" exact>
                <InvoiceDetail />
              </PrivateRoute>
              <PrivateRoute path="/staff" exact>
                <Staff />
              </PrivateRoute>
              <PrivateRoute path="/staff/detail" exact>
                <StaffDetail />
              </PrivateRoute>
              <PrivateNotViewerRoute path="/staff/create" exact>
                <StaffCreate />
              </PrivateNotViewerRoute>
              <PrivateNotViewerRoute path="/periodic-info/detail" exact>
                <PeriodicInfoDetail />
              </PrivateNotViewerRoute>
              <PrivateRoute path="/timetable/detail" exact>
                <TimetableDetail />
              </PrivateRoute>
              <PrivateNotViewerRoute path="/school-calendar/detail" exact>
                <SchoolCalendarDetail />
              </PrivateNotViewerRoute>
              <PrivateNotViewerRoute path="/week-default" exact>
                <WeekDefault />
              </PrivateNotViewerRoute>
              <PrivateNotViewerRoute path="/daily-report/detail" exact>
                <DailyReportDetail />
              </PrivateNotViewerRoute>
              <PrivateNotViewerRoute path="/work-shift/detail" exact>
                <WorkShiftDetail />
              </PrivateNotViewerRoute>
              <PrivateNotViewerRoute path="/salary" exact>
                <Salary />
              </PrivateNotViewerRoute>
              <PrivateRoute>
                <NotFound />
              </PrivateRoute>
            </Switch>
          </BrowserRouter>
        </FilterContext.Provider>
      </AppContext.Provider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
