import React, { FC, useContext } from 'react';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/all';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import { MiddleSchoolReportList } from '../../../../../types/table/middle-school-report-list';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import Table from '../Table';
import useReactTableFilter from '../../../../hooks/useReactTableFilter';
import DefaultColumnFilter from '../DefaultColumnFilter';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import './style.scss';
import columns from './columns';
import exportMiddleSchoolReportListCsv from '../../../../../helpers/exportMiddleSchoolReportListCsv';
import { FilterContext } from '../../../../context/FilterContext';

interface Props {
  data: MiddleSchoolReportList[];
  updateTableData: (
    rowIndex: number,
    columnId: string,
    score: number,
    id?: string,
  ) => void;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
  toolbarSelectProps?: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
}

const MiddleSchoolReportListComponent: FC<Props> = ({
  data,
  updateTableData,
  snackbarState,
  snackbarClose,
  toolbarSelectProps,
}) => {
  const history = useHistory();
  const filterTypes = useReactTableFilter();
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const { setFilterKey } = useContext(FilterContext);
  const pageName = 'middle-school-report-list';

  useEffectOnce(() => {
    setFilterKey(pageName);
  });

  return (
    <div>
      <Table<MiddleSchoolReportList>
        classes={{
          root: clsx('table-container'),
          table: clsx(
            'table',
            'is-fullwidth',
            'is-striped',
            'is-hoverable',
            'list-table',
          ),
        }}
        tableOptions={{
          data,
          columns,
          filterTypes,
          defaultColumn,
          manualPagination: false,
          initialState: {
            pageSize: 50,
            sortBy: [{ id: 'enrolled_cram_school' }],
            hiddenColumns: [
              'id',
              'student_id',
              'reportKey',
              'middleSchoolReport',
              'studentBasic',
            ],
          },
          updateTableData,
        }}
        theadThRender={(column) => {
          return (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              className={`column-report-${column.id}`}
            >
              <span>{column.render('Header')}</span>
              {column.isSorted &&
                (column.isSortedDesc ? (
                  <AiFillCaretDown fontSize="small" />
                ) : (
                  <AiFillCaretUp fontSize="small" />
                ))}
            </th>
          );
        }}
        tbodyTdRender={(row, cell) => {
          return (
            <td
              {...cell.getCellProps()}
              className={`column-report-${cell.column.id}`}
            >
              {cell.render('Cell')}
            </td>
          );
        }}
        onClick={(row) =>
          history.push({
            pathname: '/student-score/detail',
            state: { studentBasic: row.original.studentBasic },
          })
        }
        toolbarSelectProps={toolbarSelectProps}
        pageName={pageName}
        exportCsvHelper={exportMiddleSchoolReportListCsv}
      />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default MiddleSchoolReportListComponent;
