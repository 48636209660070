import { Row } from 'react-table';
import exportCsv from '../utils/array/exportCsv';
import arrayToCsv from '../utils/array/arrayToCsv';
import { TaskItem } from '../domain/models/task';

/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (rows: Row<any>[]) => {
  const rowArrays = rows.map((row) => {
    const rowValues = Object.entries(row.values).filter((v) => {
      return !['id', 'student_id', 'icon'].includes(v[0]);
    });

    return rowValues.map((rowValue): string => {
      if (typeof rowValue[1] === 'object' && 'done' in rowValue[1]) {
        const taskItem = rowValue[1] as TaskItem;

        return String(taskItem.done);
      }

      if (typeof rowValue[1] === 'number') {
        return String(rowValue[1]);
      }

      return typeof rowValue[1] === 'string' ? rowValue[1] : '';
    });
  });

  return exportCsv(arrayToCsv(rowArrays));
};
