import React, { useContext, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import TimetableDetailForm from '../../../../components/molecules/form/TimetableDetailForm';
import { EnrolledCramSchoolContext } from '../../../../context/EnrolledCramSchoolContext';
import useRole from '../../../../hooks/useRole';
import { AppContext } from '../../../../context/AppContext';
import {
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_SCHOOLS,
  NotesJapanSchool,
  NotesPraiseSchool,
  SELECT_STUDENT_STATUS_LABELS,
  SELECT_ALL_STATUS,
  SelectStudentStatus,
  SELECT_STUDENT_STATUS,
} from '../../../../../data/form-data';
import useFetchStudentBasics from '../../../../hooks/fetch/useFetchStudentBasics';
import { StudentBasic } from '../../../../../domain/models/student-basic';
import { Timetable } from '../../../../../domain/models/timetable';
import useFetchTimetables from '../../../../hooks/fetch/useFetchTimetables';
import useFetchPeriodicInfo from '../../../../hooks/fetch/useFetchPeriodicInfo';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const { isAdministrator, isHeadquarters } = useRole();
  const { adminUser } = useContext(AppContext);
  const selectEnrolledCramSchools =
    isAdministrator || isHeadquarters
      ? [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS]
      : [adminUser.enrolled_cram_school];
  const { enrolledCramSchool, setEnrolledCramSchool } = useContext(
    EnrolledCramSchoolContext,
  );
  const [selectedStatus, setSelectedStatus] = useState<SelectStudentStatus>(
    SELECT_ALL_STATUS,
  );
  const [filteredStudentBasics, setFilteredStudentBasics] = useState<
    StudentBasic[]
  >([]);
  const [studentBasic, setStudentBasic] = useState<StudentBasic | null>(null);
  const [timetable, setTimetable] = useState<Timetable | null>(null);

  // 次月を求める
  const today = new Date();
  const nextMonthNum = today.getMonth() + 2;
  let year;
  let nextMonth;
  if (nextMonthNum === 13) {
    // 現在12月の場合は来年の1月
    year = today.getFullYear() + 1;
    nextMonth = 1;
  } else {
    year = today.getFullYear();
    nextMonth = nextMonthNum;
  }
  const nextMonthStr =
    nextMonth <= 9 ? `0${String(nextMonth)}` : String(nextMonth);
  const yearMonth = `${year}/${nextMonthStr}`;
  const queryParams = [
    {
      fieldPath: 'year_month',
      opStr: '==',
      value: yearMonth,
    },
  ];
  const { loading: loadingTimetables, timetables } = useFetchTimetables({
    queryParams,
  });

  useEffectOnce(() => {
    setEnrolledCramSchool(
      isAdministrator || isHeadquarters
        ? [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS][0]
        : adminUser.enrolled_cram_school,
    );
  });

  const queryParamsStudentBasics = [
    {
      fieldPath: 'enrolled_cram_school',
      opStr: '==',
      value: enrolledCramSchool,
    },
  ];
  const {
    loading: loadingStudentBasics,
    studentBasics,
  } = useFetchStudentBasics({
    queryParams: queryParamsStudentBasics,
  });

  const queryParamsPeriodicInfos = [
    {
      fieldPath: 'school_name',
      opStr: '==',
      value: enrolledCramSchool,
    },
  ];
  const { loading: loadingPeriodicInfos, periodicInfos } = useFetchPeriodicInfo(
    {
      queryParams: queryParamsPeriodicInfos,
    },
  );

  const filterStudentBasicsByStatus = (status: SelectStudentStatus) => {
    if (status === SELECT_ALL_STATUS) {
      setFilteredStudentBasics(studentBasics);
    } else {
      const targetStudentBasics = studentBasics.filter(
        (sb) => sb.status === status,
      );
      setFilteredStudentBasics(targetStudentBasics);
    }
  };

  const onChangeEnrolledCramSchool: SelectInputProps['onChange'] = (event) => {
    setEnrolledCramSchool(
      event.target.value as NotesJapanSchool | NotesPraiseSchool,
    );
  };

  const onChangeStatus: SelectInputProps['onChange'] = (event) => {
    const status = event.target.value as SelectStudentStatus;
    setSelectedStatus(status);

    filterStudentBasicsByStatus(status);
  };

  const onChangeStudentBasic = (student_id: string) => {
    const targetStudentBasic = filteredStudentBasics.filter(
      (sb) => sb.id === student_id,
    )[0];
    setStudentBasic(targetStudentBasic);

    const targetTimetable = timetables.filter(
      (t) => t.student_id === student_id,
    )[0];
    setTimetable(targetTimetable);
  };

  useEffect(() => {
    setStudentBasic(null);

    filterStudentBasicsByStatus(selectedStatus);
    // eslint-disable-next-line
  }, [studentBasics, enrolledCramSchool, selectedStatus]);

  return (
    <TimetableDetailForm
      loading={
        loadingTimetables || loadingStudentBasics || loadingPeriodicInfos
      }
      studentBasic={studentBasic}
      timetable={timetable}
      periodicInfo={periodicInfos[0]}
      yearMonth={yearMonth}
      selectPropsEnrolledCramSchool={{
        label: '',
        value: enrolledCramSchool,
        options: selectEnrolledCramSchools.map((school) => ({
          label: school,
          value: school,
        })),
        onChange: onChangeEnrolledCramSchool,
      }}
      selectPropsStatus={{
        label: SELECT_STUDENT_STATUS_LABELS[selectedStatus],
        value: selectedStatus,
        options: SELECT_STUDENT_STATUS.map((status) => ({
          label: SELECT_STUDENT_STATUS_LABELS[status],
          value: status,
        })),
        onChange: onChangeStatus,
      }}
      selectPropsStudentBasic={{
        value: studentBasic
          ? {
              label: `${studentBasic.last_name} ${studentBasic.first_name} (${studentBasic.last_name_kana} ${studentBasic.first_name_kana})`,
              value: studentBasic.id,
            }
          : null,
        options: filteredStudentBasics.length
          ? filteredStudentBasics
              .sort((a, b) => {
                if (
                  `${a.last_name_kana} ${a.first_name_kana}` >
                  `${b.last_name_kana} ${b.first_name_kana}`
                ) {
                  return 1;
                }

                if (
                  `${b.last_name_kana} ${b.first_name_kana}` >
                  `${a.last_name_kana} ${a.first_name_kana}`
                ) {
                  return -1;
                }

                return 0;
              })
              .map((sb) => ({
                label: `[${sb.student_num}] ${sb.last_name} ${sb.first_name} (${sb.last_name_kana} ${sb.first_name_kana})`,
                value: sb.id,
              }))
          : [
              {
                label: '所属生徒はいません',
                value: '',
              },
            ],
        onChange: (value) => {
          if (value && 'value' in value) {
            onChangeStudentBasic(value.value);
          }
        },
        isDisabled: !filteredStudentBasics.length,
        placeholder: !filteredStudentBasics.length
          ? '所属生徒はいません'
          : '生徒を選択してください',
      }}
    />
  );
};
