import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import LoginForm from '../../containers/molecules/form/LoginForm';
import Logo from '../../../assets/images/logo_type.svg';
import './style.scss';
import { APP_TITLE, PAGE_TITLE } from '../../../data/app-basic';

export default () => {
  return (
    <div>
      <Helmet>
        <style type="text/css">{`
          html {
              background-color: #fafafa;
          }
        `}</style>
        <title>{PAGE_TITLE('ログイン')}</title>
      </Helmet>
      <div className="form-wrapper">
        <img src={Logo} alt={APP_TITLE} className="logo" />
        <LoginForm />
        <p className="login-note">
          <Link to="/forget-password">パスワードを忘れた方へ</Link>
        </p>
      </div>
    </div>
  );
};
