import { HasId } from '@firestore-simple/web/dist/types';
import { BiYen, IoMdCard } from 'react-icons/all';
import React from 'react';

export type MiddleSchoolExamScores = {
  english: number;
  math: number;
  japanese: number;
  science: number;
  society: number;
};

export type MiddleSchoolExam = HasId & {
  student_id: string;
  '1st_grader_exam_1': MiddleSchoolExamScores;
  '1st_grader_exam_2': MiddleSchoolExamScores;
  '1st_grader_exam_3': MiddleSchoolExamScores;
  '1st_grader_exam_4': MiddleSchoolExamScores;
  '2nd_grader_exam_1': MiddleSchoolExamScores;
  '2nd_grader_exam_2': MiddleSchoolExamScores;
  '2nd_grader_exam_3': MiddleSchoolExamScores;
  '2nd_grader_exam_4': MiddleSchoolExamScores;
  '3rd_grader_exam_1': MiddleSchoolExamScores;
  '3rd_grader_exam_2': MiddleSchoolExamScores;
  '3rd_grader_exam_3': MiddleSchoolExamScores;
  '3rd_grader_exam_4': MiddleSchoolExamScores;
};

export const getPrevKey = (
  object: { [key: string]: any },
  currentKey: string,
): string | undefined => {
  const objectKeys = Object.keys(object);
  const keyIndex = objectKeys.findIndex((value) => value === currentKey);
  const prevIndex = keyIndex - 1;

  return prevIndex >= 0 ? objectKeys[prevIndex] : undefined;
};

export const getSubjectScores = (
  subjectScores: MiddleSchoolExamScores,
): MiddleSchoolExamScores => {
  return {
    japanese: subjectScores.japanese || 0,
    math: subjectScores.math || 0,
    english: subjectScores.english || 0,
    society: subjectScores.society || 0,
    science: subjectScores.science || 0,
  };
};

export const getTotalScore = (
  subjectScores: MiddleSchoolExamScores,
): number => {
  return (
    subjectScores.japanese +
    subjectScores.math +
    subjectScores.english +
    subjectScores.society +
    subjectScores.science
  );
};

export const getLibraryCardPoint = (
  currentScores: MiddleSchoolExamScores,
  prevScores: MiddleSchoolExamScores | undefined,
): number => {
  const currentValues = Object.values(currentScores);
  const currentTotal = currentValues.reduce(
    (prev, current) => prev + current,
    0,
  );
  const prevValues = prevScores ? Object.values(prevScores) : undefined;
  const prevTotal = prevValues
    ? prevValues.reduce((prev, current) => prev + current, 0)
    : undefined;
  let point = 0;
  // 100点一枚につき500円
  point += currentValues.filter((value) => value === 100).length * 500;
  // 総合点数が420点以上で500円
  point += currentTotal >= 420 && currentTotal < 470 ? 500 : 0;
  // 総合点数が470点以上で1000円
  point += currentTotal >= 470 ? 1000 : 0;

  // 「1年生 定期テストⅠの場合」または「前回の点数で1教科でも0点がある場合」は、仕様上ここで加算はストップする（ほとんどの確率で50点・100点アップされちゃうので以降は加算しない方針）
  if (!prevValues || (prevValues && prevValues.some((value) => value <= 0)))
    return point;

  // 前回のテストから50点アップ：500円
  point +=
    typeof prevTotal === 'number' &&
    currentTotal - prevTotal >= 50 &&
    currentTotal - prevTotal < 100
      ? 500
      : 0;
  // 前回のテストから100点アップ：1000円
  point +=
    typeof prevTotal === 'number' && currentTotal - prevTotal >= 100 ? 1000 : 0;

  return point;
};

export const getIcon = (
  currentScores: MiddleSchoolExamScores,
  prevScores: MiddleSchoolExamScores | undefined,
) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IoMdCard style={{ marginRight: 8, fontSize: 32 }} />
      <BiYen />
      {getLibraryCardPoint(currentScores, prevScores)}
    </div>
  );
};
