import { useState } from 'react';

import useSnackbar, { SnackbarState } from '../useSnackbar';
import createStudentBasic from '../../../domain/services/create-student-basic';
import { StudentBasic } from '../../../domain/models/student-basic';

export type Result = {
  creating: boolean;
  create: (studentBasic: StudentBasic) => void;
  state: SnackbarState;
  close: () => void;
};

const useCreateStudentBasic = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (studentBasic: StudentBasic) => {
    setCreating(true);
    const result = await createStudentBasic(studentBasic);
    open(
      result.id ? 'success' : 'error',
      result.id
        ? '生徒情報の更新に成功しました'
        : '生徒情報の更新ができませんでした。もう一度お試しください。',
    );
    setCreating(false);
  };

  return { creating, create, close, state };
};

export default useCreateStudentBasic;
