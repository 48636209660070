import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { nanoid } from 'nanoid';
import { extension } from 'mime-types';
import firebase from 'firebase';

import { ChatContext } from '../../../../context/ChatContext';
import { AppContext } from '../../../../context/AppContext';
import { defaultRoom, Room } from '../../../../../domain/models/rooms';
import { Talk } from '../../../../../domain/models/talk';
import useCreateTalk from '../../../../hooks/create/useCreateTalk';
import useCreateRoom from '../../../../hooks/create/useCreateRoom';
import TextAreaComponent from '../../../../components/pages/chat/TextArea';
import { storage } from '../../../../../lib/firebase';

const TextArea: FC = () => {
  const { adminUser } = useContext(AppContext);
  const { selectedRoom, filteredRooms } = useContext(ChatContext);
  const [message, setMessage] = useState('');
  const talkInstance = useCreateTalk();
  const roomInstance = useCreateRoom();

  const onClick = () => {
    const now = firebase.firestore.Timestamp.now();
    const talk: Talk = {
      id: '',
      user_id: adminUser.id,
      message,
      date: now,
      storage_path: '',
      room_id: selectedRoom || '',
    };
    const room: Room = {
      ...(filteredRooms.find((r) => r.id === selectedRoom) || defaultRoom),
      update_date: now,
    };

    talkInstance.create(talk);
    roomInstance.create(room);
    setMessage('');
  };

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    const reader = new FileReader();
    if (!file) return;

    reader.readAsDataURL(file);
    reader.onload = async () => {
      const ext = extension(file.type) || '';
      if (!ext) return;

      const now = firebase.firestore.Timestamp.now();
      const talk: Talk = {
        id: '',
        user_id: adminUser.id,
        message: '',
        date: now,
        storage_path: '',
        room_id: selectedRoom || '',
      };
      const room: Room = {
        ...(filteredRooms.find((r) => r.id === selectedRoom) || defaultRoom),
        update_date: now,
      };
      const roomPath = `/chat/${room.id}`;
      const fileName = `${nanoid(12)}.${ext}`;
      talk.storage_path = `${roomPath}/${fileName}`;
      const storageRef = storage.ref(roomPath);
      const fileRef = storageRef.child(fileName);
      await fileRef.put(file);

      talkInstance.create(talk);
      roomInstance.create(room);
    };
  };

  return (
    <TextAreaComponent
      message={message}
      setMessage={setMessage}
      onClick={onClick}
      onChange={onChangeFile}
    />
  );
};

export default TextArea;
