import React, { FC, useContext } from 'react';
import { useDebounce } from 'react-use';
import { FilterProps } from 'react-table';
import { Slider } from '@material-ui/core';

import './style.scss';
import { FilterContext } from '../../../../context/FilterContext';

const BetweenSliderColumnFilter: FC<{
  filterProps: FilterProps<any>;
  max?: number;
  min?: number;
}> = ({ filterProps: { column }, max = 100, min = 0 }) => {
  const { filters, setFilters, filterKey } = useContext(FilterContext);
  const { render, setFilter } = column;
  const defaultValue: number[] = [min, max];
  const filterValue: any[] =
    column.filterValue instanceof Array ? column.filterValue : defaultValue;
  useDebounce(
    () => {
      setFilter(filterValue);
      if (filterValue !== undefined) {
        setFilters((prevState) => {
          if (prevState && prevState.has(filterKey)) {
            filters.set(filterKey, [
              ...prevState.get(filterKey)!.filter((v) => v.id !== column.id),
              { id: column.id, value: filterValue },
            ]);

            return filters;
          }
          filters.set(filterKey, [{ id: column.id, value: filterValue }]);

          return filters;
        });
      } else {
        setFilters((prevState) => {
          if (prevState && prevState.has(filterKey)) {
            filters.set(filterKey, [
              ...prevState.get(filterKey)!.filter((v) => v.id !== column.id),
            ]);

            return filters;
          }

          return filters;
        });
      }
    },
    300,
    [filterValue],
  );

  return (
    <div>
      <div className="label">{render('Header')}</div>
      <div className="control">
        <Slider
          value={filterValue}
          onChange={(event, newValue) => {
            setFilter(newValue instanceof Array ? newValue : [min, max]);
          }}
          valueLabelDisplay="auto"
          max={max}
          min={min}
          classes={{
            root: column.id,
            thumb: 'thumb',
          }}
        />
      </div>
    </div>
  );
};

export default BetweenSliderColumnFilter;
