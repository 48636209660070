import { createContext, Dispatch, SetStateAction } from 'react';
import { ButtonProps } from '@material-ui/core';
import { SnackbarState } from '../hooks/useSnackbar';
import { InvoiceList } from '../../types/table/invoice-list';
import { StudentInvoice } from '../../domain/models/student-invoice';

export type InvoiceContextType = {
  data: InvoiceList[];
  invoiceMonth: string;
  onClickPreviousMonth: ButtonProps['onClick'];
  onClickNextMonth: ButtonProps['onClick'];
  updateTableData: (rowIndex: number, value: InvoiceList) => void;
  creating: boolean;
  create?: (studentInvoice: StudentInvoice) => Promise<string | undefined>;
  snackbarState?: SnackbarState;
  closeSnackbar?: () => void;
  setBulkChanged?: Dispatch<SetStateAction<boolean>>;
};

export const InvoiceContext = createContext<InvoiceContextType>({
  data: [],
  invoiceMonth: '',
  onClickPreviousMonth: () => null,
  onClickNextMonth: () => null,
  updateTableData: () => null,
  creating: false,
  setBulkChanged: () => null,
});
