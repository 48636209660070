import React, { useContext } from 'react';
import {
  Cell,
  ColumnInstance,
  Row,
  TableOptions,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { ButtonProps } from '@material-ui/core';

import Pagination from './Pagination';
import Toolbar from './Toolbar';
import './style.scss';
import { FilterContext } from '../../../../context/FilterContext';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface Props<T extends {}> {
  tableOptions: TableOptions<T> & { [key: string]: any };
  theadThRender: (column: ColumnInstance<T>) => React.ReactNode;
  tbodyTdRender: (row: Row<T>, cell: Cell<T>) => React.ReactNode;
  onClick?: (row: Row<T>) => void;
  classes: {
    root: string;
    table: string;
  };
  toolbarSelectProps?: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
  toolbarSelectProps2?: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
  toolbarYearMonthOnClicks?: {
    onClickPreviousMonth: ButtonProps['onClick'];
    onClickNextMonth: ButtonProps['onClick'];
  };
  pageName: string;
  exportCsvHelper?: (rows: Row<T>[], fileName?: string) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Table = <T extends {}>({
  tableOptions,
  theadThRender,
  tbodyTdRender,
  onClick,
  classes,
  toolbarSelectProps,
  toolbarSelectProps2,
  toolbarYearMonthOnClicks,
  pageName,
  exportCsvHelper,
}: Props<T>) => {
  const { filters } = useContext(FilterContext);
  const savedPageSize = localStorage.getItem(`${pageName}-rows-per-page`);
  const pageSize = savedPageSize
    ? Number(savedPageSize)
    : tableOptions.initialState?.pageSize;
  const instance = useTable(
    {
      ...tableOptions,
      initialState:
        filters && filters.has(pageName)
          ? {
              ...tableOptions?.initialState,
              filters: filters.get(pageName),
              pageSize,
            }
          : { ...tableOptions?.initialState, pageSize },
    },
    useFilters,
    useSortBy,
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = instance;

  return (
    <div>
      <Toolbar
        instance={instance}
        selectProps={toolbarSelectProps}
        selectProps2={toolbarSelectProps2}
        yearMonthOnClicks={toolbarYearMonthOnClicks}
        exportCsvHelper={exportCsvHelper}
        pageName={pageName}
      />
      <div className={classes.root}>
        <table {...getTableProps()} className={classes.table}>
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => theadThRender(column))}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);

              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => onClick && onClick(row)}
                >
                  {row.cells.map((cell) => tbodyTdRender(row, cell))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="table-pagination">
        <Pagination instance={instance} pageName={pageName} />
      </div>
    </div>
  );
};

export default Table;
