import { Collection } from '@firestore-simple/web/dist/collection';
import { OmitId } from '@firestore-simple/web/dist/types';
import { Query } from '@firestore-simple/web';
import firestoreSimple from '../../lib/firebase-firestore';
import { QueryParams } from '../../types/firestore-simple';
import { AdminUser } from '../models/admin-user';

export type CollectionOrQuery =
  | Collection<AdminUser, OmitId<AdminUser>>
  | Query<AdminUser, OmitId<AdminUser>>;

const getAdminUsers = async ({
  id,
  queryParams,
}: {
  id?: string;
  queryParams?: QueryParams<AdminUser>;
}): Promise<{ adminUsers: AdminUser[] }> => {
  const collection = firestoreSimple.collection<AdminUser>({
    path: 'admin_user',
  });
  const query =
    queryParams && queryParams.length
      ? queryParams.reduce((prev: CollectionOrQuery, current) => {
          return prev.where(current.fieldPath, current.opStr, current.value);
        }, collection)
      : collection;
  let docs: AdminUser[] | undefined;
  if ('query' in query && 'startAt' in query) {
    docs =
      (await query.fetch().catch((error) => console.error(error))) || undefined;
  } else if (typeof id === 'string') {
    const result = await query.fetch(id).catch((error) => console.error(error));
    docs = result ? [result] : undefined;
  } else {
    docs =
      (await query.fetchAll().catch((error) => console.error(error))) ||
      undefined;
  }

  const adminUsers = docs instanceof Array ? docs : [];

  return { adminUsers };
};

export default getAdminUsers;
