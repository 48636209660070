import React from 'react';
import { Helmet } from 'react-helmet';

import AdminWrapper from '../../components/layouts/AdminWrapper';
import Container from '../../components/layouts/Container';
import ParentListContainer from '../../containers/molecules/table/ParentList';
import { PAGE_TITLE } from '../../../data/app-basic';

export default () => {
  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('保護者情報一覧')}</title>
      </Helmet>
      <Container>
        <ParentListContainer />
      </Container>
    </AdminWrapper>
  );
};
