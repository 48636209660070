const isProduction = process.env.REACT_APP_ENV === 'release';

export default isProduction
  ? {
      apiKey: process.env.REACT_APP_RELEASE_API_KEY,
      authDomain: process.env.REACT_APP_RELEASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_RELEASE_DATABASE_URL,
      projectId: process.env.REACT_APP_RELEASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_RELEASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_RELEASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_RELEASE_MESSAGING_PUB_KEY,
    }
  : {
      apiKey: process.env.REACT_APP_DEVELOP_API_KEY,
      authDomain: process.env.REACT_APP_DEVELOP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DEVELOP_DATABASE_URL,
      projectId: process.env.REACT_APP_DEVELOP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_DEVELOP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_DEVELOP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_DEVELOP_MESSAGING_PUB_KEY,
    };
