import { Column } from 'react-table';
import React from 'react';

import BetweenSliderColumnFilter from '../BetweenSliderColumnFilter';
import { MiddleSchoolReportsList } from '../../../../../types/table/middle-school-reports-list';
import EditableCell from './EditableCell';
import TotalCell from './TotalCell';

const columns: Column<MiddleSchoolReportsList>[] = [
  {
    Header: '',
    accessor: 'id',
  },
  {
    Header: '学年・学期',
    accessor: 'reportKey',
  },
  {
    Header: '国語',
    accessor: 'japanese',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '社会',
    accessor: 'society',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '数学',
    accessor: 'math',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '理科',
    accessor: 'science',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '音楽',
    accessor: 'music',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '美術',
    accessor: 'art',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '保体',
    accessor: 'health_physical',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '技家',
    accessor: 'tech_home',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '英語',
    accessor: 'english',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={5} />
    ),
    Cell: EditableCell,
  },
  {
    Header: '合計',
    accessor: 'total',
    filter: 'between',
    Filter: (filterProps) => (
      <BetweenSliderColumnFilter filterProps={filterProps} max={45} />
    ),
    Cell: TotalCell,
  },
];

export default columns;
