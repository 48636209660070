import { Row } from 'react-table';
import exportCsv from '../utils/array/exportCsv';
import arrayToCsv from '../utils/array/arrayToCsv';
import { SalaryList } from '../types/table/salary-list';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (rows: Row<SalaryList>[], fileName?: string) => {
  const rowArrays: string[][] = [];

  // ヘッダー作成
  rowArrays.splice(0, 0, [
    '社員番号',
    '社労夢CD',
    '社員名',
    '出勤日数',
    '休出日数',
    '法休日数',
    '有休日数',
    '特休日数',
    '欠勤日数',
    '授業時間',
    '事研時間',
    '普通残業',
    '休出時間',
    '深夜時間',
    '遅早回数',
    '遅早時間',
  ]);

  rows.forEach((row) => {
    const {
      staff_num,
      name,
      worked_days,
      lecture_time,
      office_training_time,
      behind_num,
      behind_time,
      early_leave_num,
      early_leave_time,
    } = row.values;

    rowArrays.push([
      staff_num ?? '',
      '',
      name,
      worked_days,
      '',
      '',
      '',
      '',
      '',
      lecture_time,
      office_training_time,
      '',
      '',
      '',
      Number(behind_num) + Number(early_leave_num),
      Number(behind_time) + Number(early_leave_time),
    ]);
  });

  const formattedFileName = fileName?.replace('/', '');

  return exportCsv(arrayToCsv(rowArrays), formattedFileName);
};
