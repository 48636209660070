import firestoreSimple from '../../lib/firebase-firestore';
import { SchoolCalendar } from '../models/school-calendar';

export type Result = {
  id?: string;
};

const createSchoolCalendar = async (
  schoolCalendar: SchoolCalendar,
): Promise<Result> => {
  const collection = firestoreSimple.collection<SchoolCalendar>({
    path: 'school_calendar',
  });

  const docId = schoolCalendar.id
    ? await collection.set(schoolCalendar).catch((error) => {
        console.error(error);
      })
    : await collection
        .add(schoolCalendar)
        .catch((error) => console.error(error));

  return { id: docId || undefined };
};

export default createSchoolCalendar;
