import React, { FC } from 'react';

import DataCounter from '../../../../components/atoms/DataCounter';
import useFetchWaitingTrialStudents from '../../../../hooks/fetch/useFetchWaitingTrialStudents';

const TrialDataCounter: FC = () => {
  const { loading, num } = useFetchWaitingTrialStudents();

  return (
    <DataCounter
      title={
        <span>
          体験入塾待ち
          <br />
          資料請求件数
        </span>
      }
      href="/student-task"
      number={num}
      loading={loading}
    />
  );
};

export default TrialDataCounter;
