import firestoreSimple from '../../lib/firebase-firestore';
import { TaskDocumentRequest } from '../models/task';

export type Result = {
  id?: string;
};

const createTaskDocumentRequest = async (
  taskDocumentRequest: TaskDocumentRequest,
): Promise<Result> => {
  const collection = firestoreSimple.collection<TaskDocumentRequest>({
    path: 'task_document_request',
  });

  const docId = taskDocumentRequest.id
    ? await collection.set(taskDocumentRequest).catch((error) => {
        console.error(error);
      })
    : await collection
        .add(taskDocumentRequest)
        .catch((error) => console.error(error));

  return { id: docId || undefined };
};

export default createTaskDocumentRequest;
