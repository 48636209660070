import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

export type Props = {
  subject: string;
};

export const SubjectLabel = ({ subject }: Props) => {
  const [className, setClassName] = useState('');

  useEffect(() => {
    // TODO 色指定のリストをもらい次第class名やstyleを修正する
    switch (subject) {
      case '':
      case undefined: {
        setClassName('subject-label-off');
        break;
      }
      default: {
        break;
      }
    }
  }, [subject]);

  return (
    <div className={clsx('subject-label', className)}>
      {subject ? <>{subject}</> : <>-</>}
    </div>
  );
};
