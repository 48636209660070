import { UseFormMethods } from 'react-hook-form/dist/types';
import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { DateTime } from 'luxon';
import { TextField } from '@material-ui/core';
import Select from 'react-select';
import clsx from 'clsx';
import { FaSave } from 'react-icons/all';
import useRole from '../../../../hooks/useRole';
import { BulkChangeWorkShift } from '../../../../../types/form/bulk-change-work-shift';
import Button from '../../../atoms/button/Button';
import DateField from '../../../atoms/form/DatePicker';
import ErrorMessage from '../../../atoms/form/ErrorMessage';
import Backdrop from '../../../atoms/feedback/Backdrop';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import {
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_SCHOOLS,
} from '../../../../../data/form-data';

export type Props = {
  formMethods: UseFormMethods<BulkChangeWorkShift>;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  creating: boolean;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
};

const WorkShiftBulkChangeForm: FC<Props> = ({
  formMethods,
  onSubmit,
  creating,
  snackbarState,
  snackbarClose,
}) => {
  const { isEditor, isViewer, enrolledCramSchool } = useRole();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { errors, control, watch, setValue, trigger } = formMethods;
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (
      DateTime.fromJSDate(new Date(watch('fromDate'))) >
      DateTime.fromJSDate(new Date(watch('toDate')))
    ) {
      setErrorMessage('終了日付は開始日付より未来の日付にしてください');

      return;
    }

    if (
      !watch('enrolledCramSchool') ||
      !watch('fromDate') ||
      !watch('toDate')
    ) {
      setErrorMessage('校舎・日付を選択してください');

      return;
    }

    setErrorMessage('');
  }, [watch]);

  return (
    <div className="modal-box">
      <div className="modal-inner">
        <form onSubmit={onSubmit}>
          <div>
            <p className="label">所属校舎</p>
            <Controller
              name="enrolledCramSchool"
              control={control}
              defaultValue={null}
              rules={{ required: '所属校舎を選択してください' }}
              as={
                <div>
                  <Select
                    name="enrolledCramSchool"
                    options={
                      isEditor || isViewer
                        ? [enrolledCramSchool].map((school) => ({
                            label: school,
                            value: school,
                          }))
                        : [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS].map(
                            (school) => ({
                              label: school,
                              value: school,
                            }),
                          )
                    }
                    value={
                      watch('enrolledCramSchool')
                        ? {
                            label: watch('enrolledCramSchool'),
                            value: watch('enrolledCramSchool'),
                          }
                        : null
                    }
                    onChange={async (value) => {
                      setValue(
                        'enrolledCramSchool',
                        value && 'value' in value ? value.value : '',
                      );
                      await trigger('enrolledCramSchool');
                    }}
                    error={errors?.enrolledCramSchool}
                    placeholder="所属校舎を選択してください"
                  />
                  <ErrorMessage
                    message={errors?.enrolledCramSchool?.message}
                    className="my-2"
                  />
                </div>
              }
            />
            <div className="field">
              <div className="field">
                <p className="label">開始日付</p>
                <Controller
                  name="fromDate"
                  control={control}
                  defaultValue={null}
                  rules={{ required: '開始日付を選択してください' }}
                  as={
                    <DateField
                      DatePickerProps={{
                        label: '',
                        name: 'fromDate',
                        value: watch('fromDate')
                          ? DateTime.fromFormat(
                              watch('fromDate') ?? '',
                              'yyyy/MM/dd',
                            )
                          : null,
                        format: 'yyyy/MM/dd',
                        TextFieldComponent: (props) => (
                          <TextField {...props} margin="none" />
                        ),
                        onChange: (v) => {
                          const date = v ? v.toFormat('yyyy/MM/dd') : null;
                          setValue('fromDate', date ?? '');
                        },
                        error: errors?.fromDate && true,
                        inputVariant: 'outlined',
                        className: 'text-field-outlined',
                      }}
                    />
                  }
                />
                <ErrorMessage
                  message={errors?.fromDate?.message}
                  className="my-2"
                />
              </div>
              <div className="field">
                <p className="label">終了日付</p>
                <Controller
                  name="toDate"
                  control={control}
                  defaultValue={null}
                  rules={{ required: '終了日付を選択してください' }}
                  as={
                    <DateField
                      DatePickerProps={{
                        label: '',
                        name: 'toDate',
                        value: watch('toDate')
                          ? DateTime.fromFormat(
                              watch('toDate') ?? '',
                              'yyyy/MM/dd',
                            )
                          : null,
                        format: 'yyyy/MM/dd',
                        TextFieldComponent: (props) => (
                          <TextField {...props} margin="none" />
                        ),
                        onChange: (v) => {
                          const date = v ? v.toFormat('yyyy/MM/dd') : null;
                          setValue('toDate', date ?? '');
                        },
                        error: errors?.toDate && true,
                        inputVariant: 'outlined',
                        className: 'text-field-outlined',
                      }}
                    />
                  }
                />
                <ErrorMessage
                  message={errors?.toDate?.message}
                  className="my-2"
                />
              </div>
            </div>
            <div className="btn-area">
              {errorMessage && (
                <ErrorMessage message={errorMessage} className="mt-5" />
              )}
              <Button
                buttonProps={{ type: 'submit', disabled: !!errorMessage }}
                color="primary"
                className={clsx('with-icon', 'btn-primary')}
              >
                <FaSave />
                実行
              </Button>
            </div>
          </div>
        </form>
      </div>
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default WorkShiftBulkChangeForm;
