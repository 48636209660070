import clsx from 'clsx';
import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';

import ErrorMessage from '../ErrorMessage';

export type Props = {
  label?: string;
  textareaProps: React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >;
  error?: FieldError;
  className?: string;
};

const Textarea: FC<Props> = ({ label, textareaProps, error, className }) => {
  const { name } = textareaProps;

  return (
    <div className={clsx('field', className)}>
      {label && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="control">
        <textarea
          className={clsx('textarea', { 'is-danger': error })}
          id={name}
          {...textareaProps}
        />
      </div>
      <ErrorMessage message={error?.message} className="mt-2" />
    </div>
  );
};

export default Textarea;
