import React from 'react';
import { Helmet } from 'react-helmet';

import { PAGE_TITLE } from '../../../data/app-basic';
import Container from '../../components/layouts/Container';
import AdminWrapper from '../../components/layouts/AdminWrapper';

export default () => (
  <div>
    <Helmet>
      <title>{PAGE_TITLE('404')}</title>
    </Helmet>
    <AdminWrapper>
      <Container>ページが見つかりません</Container>
    </AdminWrapper>
  </div>
);
