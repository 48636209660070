import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { PAGE_TITLE } from '../../../data/app-basic';
import AdminWrapper from '../../components/layouts/AdminWrapper';
import Container from '../../components/layouts/Container';
import useLinkItems from '../../hooks/useLinkItems';
import SystemInformation from '../../containers/pages/top/SystemInformation';
import TrialDataCounter from '../../containers/pages/top/TrialDataCounter';
import ClassTransferDataCounter from '../../containers/pages/top/ClassTransferDataCounter';
import './style.scss';

export default () => {
  const { linkItems } = useLinkItems();

  return (
    <div>
      <Helmet>
        <title>{PAGE_TITLE('TOP')}</title>
      </Helmet>
      <AdminWrapper>
        <Container>
          <div className="dashboard">
            <div className="data-counters">
              <TrialDataCounter />
              <ClassTransferDataCounter />
            </div>
            <SystemInformation />
          </div>
          <div className="columns is-multiline">
            {linkItems.map(({ id, icon, title, path }) => {
              if (id === 'top') {
                return null;
              }

              const Icon = icon;

              return (
                <div key={id} className="column is-half-desktop is-half-tablet">
                  <Link to={path} className="card-link">
                    <Icon className={id} />
                    <span className="card-title">{title}</span>
                  </Link>
                </div>
              );
            })}
          </div>
        </Container>
      </AdminWrapper>
    </div>
  );
};
