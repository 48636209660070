import React, { FC, PropsWithChildren, useContext } from 'react';
import {
  Cell,
  CellValue,
  ColumnInstance,
  Row,
  TableInstance,
} from 'react-table';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { MiddleSchoolExamsList } from '../../../../../types/table/middle-school-exams-list';
import { MiddleSchoolExam } from '../../../../../domain/models/middle-school-exam';
import { StudentBasic } from '../../../../../domain/models/student-basic';
import { defaultMiddleSchoolExamValues } from '../../../../../data/form-data';
import { ScoreExamContext } from '../../../../context/ScoreExamContext';
import useRole from '../../../../hooks/useRole';

const getNewValue = (value: string | number) => {
  let newValue = Number(value);
  if (Number.isNaN(newValue)) {
    return 0;
  }

  if (newValue > 100) {
    newValue = 100;
  } else if (newValue < 0) {
    newValue = 0;
  }

  return newValue;
};

const getNewTableData = (
  cell: Cell<MiddleSchoolExamsList>,
  value: number,
): MiddleSchoolExamsList => {
  let newTable: MiddleSchoolExamsList = cell.row
    .values as MiddleSchoolExamsList;
  switch (cell.column.id) {
    case 'japanese': {
      newTable = { ...newTable, japanese: value };
      break;
    }
    case 'math': {
      newTable = { ...newTable, math: value };
      break;
    }
    case 'english': {
      newTable = { ...newTable, english: value };
      break;
    }
    case 'science': {
      newTable = { ...newTable, science: value };
      break;
    }
    case 'society': {
      newTable = { ...newTable, society: value };
      break;
    }
    default: {
      break;
    }
  }

  return newTable;
};

const getNewExamData = (
  studentId: string,
  currentData: MiddleSchoolExam,
  newTableData: MiddleSchoolExamsList,
) => {
  const newExamData: MiddleSchoolExam = {
    ...defaultMiddleSchoolExamValues,
    ...currentData,
    id: newTableData.id,
    student_id: studentId,
    [newTableData.examKey]: {
      japanese: newTableData.japanese,
      math: newTableData.math,
      english: newTableData.english,
      science: newTableData.science,
      society: newTableData.society,
    },
  };

  return newExamData;
};

const getExamData = (data: MiddleSchoolExamsList[]): MiddleSchoolExam => {
  return {
    id: '',
    student_id: '',
    '1st_grader_exam_1': {
      japanese: data[0].japanese,
      math: data[0].math,
      english: data[0].english,
      society: data[0].society,
      science: data[0].science,
    },
    '1st_grader_exam_2': {
      japanese: data[1].japanese,
      math: data[1].math,
      english: data[1].english,
      society: data[1].society,
      science: data[1].science,
    },
    '1st_grader_exam_3': {
      japanese: data[2].japanese,
      math: data[2].math,
      english: data[2].english,
      society: data[2].society,
      science: data[2].science,
    },
    '1st_grader_exam_4': {
      japanese: data[3].japanese,
      math: data[3].math,
      english: data[3].english,
      society: data[3].society,
      science: data[3].science,
    },
    '2nd_grader_exam_1': {
      japanese: data[4].japanese,
      math: data[4].math,
      english: data[4].english,
      society: data[4].society,
      science: data[4].science,
    },
    '2nd_grader_exam_2': {
      japanese: data[5].japanese,
      math: data[5].math,
      english: data[5].english,
      society: data[5].society,
      science: data[5].science,
    },
    '2nd_grader_exam_3': {
      japanese: data[6].japanese,
      math: data[6].math,
      english: data[6].english,
      society: data[6].society,
      science: data[6].science,
    },
    '2nd_grader_exam_4': {
      japanese: data[7].japanese,
      math: data[7].math,
      english: data[7].english,
      society: data[7].society,
      science: data[7].science,
    },
    '3rd_grader_exam_1': {
      japanese: data[8].japanese,
      math: data[8].math,
      english: data[8].english,
      society: data[8].society,
      science: data[8].science,
    },
    '3rd_grader_exam_2': {
      japanese: data[9].japanese,
      math: data[9].math,
      english: data[9].english,
      society: data[9].society,
      science: data[9].science,
    },
    '3rd_grader_exam_3': {
      japanese: data[10].japanese,
      math: data[10].math,
      english: data[10].english,
      society: data[10].society,
      science: data[10].science,
    },
    '3rd_grader_exam_4': {
      japanese: data[11].japanese,
      math: data[11].math,
      english: data[11].english,
      society: data[11].society,
      science: data[11].science,
    },
  };
};

const EditableCell: FC<PropsWithChildren<
  TableInstance<MiddleSchoolExamsList> & {
    cell: Cell<MiddleSchoolExamsList, number>;
    column: ColumnInstance<MiddleSchoolExamsList>;
    row: Row<MiddleSchoolExamsList>;
    value: CellValue<number>;
    updateTableData: (
      rowIndex: number,
      columnId: string,
      score: number,
      id?: string,
    ) => void;
  }
>> = ({
  data,
  cell,
  value: initialValue,
  row: { index },
  column: { id },
  updateTableData,
}) => {
  const {
    state: { studentBasic },
  } = useLocation<{ studentBasic: StudentBasic }>();
  const { create } = useContext(ScoreExamContext);
  const [value, setValue] = React.useState(initialValue);
  const { isViewer } = useRole();

  useDebounce(
    async () => {
      if (initialValue === value) return;

      const newTableData = getNewTableData(cell, value);
      const newExamData = getNewExamData(
        studentBasic.id,
        getExamData(data),
        newTableData,
      );
      const examId = create ? await create(newExamData) : '';
      updateTableData(index, id, value, examId);
    },
    500,
    [value],
  );

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      type="number"
      className="input"
      onChange={(event) => {
        const newValue = getNewValue(event.target.value);
        setValue(newValue);
      }}
      value={value}
      max={100}
      min={0}
      disabled={isViewer}
    />
  );
};

export default EditableCell;
