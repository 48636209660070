import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { ButtonProps } from '@material-ui/core';
import _ from 'lodash';
import useFetchStudentInvoices from '../../../../hooks/fetch/useFetchStudentInvoices';
import {
  defaultStudentInvoice,
  StudentInvoice,
} from '../../../../../domain/models/student-invoice';
import StudentInvoiceForm from '../../../../components/molecules/form/StudentInvoiceForm';
import useFetchStudentBasic from '../../../../hooks/fetch/useFetchStudentBasic';
import useCreateStudentInvoice from '../../../../hooks/create/useCreateStudentInvoice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const location = useLocation<{
    studentId: string | undefined;
    invoiceMonth: string | undefined;
    orderedStudentIds: string[];
  }>();
  if (!location.state) return null;

  const studentId =
    'studentId' in location.state ? location.state.studentId : undefined;

  const invoiceMonth =
    'invoiceMonth' in location.state ? location.state.invoiceMonth : undefined;

  const orderedStudentIds =
    'orderedStudentIds' in location.state
      ? location.state.orderedStudentIds
      : [];

  if (studentId === undefined || invoiceMonth === undefined) return null;

  const {
    loading,
    studentInvoices,
    setStudentId,
    setInvoiceMonth,
  } = useFetchStudentInvoices({
    currentStudentId: studentId,
    currentInvoiceMonth: invoiceMonth,
  });

  const [studentInvoice, setStudentInvoice] = useState<StudentInvoice>({...defaultStudentInvoice, invoice_month: invoiceMonth});

  useEffect(() => {
    if(studentInvoices.length > 0) {
      setStudentInvoice({...studentInvoices[0]});
    } else {
      setStudentInvoice({...defaultStudentInvoice, invoice_month: invoiceMonth} as StudentInvoice)
    }
  }, [studentInvoices])

  const {
    loading: loading2,
    studentBasic,
    parentInfo,
    setId,
  } = useFetchStudentBasic({
    id: studentId,
  });

  const { creating, create, close, state } = useCreateStudentInvoice();

  const formMethods = useForm<StudentInvoice>({
    defaultValues: studentInvoice,
    shouldUnregister: false,
  });

  const currentIndex = orderedStudentIds.findIndex((v) => v === studentId);
  const isPreviousExists = currentIndex > 0;
  const isNextExists =
    currentIndex < orderedStudentIds.length - 1 && currentIndex >= 0;
  const previousStudentId = isPreviousExists
    ? orderedStudentIds[currentIndex - 1]
    : undefined;
  const nextStudentId = isNextExists
    ? orderedStudentIds[currentIndex + 1]
    : undefined;

  const onClickPreviousStudent: ButtonProps['onClick'] = () => {
    if (!previousStudentId) return;
    location.state.studentId = previousStudentId;
    setId(previousStudentId);
    setStudentId(previousStudentId);
    state.isOpening = false;
  };

  const onClickNextStudent: ButtonProps['onClick'] = () => {
    if (!nextStudentId) return;
    location.state.studentId = nextStudentId;
    setId(nextStudentId);
    setStudentId(nextStudentId);
    state.isOpening = false;
  };

  const onClickPreviousMonth: ButtonProps['onClick'] = () => {
    const previousMonth = DateTime.fromFormat(
      studentInvoice.invoice_month,
      'yyyy/MM',
    )
      .minus({ months: 1 })
      .toFormat('yyyy/MM');
    location.state.invoiceMonth = previousMonth;
    setInvoiceMonth(previousMonth);
    state.isOpening = false;
  };

  const onClickNextMonth: ButtonProps['onClick'] = () => {
    const nextMonth = DateTime.fromFormat(
      studentInvoice.invoice_month,
      'yyyy/MM',
    )
      .plus({ months: 1 })
      .toFormat('yyyy/MM');
    location.state.invoiceMonth = nextMonth;
    setInvoiceMonth(nextMonth);
    state.isOpening = false;
  };

  const onSubmit = formMethods.handleSubmit(async (data) => {
    const cloneData = _.cloneDeep(data);
    if (cloneData.invoice_items) {
      cloneData.invoice_items.forEach((invoiceItem, index) => {
        cloneData.invoice_items[index].amounts = Number(invoiceItem.amounts);
        cloneData.invoice_items[index].order = index + 1;
      });
    }
    const createValue = {
      ...studentInvoice,
      ...cloneData,
      id: studentInvoice?.id,
      student_id: studentId,
      invoice_month: invoiceMonth,
      consumption_tax: Number(cloneData.consumption_tax),
      subtotal: Number(cloneData.subtotal),
      total: Number(cloneData.total),
      withdrawal_result: studentInvoice.withdrawal_result,
    };
    const id = await create(createValue);
    if (id) {
      setStudentInvoice({
        ...createValue,
        id,
        student_id: studentId,
        invoice_month: invoiceMonth,
      });
    }
  });

  return (
    <StudentInvoiceForm
      loading={loading || loading2}
      studentBasic={studentBasic}
      parentInfo={parentInfo}
      studentInvoice={studentInvoice}
      invoiceMonth={invoiceMonth}
      formMethods={formMethods}
      onClickPreviousMonth={onClickPreviousMonth}
      onClickNextMonth={onClickNextMonth}
      onSubmit={onSubmit}
      snackbarState={state}
      snackbarClose={close}
      creating={creating}
      currentStudentId={studentId}
      previousStudentId={previousStudentId}
      nextStudentId={nextStudentId}
      onClickPreviousStudent={onClickPreviousStudent}
      onClickNextStudent={onClickNextStudent}
    />
  );
};
