import clsx from 'clsx';
import React, { FC, useEffect } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Motion, spring } from 'react-motion';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { FaSave, RiArrowGoBackFill } from 'react-icons/all';

import { RELATIONSHIPS, WITHDRAWAL_BANKS } from '../../../../../data/form-data';
import { ParentInfo, TelInfo } from '../../../../../domain/models/parent-info';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import DotProgress from '../../../atoms/feedback/DotProgress';
import Input from '../../../atoms/form/Input';
import Select from '../../../atoms/form/Select';
import Button from '../../../atoms/button/Button';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import Backdrop from '../../../atoms/feedback/Backdrop';
import TelInfoItem from '../TelInfoItem';
import './style.scss';
import useRole from '../../../../hooks/useRole';
import { StudentBasic } from '../../../../../domain/models/student-basic';

export type Props = {
  loading: boolean;
  parentInfo: ParentInfo;
  studentBasics: StudentBasic[];
  formMethods: UseFormMethods<ParentInfo>;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
  creating: boolean;
};

const ParentInfoForm: FC<Props> = ({
  loading,
  parentInfo,
  studentBasics,
  formMethods,
  onSubmit,
  snackbarState,
  snackbarClose,
  creating,
}) => {
  const { isViewer } = useRole();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { errors, register, control, setValue, trigger } = formMethods;
  const { fields, append, remove } = useFieldArray<TelInfo>({
    control,
    name: 'emergency_contacts',
  });
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    if (parentInfo?.emergency_contacts.length) {
      append(parentInfo.emergency_contacts);
    }
  }, [append, parentInfo]);

  return loading ? (
    <DotProgress />
  ) : (
    <div>
      <form onSubmit={onSubmit} className="detail-form card p-5">
        <div className="page-meta">
          <h3 className="parent-name">{parentInfo?.name}</h3>
          <ul className="student-list">
            {studentBasics.map((student) => {
              return (
                <li key={student.student_num}>
                  <h4>{`${student.last_name} ${student.first_name}`}</h4>
                  <button
                    type="button"
                    className="button is-primary"
                    onClick={() => {
                      history.push({
                        pathname: '/student/detail',
                        state: { studentId: student.id },
                      });
                    }}
                  >
                    生徒詳細ページへ
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <Input
          label="保護者氏名"
          inputProps={{
            type: 'text',
            name: 'name',
            ref: register({ required: '保護者氏名を入力してください' }),
            defaultValue: parentInfo?.name,
            disabled: isViewer,
          }}
          error={errors.name}
        />
        <Controller
          control={control}
          name="relationship"
          defaultValue={parentInfo?.relationship}
          as={
            <Select
              label="保護者続柄"
              selectProps={{
                name: 'relationship',
                options: RELATIONSHIPS.map((relationship) => ({
                  label: relationship,
                  value: relationship,
                })),
                defaultValue: {
                  label: parentInfo?.relationship,
                  value: parentInfo?.relationship,
                },
                onChange: async (value) => {
                  setValue(
                    'relationship',
                    value && 'value' in value ? value.value : '',
                  );
                  await trigger('relationship');
                },
                isDisabled: isViewer,
              }}
              error={errors.relationship}
            />
          }
        />
        <Input
          label="郵便番号"
          inputProps={{
            type: 'text',
            name: 'postal_code',
            ref: register({ required: '郵便番号を入力してください' }),
            defaultValue: parentInfo?.postal_code,
            disabled: isViewer,
          }}
          error={errors.postal_code}
        />
        <Input
          label="都道府県"
          inputProps={{
            type: 'text',
            name: 'prefecture',
            ref: register({ required: '都道府県を入力してください' }),
            defaultValue: parentInfo?.prefecture,
            disabled: isViewer,
          }}
          error={errors.prefecture}
        />
        <Input
          label="市区町村"
          inputProps={{
            type: 'text',
            name: 'city',
            ref: register({ required: '市区町村を入力してください' }),
            defaultValue: parentInfo?.city,
            disabled: isViewer,
          }}
          error={errors.city}
        />
        <Input
          label="その他住所"
          inputProps={{
            type: 'text',
            name: 'address',
            ref: register({ required: 'その他住所を入力してください' }),
            defaultValue: parentInfo?.address,
            disabled: isViewer,
          }}
          error={errors.address}
        />
        <Input
          label="メールアドレス"
          inputProps={{
            type: 'email',
            name: 'mail_address',
            ref: register({ required: 'メールアドレスを入力してください' }),
            defaultValue: parentInfo?.mail_address,
            disabled: true,
          }}
          error={errors.mail_address}
        />
        <div className="field">
          <p className="label">電話番号</p>
          <TelInfoItem
            num={{
              name: 'tel_info.num',
              defaultValue: parentInfo?.tel_info.num,
              error: errors.tel_info?.num,
              ref: register({ required: '電話番号を入力してください' }),
            }}
            relationship={{
              name: 'tel_info.relationship',
              defaultValue: parentInfo?.tel_info.relationship,
              error: errors.tel_info?.relationship,
              ref: register({ required: '電話番号の続柄を選択してください' }),
            }}
            control={control}
            selectOnChange={async (value) => {
              setValue(
                'tel_info.relationship',
                value && 'value' in value ? value.value : '',
              );
              await trigger('tel_info.relationship');
            }}
          />
        </div>
        <Input
          label="勤務先"
          inputProps={{
            type: 'text',
            name: 'work_address_name',
            ref: register(),
            defaultValue: parentInfo?.work_address_name,
            disabled: isViewer,
          }}
          error={errors.work_address_name}
        />
        <Input
          label="勤務先電話番号"
          inputProps={{
            type: 'text',
            name: 'work_address_tel_num',
            ref: register(),
            defaultValue: parentInfo?.work_address_tel_num,
            disabled: isViewer,
          }}
          error={errors.work_address_tel_num}
        />
        <div className="field">
          <p className="label">緊急連絡先</p>
          {fields.map((field, index) => {
            const errorEmergencyContacts = errors.emergency_contacts;

            return (
              <Motion
                defaultStyle={{ scale: 0.9, opacity: 0 }}
                style={{ scale: spring(1), opacity: 1 }}
                key={field.id}
              >
                {(style) => (
                  <div
                    style={{
                      transform: `scale(${style.scale})`,
                      opacity: style.opacity,
                    }}
                  >
                    <TelInfoItem
                      num={{
                        name: `emergency_contacts[${index}].num`,
                        defaultValue:
                          parentInfo?.emergency_contacts[index] &&
                          parentInfo?.emergency_contacts[index].num
                            ? parentInfo?.emergency_contacts[index].num
                            : '',
                        error:
                          errorEmergencyContacts &&
                          errorEmergencyContacts[index] !== undefined
                            ? errorEmergencyContacts[index]?.num
                            : undefined,
                        ref: register({
                          required: '電話番号を入力してください',
                        }),
                      }}
                      relationship={{
                        name: `emergency_contacts[${index}].relationship`,
                        defaultValue:
                          parentInfo?.emergency_contacts[index] &&
                          parentInfo?.emergency_contacts[index].relationship
                            ? parentInfo?.emergency_contacts[index].relationship
                            : '',
                        error:
                          errorEmergencyContacts &&
                          errorEmergencyContacts[index] !== undefined
                            ? errorEmergencyContacts[index]?.relationship
                            : undefined,
                        ref: register({
                          required: '電話番号の続柄を選択してください',
                        }),
                      }}
                      withIcon
                      iconOnClick={() => remove(index)}
                      control={control}
                      selectOnChange={async (value) => {
                        setValue(
                          `emergency_contacts[${index}].relationship`,
                          value && 'value' in value ? value.value : '',
                        );
                        await trigger(
                          `emergency_contacts[${index}].relationship`,
                        );
                      }}
                    />
                  </div>
                )}
              </Motion>
            );
          })}
          <Button
            buttonProps={{ onClick: () => append({}), disabled: isViewer }}
          >
            連絡先を追加
          </Button>
        </div>
        <Controller
          control={control}
          name="withdrawal_bank"
          defaultValue={parentInfo?.withdrawal_bank}
          as={
            <Select
              label="引落銀行"
              selectProps={{
                name: 'withdrawal_bank',
                options: WITHDRAWAL_BANKS.map((bank) => ({
                  label: bank,
                  value: bank,
                })),
                defaultValue: {
                  label: parentInfo?.withdrawal_bank,
                  value: parentInfo?.withdrawal_bank,
                },
                onChange: async (value) => {
                  setValue(
                    'withdrawal_bank',
                    value && 'value' in value ? value.value : '',
                  );
                  await trigger('withdrawal_bank');
                },
                isDisabled: isViewer,
              }}
              error={errors.withdrawal_bank}
            />
          }
        />
        <div className="btn-area">
          <Button
            buttonProps={{
              type: 'button',
              onClick: () => history.push('/parent'),
            }}
            className={clsx('with-icon', 'btn-primary')}
          >
            <RiArrowGoBackFill />
            戻る
          </Button>
          <Button
            buttonProps={{ type: 'submit', disabled: isViewer }}
            color="primary"
            className={clsx('with-icon', 'btn-primary')}
          >
            <FaSave />
            保存
          </Button>
        </div>
      </form>
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default ParentInfoForm;
