import React, { FC } from 'react';

import './style.scss';

const DotProgress: FC = () => {
  return (
    <div className="dot-progress">
      <span />
      <span />
      <span />
    </div>
  );
};

export default DotProgress;
