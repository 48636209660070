import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { ArrayField } from 'react-hook-form';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { TableCell, TableRow } from '@material-ui/core';

import {
  ElementarySchoolSubject,
  HighSchoolSubject,
  MiddleSchoolSubject,
  SPECIAL_SUBJECTS,
} from '../../../../../data/form-data';
import {
  LECTURE_KEYS,
  defaultSeparatedLecture,
  SeparatedLecture,
} from '../../../../../domain/models/student-lectures';
import {
  ScheduleItem,
  Timetable,
} from '../../../../../domain/models/timetable';
import { SelectSubject } from '../utils/SelectSubject';

export type Props = {
  formMethods: UseFormMethods<Timetable>;
  scheduleItem: Partial<ArrayField<ScheduleItem>>;
  index: number;
  subjects: (
    | ElementarySchoolSubject
    | MiddleSchoolSubject
    | HighSchoolSubject
  )[];
  isNotesElementarySchool: boolean;
};

export const TableRowCalendar = ({
  formMethods,
  scheduleItem,
  index,
  subjects,
  isNotesElementarySchool,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register } = formMethods;
  const day = new Date(scheduleItem.date as string).getDay();
  const jaDays = ['日', '月', '火', '水', '木', '金', '土'];
  let dateColor;
  if (day === 0) {
    dateColor = 'has-text-danger';
  } else if (day === 6) {
    dateColor = 'has-text-link';
  } else {
    dateColor = 'has-text-grey-dark';
  }
  const date = index + 1;

  return (
    <TableRow>
      <TableCell component="th" scope="row" className="has-text-centered">
        <div>
          <div className={clsx('is-size-4', 'has-text-weight-bold', dateColor)}>
            {date}
          </div>
          <div>{jaDays[day]}曜日</div>
        </div>
      </TableCell>
      {LECTURE_KEYS.map((lectureKey) => {
        const scheduleName = `schedule[${index}]`;
        const dateName = `${scheduleName}.date`;
        const lectureKeyName = `${scheduleName}.${lectureKey}`;
        const lectureName = `${lectureKeyName}.lecture`;
        const subjectName = `${lectureKeyName}.subject`;
        const isLectureSpecial = lectureKey === 'lecture_special';

        let defaultValueSubject: SeparatedLecture | string;
        if (isNotesElementarySchool && !isLectureSpecial) {
          // ノートス小学生の場合かつ通常の授業の場合は前半・後半に分ける
          defaultValueSubject =
            scheduleItem?.[lectureKey]?.subject ??
            _.cloneDeep(defaultSeparatedLecture);

          // もしも、中学校->小学校のグレードに戻っていた場合は、初期値をdefaultSeparatedLectureにする。
          if (typeof defaultValueSubject === 'string') {
            defaultValueSubject = _.cloneDeep(defaultSeparatedLecture);
          }
        } else {
          // ノートス小学生以外の場合かつ特別授業の場合は前半・後半に分けない
          defaultValueSubject = scheduleItem?.[lectureKey]?.subject ?? '';

          // もしも、小学校->中学校のグレードに上がっていた場合は、初期値をstring（空文字）にする。
          if (typeof defaultValueSubject !== 'string') {
            defaultValueSubject = '';
          }
        }

        return (
          <TableCell key={lectureKeyName} className="has-text-centered">
            <input
              type="hidden"
              name={dateName}
              defaultValue={scheduleItem.date}
              ref={register}
            />
            <input
              type="hidden"
              name={lectureName}
              defaultValue={scheduleItem[lectureKey]?.lecture}
              ref={register}
            />
            {typeof defaultValueSubject === 'string' ? (
              <SelectSubject
                formMethods={formMethods}
                subjects={isLectureSpecial ? SPECIAL_SUBJECTS : subjects}
                subjectName={subjectName}
                defaultValueSubject={defaultValueSubject}
              />
            ) : (
              <>
                <div className="mb-2">前半</div>
                <SelectSubject
                  formMethods={formMethods}
                  subjects={subjects}
                  subjectName={`${subjectName}.first`}
                  defaultValueSubject={defaultValueSubject.first}
                  selectPropsClassName="mb-5"
                />
                <div className="mb-2">後半</div>
                <SelectSubject
                  formMethods={formMethods}
                  subjects={subjects}
                  subjectName={`${subjectName}.latter`}
                  defaultValueSubject={defaultValueSubject.latter}
                  selectPropsClassName="mb-5"
                />
              </>
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
