import { Row } from 'react-table';
import { MiddleSchoolExamList } from '../../types/table/middle-school-exam-list';

export default (
  nextRow: Row<MiddleSchoolExamList>,
  currentRow: Row<MiddleSchoolExamList>,
): 0 | 1 | -1 => {
  const original = [
    '小1',
    '小2',
    '小3',
    '小4',
    '小5',
    '小6',
    '中1',
    '中2',
    '中3',
    '高1',
    '高2',
    '高3',
    '既卒',
  ];

  return original.findIndex((v) => v === nextRow?.values?.grade) >
    original.findIndex((v) => v === currentRow?.values?.grade)
    ? 1
    : -1;
};
