import { Dispatch, SetStateAction, useState } from 'react';
import { BulkChangeInvoice } from '../../../types/form/bulk-change-invoice';
import getStudentInvoice from '../../../domain/services/get-student-invoices';
import {
  defaultStudentInvoice,
  InvoiceItem,
  StudentInvoice,
} from '../../../domain/models/student-invoice';
import { InvoiceStatus, TAX_RATE } from '../../../data/form-data';
import bulkCreateStudentInvoice from '../../../domain/services/bulk-create-student-invoice';
import useSnackbar, { SnackbarState } from '../useSnackbar';

export type Result = {
  creating: boolean;
  bulkCreate: (
    invoiceMonth: string,
    studentIds: string[],
    data: BulkChangeInvoice,
    setBulkChanged: Dispatch<SetStateAction<boolean>>,
  ) => void;
  state: SnackbarState;
  close: () => void;
};

const useBulkCreateStudentInvoice = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const bulkCreate = async (
    invoiceMonth: string,
    studentIds: string[],
    data: BulkChangeInvoice,
    setBulkChanged: Dispatch<SetStateAction<boolean>>,
  ) => {
    setCreating(true);
    const { studentInvoices } = await getStudentInvoice({
      queryParams: [
        {
          fieldPath: 'invoice_month',
          opStr: '==',
          value: invoiceMonth,
        },
      ],
    });

    const changeData = studentIds.map(
      (studentId): StudentInvoice => {
        // 請求データがあれば抽出
        const invoice = studentInvoices.find((studentInvoice) => {
          return studentInvoice.student_id === studentId;
        });

        let invoice_items: InvoiceItem[] = invoice ? invoice.invoice_items : [];
        let subtotal = invoice ? invoice.subtotal : 0;
        let consumption_tax = invoice ? invoice.consumption_tax : 0;
        let total = invoice ? invoice.total : 0;

        if (data.invoice_items) {
          data.invoice_items.forEach((invoiceItem) => {
            invoice_items.push({
              ...invoiceItem,
              amounts: Number(invoiceItem.amounts),
            });
          });
          //
          subtotal = 0;
          let taxableAmount = 0;
          invoice_items = invoice_items.map((item, index) => {
            subtotal += item.amounts;
            if (!item.tax_included) {
              taxableAmount += item.amounts;
            }

            return {
              ...item,
              order: index + 1,
            };
          });
          consumption_tax = Math.round(taxableAmount * TAX_RATE);
          consumption_tax =
            Math.sign(consumption_tax) > 0 ? consumption_tax : 0;
          total = subtotal + consumption_tax;
        }

        const status: InvoiceStatus =
          data.status || invoice?.status || 'private';
        const withdrawal_date =
          data.withdrawal_date || invoice?.withdrawal_date || '';
        const remarks =
          String(invoice?.remarks || '') +
          String(invoice?.remarks && data.remarks ? '\n' : '') +
          String(data.remarks ? data.remarks : '');

        return {
          ...defaultStudentInvoice,
          ...invoice,
          student_id: studentId,
          invoice_month: invoiceMonth,
          status,
          withdrawal_date,
          invoice_items,
          remarks,
          subtotal,
          consumption_tax,
          total,
        };
      },
    );

    const isSuccess = await bulkCreateStudentInvoice(changeData);

    open(
      isSuccess ? 'success' : 'error',
      isSuccess
        ? '請求情報の一括更新に成功しました'
        : '請求情報の一括更新ができませんでした。もう一度お試しください。',
    );
    setCreating(false);

    if (isSuccess) {
      setBulkChanged(true);
    }
  };

  return { creating, bulkCreate, state, close };
};

export default useBulkCreateStudentInvoice;
