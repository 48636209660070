import { OmitId } from '@firestore-simple/web/dist/types';
import { Collection } from '@firestore-simple/web/dist/collection';
import { Query } from '@firestore-simple/web';

import firestoreSimple from '../../lib/firebase-firestore';
import { PeriodicInfo } from '../models/periodic-info';
import { QueryParams } from '../../types/firestore-simple';

export type CollectionOrQuery =
  | Collection<PeriodicInfo, OmitId<PeriodicInfo>>
  | Query<PeriodicInfo, OmitId<PeriodicInfo>>;

const getPeriodicInfos = async ({
  queryParams,
}: {
  queryParams?: QueryParams<PeriodicInfo>;
}): Promise<{ periodicInfos: PeriodicInfo[] }> => {
  const collection = firestoreSimple.collection<PeriodicInfo>({
    path: 'periodic_info',
  });
  const query =
    queryParams && queryParams.length
      ? queryParams.reduce((prev: CollectionOrQuery, current) => {
          return prev.where(current.fieldPath, current.opStr, current.value);
        }, collection)
      : collection;
  let docs: PeriodicInfo[] | undefined;
  if ('query' in query && 'startAt' in query) {
    docs =
      (await query.fetch().catch((error) => console.error(error))) || undefined;
  } else {
    docs =
      (await query.fetchAll().catch((error) => console.error(error))) ||
      undefined;
  }
  const periodicInfos = docs instanceof Array ? docs : [];

  return { periodicInfos };
};

export default getPeriodicInfos;
