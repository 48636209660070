import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

export type Props = {
  defaultValueSubject: string;
};

export const Subject = ({ defaultValueSubject }: Props) => {
  const [className, setClassName] = useState('');

  useEffect(() => {
    changeClass(defaultValueSubject);
  }, [defaultValueSubject]);

  const changeClass = (value?: string) => {
    // TODO 将来的に振替の場合に色を変えるため、色変えロジックは残しておく
    switch (value) {
      default: {
        setClassName('');
        break;
      }
    }
  };

  return (
    <>
      {defaultValueSubject ? (
        <div className={clsx('subject-box', className)}>
          {defaultValueSubject}
        </div>
      ) : null}
    </>
  );
};
