import React, { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { Row } from 'react-table';
import _ from 'lodash';
import { BulkChangeInvoice } from '../../../../../types/form/bulk-change-invoice';
import InvoiceBulkChangeForm from '../../../../components/molecules/form/InvoiceBulkChangeForm';
import { InvoiceList } from '../../../../../types/table/invoice-list';
import useBulkCreateStudentInvoice from '../../../../hooks/create/useBulkCreateStudentInvoice';

export type Props = {
  invoiceMonth: string;
  rows: Row<InvoiceList>[];
  setBulkChanged: Dispatch<SetStateAction<boolean>>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default ({ invoiceMonth, rows, setBulkChanged }: Props) => {
  const formMethods = useForm<BulkChangeInvoice>({
    defaultValues: {
      invoice_items: [],
      status: null,
      withdrawal_date: null,
      remarks: null,
    },
  });
  const { creating, bulkCreate, state, close } = useBulkCreateStudentInvoice();
  const onSubmit = formMethods.handleSubmit((data) => {
    if (
      !(
        data.invoice_items ||
        data.status ||
        data.withdrawal_date ||
        data.remarks
      )
    ) {
      return;
    }

    const studentIds = rows.map((row): string => {
      const values = _.filter(row.values, (__, key) => {
        return ['student_id'].includes(key);
      });

      return values[0] as string;
    });

    bulkCreate(invoiceMonth, studentIds, data, setBulkChanged);
  });

  return (
    <InvoiceBulkChangeForm
      formMethods={formMethods}
      onSubmit={onSubmit}
      creating={creating}
      snackbarState={state}
      snackbarClose={close}
    />
  );
};
