import React, { useEffect, useState } from 'react';
import { ArrayField } from 'react-hook-form';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { TableCell, TableRow } from '@material-ui/core';

import {
  LectureItemForShift,
  WorkShift,
} from '../../../../../domain/models/work-shift';
import { StudentBasic } from '../../../../../domain/models/student-basic';
import {
  DEFAULT_SEATS,
  DEFAULT_SEATS_FOR_PRAISE,
  SETTLEMENT_SUBJECTS,
  SettlementSubject,
  UNDERSTANDING_SUBJECTS,
  UnderstandingSubject,
} from '../../../../../data/form-data';
import { SelectForForm } from './SelectForForm';
import { SelectedStaff } from '../../../../../domain/models/admin-user';

export type Props = {
  index: number;
  fieldArrayName: string;
  formMethods: UseFormMethods<WorkShift>;
  lectureItemForShift: Partial<ArrayField<LectureItemForShift, 'id'>>;
  staffs: SelectedStaff[];
  studentBasic: StudentBasic;
  onChangeCallback: () => void;
  isNotesJapan: boolean;
};

/* eslint-disable */
export const TableRowShift = ({
  index,
  fieldArrayName,
  formMethods,
  lectureItemForShift,
  staffs,
  studentBasic,
  onChangeCallback,
  isNotesJapan,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { watch } = formMethods;
  const [selectDefaultSeats, setSelectDefaultSeats] = useState<string[]>(DEFAULT_SEATS);
  type SelectValue = {
    label: string;
    value: string;
  } | null;
  const [selectValueOptions, setSelectValueOptions] = useState<SelectValue[] | null>(null);
  const [selectValueOptions2, setSelectValueOptions2] = useState<SelectValue[] | null>(null);

  useEffect(() => {
    setSelectDefaultSeats(isNotesJapan ? DEFAULT_SEATS : DEFAULT_SEATS_FOR_PRAISE);
  }, [isNotesJapan, setSelectDefaultSeats]);

  useEffect(() => {
      setSelectValueOptions(null)
      setSelectValueOptions2(null)
  }, [staffs, lectureItemForShift]);

  useEffect(() => {
    let fs;
    let fs2;
    let baseOptions;
    let baseOptions2;

    if (typeof lectureItemForShift.subject !== 'string' &&
      typeof lectureItemForShift.teacher_id !== 'string' &&
      typeof lectureItemForShift.default_seat !== 'string') {
      fs = filterStaffs(lectureItemForShift?.subject?.first ?? undefined);
      baseOptions = [...fs?.map((staff) => ({
        label: `${staff.last_name} ${staff.first_name}`,
        value: staff.id,
      }))];

      fs2 = filterStaffs(lectureItemForShift?.subject?.latter ?? undefined);
      baseOptions2 = [...fs2?.map((staff) => ({
        label: `${staff.last_name} ${staff.first_name}`,
        value: staff.id,
      }))];

      if (watch(`${fieldArrayName}.${index}.teacher_id.first`)) {
        baseOptions = [...baseOptions, {
          label: `${getStaffName(watch(`${fieldArrayName}.${index}.teacher_id.first`) as string)}`,
          value: watch(`${fieldArrayName}.${index}.teacher_id.first`) as string,
        }]
      }

      if (watch(`${fieldArrayName}.${index}.teacher_id.latter`)) {
        baseOptions2 = [...baseOptions2, {
          label: `${getStaffName(watch(`${fieldArrayName}.${index}.teacher_id.latter`) as string)}`,
          value: watch(`${fieldArrayName}.${index}.teacher_id.latter`) as string,
        }]
      }

      // 重複削除
      baseOptions = baseOptions.filter((o, idx, self) => {
        return self.findIndex((o2) => o2.value === o.value) === idx
      });
      baseOptions2 = baseOptions2.filter((o, idx, self) => {
        return self.findIndex((o2) => o2.value === o.value) === idx
      });


      setSelectValueOptions(baseOptions)
      setSelectValueOptions2(baseOptions2)
    } else {
      fs = filterStaffs(typeof lectureItemForShift?.subject === 'string' ? lectureItemForShift?.subject : undefined);
      baseOptions = [...fs?.map((staff) => ({
        label: `${staff.last_name} ${staff.first_name}`,
        value: staff.id,
      }))];

      if (watch(`${fieldArrayName}.${index}.teacher_id`)) {
        baseOptions = [...baseOptions, {
          label: `${getStaffName(watch(`${fieldArrayName}.${index}.teacher_id`) as string)}`,
          value: watch(`${fieldArrayName}.${index}.teacher_id`) as string,
        }]
      }

      // 重複削除
      baseOptions = baseOptions.filter((o, idx, self) => {
        return self.findIndex((o2) => o2.value === o.value) === idx
      });

      setSelectValueOptions(baseOptions)
      setSelectValueOptions2(null)
    }
    // eslint-disable-next-line
  }, [staffs, lectureItemForShift, watch]);

  const getStaffName = (teacherId: string) => {
    const staff = staffs.find((s) => s.id === teacherId);
    if (staff) {
      return `${staff.last_name} ${staff.first_name}`;
    }
    return '現在選択している校舎に所属していない講師が選択されています';
  }

  // 教科が理解授業&&理解授業を3つ以上持つスタッフ、または、教科が理解授業&&定着授業を持っているスタッフ（またはその逆）の場合は選択肢から除外
  const filterStaffs = (subject: string | undefined) => {
    if (!subject) { // TypeScriptエラー用対策
      return [];
    }

    const isUnderstandingSubject = UNDERSTANDING_SUBJECTS.includes(subject as UnderstandingSubject);
    const isSettlementSubject = SETTLEMENT_SUBJECTS.includes(subject as SettlementSubject);

    return staffs.filter((staff) => !((staff?.understandingSubjectNum ?? 0) >= 3 ||
      (isUnderstandingSubject && staff?.hasSettlementSubject) ||
      (isSettlementSubject && (staff?.understandingSubjectNum ?? 0)) >= 1)
    );
  }

  return (
    <TableRow>
      <TableCell>{studentBasic?.student_num}</TableCell>
      <TableCell>
        {studentBasic?.last_name} {studentBasic?.first_name}
      </TableCell>
      <TableCell>{studentBasic?.grade}</TableCell>
      <TableCell>
        {lectureItemForShift.subject && typeof lectureItemForShift.subject !== 'string' &&
        typeof lectureItemForShift.teacher_id !== 'string' &&
        typeof lectureItemForShift.default_seat !== 'string'  ? (
          <div>
            <div className="my-5 has-text-centered shift-subject-separate-container">
              <div className="mb-2">【前半】</div>
              {lectureItemForShift.subject.first ? (
                <div className="subject-box">
                  {lectureItemForShift.subject.first}
                </div>
              ) : <p className="has-text-centered shift-subject-separate-no-content">ー</p>}
            </div>
            <div className="my-5 has-text-centered shift-subject-separate-container">
              <div className="mb-2">【後半】</div>
              {lectureItemForShift.subject.latter ? (
                <div className="subject-box">
                  {lectureItemForShift.subject.latter}
                </div>
              ) : <p className="has-text-centered shift-subject-separate-no-content">ー</p>}
            </div>
          </div>
        ) : (
          <div className="my-5 has-text-centered">
            <div className="subject-box">{lectureItemForShift.subject}</div>
          </div>
        )}
      </TableCell>
      <TableCell>
        {lectureItemForShift.default_seat &&
        typeof lectureItemForShift.subject !== 'string' &&
        typeof lectureItemForShift.teacher_id !== 'string' &&
        typeof lectureItemForShift.default_seat !== 'string' ? (
          <div>
            <div className="my-5 has-text-centered shift-subject-separate-container">
              <div className="mb-2">【前半】</div>
                <div>
                  <SelectForForm
                    formMethods={formMethods}
                    name={`${fieldArrayName}.${index}.default_seat.first`}
                    options={DEFAULT_SEATS.map((defaultSeat) => ({
                      label: defaultSeat,
                      value: defaultSeat,
                    }))}
                    defaultValue={lectureItemForShift.default_seat.first ? {
                      label: lectureItemForShift.default_seat.first,
                      value: lectureItemForShift.default_seat.first,
                    } : null}
                    isDisabled={!lectureItemForShift.subject?.first}
                    className={'select-seat'}
                  />
                </div>
            </div>
            <div className="my-5 has-text-centered shift-subject-separate-container">
              <div className="mb-2">【後半】</div>
                <div>
                  <SelectForForm
                    formMethods={formMethods}
                    name={`${fieldArrayName}.${index}.default_seat.latter`}
                    options={DEFAULT_SEATS.map((defaultSeat) => ({
                      label: defaultSeat,
                      value: defaultSeat,
                    }))}
                    defaultValue={lectureItemForShift.default_seat.latter ? {
                      label: lectureItemForShift.default_seat.latter,
                      value: lectureItemForShift.default_seat.latter,
                    } : null}
                    isDisabled={!lectureItemForShift.subject?.latter}
                    className={'select-seat'}
                  />
                </div>
            </div>
          </div>
        ) : (
          <div className="my-5 has-text-centered">
            <div>
              <SelectForForm
                formMethods={formMethods}
                name={`${fieldArrayName}.${index}.default_seat`}
                options={selectDefaultSeats.map((defaultSeat) => ({
                  label: defaultSeat,
                  value: defaultSeat,
                }))}
                defaultValue={lectureItemForShift.default_seat ? {
                  label: typeof lectureItemForShift.default_seat === 'string' ? lectureItemForShift.default_seat : '',
                  value: typeof lectureItemForShift.default_seat === 'string' ? lectureItemForShift.default_seat : '',
                } : null}
                className={'select-seat'}
              />
            </div>
          </div>
        )}
      </TableCell>
      <TableCell>
        {lectureItemForShift.teacher_id &&
        typeof lectureItemForShift.subject !== 'string' &&
        typeof lectureItemForShift.teacher_id !== 'string' &&
        typeof lectureItemForShift.default_seat !== 'string' ? (
          <div>
            <div className="my-5 has-text-centered shift-subject-separate-container">
              <div className="mb-2">【前半】</div>
                <div>
                  <SelectForForm
                    formMethods={formMethods}
                    name={`${fieldArrayName}.${index}.teacher_id.first`}
                    options={selectValueOptions?.map((option) => ({
                      label: option?.label ?? '',
                      value: option?.value ?? ''
                    }))}
                    defaultValue={lectureItemForShift.teacher_id.first ? {
                      label: `${getStaffName(lectureItemForShift.teacher_id.first)}`,
                      value: lectureItemForShift.teacher_id.first,
                    } : null}
                    isDisabled={!lectureItemForShift.subject?.first}
                    className={'select-teacher'}
                    onChangeCallback={onChangeCallback}
                  />
                </div>
            </div>
            <div className="my-5 has-text-centered shift-subject-separate-container">
              <div className="mb-2">【後半】</div>
                <div>
                  <SelectForForm
                    formMethods={formMethods}
                    name={`${fieldArrayName}.${index}.teacher_id.latter`}
                    options={selectValueOptions2?.map((option) => ({
                      label: option?.label ?? '',
                      value: option?.value ?? ''
                    }))}
                    defaultValue={lectureItemForShift.teacher_id.latter ? {
                      label: `${getStaffName(lectureItemForShift.teacher_id.latter)}`,
                      value: lectureItemForShift.teacher_id.latter,
                    } : null}
                    isDisabled={!lectureItemForShift.subject?.latter}
                    className={'select-teacher'}
                    onChangeCallback={onChangeCallback}
                  />
                </div>
            </div>
          </div>
        ) : (
          <div className="my-5 has-text-centered">
            <div>
              <SelectForForm
                formMethods={formMethods}
                name={`${fieldArrayName}.${index}.teacher_id`}
                options={selectValueOptions?.map((option) => ({
                  label: option?.label ?? '',
                  value: option?.value ?? ''
                }))}
                defaultValue={lectureItemForShift.teacher_id ? {
                  label: typeof lectureItemForShift.teacher_id === 'string' ? `${getStaffName(lectureItemForShift.teacher_id)}` : '',
                  value: typeof lectureItemForShift.teacher_id === 'string' ? lectureItemForShift.teacher_id : '',
                }: null}
                className={'select-teacher'}
                onChangeCallback={onChangeCallback}
              />
            </div>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};
