import { useAsync } from 'react-use';

import getStudentBasics from '../../../domain/services/get-student-basics';
import useRole from '../useRole';

export type Result = {
  loading: boolean;
  num: number;
};

const useFetchStudentBasics = (): Result => {
  const { isAdministrator, isHeadquarters, enrolledCramSchool } = useRole();
  const { loading, value } = useAsync(async () => {
    const defaultQuery =
      isAdministrator || isHeadquarters
        ? []
        : [
            {
              fieldPath: 'enrolled_cram_school',
              opStr: '==',
              value: enrolledCramSchool,
            },
          ];
    const { studentBasics } = await getStudentBasics({
      queryParams: [
        ...defaultQuery,
        {
          fieldPath: 'status',
          opStr: 'in',
          value: ['document_request', 'waiting_trial'],
        },
      ],
    });

    return studentBasics.length;
  }, []);

  return { loading, num: value || 0 };
};

export default useFetchStudentBasics;
