import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { StudentBasic } from '../../../domain/models/student-basic';
import { StudentLectures } from '../../../domain/models/student-lectures';
import { QueryParams } from '../../../types/firestore-simple';
import getStudentBasics from '../../../domain/services/get-student-basics';
import getStudentLectures from '../../../domain/services/get-student-lectures';

export type Result = {
  loading: boolean;
  studentBasics: StudentBasic[];
  studentLectures: StudentLectures[];
};

const useFetchStudentLectures = ({
  id,
  queryParams,
}: {
  id?: string;
  queryParams?: QueryParams<StudentLectures>;
}): Result => {
  const [loading, setLoading] = useState(false);
  const [studentBasics, setStudentBasics] = useState<StudentBasic[]>([]);
  const [studentLectures, setStudentLectures] = useState<StudentLectures[]>([]);

  useEffectOnce(() => {
    void (async () => {
      setLoading(true);

      const basicResult = await getStudentBasics({ id });
      if (basicResult.studentBasics.length) {
        setStudentBasics(basicResult.studentBasics);
      }

      const lecturesResult = await getStudentLectures({
        id,
        queryParams,
      });
      if (lecturesResult.studentLectures.length) {
        setStudentLectures(lecturesResult.studentLectures);
      }

      setLoading(false);
    })();
  });

  return { loading, studentBasics, studentLectures };
};

export default useFetchStudentLectures;
