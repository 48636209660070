import React, { FC, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AppContext } from '../../../../context/AppContext';
import useRole from '../../../../hooks/useRole';
import NotFound from '../../../../view/NotFound';

const PrivateNotViewerRoute: FC<RouteProps> = ({ children, ...rest }) => {
  const { adminUser } = useContext(AppContext);
  const { isViewer } = useRole();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (adminUser.id) {
          if (!isViewer) {
            return children;
          }

          return <NotFound />;
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateNotViewerRoute;
