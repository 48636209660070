import { OmitId } from '@firestore-simple/web/dist/types';
import { Collection } from '@firestore-simple/web/dist/collection';
import { Query } from '@firestore-simple/web';

import firestoreSimple from '../../lib/firebase-firestore';
import { ParentInfo } from '../models/parent-info';
import { QueryParams } from '../../types/firestore-simple';

export type CollectionOrQuery =
  | Collection<ParentInfo, OmitId<ParentInfo>>
  | Query<ParentInfo, OmitId<ParentInfo>>;

const getParents = async ({
  id,
  queryParams,
}: {
  id?: string;
  queryParams?: QueryParams<ParentInfo>;
}): Promise<{ parents: ParentInfo[] }> => {
  const collection = firestoreSimple.collection<ParentInfo>({
    path: 'parent_info',
  });
  const query =
    queryParams && queryParams.length
      ? queryParams.reduce((prev: CollectionOrQuery, current) => {
          return prev.where(current.fieldPath, current.opStr, current.value);
        }, collection)
      : collection;
  let docs: ParentInfo[] | undefined;
  if ('query' in query && 'startAt' in query) {
    docs =
      (await query.fetch().catch((error) => console.error(error))) || undefined;
  } else if (typeof id === 'string') {
    const result = await query.fetch(id).catch((error) => console.error(error));
    docs = result ? [result] : undefined;
  } else {
    docs =
      (await query.fetchAll().catch((error) => console.error(error))) ||
      undefined;
  }
  const parents = docs instanceof Array ? docs : [];

  return { parents };
};

export default getParents;
