import React, { useContext, FC } from 'react';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';

import { APP_TITLE } from '../../../../data/app-basic';
import Logo from '../../../../assets/images/logo_type_white.svg';
import { AppContext } from '../../../context/AppContext';
import './style.scss';

export type Props = {
  anchorEl: HTMLButtonElement | null;
  handleOpen: (ele: HTMLButtonElement) => void;
  handleClose: () => void;
  handleLinkButton: (pathname: string) => void;
  handleSignOut: () => void;
};

const AppBar: FC<Props> = ({
  anchorEl,
  handleOpen,
  handleClose,
  handleSignOut,
}) => {
  const { adminUser } = useContext(AppContext);

  return (
    <MuiAppBar position="fixed" className="app-bar">
      <Toolbar className="toolbar">
        <img src={Logo} alt={APP_TITLE} className="header-logo" />
        <Button
          onClick={(event) => handleOpen(event.currentTarget)}
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          こんにちは、
          <strong>
            {adminUser.last_name} {adminUser.first_name}
          </strong>
          さん
        </Button>
        <Menu
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          id="simple-menu"
        >
          <MenuItem onClick={handleSignOut}>ログアウト</MenuItem>
        </Menu>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
