import { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { StudentBasic } from '../../../domain/models/student-basic';
import getStudentBasic from '../../../domain/services/get-student-basics';
import {
  documentRequestTaskItems,
  TaskDocumentRequest,
} from '../../../domain/models/task';
import { TaskDocumentRequestList } from '../../../types/table/task-document-request-list';
import getTaskDocumentRequest from '../../../domain/services/get-task-document-request';
import getIcon from '../../../helpers/table/getIcon';
import { AppContext } from '../../context/AppContext';

export type Result = {
  loading: boolean;
  documentRequestTaskList: TaskDocumentRequestList[];
};

const useFetchTaskDocumentRequestLists = (): Result => {
  const { adminUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [, setStudentBasic] = useState<StudentBasic[]>([]);
  const [, setTaskDocumentRequests] = useState<TaskDocumentRequest[]>([]);
  const [documentRequestTaskList, setDocumentRequestTaskList] = useState<
    TaskDocumentRequestList[]
  >([]);
  const defaultQuery = [
    {
      fieldPath: 'status',
      opStr: '==',
      value: 'document_request',
    },
  ];
  const queryParams = ['editor', 'viewer'].includes(adminUser.role)
    ? [
        ...defaultQuery,
        {
          fieldPath: 'enrolled_cram_school',
          opStr: '==',
          value: adminUser.enrolled_cram_school,
        },
      ]
    : [...defaultQuery];

  useEffectOnce(() => {
    void (async () => {
      setLoading(true);

      const { studentBasics } = await getStudentBasic({ queryParams });
      setStudentBasic(studentBasics);

      const { taskDocumentRequests } = await getTaskDocumentRequest({});
      setTaskDocumentRequests(taskDocumentRequests);

      setDocumentRequestTaskList(
        studentBasics.map(
          (studentBasic): TaskDocumentRequestList => {
            const studentTask = taskDocumentRequests.find(
              (value) => value.student_id === studentBasic.id,
            );
            const tasks: TaskDocumentRequestList = {
              icon: null,
              id: studentTask?.id || '',
              student_id: studentBasic.id,
              student_name: `${studentBasic.last_name}${studentBasic.first_name}`,
              enrolled_cram_school: studentBasic.enrolled_cram_school,
              send_document:
                studentTask && studentTask.tasks.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'send_document',
                    ) || documentRequestTaskItems[0]
                  : documentRequestTaskItems[0],
              call_after_send_document:
                studentTask && studentTask.tasks.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'call_after_send_document',
                    ) || documentRequestTaskItems[0]
                  : documentRequestTaskItems[1],
              deadline:
                studentTask && studentTask.tasks.length
                  ? studentTask.tasks.find(
                      (value) => value.name === 'deadline',
                    ) || documentRequestTaskItems[0]
                  : documentRequestTaskItems[2],
            };

            const icon = getIcon(tasks);

            return { ...tasks, icon };
          },
        ),
      );

      setLoading(false);
    })();
  });

  return { loading, documentRequestTaskList };
};

export default useFetchTaskDocumentRequestLists;
