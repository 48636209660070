import React, { FC, useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { CgCloseO } from 'react-icons/cg';
import { Motion, spring } from 'react-motion';
import {
  AiFillCaretLeft,
  AiFillCaretRight,
  FaSave,
  RiArrowGoBackFill,
} from 'react-icons/all';
import { useHistory } from 'react-router-dom';
import { ButtonProps, IconButton, TextField } from '@material-ui/core';
import { Controller, useFieldArray } from 'react-hook-form';
import Select from 'react-select';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import { StudentBasic } from '../../../../../domain/models/student-basic';
import {
  InvoiceItem,
  StudentInvoice,
} from '../../../../../domain/models/student-invoice';
import DotProgress from '../../../atoms/feedback/DotProgress';
import { ParentInfo } from '../../../../../domain/models/parent-info';
import './style.scss';
import {
  INVOICE_STATUS,
  INVOICE_STATUS_LABELS,
  PAYMENT_METHOD_LABELS,
  PAYMENT_METHODS,
  TAX_RATE,
  WITHDRAWAL_BANKS,
} from '../../../../../data/form-data';
import ErrorMessage from '../../../atoms/form/ErrorMessage';
import useRole from '../../../../hooks/useRole';
import Button from '../../../atoms/button/Button';
import DateField from '../../../atoms/form/DatePicker';
import Textarea from '../../../atoms/form/Textarea';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import Backdrop from '../../../atoms/feedback/Backdrop';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import { INVOICE_ITEM_AUTOCOMPLETES } from '../../../../../data/data-list';

export type Props = {
  loading: boolean;
  studentBasic: StudentBasic | null;
  parentInfo: ParentInfo | null;
  studentInvoice: StudentInvoice;
  invoiceMonth: string;
  formMethods: UseFormMethods<StudentInvoice>;
  onClickPreviousMonth: ButtonProps['onClick'];
  onClickNextMonth: ButtonProps['onClick'];
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
  creating: boolean;
  currentStudentId: string;
  previousStudentId?: string;
  nextStudentId?: string;
  onClickPreviousStudent: ButtonProps['onClick'];
  onClickNextStudent: ButtonProps['onClick'];
};

const StudentInvoiceForm: FC<Props> = ({
  loading,
  studentBasic,
  parentInfo,
  studentInvoice,
  invoiceMonth,
  formMethods,
  onClickPreviousMonth,
  onClickNextMonth,
  onSubmit,
  snackbarState,
  snackbarClose,
  creating,
  currentStudentId,
  previousStudentId,
  nextStudentId,
  onClickPreviousStudent,
  onClickNextStudent,
}: Props) => {
  const { isViewer } = useRole();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { errors, register, control, watch, setValue, trigger } = formMethods;
  const { fields, append, remove } = useFieldArray<InvoiceItem>({
    control,
    name: 'invoice_items',
  });

  const [subtotal, setSubtotal] = useState<number>(studentInvoice.subtotal);
  const [tax, setTax] = useState<number>(studentInvoice.consumption_tax);
  const [total, setTotal] = useState<number>(studentInvoice.total);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);

    const sortedInvoiceItems = studentInvoice.invoice_items.sort((a, b) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;

      return 0;
    });

    if (sortedInvoiceItems.length) {
      setValue('invoice_items', sortedInvoiceItems);
    }

    setSubtotal(studentInvoice.subtotal);
    setTax(studentInvoice.consumption_tax);
    setTotal(studentInvoice.total);

    setValue('subtotal', studentInvoice.subtotal);
    setValue('consumption_tax', studentInvoice.consumption_tax);
    setValue('total', studentInvoice.total);
    setValue('status', studentInvoice.status);
    setValue('withdrawal_bank', studentInvoice.withdrawal_bank);
    setValue('withdrawal_date', studentInvoice.withdrawal_date);
    setValue('payment_method', studentInvoice.payment_method);
    setValue('message', studentInvoice.message);
    setValue('remarks', studentInvoice.remarks);
  }, [
    append,
    setValue,
    studentInvoice.invoice_items,
    studentInvoice.subtotal,
    studentInvoice.consumption_tax,
    studentInvoice.total,
    studentInvoice.status,
    studentInvoice.withdrawal_bank,
    studentInvoice.withdrawal_date,
    studentInvoice.payment_method,
    studentInvoice.message,
    studentInvoice.remarks,
  ]);

  useEffect(() => {
    remove();
  }, [currentStudentId, invoiceMonth, remove]);

  const changeInvoiceItems = () => {
    const invoiceItems = watch('invoice_items');
    let _subtotal = 0;
    let taxableAmount = 0;
    invoiceItems.forEach((invoiceItem) => {
      _subtotal += Number(invoiceItem.amounts ?? 0);
      if (!invoiceItem.tax_included) {
        taxableAmount += Number(invoiceItem.amounts ?? 0);
      }
    });
    let _tax = Math.round(taxableAmount * TAX_RATE);
    _tax = Math.sign(_tax) > 0 ? _tax : 0;
    //
    setSubtotal(_subtotal);
    setTax(_tax);
    setTotal(_subtotal + _tax);
  };

  const onChangeInvoiceItemName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const presetItem = INVOICE_ITEM_AUTOCOMPLETES.find((v) => {
      return v.name === event.target.value;
    });
    if (presetItem) {
      const { index } = event.target.dataset;
      if (!index) return;
      const amountField = document.getElementsByName(
        `invoice_items[${index}].amounts`,
      );
      (amountField[0] as HTMLInputElement).value = String(presetItem.amount);
      changeInvoiceItems();
    }
  };

  return loading ? (
    <DotProgress />
  ) : (
    <div>
      <form className="detail-form card p-5">
        <div className="invoice-header">
          <div className="parent-name">
            <button
              type="button"
              className="btn-underline"
              onClick={() => {
                window.open(`/parent/detail?id=${parentInfo?.id ?? ''}`);
              }}
            >
              {parentInfo?.name}
            </button>
          </div>
          <div className="student-info">
            <p>
              生徒番号：{studentBasic?.student_num}
              <br />
              <button
                type="button"
                className="btn-underline"
                onClick={() => {
                  window.open(`/student/detail?id=${studentBasic?.id ?? ''}`);
                }}
              >
                生徒名：{studentBasic?.last_name}
                {studentBasic?.first_name}
              </button>
            </p>
          </div>
        </div>
        <div className="select-student-container">
          <div className="btn-left">
            {previousStudentId && (
              <button type="button" onClick={onClickPreviousStudent}>
                ＜ 前の請求書へ
              </button>
            )}
          </div>
          <div className="btn-right">
            {nextStudentId && (
              <button type="button" onClick={onClickNextStudent}>
                次の請求書へ ＞
              </button>
            )}
          </div>
        </div>
        {!nextStudentId && <br />}
        <div className="select-invoice-month">
          <IconButton className="select-button" onClick={onClickPreviousMonth}>
            <AiFillCaretLeft />
          </IconButton>
          <div className="month-label">{studentInvoice.invoice_month}</div>
          <IconButton className="select-button" onClick={onClickNextMonth}>
            <AiFillCaretRight />
          </IconButton>
        </div>
        <div className="invoice-container">
          <datalist id="keywords">
            {INVOICE_ITEM_AUTOCOMPLETES.map((value) => (
              <option key={value.name} value={value.name}>
                {value.name}
              </option>
            ))}
          </datalist>
          <table className="table-invoice-item">
            <thead>
              <tr>
                <th className="th-item">項目</th>
                <th className="th-tax">税込</th>
                <th className="th-amount">金額</th>
                <th className="th-remove">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field, index) => {
                const errorInvoiceItems = errors.invoice_items;

                return (
                  <Motion
                    defaultStyle={{ scale: 0.9, opacity: 0 }}
                    style={{ scale: spring(1), opacity: 1 }}
                    key={field.id}
                  >
                    {(style) => (
                      <tr
                        style={{
                          transform: `scale(${style.scale})`,
                          opacity: style.opacity,
                        }}
                      >
                        <td className="td-item">
                          <input
                            type="text"
                            name={`invoice_items[${index}].label`}
                            defaultValue={
                              studentInvoice.invoice_items[index] !== undefined
                                ? studentInvoice.invoice_items[index].label
                                : ''
                            }
                            ref={register({
                              required: '項目名を入力してください',
                            })}
                            autoComplete="off"
                            list="keywords"
                            data-index={index}
                            onChange={onChangeInvoiceItemName}
                          />
                          <ErrorMessage
                            message={
                              errorInvoiceItems &&
                              errorInvoiceItems[index] !== undefined
                                ? errorInvoiceItems[index]?.label?.message
                                : undefined
                            }
                            className="mt-2"
                          />
                        </td>
                        <td className="td-tax">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={`invoice_items[${index}].tax_included`}
                            defaultChecked={
                              studentInvoice.invoice_items[index] !== undefined
                                ? studentInvoice.invoice_items[index]
                                    .tax_included
                                : false
                            }
                            onChange={changeInvoiceItems}
                            ref={register()}
                          />
                        </td>
                        <td className="td-amount">
                          <input
                            type="number"
                            name={`invoice_items[${index}].amounts`}
                            defaultValue={
                              studentInvoice.invoice_items[index] !== undefined
                                ? studentInvoice.invoice_items[index].amounts
                                : ''
                            }
                            step="1"
                            ref={register({
                              required: '金額を入力してください',
                            })}
                            onChange={changeInvoiceItems}
                          />
                          <ErrorMessage
                            message={
                              errorInvoiceItems &&
                              errorInvoiceItems[index] !== undefined
                                ? errorInvoiceItems[index]?.amounts?.message
                                : undefined
                            }
                            className="mt-2"
                          />
                        </td>
                        <td className="td-remove">
                          <CgCloseO
                            onClick={() => {
                              remove(index);
                              // eslint-disable-next-line no-param-reassign
                              delete studentInvoice.invoice_items[index];
                              changeInvoiceItems();
                            }}
                          />
                        </td>
                      </tr>
                    )}
                  </Motion>
                );
              })}
            </tbody>
          </table>
          <div className="bottom-invoice-item-container">
            <div className="btn-add-item">
              <Button
                buttonProps={{
                  onClick: () => append({}),
                }}
              >
                項目を追加
              </Button>
            </div>
            <div className="amount-info">
              <div className="amount-item">
                <p>小計</p>
                <p>{subtotal.toLocaleString()}</p>
                <input
                  type="hidden"
                  name="subtotal"
                  value={subtotal}
                  defaultValue={studentInvoice.subtotal}
                  ref={register}
                />
              </div>
              <div className="border" />
              <div className="amount-item">
                <p>消費税額</p>
                <p>{tax.toLocaleString()}</p>
                <input
                  type="hidden"
                  name="consumption_tax"
                  value={tax}
                  defaultValue={studentInvoice.consumption_tax}
                  ref={register}
                />
              </div>
              <div className="border" />
              <div className="amount-item">
                <p>合計</p>
                <p>{total.toLocaleString()}</p>
                <input
                  type="hidden"
                  name="total"
                  value={total}
                  defaultValue={studentInvoice.total}
                  ref={register}
                />
              </div>
            </div>
          </div>
          <div className="bottom-invoice-container">
            <div className="field">
              <p className="label">公開ステータス</p>
              <Controller
                name="status"
                control={control}
                defaultValue={studentInvoice.status}
                as={
                  <div>
                    <Select
                      name="status"
                      options={INVOICE_STATUS.map((value) => ({
                        label: INVOICE_STATUS_LABELS[value],
                        value,
                      }))}
                      defaultValue={{
                        label: INVOICE_STATUS_LABELS[studentInvoice.status],
                        value: studentInvoice.status,
                      }}
                      onChange={(value) => {
                        setValue(
                          'status',
                          value && 'value' in value ? value.value : '',
                        );
                      }}
                    />
                  </div>
                }
              />
            </div>
            <div className="field">
              <p className="label">引落銀行</p>
              <Controller
                name="withdrawal_bank"
                control={control}
                defaultValue={studentInvoice.withdrawal_bank}
                as={
                  <div>
                    <Select
                      name="withdrawal_bank"
                      options={WITHDRAWAL_BANKS.map((value) => ({
                        label: value,
                        value,
                      }))}
                      defaultValue={
                        studentInvoice.withdrawal_bank
                          ? {
                              label: studentInvoice.withdrawal_bank,
                              value: studentInvoice.withdrawal_bank,
                            }
                          : null
                      }
                      onChange={(value) => {
                        setValue(
                          'withdrawal_bank',
                          value && 'value' in value ? value.value : '',
                        );
                      }}
                    />
                  </div>
                }
              />
            </div>
            <div className="field">
              <p className="label">支払予定日</p>
              <Controller
                name="withdrawal_date"
                control={control}
                defaultValue={studentInvoice.withdrawal_date || null}
                as={
                  <DateField
                    DatePickerProps={{
                      label: '',
                      name: 'withdrawal_date',
                      value:
                        !errors?.withdrawal_date && watch('withdrawal_date')
                          ? DateTime.fromFormat(
                              watch('withdrawal_date'),
                              'yyyy/MM/dd',
                            )
                          : (studentInvoice.withdrawal_date &&
                              DateTime.fromFormat(
                                studentInvoice.withdrawal_date,
                                'yyyy/MM/dd',
                              )) ||
                            null,
                      format: 'yyyy/MM/dd',
                      inputRef: register(),
                      TextFieldComponent: (props) => (
                        <TextField {...props} margin="none" />
                      ),
                      onChange: (v) => {
                        const date = v ? v.toFormat('yyyy/MM/dd') : null;
                        setValue('withdrawal_date', date || '');
                        void trigger('withdrawal_date');
                      },
                    }}
                  />
                }
              />
            </div>
            <div className="field">
              <p className="label">支払方法</p>
              <Controller
                name="payment_method"
                control={control}
                defaultValue={studentInvoice.payment_method}
                as={
                  <div>
                    <Select
                      name="payment_method"
                      options={PAYMENT_METHODS.map((value) => ({
                        label: PAYMENT_METHOD_LABELS[value],
                        value,
                      }))}
                      defaultValue={
                        studentInvoice.payment_method
                          ? {
                              label:
                                PAYMENT_METHOD_LABELS[
                                  studentInvoice.payment_method
                                ],
                              value: studentInvoice.payment_method,
                            }
                          : null
                      }
                      onChange={(value) => {
                        setValue(
                          'payment_method',
                          value && 'value' in value ? value.value : '',
                        );
                      }}
                    />
                  </div>
                }
              />
            </div>
            <div className="field">
              <Textarea
                label="保護者様へのメッセージ"
                textareaProps={{
                  name: 'message',
                  defaultValue: studentInvoice.message,
                  ref: register(),
                }}
              />
            </div>
            <div className="field">
              <Textarea
                label="備考"
                textareaProps={{
                  name: 'remarks',
                  defaultValue: studentInvoice.remarks,
                  ref: register(),
                }}
              />
            </div>
          </div>
          <div className="btn-area">
            <Button
              buttonProps={{
                type: 'button',
                onClick: () => history.push('/invoice'),
              }}
              className={clsx('with-icon', 'btn-primary')}
            >
              <RiArrowGoBackFill />
              戻る
            </Button>
            <Button
              buttonProps={{
                type: 'button',
                disabled: isViewer,
                onClick: onSubmit,
              }}
              color="primary"
              className={clsx('with-icon', 'btn-primary')}
            >
              <FaSave />
              保存
            </Button>
          </div>
        </div>
      </form>
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default StudentInvoiceForm;
