import React, { FC } from 'react';
import { FieldElement, Ref } from 'react-hook-form/dist/types/fields';
import { ValidationRules } from 'react-hook-form/dist/types/validator';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Backdrop, CircularProgress } from '@material-ui/core';

import { SnackbarState } from '../../../../hooks/useSnackbar';
import { LoginData } from '../../../../../types/form/login-data';
import Input from '../../../atoms/form/Input';
import Button from '../../../atoms/button/Button';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';

export type Props = {
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  loading: boolean;
  register<TFieldElement extends FieldElement>(
    rules?: ValidationRules,
  ): (ref: (TFieldElement & Ref) | null) => void;
  errors: FieldErrors<LoginData>;
  snackbarState: SnackbarState;
};

const ForgetPasswordForm: FC<Props> = ({
  onSubmit,
  loading,
  register,
  errors,
  snackbarState,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <Input
        label="Email"
        inputProps={{
          type: 'email',
          name: 'mail_address',
          placeholder: 'メールアドレス',
          ref: register({ required: 'メールアドレスを入力してください' }),
        }}
        error={errors.mail_address}
      />
      <div className="field is-grouped is-grouped-centered mb-0 mt-5">
        <div className="control">
          <Button buttonProps={{ type: 'submit' }}>パスワードをリセット</Button>
        </div>
      </div>
      <Backdrop open={loading} style={{ zIndex: 2 }}>
        <CircularProgress color="inherit" style={{ color: '#66bb6a' }} />
      </Backdrop>
      <SnackbarWrapper
        variant={snackbarState.variant}
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
      />
    </form>
  );
};

export default ForgetPasswordForm;
