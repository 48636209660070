import { Row } from 'react-table';
import exportCsv from '../utils/array/exportCsv';
import arrayToCsv from '../utils/array/arrayToCsv';
import { MiddleSchoolExamList } from '../types/table/middle-school-exam-list';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (rows: Row<MiddleSchoolExamList>[]) => {
  const rowArrays = rows.map((row) => {
    const rowValues = Object.entries(row.values).filter((v) => {
      return ![
        'id',
        'icon',
        'student_id',
        'studentBasic',
        'examKey',
        'middleSchoolExam',
        'prevTotal',
      ].includes(v[0]);
    });

    return rowValues.map((rowValue): string => {
      switch (rowValue[0]) {
        case 'libraryCardPoint': {
          return `¥${rowValue[1] as string}`;
        }
        default:
          break;
      }

      if (typeof rowValue[1] === 'number') {
        return String(rowValue[1]);
      }

      return typeof rowValue[1] === 'string' ? rowValue[1] : '';
    });
  });

  return exportCsv(arrayToCsv(rowArrays));
};
