import React, { useState } from 'react';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import {
  SubmitErrorHandler,
  SubmitHandler,
} from 'react-hook-form/dist/types/form';

import { ForgetPasswordData } from '../../types/form/forget-password-data';
import useSnackbar from './useSnackbar';
import resetPasswordFirebaseAuth from '../../domain/services/reset-password-firebase-auth';

export default ({
  handleSubmit,
}: {
  handleSubmit: <TSubmitFieldValues extends FieldValues = ForgetPasswordData>(
    onValid: SubmitHandler<TSubmitFieldValues>,
    onInvalid?: SubmitErrorHandler<ForgetPasswordData>,
  ) => (e?: React.BaseSyntheticEvent) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const { state, open, close } = useSnackbar();

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const { mail_address } = data;
    const resultAuth = await resetPasswordFirebaseAuth(mail_address);

    const variantKey = !resultAuth.message ? 'success' : 'error';
    const message =
      resultAuth.message ||
      'パスワード再設定のためのメールアドレスを送信しました';

    open(variantKey, message);
    setLoading(false);
    setTimeout(() => close(), 3000);
  });

  return {
    onSubmit,
    loading,
    snackbarState: state,
  };
};
