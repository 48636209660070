import React, { FC, useEffect, useState } from 'react';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import DotProgress from '../../../../components/atoms/feedback/DotProgress';
import { MiddleSchoolReportList } from '../../../../../types/table/middle-school-report-list';
import useFetchMiddleSchoolReportLists from '../../../../hooks/fetch/useFetchMiddleSchoolReportLists';
import MiddleSchoolReportListComponent from '../../../../components/molecules/table/MiddleSchoolReportList';
import {
  MiddleSchoolReportKeys,
  middleSchoolReportLabels,
} from '../../../../../data/form-data';
import useCreateMiddleSchoolReport from '../../../../hooks/create/useCreateMiddleSchoolReport';
import { ScoreReportContext } from '../../../../context/ScoreReportContext';
import { getSubjectScores } from '../../../../../domain/models/middle-school-report';

const MiddleSchoolReportListContainer: FC = () => {
  const {
    loading,
    error,
    middleSchoolReportLists,
    reportKey,
    setReportKey,
  } = useFetchMiddleSchoolReportLists();
  const { create, close, state } = useCreateMiddleSchoolReport();
  const [data, setData] = useState<MiddleSchoolReportList[]>([]);

  useEffect(() => {
    if (middleSchoolReportLists) {
      setData(middleSchoolReportLists);
    }
  }, [middleSchoolReportLists]);

  const updateTableData = (
    rowIndex: number,
    columnId: string,
    score: number,
    id?: string,
  ) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          const newData = {
            ...old[rowIndex],
            [columnId]: score,
            id: id || '',
          };
          const subjectScores = getSubjectScores(newData);

          return {
            ...newData,
            total: Object.values(subjectScores).reduce(
              (prev, current) => prev + current,
              0,
            ),
          };
        }

        return row;
      }),
    );
  };

  if (loading) return <DotProgress />;

  if (error) {
    console.error(error);

    return <p>通知表情報の取得に失敗しました</p>;
  }

  if (!middleSchoolReportLists?.length) return <p>通知表情報が0件です</p>;

  const onChange: SelectInputProps['onChange'] = (event) => {
    const selectedReportKey = event.target.value as MiddleSchoolReportKeys;
    setReportKey(selectedReportKey);
    localStorage.setItem('selectedReportKey', selectedReportKey);
  };

  return (
    <ScoreReportContext.Provider
      value={{
        data: [],
        updateTableData: () => null,
        create,
      }}
    >
      <MiddleSchoolReportListComponent
        data={data}
        updateTableData={updateTableData}
        snackbarState={state}
        snackbarClose={close}
        toolbarSelectProps={{
          label: '',
          value: reportKey,
          options: Object.entries(middleSchoolReportLabels).map(
            ([key, value]) => ({
              label: value,
              value: key,
            }),
          ),
          onChange,
        }}
      />
    </ScoreReportContext.Provider>
  );
};

export default MiddleSchoolReportListContainer;
