import React, { useContext, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useForm } from 'react-hook-form';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { DateTime } from 'luxon';

import _ from 'lodash';
import {
  defaultWorkShift,
  WorkShift,
} from '../../../../../domain/models/work-shift';
import { SelectedStaff } from '../../../../../domain/models/admin-user';
import WorkShiftDetailForm from '../../../../components/molecules/form/WorkShiftDetailForm';
import { EnrolledCramSchoolContext } from '../../../../context/EnrolledCramSchoolContext';
import { WorkShiftSelectedStaffsContext } from '../../../../context/WorkShiftSelectedStaffsContext';
import { AppContext } from '../../../../context/AppContext';
import {
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_SCHOOLS,
  NotesJapanSchool,
  NotesPraiseSchool,
} from '../../../../../data/form-data';
import useRole from '../../../../hooks/useRole';
import useFetchWorkShifts from '../../../../hooks/fetch/useFetchWorkShifts';
import useFetchStaff from '../../../../hooks/fetch/useFetchStaff';
import useFetchStudentBasics from '../../../../hooks/fetch/useFetchStudentBasics';
import useCreateWorkShift from '../../../../hooks/create/useCreateWorkShift';
import useFetchSchoolCalendar from '../../../../hooks/fetch/useFetchSchoolCalendar';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const { isAdministrator, isHeadquarters } = useRole();
  const { adminUser } = useContext(AppContext);
  const selectEnrolledCramSchools =
    isAdministrator || isHeadquarters
      ? [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS]
      : [adminUser.enrolled_cram_school];
  const { enrolledCramSchool, setEnrolledCramSchool } = useContext(
    EnrolledCramSchoolContext,
  );
  const [allWorkShifts, setAllWorkShifts] = useState<WorkShift[]>([]);
  const [filteredWorkShift, setFilteredWorkShift] = useState<WorkShift | null>(
    null,
  );
  const dt = DateTime.fromJSDate(new Date());
  const [date, setDate] = useState<string>(dt.toFormat('yyyy/MM/dd'));
  const [isHoliday, setIsHoliday] = useState(false);

  const [lecture1SelectedStaffs, setLecture1SelectedStaffs] = useState<
    SelectedStaff[]
  >([]);
  const [lecture2SelectedStaffs, setLecture2SelectedStaffs] = useState<
    SelectedStaff[]
  >([]);
  const [lecture3SelectedStaffs, setLecture3SelectedStaffs] = useState<
    SelectedStaff[]
  >([]);
  const [lecture4SelectedStaffs, setLecture4SelectedStaffs] = useState<
    SelectedStaff[]
  >([]);
  const [lecture5SelectedStaffs, setLecture5SelectedStaffs] = useState<
    SelectedStaff[]
  >([]);
  const [
    lectureSpecialSelectedStaffs,
    setLectureSpecialSelectedStaffs,
  ] = useState<SelectedStaff[]>([]);
  const [
    lecture1SelectedStaffsInitialLoading,
    setLecture1SelectedStaffsInitialLoading,
  ] = useState<boolean>(true);
  const [
    lecture2SelectedStaffsInitialLoading,
    setLecture2SelectedStaffsInitialLoading,
  ] = useState<boolean>(true);
  const [
    lecture3SelectedStaffsInitialLoading,
    setLecture3SelectedStaffsInitialLoading,
  ] = useState<boolean>(true);
  const [
    lecture4SelectedStaffsInitialLoading,
    setLecture4SelectedStaffsInitialLoading,
  ] = useState<boolean>(true);
  const [
    lecture5SelectedStaffsInitialLoading,
    setLecture5SelectedStaffsInitialLoading,
  ] = useState<boolean>(true);
  const [
    lectureSpecialSelectedStaffsInitialLoading,
    setLectureSpecialSelectedStaffsInitialLoading,
  ] = useState<boolean>(true);

  useEffectOnce(() => {
    setEnrolledCramSchool(
      isAdministrator || isHeadquarters
        ? [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS][0]
        : adminUser.enrolled_cram_school,
    );
  });

  const { loading, workShifts } = useFetchWorkShifts({});
  const { loading: loading2, staffs } = useFetchStaff({});
  const { loading: loading3, studentBasics } = useFetchStudentBasics({});
  const { loading: loading4, schoolCalendars } = useFetchSchoolCalendar({
    queryParams: [
      {
        fieldPath: 'school_name',
        opStr: '==',
        value: enrolledCramSchool,
      },
    ],
  });

  const { creating, create, close, state } = useCreateWorkShift();
  const formMethods = useForm<WorkShift>({
    defaultValues: filteredWorkShift ?? defaultWorkShift,
  });

  const filterWorkShift = () => {
    setFilteredWorkShift(
      allWorkShifts.find(
        (ws) => ws.school_name === enrolledCramSchool && ws.date === date,
      ) ?? null,
    );
  };

  const onChangeEnrolledCramSchool: SelectInputProps['onChange'] = (event) => {
    const schoolName = event.target.value as
      | NotesJapanSchool
      | NotesPraiseSchool;

    setEnrolledCramSchool(schoolName);
  };

  const onChangeDate = (dateStr: string) => {
    setDate(dateStr);
  };

  const onSubmit = formMethods.handleSubmit(async (data) => {
    // 編集用データの元
    const createValue = {
      ...defaultWorkShift,
      ...data,
      id: filteredWorkShift?.id ?? '',
      date: data.date ? data.date : date,
      school_name: filteredWorkShift?.school_name
        ? filteredWorkShift?.school_name
        : enrolledCramSchool,
      shift: {
        lecture_1: data.shift.lecture_1 ?? [],
        lecture_2: data.shift.lecture_2 ?? [],
        lecture_3: data.shift.lecture_3 ?? [],
        lecture_4: data.shift.lecture_4 ?? [],
        lecture_5: data.shift.lecture_5 ?? [],
        lecture_special: data.shift.lecture_special ?? [],
      },
      works: data.works ?? [],
    };

    // 更新のみ
    if (createValue?.id && createValue?.date && createValue?.school_name) {
      const id = await create(createValue);
      if (id) {
        const newWorkShift = {
          ...createValue,
          id,
        };
        const newWorkShifts = _.cloneDeep(allWorkShifts);
        const targetWorkShiftIndex = newWorkShifts.findIndex(
          (ws) => ws.id === id,
        );
        if (targetWorkShiftIndex >= 0) {
          newWorkShifts.splice(targetWorkShiftIndex, 1, newWorkShift);
          setAllWorkShifts(newWorkShifts);
        }
      }
    }
  });

  useEffect(() => {
    setAllWorkShifts(workShifts);
    filterWorkShift();
    // eslint-disable-next-line
  }, [workShifts]);

  useEffect(() => {
    filterWorkShift();

    // 休校日かどうかの設定
    const targetYear = DateTime.fromJSDate(new Date(date)).toFormat('yyyy');
    const targetSchoolCalendar = schoolCalendars.find(
      (sc) => sc.year === targetYear,
    );
    if (targetSchoolCalendar && targetSchoolCalendar.holidays?.includes(date)) {
      setIsHoliday(true);
    } else {
      setIsHoliday(false);
    }
    // eslint-disable-next-line
  }, [enrolledCramSchool, date, schoolCalendars]);

  return (
    <WorkShiftSelectedStaffsContext.Provider
      value={{
        lecture1SelectedStaffs,
        lecture2SelectedStaffs,
        lecture3SelectedStaffs,
        lecture4SelectedStaffs,
        lecture5SelectedStaffs,
        lectureSpecialSelectedStaffs,
        setLecture1SelectedStaffs,
        setLecture2SelectedStaffs,
        setLecture3SelectedStaffs,
        setLecture4SelectedStaffs,
        setLecture5SelectedStaffs,
        setLectureSpecialSelectedStaffs,
        lecture1SelectedStaffsInitialLoading,
        lecture2SelectedStaffsInitialLoading,
        lecture3SelectedStaffsInitialLoading,
        lecture4SelectedStaffsInitialLoading,
        lecture5SelectedStaffsInitialLoading,
        lectureSpecialSelectedStaffsInitialLoading,
        setLecture1SelectedStaffsInitialLoading,
        setLecture2SelectedStaffsInitialLoading,
        setLecture3SelectedStaffsInitialLoading,
        setLecture4SelectedStaffsInitialLoading,
        setLecture5SelectedStaffsInitialLoading,
        setLectureSpecialSelectedStaffsInitialLoading,
      }}
    >
      <WorkShiftDetailForm
        formMethods={formMethods}
        onSubmit={onSubmit}
        creating={creating}
        snackbarState={state}
        snackbarClose={close}
        loading={loading && loading2 && loading3 && loading4}
        isHoliday={isHoliday}
        workShift={filteredWorkShift}
        staffs={staffs}
        studentBasics={studentBasics}
        selectPropsEnrolledCramSchool={{
          label: '',
          value: enrolledCramSchool,
          options: selectEnrolledCramSchools.map((school) => ({
            label: school,
            value: school,
          })),
          onChange: onChangeEnrolledCramSchool,
        }}
        onChangeDate={onChangeDate}
        date={date}
      />
    </WorkShiftSelectedStaffsContext.Provider>
  );
};
