import React, { FC, PropsWithChildren, useContext, useState } from 'react';
import {
  Cell,
  CellValue,
  ColumnInstance,
  Row,
  TableInstance,
} from 'react-table';
import { useDebounce } from 'react-use';
import { TextField } from '@material-ui/core';
import { InvoiceList } from '../../../../../types/table/invoice-list';
import { InvoiceStatus, WithdrawalBank } from '../../../../../data/form-data';
import { InvoiceContext } from '../../../../context/InvoiceContext';
import useRole from '../../../../hooks/useRole';
import { StudentInvoice } from '../../../../../domain/models/student-invoice';
import DateField from '../../../atoms/form/DatePicker';

const InputWithdrawalDateCell: FC<PropsWithChildren<
  TableInstance<InvoiceList> & {
    cell: Cell<InvoiceList>;
    column: ColumnInstance<InvoiceList>;
    row: Row<InvoiceList>;
    value: CellValue<string>;
    updateTableData: (rowIndex: number, value: InvoiceList) => void;
  }
>> = ({ cell, value: initialValue, updateTableData }) => {
  const { create } = useContext(InvoiceContext);
  const [value, setValue] = useState<string>(initialValue);
  const { isViewer } = useRole();

  useDebounce(
    async () => {
      if (initialValue === value) return;

      const newStudentInvoice: StudentInvoice = {
        id: cell.row.original.id,
        student_id: cell.row.original.student_id,
        status: cell.row.original.invoice_status as InvoiceStatus,
        invoice_month: cell.row.original.invoice_month,
        invoice_items: cell.row.original.invoice_items,
        withdrawal_date: value,
        withdrawal_bank:
          (cell.row.original.withdrawal_bank as WithdrawalBank) || '',
        withdrawal_result: cell.row.original.withdrawal_result,
        consumption_tax: cell.row.original.consumption_tax || 0,
        subtotal: cell.row.original.subtotal || 0,
        total: cell.row.original.total || 0,
        payment_method: cell.row.original.payment_method,
        message: cell.row.original.message,
        remarks: cell.row.original.remarks,
        is_transfer: cell.row.original.is_transfer,
      };

      const newInvoiceList: InvoiceList = {
        id: cell.row.original.id,
        student_id: cell.row.original.student_id,
        student_num: cell.row.original.student_num,
        student_name: cell.row.original.student_name,
        student_status: cell.row.original.student_status,
        grade: cell.row.original.grade,
        enrolled_cram_school: cell.row.original.enrolled_cram_school,
        invoice_status: cell.row.original.invoice_status as InvoiceStatus,
        invoice_month: cell.row.original.invoice_month,
        withdrawal_date: value,
        withdrawal_bank: cell.row.original.withdrawal_bank,
        total: cell.row.original.total,
        withdrawal_result: cell.row.original.withdrawal_result,
        invoice_items: cell.row.original.invoice_items,
        consumption_tax: cell.row.original.consumption_tax,
        subtotal: cell.row.original.subtotal,
        payment_method: cell.row.original.payment_method,
        message: cell.row.original.message,
        remarks: cell.row.original.remarks,
        is_transfer: cell.row.original.is_transfer,
      };

      const studentInvoiceId = create ? await create(newStudentInvoice) : '';
      if (studentInvoiceId) {
        newInvoiceList.id = studentInvoiceId;
      }

      updateTableData(cell.row.index, newInvoiceList);
    },
    500,
    [value],
  );

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    <div onClick={(event) => event.stopPropagation()}>
      <DateField
        DatePickerProps={{
          label: '',
          name: cell.column.id,
          value: value || null,
          format: 'yyyy/MM/dd',
          disabled: isViewer,
          TextFieldComponent: (props) => <TextField {...props} margin="none" />,
          onChange: (v) => {
            const date = v ? v.toFormat('yyyy/MM/dd') : '';
            setValue(date);
          },
        }}
      />
    </div>
  );
};

export default InputWithdrawalDateCell;
