import React from 'react';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  DatePickerProps as PickerProps,
} from '@material-ui/pickers';
import { dateFormat } from '../../../../../data/form-data';
import { ExtendLuxonUtils } from './extend-luxon-utils';

interface Props {
  DatePickerProps: PickerProps;
}

const DateField: React.FC<Props> = ({ DatePickerProps }) => {
  return (
    <MuiPickersUtilsProvider utils={ExtendLuxonUtils} locale="ja">
      <DatePicker
        animateYearScrolling={false}
        leftArrowIcon={<div>←</div>}
        rightArrowIcon={<div>→</div>}
        InputLabelProps={{ shrink: true }}
        margin="normal"
        value={null}
        okLabel="決定"
        cancelLabel="キャンセル"
        clearLabel="削除"
        autoOk
        fullWidth
        clearable
        format={dateFormat}
        {...DatePickerProps}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateField;
