import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import { UseFormMethods } from 'react-hook-form/dist/types';

import useRole from '../../../../hooks/useRole';
import Select from '../../../atoms/form/Select';
import {
  ElementarySchoolSubject,
  HighSchoolSubject,
  MiddleSchoolSubject,
  SpecialSubject,
} from '../../../../../data/form-data';

export type Props = {
  formMethods: UseFormMethods<any>;
  subjects: (
    | ElementarySchoolSubject
    | MiddleSchoolSubject
    | HighSchoolSubject
    | SpecialSubject
  )[];
  subjectName: string;
  defaultValueSubject: string;
  selectPropsClassName?: string;
};

export const SelectSubject = ({
  formMethods,
  subjectName,
  subjects,
  defaultValueSubject,
  selectPropsClassName,
}: Props) => {
  const { isViewer } = useRole();
  const [className, setClassName] = useState('');
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, setValue, trigger } = formMethods;

  useEffect(() => {
    changeClass(defaultValueSubject);
  }, [defaultValueSubject]);

  const changeClass = (value?: string) => {
    // TODO 将来的に振替の場合に色を変えるため、色変えロジックは残しておく
    switch (value) {
      default: {
        setClassName('');
        break;
      }
    }
  };

  return (
    <Controller
      control={control}
      name={subjectName}
      defaultValue={defaultValueSubject}
      as={
        <Select
          selectProps={{
            name: subjectName,
            options: subjects.map((v) => ({
              label: v,
              value: v,
            })),
            defaultValue: {
              label: defaultValueSubject,
              value: defaultValueSubject,
            },
            onChange: async (value) => {
              setValue(
                subjectName,
                value && 'value' in value ? value.value : '',
              );
              await trigger(subjectName);
              if (value && 'value' in value) {
                changeClass(value.value);
              } else {
                changeClass();
              }
            },
            isDisabled: isViewer,
            isClearable: true,
            placeholder: '',
            className: clsx('select-subject', className, selectPropsClassName),
          }}
        />
      }
    />
  );
};
