import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';

import { PAGE_TITLE } from '../../../data/app-basic';
import AdminWrapper from '../../components/layouts/AdminWrapper';
import Container from '../../components/layouts/Container';
import WeekDefaultListContainer from '../../containers/molecules/table/WeekDefaultList';
import { EnrolledCramSchoolContext } from '../../context/EnrolledCramSchoolContext';
import { DayOfWeekContext } from '../../context/DayOfWeekContext';
import {
  JaLectureDay,
  jaLectureDaysArr,
} from '../../../domain/models/student-lectures';

const WeekDefault: FC = () => {
  const [enrolledCramSchool, setEnrolledCramSchool] = useState<string>('');
  const dayOfTodayNum = new Date().getDay();
  const [dayOfWeek, setDayOfWeek] = useState<JaLectureDay>(
    jaLectureDaysArr[dayOfTodayNum],
  );

  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('曜日マスター')}</title>
      </Helmet>
      <Container>
        <EnrolledCramSchoolContext.Provider
          value={{
            enrolledCramSchool,
            setEnrolledCramSchool,
          }}
        >
          <DayOfWeekContext.Provider
            value={{
              dayOfWeek,
              setDayOfWeek,
            }}
          >
            <WeekDefaultListContainer />
          </DayOfWeekContext.Provider>
        </EnrolledCramSchoolContext.Provider>
      </Container>
    </AdminWrapper>
  );
};

export default WeekDefault;
