import _ from 'lodash';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { useAsync } from 'react-use';

import firebase from 'firebase';
import { ChatContext } from '../../context/ChatContext';
import { dateFormat } from '../../../data/form-data';
import onSnapshotTalks from '../../../domain/services/snapshot-talks';

export default () => {
  const { selectedRoom, setDateTalks } = useContext(ChatContext);
  const { loading, error } = useAsync(async () => {
    if (!selectedRoom) return {};

    setDateTalks({});
    await new Promise((resolve) => setTimeout(resolve, 0));

    onSnapshotTalks({
      queryParams: [{ fieldPath: 'room_id', opStr: '==', value: selectedRoom }],
      callback: (talk, changeType: firebase.firestore.DocumentChangeType) => {
        const date = DateTime.fromJSDate(
          talk.date?.toDate() || new Date(),
        ).toFormat(dateFormat);
        setDateTalks((prevState) => {
          const dateTalks = _.cloneDeep(prevState);
          let newDateTalks = {
            ...dateTalks,
            [date]: date in prevState ? prevState[date] : [],
          };

          if (changeType === 'removed') {
            const index = newDateTalks[date].findIndex((v) => v.id === talk.id);
            if (index >= 0) {
              newDateTalks[date].splice(index, 1);
            }
            if (!newDateTalks[date].length) {
              delete newDateTalks[date];
            }
          } else if (changeType === 'modified') {
            const index = newDateTalks[date].findIndex((v) => v.id === talk.id);
            newDateTalks[date][index] = talk;
          } else if (
            !newDateTalks[date].length ||
            newDateTalks[date].every((t) => t.id !== talk.id)
          ) {
            newDateTalks[date].push(talk);
          }

          newDateTalks = Object.keys(newDateTalks)
            .sort((a, b) => {
              return DateTime.fromFormat(a, dateFormat) >
                DateTime.fromFormat(b, dateFormat)
                ? 1
                : -1;
            })
            .reduce((prev, current) => {
              return {
                ...prev,
                [current]: newDateTalks[current].sort((a, b) => {
                  if (!a.date || !b.date) return 0;

                  return a.date.toDate() > b.date.toDate() ? 1 : -1;
                }),
              };
            }, {});

          return newDateTalks;
        });
      },
    });

    return undefined;
  }, [selectedRoom]);

  return { loading, error };
};
