import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import AdminWrapper from '../../components/layouts/AdminWrapper';
import Container from '../../components/layouts/Container';
import StaffListContainer from '../../containers/molecules/table/StaffList';
import { PAGE_TITLE } from '../../../data/app-basic';
import { EnrolledCramSchoolContext } from '../../context/EnrolledCramSchoolContext';

export default () => {
  const [enrolledCramSchool, setEnrolledCramSchool] = useState<string>('');

  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('社員情報一覧')}</title>
      </Helmet>
      <Container>
        <EnrolledCramSchoolContext.Provider
          value={{
            enrolledCramSchool,
            setEnrolledCramSchool,
          }}
        >
          <StaffListContainer />
        </EnrolledCramSchoolContext.Provider>
      </Container>
    </AdminWrapper>
  );
};
