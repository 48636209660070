import { createContext } from 'react';
import { AdminUser, defaultAdminUser } from '../../domain/models/admin-user';

export type AppContextType = {
  adminUser: AdminUser;
  setAdminUser: (value: AdminUser) => void;
};

export const AppContext = createContext<AppContextType>({
  adminUser: defaultAdminUser,
  setAdminUser: () => '',
});
