import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  defaultPeriodicInfo,
  PeriodicInfo,
} from '../../../domain/models/periodic-info';
import getPeriodicInfos from '../../../domain/services/get-periodic-info';
import { QueryParams } from '../../../types/firestore-simple';
import { EnrolledCramSchoolContext } from '../../context/EnrolledCramSchoolContext';

export type Result = {
  loading: boolean;
  periodicInfos: PeriodicInfo[];
  setPeriodicInfos: Dispatch<SetStateAction<PeriodicInfo[]>>;
};

const useFetchPeriodicInfo = ({
  queryParams,
}: {
  queryParams?: QueryParams<PeriodicInfo>;
}): Result => {
  const [loading, setLoading] = useState(false);
  const [periodicInfos, setPeriodicInfos] = useState<PeriodicInfo[]>([]);
  const { enrolledCramSchool } = useContext(EnrolledCramSchoolContext);

  useEffect(() => {
    void (async () => {
      setLoading(true);

      const result = await getPeriodicInfos({ queryParams });
      if (result.periodicInfos.length) {
        setPeriodicInfos(result.periodicInfos);
      } else {
        setPeriodicInfos([defaultPeriodicInfo]);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [enrolledCramSchool]);

  return { loading, periodicInfos, setPeriodicInfos };
};

export default useFetchPeriodicInfo;
