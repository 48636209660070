import React, { FC, PropsWithChildren, useContext } from 'react';
import {
  Cell,
  CellValue,
  ColumnInstance,
  Row,
  TableInstance,
} from 'react-table';
import { useDebounce } from 'react-use';

import { MiddleSchoolReportList } from '../../../../../types/table/middle-school-report-list';
import { MiddleSchoolReport } from '../../../../../domain/models/middle-school-report';
import { defaultMiddleSchoolReportValues } from '../../../../../data/form-data';
import { ScoreReportContext } from '../../../../context/ScoreReportContext';
import useRole from '../../../../hooks/useRole';

const MAX = 5;
const MIN = 0;

const getNewValue = (value: string | number) => {
  let newValue = Number(value);
  if (Number.isNaN(newValue)) {
    return MIN;
  }

  if (newValue > MAX) {
    newValue = MAX;
  } else if (newValue < MIN) {
    newValue = MIN;
  }

  return newValue;
};

const getNewTableData = (
  cell: Cell<MiddleSchoolReportList>,
  value: number,
): MiddleSchoolReportList => {
  let newTable: MiddleSchoolReportList = cell.row
    .values as MiddleSchoolReportList;
  switch (cell.column.id) {
    case 'japanese': {
      newTable = { ...newTable, japanese: value };
      break;
    }
    case 'math': {
      newTable = { ...newTable, math: value };
      break;
    }
    case 'english': {
      newTable = { ...newTable, english: value };
      break;
    }
    case 'science': {
      newTable = { ...newTable, science: value };
      break;
    }
    case 'society': {
      newTable = { ...newTable, society: value };
      break;
    }
    case 'music': {
      newTable = { ...newTable, music: value };
      break;
    }
    case 'art': {
      newTable = { ...newTable, art: value };
      break;
    }
    case 'health_physical': {
      newTable = { ...newTable, health_physical: value };
      break;
    }
    case 'tech_home': {
      newTable = { ...newTable, tech_home: value };
      break;
    }
    default: {
      break;
    }
  }

  return newTable;
};

const getNewReportData = (newTableData: MiddleSchoolReportList) => {
  const newReportData: MiddleSchoolReport = {
    ...defaultMiddleSchoolReportValues,
    ...newTableData.middleSchoolReport,
    id: newTableData.id,
    student_id: newTableData.student_id,
    [newTableData.reportKey]: {
      japanese: newTableData.japanese,
      math: newTableData.math,
      english: newTableData.english,
      science: newTableData.science,
      society: newTableData.society,
      music: newTableData.music,
      art: newTableData.art,
      health_physical: newTableData.health_physical,
      tech_home: newTableData.tech_home,
    },
  };

  return newReportData;
};

const EditableCell: FC<PropsWithChildren<
  TableInstance<MiddleSchoolReportList> & {
    cell: Cell<MiddleSchoolReportList, number>;
    column: ColumnInstance<MiddleSchoolReportList>;
    row: Row<MiddleSchoolReportList>;
    value: CellValue<number>;
    updateTableData: (
      rowIndex: number,
      columnId: string,
      score: number,
      id?: string,
    ) => void;
  }
>> = ({
  cell,
  value: initialValue,
  row: { index },
  column: { id },
  updateTableData,
}) => {
  const { create } = useContext(ScoreReportContext);
  const [value, setValue] = React.useState(initialValue);
  const { isViewer } = useRole();

  useDebounce(
    async () => {
      if (initialValue === value) return;

      const newTableData = getNewTableData(cell, value);
      const newReportData = getNewReportData(newTableData);
      const reportId = create ? await create(newReportData) : '';
      updateTableData(index, id, value, reportId);
    },
    500,
    [value],
  );

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      type="number"
      className="input"
      onChange={(event) => {
        const newValue = getNewValue(event.target.value);
        setValue(newValue);
      }}
      onClick={(event) => event.stopPropagation()}
      value={value}
      max={MAX}
      min={MIN}
      disabled={isViewer}
    />
  );
};

export default EditableCell;
