import React, { FC, useEffect, useState } from 'react';
import { Column } from 'react-table';

import TaskDocumentRequestListComponent from '../../../../components/molecules/table/TaskDocumentRequestListComponent';
import useFetchTaskDocumentRequestLists from '../../../../hooks/fetch/useFetchTaskDocumentRequestLists';
import useCreateTaskDocumentRequest from '../../../../hooks/create/useCreateTaskDocumentRequest';
import { TaskDocumentRequestList } from '../../../../../types/table/task-document-request-list';
import SelectColumnFilter from '../../../../components/molecules/table/SelectColumnFilter';

const columns: Column<TaskDocumentRequestList>[] = [
  {
    Header: '',
    accessor: 'icon',
    minWidth: 60,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: '',
    accessor: 'id',
  },
  {
    Header: '',
    accessor: 'student_id',
  },
  {
    Header: '生徒名',
    accessor: 'student_name',
  },
  {
    Header: '在籍校舎',
    accessor: 'enrolled_cram_school',
    filter: 'includeSelectValue',
    Filter: SelectColumnFilter,
  },
  {
    Header: '資料発送済み',
    accessor: 'send_document',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: '資料発送後、電話を掛けた',
    accessor: 'call_after_send_document',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'タスク完了期限',
    accessor: 'deadline',
    disableSortBy: true,
    disableFilters: true,
  },
];

const TaskDocumentRequestListContainer: FC = () => {
  const {
    loading,
    documentRequestTaskList,
  } = useFetchTaskDocumentRequestLists();
  const { creating, create, state, close } = useCreateTaskDocumentRequest();
  const [data, setData] = useState<TaskDocumentRequestList[]>([]);

  useEffect(() => {
    setData(documentRequestTaskList);
  }, [documentRequestTaskList]);

  const updateTableData = (
    rowIndex: number,
    value: TaskDocumentRequestList,
  ) => {
    setData((old) => {
      return old.map((row, index) => {
        if (rowIndex === index) {
          return {
            ...old[rowIndex],
            ...value,
          };
        }

        return row;
      });
    });
  };

  return (
    <TaskDocumentRequestListComponent
      loading={loading}
      data={data}
      updateTableData={updateTableData}
      columns={columns}
      create={create}
      creating={creating}
      snackbarState={state}
      snackbarClose={close}
    />
  );
};

export default TaskDocumentRequestListContainer;
