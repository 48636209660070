import { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { StudentBasic } from '../../../domain/models/student-basic';
import { StudentList } from '../../../types/table/student-list';
import getStudentBasic from '../../../domain/services/get-student-basics';
import { AppContext } from '../../context/AppContext';

export type Result = {
  loading: boolean;
  studentList: StudentList[];
};

const useFetchStudentLists = (): Result => {
  const { adminUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [, setStudentBasic] = useState<StudentBasic[]>([]);
  const [studentList, setStudentList] = useState<StudentList[]>([]);
  const queryParams = ['editor', 'viewer'].includes(adminUser.role)
    ? [
        {
          fieldPath: 'enrolled_cram_school',
          opStr: '==',
          value: adminUser.enrolled_cram_school,
        },
      ]
    : undefined;

  useEffectOnce(() => {
    void (async () => {
      setLoading(true);

      const { studentBasics } = await getStudentBasic({ queryParams });
      setStudentBasic(studentBasics);

      setStudentList(
        studentBasics.map(
          (studentBasic): StudentList => {
            return {
              studentId: studentBasic.id,
              student_num: studentBasic.student_num,
              name: `${studentBasic.last_name} ${studentBasic.first_name}`,
              status: studentBasic.status,
              grade: studentBasic.grade,
              enrolled_cram_school: studentBasic.enrolled_cram_school,
              school_name: studentBasic.school_name,
            };
          },
        ),
      );

      setLoading(false);
    })();
  });

  return { loading, studentList };
};

export default useFetchStudentLists;
