import firestoreSimple from '../../lib/firebase-firestore';
import { StudentLectures } from '../models/student-lectures';

export type Result = {
  id?: string;
};

const createStudentLectures = async (
  studentLectures: StudentLectures,
): Promise<Result> => {
  const collection = firestoreSimple.collection<StudentLectures>({
    path: 'student_lectures',
  });

  const docId = studentLectures.id
    ? await collection.set(studentLectures).catch((error) => {
        console.error(error);
      })
    : await collection
        .add(studentLectures)
        .catch((error) => console.error(error));

  return { id: docId || undefined };
};

export default createStudentLectures;
