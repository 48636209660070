import { Row } from 'react-table';
import { ParentList } from '../types/table/parent-list';
import { TelInfo } from '../domain/models/parent-info';
import { StudentBasic } from '../domain/models/student-basic';
import exportCsv from '../utils/array/exportCsv';
import arrayToCsv from '../utils/array/arrayToCsv';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (rows: Row<ParentList>[]) => {
  const rowArrays = rows.map((row) => {
    const rowValues = Object.entries(row.values).filter((v) => {
      return !['id'].includes(v[0]);
    });

    return rowValues.map((rowValue): string => {
      switch (rowValue[0]) {
        case 'students': {
          const studentBasics = rowValue[1] as StudentBasic[];

          return studentBasics
            .map(
              (value) =>
                `【${value.student_num}】${value.last_name} ${value.first_name}`,
            )
            .join('、');
        }
        case 'tel': {
          const value = rowValue[1] as TelInfo;

          return `${value.num}(${value.relationship})`;
        }
        case 'emergency_contacts': {
          const value = rowValue[1] as TelInfo[];

          return value
            .map((telInfo) => `${telInfo.num}(${telInfo.relationship})`)
            .join('、');
        }
        default:
          break;
      }

      if (typeof rowValue[1] === 'number') {
        return String(rowValue[1]);
      }

      return typeof rowValue[1] === 'string' ? rowValue[1] : '';
    });
  });

  return exportCsv(arrayToCsv(rowArrays));
};
