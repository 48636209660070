import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { nanoid } from 'nanoid';
import { extension } from 'mime-types';
import firebase from 'firebase';

import { ChatContext } from '../../../../context/ChatContext';
import { AppContext } from '../../../../context/AppContext';
import { storage } from '../../../../../lib/firebase';
import { Talk } from '../../../../../domain/models/talk';
import useCreateTalk from '../../../../hooks/create/useCreateTalk';
import useCreateRoom from '../../../../hooks/create/useCreateRoom';
import BatchSendingComponent from '../../../../components/pages/chat/BatchSending';

interface Props {
  anchorEl: HTMLButtonElement | HTMLAnchorElement | null;
  setAnchorEl: Dispatch<
    SetStateAction<HTMLButtonElement | HTMLAnchorElement | null>
  >;
}

const BatchSending: FC<Props> = ({ anchorEl, setAnchorEl }) => {
  const { adminUser } = useContext(AppContext);
  const { selectedRoom, filteredRooms } = useContext(ChatContext);
  const [message, setMessage] = useState('');
  const talkInstance = useCreateTalk();
  const roomInstance = useCreateRoom();

  const onClick = async () => {
    const now = firebase.firestore.Timestamp.now();
    const talk: Talk = {
      id: '',
      user_id: adminUser.id,
      message,
      date: now,
      storage_path: '',
      room_id: selectedRoom || '',
    };

    await Promise.all(
      filteredRooms.map((room) => {
        const updatedRoom = {
          ...room,
          update_date: now,
        };
        talkInstance.create({
          ...talk,
          room_id: room.id,
        });
        roomInstance.create(updatedRoom);

        return room.id;
      }),
    );

    setMessage('');
  };

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    const reader = new FileReader();
    if (!file) return;

    reader.readAsDataURL(file);
    reader.onload = async () => {
      const ext = extension(file.type) || '';
      if (!ext) return;

      const now = firebase.firestore.Timestamp.now();
      const talk: Talk = {
        id: '',
        user_id: adminUser.id,
        message: '',
        date: now,
        storage_path: '',
        room_id: selectedRoom || '',
      };
      await Promise.all(
        filteredRooms.map(async (room) => {
          const updatedRoom = {
            ...room,
            update_date: now,
          };
          const roomPath = `/chat/batch`;
          const fileName = `${nanoid(12)}.${ext}`;
          talk.storage_path = `${roomPath}/${fileName}`;
          const storageRef = storage.ref(roomPath);
          const fileRef = storageRef.child(fileName);
          await fileRef.put(file);
          talkInstance.create({
            ...talk,
            room_id: room.id,
          });
          roomInstance.create(updatedRoom);

          return fileName;
        }),
      );
    };
  };

  return (
    <BatchSendingComponent
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      message={message}
      setMessage={setMessage}
      onClick={onClick}
      onChange={onChangeFile}
    />
  );
};

export default BatchSending;
