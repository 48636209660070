import { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { ParentList } from '../../../types/table/parent-list';
import { ParentInfo } from '../../../domain/models/parent-info';
import { StudentBasic } from '../../../domain/models/student-basic';
import getParents from '../../../domain/services/get-parent-info';
import getStudentBasic from '../../../domain/services/get-student-basics';
import { AppContext } from '../../context/AppContext';
import useRole from '../useRole';

export type Result = {
  loading: boolean;
  parentList: ParentList[];
};

const useFetchParents = (): Result => {
  const [loading, setLoading] = useState(false);
  const [, setParents] = useState<ParentInfo[]>([]);
  const [, setStudents] = useState<StudentBasic[]>([]);
  const [parentList, setParentList] = useState<ParentList[]>([]);
  const { adminUser } = useContext(AppContext);
  const { isAdministrator, isHeadquarters } = useRole();

  useEffectOnce(() => {
    void (async () => {
      setLoading(true);

      const { parents } = await getParents({});
      setParents(parents);

      const { studentBasics } = await getStudentBasic({});
      setStudents(studentBasics);

      setParentList(
        parents
          .filter((parent) => {
            if (isAdministrator || isHeadquarters) return true;

            const children: StudentBasic[] = studentBasics.filter(
              (student) => student.parent_id === parent.id,
            );

            return children.some(
              (basic) =>
                basic.enrolled_cram_school === adminUser.enrolled_cram_school,
            );
          })
          .map((parent) => {
            const children: StudentBasic[] = studentBasics.filter(
              (student) => student.parent_id === parent.id,
            );

            return {
              name: parent.name,
              relationship: parent.relationship,
              mail_address: parent.mail_address,
              tel: parent.tel_info,
              emergency_contacts: parent.emergency_contacts,
              students: children,
            };
          }),
      );

      setLoading(false);
    })();
  });

  return { loading, parentList };
};

export default useFetchParents;
