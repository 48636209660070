import firestoreSimple from '../../lib/firebase-firestore';
import { StudentInvoice } from '../models/student-invoice';

export type Result = {
  id?: string;
};

const createStudentInvoice = async (
  studentInvoice: StudentInvoice,
): Promise<Result> => {
  const collection = firestoreSimple.collection<StudentInvoice>({
    path: 'student_invoice',
  });

  const docId = studentInvoice.id
    ? await collection.set(studentInvoice).catch((error) => {
        console.error(error);
      })
    : await collection
        .add(studentInvoice)
        .catch((error) => console.error(error));

  return { id: docId || undefined };
};

export default createStudentInvoice;
