import { useAsync } from 'react-use';
import { useEffect, useState } from 'react';

import getMiddleSchoolExam from '../../../domain/services/get-middle-school-exam';
import { StudentBasic } from '../../../domain/models/student-basic';
import { MiddleSchoolExamsList } from '../../../types/table/middle-school-exams-list';
import {
  getIcon,
  getSubjectScores,
  MiddleSchoolExamScores,
} from '../../../domain/models/middle-school-exam';
import { defaultMiddleSchoolExamValues } from '../../../data/form-data';

export type Result = {
  loading: boolean;
  middleSchoolExamsLists: MiddleSchoolExamsList[];
  error: Error | undefined;
  getLibraryCardIcon: (
    rowIndex: number,
    rowValues: MiddleSchoolExamsList,
  ) => JSX.Element | null;
  updateTableData: (
    rowIndex: number,
    columnId: string,
    score: number,
    id?: string,
  ) => void;
};

const useFetchMiddleSchoolExamsLists = (studentBasic: StudentBasic): Result => {
  const [data, setData] = useState<MiddleSchoolExamsList[]>([]);
  const { loading, error, value } = useAsync(async () => {
    const { middleSchoolExam } = await getMiddleSchoolExam({
      queryParams: [
        { fieldPath: 'student_id', opStr: '==', value: studentBasic.id },
      ],
    });
    const exam = middleSchoolExam[0] || { ...defaultMiddleSchoolExamValues };
    const defaultExam = { icon: null, id: exam.id };
    const getTotal = (middleSchoolExamScores: MiddleSchoolExamScores) =>
      Object.values(middleSchoolExamScores).reduce(
        (prev, current) => prev + current,
        0,
      );
    const middleSchoolExamsList: MiddleSchoolExamsList[] = [
      {
        ...defaultExam,
        ...exam['1st_grader_exam_1'],
        examKey: '1st_grader_exam_1',
        total: getTotal(exam['1st_grader_exam_1']),
      },
      {
        ...defaultExam,
        ...exam['1st_grader_exam_2'],
        examKey: '1st_grader_exam_2',
        total: getTotal(exam['1st_grader_exam_2']),
      },
      {
        ...defaultExam,
        ...exam['1st_grader_exam_3'],
        examKey: '1st_grader_exam_3',
        total: getTotal(exam['1st_grader_exam_3']),
      },
      {
        ...defaultExam,
        ...exam['1st_grader_exam_4'],
        examKey: '1st_grader_exam_4',
        total: getTotal(exam['1st_grader_exam_4']),
      },
      {
        ...defaultExam,
        ...exam['2nd_grader_exam_1'],
        examKey: '2nd_grader_exam_1',
        total: getTotal(exam['2nd_grader_exam_1']),
      },
      {
        ...defaultExam,
        ...exam['2nd_grader_exam_2'],
        examKey: '2nd_grader_exam_2',
        total: getTotal(exam['2nd_grader_exam_2']),
      },
      {
        ...defaultExam,
        ...exam['2nd_grader_exam_3'],
        examKey: '2nd_grader_exam_3',
        total: getTotal(exam['2nd_grader_exam_3']),
      },
      {
        ...defaultExam,
        ...exam['2nd_grader_exam_4'],
        examKey: '2nd_grader_exam_4',
        total: getTotal(exam['2nd_grader_exam_4']),
      },
      {
        ...defaultExam,
        ...exam['3rd_grader_exam_1'],
        examKey: '3rd_grader_exam_1',
        total: getTotal(exam['3rd_grader_exam_1']),
      },
      {
        ...defaultExam,
        ...exam['3rd_grader_exam_2'],
        examKey: '3rd_grader_exam_2',
        total: getTotal(exam['3rd_grader_exam_2']),
      },
      {
        ...defaultExam,
        ...exam['3rd_grader_exam_3'],
        examKey: '3rd_grader_exam_3',
        total: getTotal(exam['3rd_grader_exam_3']),
      },
      {
        ...defaultExam,
        ...exam['3rd_grader_exam_4'],
        examKey: '3rd_grader_exam_4',
        total: getTotal(exam['3rd_grader_exam_4']),
      },
    ];

    return middleSchoolExamsList;
  }, []);

  useEffect(() => {
    if (value) setData(value);
  }, [value]);

  const updateTableData = (
    rowIndex: number,
    columnId: string,
    score: number,
    id?: string,
  ) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          const newData = {
            ...old[rowIndex],
            [columnId]: score,
            id: id || '',
          };
          const subjectScores = getSubjectScores(newData);

          return {
            ...newData,
            total: Object.values(subjectScores).reduce(
              (prev, current) => prev + current,
              0,
            ),
          };
        }

        return row;
      }),
    );
  };

  const getLibraryCardIcon = (
    rowIndex: number,
    rowValues: MiddleSchoolExamsList,
  ) => {
    if (!value) return null;

    const currentScores = getSubjectScores({
      japanese: rowValues.japanese,
      math: rowValues.math,
      english: rowValues.english,
      society: rowValues.society,
      science: rowValues.science,
    });

    const prevScores =
      data.length && data[rowIndex] && rowIndex - 1 >= 0
        ? getSubjectScores(data[rowIndex - 1])
        : undefined;

    return getIcon(currentScores, prevScores);
  };

  return {
    loading,
    error,
    middleSchoolExamsLists: data,
    getLibraryCardIcon,
    updateTableData,
  };
};

export default useFetchMiddleSchoolExamsLists;
