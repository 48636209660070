import React, { ReactElement } from 'react';
import { GrDocumentText, GrPhone, FaPhone } from 'react-icons/all';
import { TaskDocumentRequestList } from '../../types/table/task-document-request-list';
import { TaskTrialList } from '../../types/table/task-trial-list';

export default (tasks: TaskDocumentRequestList | TaskTrialList) => {
  let icon: ReactElement | null = null;

  if ('send_document' in tasks) {
    if (!tasks.call_after_send_document.done) icon = <GrPhone />;
    if (!tasks.send_document.done) icon = <GrDocumentText />;
  }

  if ('input_invoice_data' in tasks) {
    if (!tasks.reserve_interview.done) icon = <FaPhone />;
  }

  return icon;
};
