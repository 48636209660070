import { useState } from 'react';

import createTalk from '../../../domain/services/create-talk';
import useSnackbar, { SnackbarState } from '../useSnackbar';
import { Talk } from '../../../domain/models/talk';

export type Result = {
  creating: boolean;
  create: (talk: Talk) => void;
  state: SnackbarState;
  close: () => void;
};

const useCreateTalk = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (talk: Talk) => {
    setCreating(true);
    try {
      const result = await createTalk(talk);
      if (!result.id) throw new Error();

      open('success', 'トークの作成に成功しました');
    } catch (e) {
      console.error(e);
      open('error', 'トークの作成ができませんでした。もう一度お試しください。');
    } finally {
      setCreating(false);
    }
  };

  return { creating, create, close, state };
};

export default useCreateTalk;
