import { createContext, Dispatch, SetStateAction } from 'react';

export type YearContextType = {
  year: string;
  setYear: Dispatch<SetStateAction<string>>;
};

export const YearContext = createContext<YearContextType>({
  year: '',
  setYear: () => null,
});
