import firebase from 'firebase';
import { firebaseAuth } from '../../lib/firebase';

export type ResetPasswordResponse = {
  message: string;
};

const resetPasswordFirebaseAuth = async (
  email: string,
): Promise<ResetPasswordResponse> => {
  let message = '';
  await firebaseAuth
    .sendPasswordResetEmail(email)
    .catch((error: firebase.auth.AuthError) => {
      console.error(error);
      switch (error.code) {
        case 'auth/invalid-email': {
          message = 'メールアドレスが有効ではありません';
          break;
        }
        case 'auth/user-not-found': {
          message = '該当のメールアドレスに対応するユーザーが存在しません';
          break;
        }
        default: {
          message = 'ログインに失敗しました。';
          break;
        }
      }
    });

  return { message };
};

export default resetPasswordFirebaseAuth;
