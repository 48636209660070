import { Column } from 'react-table';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { WeekDefaultList } from '../../../../../types/table/week-default-list';
import SelectColumnFilter from '../../../../components/molecules/table/SelectColumnFilter';
import WeekDefaultListComponent from '../../../../components/molecules/table/WeekDefaultList';
import useFetchWeekDefaultLists from '../../../../hooks/fetch/useFetchWeekDefaultLists';
import DotProgress from '../../../../components/atoms/feedback/DotProgress';
import useCreateWeekDefault from '../../../../hooks/create/useCreateWeekDefault';
import { WeekDefaultContext } from '../../../../context/WeekDefaultContext';
import SelectTeacherCell from '../../../../components/molecules/table/WeekDefaultList/SelectTeacherCell';
import { EnrolledCramSchoolContext } from '../../../../context/EnrolledCramSchoolContext';
import {
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_SCHOOLS,
  NotesJapanSchool,
  NotesPraiseSchool,
} from '../../../../../data/form-data';
import useRole from '../../../../hooks/useRole';
import { AppContext } from '../../../../context/AppContext';
import { DayOfWeekContext } from '../../../../context/DayOfWeekContext';
import {
  JaLectureDay,
  jaLectureDaysArr,
} from '../../../../../domain/models/student-lectures';

const WeekDefaultListContainer: FC = () => {
  const {
    loading,
    weekDefaultList,
    setWeekDefaultList,
    studentBasics,
    studentLectures,
    staffs,
  } = useFetchWeekDefaultLists();
  const { creating, create, state, close } = useCreateWeekDefault();
  const [data, setData] = useState<WeekDefaultList[]>([]);
  const [bulkChanged, setBulkChanged] = useState(false);
  const [isNotesJapan, setIsNotesJapan] = useState(false);
  const { isAdministrator, isHeadquarters } = useRole();
  const { adminUser } = useContext(AppContext);
  const selectEnrolledCramSchools =
    isAdministrator || isHeadquarters
      ? [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS]
      : [adminUser.enrolled_cram_school];
  const { enrolledCramSchool, setEnrolledCramSchool } = useContext(
    EnrolledCramSchoolContext,
  );
  const { dayOfWeek, setDayOfWeek } = useContext(DayOfWeekContext);
  const dayOfWeekNum = new Date().getDay();

  useEffectOnce(() => {
    setEnrolledCramSchool(
      isAdministrator || isHeadquarters
        ? [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS][0]
        : adminUser.enrolled_cram_school,
    );

    setDayOfWeek(jaLectureDaysArr[dayOfWeekNum]);
  });

  useEffect(() => {
    if (bulkChanged) {
      setBulkChanged(false);
    }
  }, [bulkChanged]);

  useEffect(() => {
    // 曜日によってフィルタリング
    const newWeekDefaultList = weekDefaultList.filter(
      (list) => list.day_of_week === dayOfWeek,
    );
    setData(newWeekDefaultList);
  }, [weekDefaultList, dayOfWeek]);

  useEffect(() => {
    setIsNotesJapan(NOTES_JAPAN_SCHOOLS.some((v) => v === enrolledCramSchool));
  }, [setIsNotesJapan, enrolledCramSchool]);

  const updateTableData = (rowIndex: number, value: WeekDefaultList) => {
    setData((old) => {
      return old.map((row, index) => {
        if (rowIndex === index) {
          return {
            ...old[rowIndex],
            ...value,
          };
        }

        return row;
      });
    });
  };

  const onChange: SelectInputProps['onChange'] = (event) => {
    setEnrolledCramSchool(
      event.target.value as NotesJapanSchool | NotesPraiseSchool,
    );
  };
  const onChangeDayOfWeek: SelectInputProps['onChange'] = (event) => {
    setDayOfWeek(event.target.value as JaLectureDay);
  };

  const columns: Column<WeekDefaultList>[] = useMemo(() => {
    let lecture1Header;
    let lecture2Header;
    let lecture3Header;
    let lecture4Header;
    let lecture5Header;

    if (isNotesJapan) {
      lecture1Header = 'N1';
      lecture2Header = 'N2';
      lecture3Header = 'N3';
      lecture4Header = 'N4';
      lecture5Header = 'N5';
    } else if (['日', '土'].includes(dayOfWeek)) {
      lecture1Header = 'P1';
      lecture2Header = 'P2';
      lecture3Header = 'P3';
      lecture4Header = 'P4';
      lecture5Header = 'P5';
    } else {
      lecture1Header = 'P4';
      lecture2Header = 'P5';
      lecture3Header = 'P6';
      lecture4Header = 'P7';
      lecture5Header = 'P8';
    }

    return [
      {
        Header: '',
        accessor: 'student_id',
        disableFilters: true,
      },
      {
        Header: '生徒番号',
        accessor: 'student_num',
        filter: 'includesStringFilter',
      },
      {
        Header: '生徒名',
        accessor: 'student_name',
        filter: 'includesStringFilter',
        disableSortBy: true,
      },
      {
        Header: '学年',
        accessor: 'grade',
        filter: 'includeSelectValue',
        Filter: SelectColumnFilter,
        disableSortBy: true,
      },
      {
        Header: lecture1Header,
        accessor: 'lecture_1',
        disableSortBy: true,
        Cell: SelectTeacherCell,
        disableFilters: true,
      },
      {
        Header: lecture2Header,
        accessor: 'lecture_2',
        disableSortBy: true,
        Cell: SelectTeacherCell,
        disableFilters: true,
      },
      {
        Header: lecture3Header,
        accessor: 'lecture_3',
        disableSortBy: true,
        Cell: SelectTeacherCell,
        disableFilters: true,
      },
      {
        Header: lecture4Header,
        accessor: 'lecture_4',
        disableSortBy: true,
        Cell: SelectTeacherCell,
        disableFilters: true,
      },
      {
        Header: lecture5Header,
        accessor: 'lecture_5',
        disableSortBy: true,
        Cell: SelectTeacherCell,
        disableFilters: true,
      },
    ];
  }, [isNotesJapan, dayOfWeek]);

  if (loading) return <DotProgress />;

  return (
    <WeekDefaultContext.Provider
      value={{
        data: [],
        updateTableData: () => null,
        create,
        creating,
        setBulkChanged,
        weekDefaultList,
        setWeekDefaultList,
        studentBasics,
        studentLectures,
        staffs,
      }}
    >
      <WeekDefaultListComponent
        loading={loading}
        data={data}
        updateTableData={updateTableData}
        columns={columns}
        snackbarState={state}
        snackbarClose={close}
        toolbarSelectProps={{
          label: '',
          value: enrolledCramSchool,
          options: selectEnrolledCramSchools.map((school) => ({
            label: school,
            value: school,
          })),
          onChange,
        }}
        toolbarSelectProps2={{
          label: '',
          value: dayOfWeek,
          options: jaLectureDaysArr.map((jaLectureDay) => ({
            label: jaLectureDay,
            value: jaLectureDay,
          })),
          onChange: onChangeDayOfWeek,
        }}
      />
    </WeekDefaultContext.Provider>
  );
};

export default WeekDefaultListContainer;
