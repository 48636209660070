import { StudentInvoice } from '../models/student-invoice';
import firestoreSimple from '../../lib/firebase-firestore';

const bulkCreateStudentInvoice = async (
  studentInvoices: StudentInvoice[],
): Promise<boolean> => {
  try {
    const collection = firestoreSimple.collection<StudentInvoice>({
      path: 'student_invoice',
    });

    const addData = studentInvoices.filter((studentInvoice) => {
      return !studentInvoice.id;
    });

    await collection.bulkAdd(addData).catch((error) => console.error(error));

    const setData = studentInvoices.filter((studentInvoice) => {
      return studentInvoice.id;
    });

    await collection.bulkSet(setData).catch((error) => console.error(error));

    return true;
  } catch (e) {
    console.error(e);

    return false;
  }
};

export default bulkCreateStudentInvoice;
