import React from 'react';
import { useLocation } from 'react-router-dom';

import { ScoreExamContext } from '../../../../../context/ScoreExamContext';
import { StudentBasic } from '../../../../../../domain/models/student-basic';
import useFetchMiddleSchoolExamsLists from '../../../../../hooks/fetch/useFetchMiddleSchoolExamsLists';
import DotProgress from '../../../../../components/atoms/feedback/DotProgress';
import MiddleSchoolExamsListComponent from '../../../../../components/molecules/table/MiddleSchoolExamsList';
import ExamChart from '../../../../../components/pages/score/detail/ExamChart';
import useCreateMiddleSchoolExam from '../../../../../hooks/create/useCreateMiddleSchoolExam';

const ExamTab = () => {
  const {
    state: { studentBasic },
  } = useLocation<{ studentBasic: StudentBasic }>();
  const {
    loading,
    error,
    middleSchoolExamsLists,
    updateTableData,
    getLibraryCardIcon,
  } = useFetchMiddleSchoolExamsLists(studentBasic);
  const { creating, create, close, state } = useCreateMiddleSchoolExam();

  if (loading) return <DotProgress />;

  if (error) {
    console.error(error);

    return <p>成績情報の取得に失敗しました</p>;
  }

  if (!middleSchoolExamsLists?.length) return <p>成績情報が0件です</p>;

  return (
    <ScoreExamContext.Provider
      value={{
        data: middleSchoolExamsLists,
        updateTableData,
        getLibraryCardIcon,
        creating,
        create,
        closeSnackbar: close,
        snackbarState: state,
      }}
    >
      <ExamChart />
      <MiddleSchoolExamsListComponent />
    </ScoreExamContext.Provider>
  );
};

export default ExamTab;
