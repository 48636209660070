import { createContext, Dispatch, SetStateAction } from 'react';

export type YearMonthContextType = {
  yearMonth: string;
  setYearMonth: Dispatch<SetStateAction<string>>;
};

export const YearMonthContext = createContext<YearMonthContextType>({
  yearMonth: '',
  setYearMonth: () => null,
});
