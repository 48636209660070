import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { DateTime } from 'luxon';

import AdminWrapper from '../../components/layouts/AdminWrapper';
import Container from '../../components/layouts/Container';
import SalaryListContainer from '../../containers/molecules/table/SalaryList';
import { PAGE_TITLE } from '../../../data/app-basic';
import { EnrolledCramSchoolContext } from '../../context/EnrolledCramSchoolContext';
import { YearMonthContext } from '../../context/YearMonthContext';

export default () => {
  const [enrolledCramSchool, setEnrolledCramSchool] = useState<string>('');
  const [yearMonth, setYearMonth] = useState<string>(
    DateTime.fromJSDate(new Date()).toFormat('yyyy/MM'),
  );

  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('給与データ一覧')}</title>
      </Helmet>
      <Container>
        <EnrolledCramSchoolContext.Provider
          value={{
            enrolledCramSchool,
            setEnrolledCramSchool,
          }}
        >
          <YearMonthContext.Provider
            value={{
              yearMonth,
              setYearMonth,
            }}
          >
            <SalaryListContainer />
          </YearMonthContext.Provider>
        </EnrolledCramSchoolContext.Provider>
      </Container>
    </AdminWrapper>
  );
};
