import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';
import clsx from 'clsx';

import ErrorMessage from '../ErrorMessage';

export type Props = {
  label?: string;
  inputProps: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  error?: FieldError;
  isAsterisk?: boolean;
  className?: string;
};

const Input: FC<Props> = ({
  label,
  inputProps,
  error,
  isAsterisk,
  className,
}) => {
  const { name } = inputProps;

  return (
    <div className={clsx('field', className)}>
      {label && (
        <label className="label" htmlFor={name}>
          {label}
          {isAsterisk && <span className="has-text-danger ml-1">※</span>}
        </label>
      )}
      <p className="control">
        <input
          className={clsx('input', { 'is-danger': error })}
          name={name}
          id={name}
          {...inputProps}
        />
      </p>
      <ErrorMessage message={error?.message} className="mt-2" />
    </div>
  );
};

export default Input;
