import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Dictionary } from 'lodash';

import { PAGE_TITLE } from '../../../data/app-basic';
import Container from '../../components/layouts/Container';
import AdminWrapper from '../../components/layouts/AdminWrapper';
import SidebarContainer from '../../containers/pages/chat/Sidebar';
import RoomContainer from '../../containers/pages/chat/Room';
import { ChatContext } from '../../context/ChatContext';
import './style.scss';
import { Room } from '../../../domain/models/rooms';
import { ParentInfo } from '../../../domain/models/parent-info';
import { StudentBasic } from '../../../domain/models/student-basic';
import { Talk } from '../../../domain/models/talk';
import { AdminUser } from '../../../domain/models/admin-user';

export default () => {
  const [selectedRoom, setSelectedRoom] = useState('');
  const [rooms, setRooms] = useState<Room[]>([]);
  const [filteredRooms, setFilteredRooms] = useState<Room[]>([]);
  const [parents, setParents] = useState<ParentInfo[]>([]);
  const [studentBasics, setStudentBasics] = useState<StudentBasic[]>([]);
  const [dateTalks, setDateTalks] = useState<Dictionary<Talk[]>>({});
  const [adminUsers, setAdminUsers] = useState<AdminUser[]>([]);

  return (
    <ChatContext.Provider
      value={{
        selectedRoom,
        adminUsers,
        rooms,
        filteredRooms,
        parents,
        studentBasics,
        dateTalks,
        setSelectedRoom,
        setRooms,
        setFilteredRooms,
        setParents,
        setStudentBasics,
        setDateTalks,
        setAdminUsers,
      }}
    >
      <AdminWrapper>
        <Helmet>
          <title>{PAGE_TITLE('チャット機能')}</title>
        </Helmet>
        <Container>
          <div className="card chat-root">
            <SidebarContainer />
            <RoomContainer />
          </div>
        </Container>
      </AdminWrapper>
    </ChatContext.Provider>
  );
};
