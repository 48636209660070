import React, { useContext, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { ValueType } from 'react-select/src/types';
import { DateTime } from 'luxon';
import _ from 'lodash';

import DailyReportDetailForm from '../../../../components/molecules/form/DailyReportDetailForm';
import { EnrolledCramSchoolContext } from '../../../../context/EnrolledCramSchoolContext';
import useRole from '../../../../hooks/useRole';
import { AppContext } from '../../../../context/AppContext';
import {
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_SCHOOLS,
  NotesJapanSchool,
  NotesPraiseSchool,
} from '../../../../../data/form-data';
import useFetchStaff from '../../../../hooks/fetch/useFetchStaff';
import useFetchPeriodicInfo from '../../../../hooks/fetch/useFetchPeriodicInfo';
import useFetchStudentBasics from '../../../../hooks/fetch/useFetchStudentBasics';
import useFetchWorkShifts from '../../../../hooks/fetch/useFetchWorkShifts';
import { AdminUser } from '../../../../../domain/models/admin-user';
import {
  LectureItemForShift,
  LectureShiftItem,
  WorkShift,
} from '../../../../../domain/models/work-shift';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const { isAdministrator, isHeadquarters } = useRole();
  const { adminUser } = useContext(AppContext);
  const selectEnrolledCramSchools =
    isAdministrator || isHeadquarters
      ? [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS]
      : [adminUser.enrolled_cram_school];
  const { enrolledCramSchool, setEnrolledCramSchool } = useContext(
    EnrolledCramSchoolContext,
  );
  const [selectedStaff, setSelectedStaff] = useState<AdminUser | null>(null);
  const [filteredWorkShift, setFilteredWorkShift] = useState<WorkShift | null>(
    null,
  );
  const [filteredShift, setFilteredShift] = useState<LectureShiftItem | null>(
    null,
  );
  const dt = DateTime.fromJSDate(new Date());
  const [date] = useState<string>(dt.toFormat('yyyy/MM/dd'));

  useEffectOnce(() => {
    setEnrolledCramSchool(
      isAdministrator || isHeadquarters
        ? [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS][0]
        : adminUser.enrolled_cram_school,
    );
  });

  const { loading, staffs } = useFetchStaff({});
  const { loading: loading2, periodicInfos } = useFetchPeriodicInfo({});
  const { loading: loading3, studentBasics } = useFetchStudentBasics({});
  const { loading: loading4, workShifts } = useFetchWorkShifts({
    queryParams: [
      {
        fieldPath: 'date',
        opStr: '==',
        value: date,
      },
    ],
  });

  const filterWorkShift = () => {
    setFilteredWorkShift(
      workShifts.find((ws) => ws.school_name === enrolledCramSchool) ?? null,
    );
  };

  const filterLectureItemForShifts = (
    lectureItemForShifts: LectureItemForShift[] | undefined,
    teacherId: string,
  ) => {
    return lectureItemForShifts
      ? lectureItemForShifts.filter((li) => {
          if (
            typeof li.subject !== 'string' &&
            typeof li.teacher_id !== 'string'
          ) {
            return (
              li.teacher_id.first === teacherId ||
              li.teacher_id.latter === teacherId
            );
          }

          return li.teacher_id === teacherId;
        })
      : [];
  };

  const filterShift = (staff: AdminUser) => {
    const teacherId = staff.id;
    if (filteredWorkShift) {
      const shift = _.cloneDeep(filteredWorkShift.shift);
      shift.lecture_1 = filterLectureItemForShifts(shift.lecture_1, teacherId);
      shift.lecture_2 = filterLectureItemForShifts(shift.lecture_2, teacherId);
      shift.lecture_3 = filterLectureItemForShifts(shift.lecture_3, teacherId);
      shift.lecture_4 = filterLectureItemForShifts(shift.lecture_4, teacherId);
      shift.lecture_5 = filterLectureItemForShifts(shift.lecture_5, teacherId);
      shift.lecture_special = filterLectureItemForShifts(
        shift.lecture_special,
        teacherId,
      );

      setFilteredShift(shift);
    }
  };

  const onChangeEnrolledCramSchool: SelectInputProps['onChange'] = (event) => {
    const schoolName = event.target.value as
      | NotesJapanSchool
      | NotesPraiseSchool;

    setEnrolledCramSchool(schoolName);
  };

  const onChangeStaff = (teacherId: string) => {
    const targetStaff = staffs.filter((s) => s.id === teacherId)[0];
    setSelectedStaff(targetStaff);
    filterShift(targetStaff);
  };

  useEffect(() => {
    filterWorkShift();
    setSelectedStaff(null);
    setFilteredShift(null);
    // eslint-disable-next-line
  }, [enrolledCramSchool, workShifts]);

  return (
    <DailyReportDetailForm
      loading={loading && loading2 && loading3 && loading4}
      staff={selectedStaff}
      periodicInfo={
        periodicInfos.find((pi) => pi.school_name === enrolledCramSchool) ??
        null
      }
      shift={filteredShift}
      studentBasics={studentBasics}
      selectPropsEnrolledCramSchool={{
        label: '',
        value: enrolledCramSchool,
        options: selectEnrolledCramSchools.map((school) => ({
          label: school,
          value: school,
        })),
        onChange: onChangeEnrolledCramSchool,
      }}
      selectPropsStaff={{
        value: selectedStaff
          ? {
              label: `${selectedStaff.last_name} ${selectedStaff.first_name} (${selectedStaff.last_name_kana} ${selectedStaff.first_name_kana})`,
              value: selectedStaff.id,
            }
          : null,
        options: staffs.length
          ? staffs
              .filter(
                (staff) => staff.enrolled_cram_school === enrolledCramSchool,
              )
              .map((s) => ({
                label: `${s.last_name} ${s.first_name} (${s.last_name_kana} ${s.first_name_kana})`,
                value: s.id,
              }))
          : [
              {
                label: '所属講師はいません',
                value: '',
              },
            ],
        onChange: (value: ValueType<{ label: string; value: string }>) => {
          if (value && 'value' in value) {
            onChangeStaff(value.value);
          }
        },
        isDisabled: !staffs.length,
        placeholder: '講師を選択してください',
      }}
    />
  );
};
