import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  defaultSchoolCalendar,
  SchoolCalendar,
} from '../../../domain/models/school-calendar';
import getSchoolCalendars from '../../../domain/services/get-school-calendar';
import { QueryParams } from '../../../types/firestore-simple';
import { EnrolledCramSchoolContext } from '../../context/EnrolledCramSchoolContext';
import { YearContext } from '../../context/YearContext';

export type Result = {
  loading: boolean;
  schoolCalendars: SchoolCalendar[];
  setSchoolCalendars: Dispatch<SetStateAction<SchoolCalendar[]>>;
};

const useFetchSchoolCalendar = ({
  queryParams,
}: {
  queryParams?: QueryParams<SchoolCalendar>;
}): Result => {
  const [loading, setLoading] = useState(false);
  const [schoolCalendars, setSchoolCalendars] = useState<SchoolCalendar[]>([]);
  const { enrolledCramSchool } = useContext(EnrolledCramSchoolContext);
  const { year } = useContext(YearContext);

  useEffect(() => {
    void (async () => {
      setLoading(true);

      const result = await getSchoolCalendars({ queryParams });
      if (result.schoolCalendars.length) {
        setSchoolCalendars(result.schoolCalendars);
      } else {
        setSchoolCalendars([defaultSchoolCalendar]);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [enrolledCramSchool, year]);

  return { loading, schoolCalendars, setSchoolCalendars };
};

export default useFetchSchoolCalendar;
