import React from 'react';
import { Controller } from 'react-hook-form';
import { UseFormMethods } from 'react-hook-form/dist/types';

import useRole from '../../../../hooks/useRole';
import Select from '../../../atoms/form/Select';

type SelectValue = {
  label: string;
  value: string;
};

export type Props = {
  formMethods: UseFormMethods<any>;
  name: string;
  options: SelectValue[] | undefined;
  defaultValue: SelectValue | null;
  className: string;
  onChangeCallback?: () => void;
  isDisabled?: boolean;
};

/* eslint-disable */
export const SelectForForm = ({
  formMethods,
  options,
  name,
  defaultValue,
  className,
  onChangeCallback,
  isDisabled,
}: Props) => {
  const { isViewer } = useRole();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, setValue, trigger, watch } = formMethods;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue?.value ?? null}
      as={
        <Select
          selectProps={{
            name: name,
            options: options,
            value: watch(name) ? {
              label: options?.find((o) => o.value === watch(name))?.label ?? '選択された値が選択肢の中に存在していません',
              value: watch(name),
            } : null,
            onChange: async (value) => {
              setValue(
                name,
                value && 'value' in value ? value.value : '',
              );
              await trigger(name);
              if (onChangeCallback) {
                onChangeCallback();
              }
            },
            isDisabled: isViewer || isDisabled,
            isClearable: true,
            className: className,
          }}
        />
      }
    />
  );
};
