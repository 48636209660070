import { useState } from 'react';
import useSnackbar, { SnackbarState } from '../useSnackbar';
import createStudentLectures from '../../../domain/services/create-student-lectfures';
import { StudentLectures } from '../../../domain/models/student-lectures';

export type Result = {
  creating: boolean;
  create: (studentLecture: StudentLectures) => Promise<string | undefined>;
  state: SnackbarState;
  close: () => void;
};

const useCreateWeekDefault = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (studentLecture: StudentLectures) => {
    setCreating(true);
    const result = await createStudentLectures(studentLecture);
    open(
      result.id ? 'success' : 'error',
      result.id
        ? '曜日マスタの更新に成功しました'
        : '曜日マスタの更新ができませんでした。もう一度お試しください。',
    );
    setCreating(false);

    return result.id;
  };

  return { creating, create, close, state };
};

export default useCreateWeekDefault;
