import clsx from 'clsx';
import React, { FC, LegacyRef } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select/src/types';
import { CgCloseO } from 'react-icons/cg';

import Input from '../../../atoms/form/Input';
import Select from '../../../atoms/form/Select';
import { PHONE_RELATIONSHIPS } from '../../../../../data/form-data';
import './style.scss';
import useRole from '../../../../hooks/useRole';

type Value = {
  name: string;
  defaultValue?: string;
  error?: FieldError;
  ref: LegacyRef<any>;
};

export type Props = {
  num: Value;
  relationship: Value;
  withIcon?: boolean;
  iconOnClick?: () => void;
  control: Control;
  selectOnChange: (
    value: ValueType<OptionTypeBase>,
    action: ActionMeta<OptionTypeBase>,
  ) => void;
};

const TelInfoItem: FC<Props> = ({
  num,
  relationship,
  withIcon,
  iconOnClick,
  control,
  selectOnChange,
}) => {
  const { isViewer } = useRole();

  return (
    <div className={clsx('tel-info', { 'with-icon': withIcon })}>
      <div className="columns">
        <div className="column is-half">
          <Input
            label="番号"
            inputProps={{
              type: 'text',
              name: num.name,
              ref: num.ref,
              defaultValue: num.defaultValue,
              disabled: isViewer,
            }}
            error={num.error}
          />
        </div>
        <div className="column is-half">
          <Controller
            control={control}
            name={relationship.name}
            defaultValue={relationship.defaultValue}
            as={
              <Select
                label="続柄"
                selectProps={{
                  name: 'relationship',
                  options: PHONE_RELATIONSHIPS.map((value) => ({
                    label: value,
                    value,
                  })),
                  defaultValue: {
                    label: relationship.defaultValue || '',
                    value: relationship.defaultValue || '',
                  },
                  onChange: selectOnChange,
                  isDisabled: isViewer,
                }}
                error={relationship.error}
              />
            }
          />
        </div>
      </div>
      {withIcon && <CgCloseO onClick={iconOnClick} />}
    </div>
  );
};

export default TelInfoItem;
