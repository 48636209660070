import { Collection } from '@firestore-simple/web/dist/collection';
import { OmitId } from '@firestore-simple/web/dist/types';
import { Query } from '@firestore-simple/web';

import { Room } from '../models/rooms';
import firestoreSimple from '../../lib/firebase-firestore';
import { QueryParams } from '../../types/firestore-simple';

export type CollectionOrQuery =
  | Collection<Room, OmitId<Room>>
  | Query<Room, OmitId<Room>>;

const getRooms = async ({
  id,
  queryParams,
}: {
  id?: string;
  queryParams?: QueryParams<Room>;
}): Promise<{
  rooms: Room[];
}> => {
  const collection = firestoreSimple.collection<Room>({
    path: 'rooms',
  });
  const query =
    queryParams && queryParams.length
      ? queryParams.reduce((prev: CollectionOrQuery, current) => {
          return prev.where(current.fieldPath, current.opStr, current.value);
        }, collection)
      : collection;
  let docs: Room[] | undefined;
  if ('query' in query && 'startAt' in query) {
    docs =
      (await query.fetch().catch((error) => console.error(error))) || undefined;
  } else if (typeof id === 'string') {
    const result = await query.fetch(id).catch((error) => console.error(error));
    docs = result ? [result] : undefined;
  } else {
    docs =
      (await query.fetchAll().catch((error) => console.error(error))) ||
      undefined;
  }

  const rooms = docs instanceof Array ? docs : [];

  return { rooms };
};

export default getRooms;
