import { HasId } from '@firestore-simple/web/dist/types';

export type TaskItem = {
  name: string;
  label: string;
  done: boolean;
  date: string | null;
};

export type DocumentRequestTaskItems = [
  {
    name: 'send_document';
    label: '資料発送済み';
    done: boolean;
    date: string | null;
  },
  {
    name: 'call_after_send_document';
    label: '資料発送後、電話を掛けた';
    done: boolean;
    date: string | null;
  },
  {
    name: 'deadline';
    label: 'タスク完了期限';
    done: boolean;
    date: string | null;
  },
];
export const documentRequestTaskItems: DocumentRequestTaskItems = [
  {
    name: 'send_document',
    label: '資料発送済み',
    done: false,
    date: null,
  },
  {
    name: 'call_after_send_document',
    label: '資料発送後、電話を掛けた',
    done: false,
    date: null,
  },
  {
    name: 'deadline',
    label: 'タスク完了期限',
    done: false,
    date: null,
  },
];

export type TrialTaskItems = [
  {
    name: 'reserve_interview';
    label: '教室へ面談予約済み';
    done: boolean;
    date: string | null;
  },
  {
    name: 'input_invoice_data';
    label: '請求書データ入力';
    done: boolean;
    date: string | null;
  },
  {
    name: 'input_class_schedule';
    label: '授業日程管理データ入力';
    done: boolean;
    date: string | null;
  },
  {
    name: 'create_report_curriculum';
    label: '報告書、カリキュラムの作成';
    done: boolean;
    date: string | null;
  },
  {
    name: 'create_homework_print';
    label: '授業・宿題用プリント作成';
    done: boolean;
    date: string | null;
  },
  {
    name: 'deadline';
    label: 'タスク完了期限';
    done: boolean;
    date: string | null;
  },
];
export const trialTaskItems: TrialTaskItems = [
  {
    name: 'reserve_interview',
    label: '教室へ面談予約済み',
    done: false,
    date: null,
  },
  {
    name: 'input_invoice_data',
    label: '請求書データ入力',
    done: false,
    date: null,
  },
  {
    name: 'input_class_schedule',
    label: '授業日程管理データ入力',
    done: false,
    date: null,
  },
  {
    name: 'create_report_curriculum',
    label: '報告書、カリキュラムの作成',
    done: false,
    date: null,
  },
  {
    name: 'create_homework_print',
    label: '授業・宿題用プリント作成',
    done: false,
    date: null,
  },
  {
    name: 'deadline',
    label: 'タスク完了期限',
    done: false,
    date: null,
  },
];

export type EnrollTaskItems = [
  {
    name: 'input_invoice_data';
    label: '請求書データ入力';
    done: boolean;
    date: string | null;
  },
  {
    name: 'input_class_schedule';
    label: '授業日程管理データ入力';
    done: boolean;
    date: string | null;
  },
  {
    name: 'create_student_file';
    label: '生徒・定着ファイルの作成';
    done: boolean;
    date: string | null;
  },
  {
    name: 'create_report_curriculum';
    label: '報告書、カリキュラムの作成';
    done: boolean;
    date: string | null;
  },
  {
    name: 'order_text_book';
    label: 'テキスト発注';
    done: boolean;
    date: string | null;
  },
  {
    name: 'distribute_text_book';
    label: 'テキスト配布';
    done: boolean;
    date: string | null;
  },
  {
    name: 'distribute_bank_account_print';
    label: '引き落とし銀行口座用紙配布';
    done: boolean;
    date: string | null;
  },
  {
    name: 'receive_bank_account_print';
    label: '引き落とし銀行口座用紙受け取り';
    done: boolean;
    date: string | null;
  },
  {
    name: 'process_bank_account_print';
    label: '引き落とし銀行口座用紙手続き';
    done: boolean;
    date: string | null;
  },
  {
    name: 'distribute_privilege';
    label: '特典配布';
    done: boolean;
    date: string | null;
  },
  {
    name: 'collection_result';
    label: '成績・点数回収';
    done: boolean;
    date: string | null;
  },
  {
    name: 'deadline';
    label: 'タスク完了期限';
    done: boolean;
    date: string | null;
  },
];
export const enrollTaskItems: EnrollTaskItems = [
  {
    name: 'input_invoice_data',
    label: '請求書データ入力',
    done: false,
    date: null,
  },
  {
    name: 'input_class_schedule',
    label: '授業日程管理データ入力',
    done: false,
    date: null,
  },
  {
    name: 'create_student_file',
    label: '生徒・定着ファイルの作成',
    done: false,
    date: null,
  },
  {
    name: 'create_report_curriculum',
    label: '報告書、カリキュラムの作成',
    done: false,
    date: null,
  },
  {
    name: 'order_text_book',
    label: 'テキスト発注',
    done: false,
    date: null,
  },
  {
    name: 'distribute_text_book',
    label: 'テキスト配布',
    done: false,
    date: null,
  },
  {
    name: 'distribute_bank_account_print',
    label: '引き落とし銀行口座用紙配布',
    done: false,
    date: null,
  },
  {
    name: 'receive_bank_account_print',
    label: '引き落とし銀行口座用紙受け取り',
    done: false,
    date: null,
  },
  {
    name: 'process_bank_account_print',
    label: '引き落とし銀行口座用紙手続き',
    done: false,
    date: null,
  },
  {
    name: 'distribute_privilege',
    label: '特典配布',
    done: false,
    date: null,
  },
  {
    name: 'collection_result',
    label: '成績・点数回収',
    done: false,
    date: null,
  },
  {
    name: 'deadline',
    label: 'タスク完了期限',
    done: false,
    date: null,
  },
];

export type Task = HasId & {
  student_id: string;
  tasks: TaskItem[];
};

export type TaskDocumentRequest = Task & { tasks: DocumentRequestTaskItems };

export type TaskTrial = Task & { tasks: TrialTaskItems };

export type TaskEnroll = Task & { tasks: EnrollTaskItems };
