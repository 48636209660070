import React, { FC } from 'react';

import DataCounter from '../../../../components/atoms/DataCounter';
import useFetchClassTransfers from '../../../../hooks/fetch/useFetchClassTransfers';

const ClassTransferDataCounter: FC = () => {
  const { loading, classTransfers } = useFetchClassTransfers();
  const num =
    classTransfers?.filter((classTransfer) => {
      return classTransfer.status === 'applying';
    }).length || 0;

  return (
    <DataCounter
      title="授業振替申請件数"
      href="/class-transfer"
      number={num}
      loading={loading}
    />
  );
};

export default ClassTransferDataCounter;
