import firestoreSimple from '../../lib/firebase-firestore';
import { MiddleSchoolExam } from '../models/middle-school-exam';

export type Result = {
  id?: string;
};

const createMiddleSchoolExam = async (
  middleSchoolExam: MiddleSchoolExam,
): Promise<Result> => {
  const collection = firestoreSimple.collection<MiddleSchoolExam>({
    path: 'middle_school_exam',
  });

  const docId = middleSchoolExam.id
    ? await collection.set(middleSchoolExam).catch((error) => {
        console.error(error);
      })
    : await collection
        .add(middleSchoolExam)
        .catch((error) => console.error(error));

  return { id: docId || undefined };
};

export default createMiddleSchoolExam;
