import firestoreSimple from '../../lib/firebase-firestore';
import { AdminUser } from '../models/admin-user';

export type Result = {
  id?: string;
};

const createAdminUser = async (adminUser: AdminUser): Promise<Result> => {
  const collection = firestoreSimple.collection<AdminUser>({
    path: 'admin_user',
  });

  const docId = adminUser.id
    ? await collection.set(adminUser).catch((error) => {
        console.error(error);
      })
    : await collection.add(adminUser).catch((error) => console.error(error));

  return { id: docId || undefined };
};

export default createAdminUser;
