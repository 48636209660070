import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { PAGE_TITLE } from '../../../data/app-basic';
import Container from '../../components/layouts/Container';
import AdminWrapper from '../../components/layouts/AdminWrapper';
import StudentInvoiceForm from '../../containers/molecules/form/StudentInvoiceForm';

const InvoiceDetail: FC = () => {
  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('請求書情報編集')}</title>
      </Helmet>
      <Container>
        <StudentInvoiceForm />
      </Container>
    </AdminWrapper>
  );
};

export default InvoiceDetail;
