import React from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useFetchTaskDocumentRequest from '../../../../hooks/fetch/useFetchTaskDocumentRequest';
import { TaskDocumentRequest } from '../../../../../domain/models/task';
import TaskDocumentRequestForm from '../../../../components/molecules/form/TaskDocumentRequestForm';
import useCreateTaskDocumentRequest from '../../../../hooks/create/useCreateTaskDocumentRequest';

export default () => {
  const location = useLocation<{ studentId: string | undefined }>();
  if (!location.state) return null;

  const studentId =
    'studentId' in location.state ? location.state.studentId : undefined;

  const { loading, taskItems } = useFetchTaskDocumentRequest({
    queryParams: [{ fieldPath: 'student_id', opStr: '==', value: studentId }],
  });

  const { create, state, close } = useCreateTaskDocumentRequest();

  const formMethods = useForm<TaskDocumentRequest>({
    defaultValues: taskItems,
  });
  const onSubmit = formMethods.handleSubmit(async (data) => {
    const id = await create({
      ...taskItems,
      ...data,
      student_id: studentId || '',
    });
    if (id) formMethods.setValue('id', id);
  });

  return (
    <TaskDocumentRequestForm
      loading={loading}
      taskItems={taskItems}
      formMethods={formMethods}
      onSubmit={onSubmit}
      snackbarClose={close}
      snackbarState={state}
    />
  );
};
