import React from 'react';
import { useLocation } from 'react-router-dom';

import { ScoreReportContext } from '../../../../../context/ScoreReportContext';
import { StudentBasic } from '../../../../../../domain/models/student-basic';
import useFetchMiddleSchoolReportsLists from '../../../../../hooks/fetch/useFetchMiddleSchoolReportsLists';
import useCreateMiddleSchoolReport from '../../../../../hooks/create/useCreateMiddleSchoolReport';
import DotProgress from '../../../../../components/atoms/feedback/DotProgress';
import MiddleSchoolReportsListComponent from '../../../../../components/molecules/table/MiddleSchoolReportsList';
import ReportChart from '../../../../../components/pages/score/detail/ReportChart';

const ReportTab = () => {
  const {
    state: { studentBasic },
  } = useLocation<{ studentBasic: StudentBasic }>();
  const {
    loading,
    error,
    middleSchoolReportsLists,
    updateTableData,
  } = useFetchMiddleSchoolReportsLists(studentBasic);
  const { create, creating, close, state } = useCreateMiddleSchoolReport();

  if (loading) return <DotProgress />;

  if (error) {
    console.error(error);

    return <p>成績情報の取得に失敗しました</p>;
  }

  if (!middleSchoolReportsLists?.length) return <p>成績情報が0件です</p>;

  return (
    <ScoreReportContext.Provider
      value={{
        data: middleSchoolReportsLists,
        updateTableData,
        creating,
        create,
        closeSnackbar: close,
        snackbarState: state,
      }}
    >
      <ReportChart />
      <MiddleSchoolReportsListComponent />
    </ScoreReportContext.Provider>
  );
};

export default ReportTab;
