import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { useEffectOnce } from 'react-use';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import PeriodicInfoForm from '../../../../components/molecules/form/PeriodicInfoForm';
import { EnrolledCramSchoolContext } from '../../../../context/EnrolledCramSchoolContext';
import useRole from '../../../../hooks/useRole';
import { AppContext } from '../../../../context/AppContext';
import {
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_SCHOOLS,
  NotesJapanSchool,
  NotesPraiseSchool,
} from '../../../../../data/form-data';
import useFetchPeriodicInfo from '../../../../hooks/fetch/useFetchPeriodicInfo';
import useCreatePeriodicInfo from '../../../../hooks/create/useCreatePeriodicInfo';
import {
  defaultPeriodicInfo,
  PeriodicInfo,
} from '../../../../../domain/models/periodic-info';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const { isAdministrator, isHeadquarters } = useRole();
  const { adminUser } = useContext(AppContext);
  const selectEnrolledCramSchools =
    isAdministrator || isHeadquarters
      ? [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS]
      : [adminUser.enrolled_cram_school];
  const { enrolledCramSchool, setEnrolledCramSchool } = useContext(
    EnrolledCramSchoolContext,
  );
  useEffectOnce(() => {
    setEnrolledCramSchool(
      isAdministrator || isHeadquarters
        ? [...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS][0]
        : adminUser.enrolled_cram_school,
    );
  });

  const periodicInfoSchoolNameValue = enrolledCramSchool;
  const queryParams = [
    {
      fieldPath: 'school_name',
      opStr: '==',
      value: periodicInfoSchoolNameValue,
    },
  ];

  const { loading, periodicInfos, setPeriodicInfos } = useFetchPeriodicInfo({
    queryParams,
  });
  const { creating, create, close, state } = useCreatePeriodicInfo();
  const formMethods = useForm<PeriodicInfo>({
    defaultValues: periodicInfos[0],
  });

  const onChange: SelectInputProps['onChange'] = (event) => {
    setEnrolledCramSchool(
      event.target.value as NotesJapanSchool | NotesPraiseSchool,
    );
  };

  const onSubmit = formMethods.handleSubmit(async (data) => {
    // 登録・編集用データの元
    const createValue = {
      ...defaultPeriodicInfo,
      ...data,
      id: periodicInfos[0]?.id,
      school_name: periodicInfos[0]?.school_name
        ? periodicInfos[0]?.school_name
        : enrolledCramSchool,
    };

    const id = await create(createValue);

    if (id) {
      setPeriodicInfos([
        {
          ...createValue,
          id,
          school_name: enrolledCramSchool,
        },
      ]);
    }
  });

  return (
    <PeriodicInfoForm
      loading={loading}
      periodicInfo={periodicInfos[0] ?? defaultPeriodicInfo}
      formMethods={formMethods}
      onSubmit={onSubmit}
      creating={creating}
      snackbarState={state}
      snackbarClose={close}
      selectProps={{
        label: '',
        value: enrolledCramSchool,
        options: selectEnrolledCramSchools.map((school) => ({
          label: school,
          value: school,
        })),
        onChange,
      }}
    />
  );
};
