import { HasId } from '@firestore-simple/web/dist/types';
import {
  NOTES_JAPAN_CLASSES,
  NOTES_PRAISE_HOLIDAY_CLASSES,
  NOTES_PRAISE_WEEKDAYS_CLASSES,
  NotesJapanClass,
  NotesPraiseClass,
} from '../../data/form-data';

export type BaseLectureKey =
  | 'lecture_1'
  | 'lecture_2'
  | 'lecture_3'
  | 'lecture_4'
  | 'lecture_5';
export const BASE_LECTURE_KEYS: BaseLectureKey[] = [
  'lecture_1',
  'lecture_2',
  'lecture_3',
  'lecture_4',
  'lecture_5',
];
export type LectureKey = BaseLectureKey | 'lecture_special';
export const LECTURE_KEYS: LectureKey[] = [
  ...BASE_LECTURE_KEYS,
  'lecture_special',
];

export type LectureDayKey =
  | 'lecture_monday'
  | 'lecture_tuesday'
  | 'lecture_wednesday'
  | 'lecture_thursday'
  | 'lecture_friday'
  | 'lecture_saturday'
  | 'lecture_sunday';
export const LECTURE_DAY_KEYS: LectureDayKey[] = [
  'lecture_monday',
  'lecture_tuesday',
  'lecture_wednesday',
  'lecture_thursday',
  'lecture_friday',
  'lecture_saturday',
  'lecture_sunday',
];
export type JaLectureDay = '月' | '火' | '水' | '木' | '金' | '土' | '日';
export const jaLectureDays: { [key in LectureDayKey]: JaLectureDay } = {
  lecture_monday: '月',
  lecture_tuesday: '火',
  lecture_wednesday: '水',
  lecture_thursday: '木',
  lecture_friday: '金',
  lecture_saturday: '土',
  lecture_sunday: '日',
};
export const jaLectureDaysArr: JaLectureDay[] = [
  '日',
  '月',
  '火',
  '水',
  '木',
  '金',
  '土',
];

export type SeparatedLecture = {
  first: string;
  latter: string;
};

export type LectureItem = {
  lecture: string;
  subject: SeparatedLecture | string;
};

export type LectureItemForLecture = LectureItem & {
  teacher_id: SeparatedLecture | string;
  default_seat: SeparatedLecture | string;
};

export type LectureDayItem = {
  [key in LectureKey]?: LectureItemForLecture;
};

export type TrialLecture = {
  trial_date: string;
  trial_class: string;
  trial_subject: string;
};

export type StudentLectures = HasId & {
  student_id: string;
  course: string[];
  lecture_count: string;
  lecture_time: string;
  lecture_monday: LectureDayItem;
  lecture_tuesday: LectureDayItem;
  lecture_wednesday: LectureDayItem;
  lecture_thursday: LectureDayItem;
  lecture_friday: LectureDayItem;
  lecture_saturday: LectureDayItem;
  lecture_sunday: LectureDayItem;
  first_date: string;
  first_class: string;
  first_subject: string;
  trial_lectures: TrialLecture[];
};

export const defaultSeparatedLecture: SeparatedLecture = {
  first: '',
  latter: '',
};

export const defaultLectureItem: LectureItemForLecture = {
  lecture: '',
  subject: '',
  teacher_id: '',
  default_seat: '',
};

export const defaultLectureDayItem: LectureDayItem = {
  lecture_1: defaultLectureItem,
  lecture_2: defaultLectureItem,
  lecture_3: defaultLectureItem,
  lecture_4: defaultLectureItem,
  lecture_5: defaultLectureItem,
};

export const defaultStudentLectures: StudentLectures = {
  id: '',
  student_id: '',
  course: [],
  lecture_count: '',
  lecture_time: '',
  lecture_monday: defaultLectureDayItem,
  lecture_tuesday: defaultLectureDayItem,
  lecture_wednesday: defaultLectureDayItem,
  lecture_thursday: defaultLectureDayItem,
  lecture_friday: defaultLectureDayItem,
  lecture_saturday: defaultLectureDayItem,
  lecture_sunday: defaultLectureDayItem,
  first_date: '',
  first_class: '',
  first_subject: '',
  trial_lectures: [],
};

// lectureを探す
export const getLecture = (
  lectureDayKey: LectureDayKey,
  lectureKey: LectureKey,
  isNotesJapan: boolean,
) => {
  let lecture: NotesJapanClass | NotesPraiseClass | undefined;
  const [n1, n2, n3, n4, n5] = NOTES_JAPAN_CLASSES;
  const [p1H, p2H, p3H, p4H, p5H] = NOTES_PRAISE_HOLIDAY_CLASSES;
  const [p4W, p5W, p6W, p7W, p8W] = NOTES_PRAISE_WEEKDAYS_CLASSES;
  const [lecture1, lecture2, lecture3, lecture4, lecture5] = BASE_LECTURE_KEYS;
  if (isNotesJapan) {
    switch (lectureKey) {
      case lecture1: {
        lecture = n1;
        break;
      }
      case lecture2: {
        lecture = n2;
        break;
      }
      case lecture3: {
        lecture = n3;
        break;
      }
      case lecture4: {
        lecture = n4;
        break;
      }
      case lecture5: {
        lecture = n5;
        break;
      }
      default: {
        break;
      }
    }
  } else if (
    lectureDayKey === 'lecture_saturday' ||
    lectureDayKey === 'lecture_sunday'
  ) {
    // 土日の場合
    switch (lectureKey) {
      case lecture1: {
        lecture = p1H;
        break;
      }
      case lecture2: {
        lecture = p2H;
        break;
      }
      case lecture3: {
        lecture = p3H;
        break;
      }
      case lecture4: {
        lecture = p4H;
        break;
      }
      case lecture5: {
        lecture = p5H;
        break;
      }
      default: {
        break;
      }
    }
  } else {
    // 平日の場合
    switch (lectureKey) {
      case lecture1: {
        lecture = p4W;
        break;
      }
      case lecture2: {
        lecture = p5W;
        break;
      }
      case lecture3: {
        lecture = p6W;
        break;
      }
      case lecture4: {
        lecture = p7W;
        break;
      }
      case lecture5: {
        lecture = p8W;
        break;
      }
      default: {
        break;
      }
    }
  }

  return lecture;
};

export const getLectureItem = (
  studentLecture: StudentLectures | undefined,
  dayOfWeek: JaLectureDay | '',
  lectureKey: BaseLectureKey,
  isNotesJapan: boolean,
  isNotesElementarySchool: boolean,
) => {
  const lectureDayKey: LectureDayKey = Object.keys(jaLectureDays).find(
    (ldk) => jaLectureDays[ldk as LectureDayKey] === dayOfWeek,
  ) as LectureDayKey;

  const lecture = getLecture(lectureDayKey, lectureKey, isNotesJapan);

  const lectureDayItem = studentLecture?.[lectureDayKey]?.[lectureKey];
  let subject;
  let teacher_id;
  let default_seat;
  if (isNotesElementarySchool && typeof lectureDayItem?.subject !== 'string') {
    // ノートス小学生かつ曜日マスタが前半・後半に分かれている状態で保存されているときはそのまま値を使用する
    subject = {
      first: lectureDayItem?.subject?.first ?? '',
      latter: lectureDayItem?.subject?.latter ?? '',
    };
    teacher_id = {
      first:
        typeof lectureDayItem?.teacher_id !== 'string'
          ? lectureDayItem?.teacher_id?.first ?? ''
          : '',
      latter:
        typeof lectureDayItem?.teacher_id !== 'string'
          ? lectureDayItem?.teacher_id?.latter ?? ''
          : '',
    };
    default_seat = {
      first:
        typeof lectureDayItem?.default_seat !== 'string'
          ? lectureDayItem?.default_seat?.first ?? ''
          : '',
      latter:
        typeof lectureDayItem?.default_seat !== 'string'
          ? lectureDayItem?.default_seat?.latter ?? ''
          : '',
    };
  } else if (
    isNotesElementarySchool &&
    typeof lectureDayItem?.subject === 'string'
  ) {
    // ノートス小学生かつ曜日マスタが前半・後半に分かれていない状態で保存されている（万が一プレイズからノートス小学生に変更された場合等）ときは初期値を使用する
    subject = defaultSeparatedLecture;
    teacher_id = defaultSeparatedLecture;
    default_seat = defaultSeparatedLecture;
  } else if (
    !isNotesElementarySchool &&
    typeof lectureDayItem?.subject !== 'string'
  ) {
    // ノートス小学生でないかつ曜日マスタが前半・後半に分かれている状態で保存されている（万が一ノートス小学生からノートス中学生orプレイズに変更された場合等）ときは初期値を使用する
    subject = '';
    teacher_id = '';
    default_seat = '';
  } else {
    // ノートス小学生でないかつ曜日マスタが前半・後半に分かれていない状態で保存されているときはそのまま値を使用する
    subject = lectureDayItem?.subject ?? '';
    teacher_id = lectureDayItem?.teacher_id ?? '';
    default_seat = lectureDayItem?.default_seat ?? '';
  }

  return {
    lecture: lecture ?? '',
    subject,
    teacher_id,
    default_seat,
  };
};

export const getLectureDayKey = (dayOfWeek: JaLectureDay) =>
  LECTURE_DAY_KEYS.find((key) => jaLectureDays[key] === dayOfWeek);
