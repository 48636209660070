import { Collection } from '@firestore-simple/web/dist/collection';
import { OmitId } from '@firestore-simple/web/dist/types';
import { Query } from '@firestore-simple/web';

import { MiddleSchoolExam } from '../models/middle-school-exam';
import firestoreSimple from '../../lib/firebase-firestore';
import { QueryParams } from '../../types/firestore-simple';

export type CollectionOrQuery =
  | Collection<MiddleSchoolExam, OmitId<MiddleSchoolExam>>
  | Query<MiddleSchoolExam, OmitId<MiddleSchoolExam>>;

const getMiddleSchoolExam = async ({
  id,
  queryParams,
}: {
  id?: string;
  queryParams?: QueryParams<MiddleSchoolExam>;
}): Promise<{
  middleSchoolExam: MiddleSchoolExam[];
}> => {
  const collection = firestoreSimple.collection<MiddleSchoolExam>({
    path: 'middle_school_exam',
  });
  const query =
    queryParams && queryParams.length
      ? queryParams.reduce((prev: CollectionOrQuery, current) => {
          return prev.where(current.fieldPath, current.opStr, current.value);
        }, collection)
      : collection;
  let docs: MiddleSchoolExam[] | undefined;
  if ('query' in query && 'startAt' in query) {
    docs =
      (await query.fetch().catch((error) => console.error(error))) || undefined;
  } else if (typeof id === 'string') {
    const result = await query.fetch(id).catch((error) => console.error(error));
    docs = result ? [result] : undefined;
  } else {
    docs =
      (await query.fetchAll().catch((error) => console.error(error))) ||
      undefined;
  }

  const middleSchoolExam = docs instanceof Array ? docs : [];

  return { middleSchoolExam };
};

export default getMiddleSchoolExam;
