import React, { FC, useContext, useState } from 'react';
import {
  LineChart,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  TooltipPayload,
} from 'recharts';
import { FormControlLabel, Switch } from '@material-ui/core';

import { ScoreExamContext } from '../../../../../context/ScoreExamContext';
import { MiddleSchoolExamScores } from '../../../../../../domain/models/middle-school-exam';
import VerticalText from './VerticalText';
import './style.scss';
import {
  middleSchoolExamSubjects,
  subjectColors,
} from '../../../../../../data/form-data';
import Dot from './Dot';
import TooltipContent from './TooltipContent';

const ExamChart: FC = () => {
  const [tooltip, setTooltip] = useState(true);
  const { data } = useContext(ScoreExamContext);
  const chartData = data.map((d): {
    examKey: string;
    total: number;
  } & MiddleSchoolExamScores => ({
    examKey: d.examKey,
    japanese: d.japanese,
    math: d.math,
    english: d.english,
    society: d.society,
    science: d.science,
    total: d.total,
  }));

  return (
    <div className="chart-root">
      <FormControlLabel
        control={
          <Switch checked={tooltip} onChange={() => setTooltip(!tooltip)} />
        }
        label="マウスホバーでデータを表示する"
      />

      <div className="chart-container">
        <ResponsiveContainer>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="examKey"
              tick={VerticalText}
              height={100}
              tickCount={12}
              interval={0}
            />
            <YAxis width={36} domain={[0, 100]} yAxisId="left" />
            <YAxis domain={[0, 500]} yAxisId="right" orientation="right" />
            {tooltip ? (
              <Tooltip
                content={(props: TooltipPayload) => {
                  return <TooltipContent {...props} />;
                }}
              />
            ) : null}
            {Object.keys(middleSchoolExamSubjects).map((subject) => (
              <Line
                key={subject}
                yAxisId="left"
                dataKey={subject}
                stroke={subjectColors[subject as keyof MiddleSchoolExamScores]}
                dot={(props) => <Dot dotProps={props} data={data} />}
              />
            ))}
            <Line
              dataKey="total"
              yAxisId="right"
              stroke="#000"
              dot={(props) => <Dot dotProps={props} data={data} />}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ExamChart;
