import { useState } from 'react';

import useSnackbar, { SnackbarState } from '../useSnackbar';
import { MiddleSchoolExam } from '../../../domain/models/middle-school-exam';
import createMiddleSchoolExam from '../../../domain/services/create-middle-school-exam';

export type Result = {
  creating: boolean;
  create: (middleSchoolExam: MiddleSchoolExam) => Promise<string | undefined>;
  state: SnackbarState;
  close: () => void;
};

const useCreateMiddleSchoolExam = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (
    middleSchoolExam: MiddleSchoolExam,
  ): Promise<string | undefined> => {
    setCreating(true);
    try {
      const result = await createMiddleSchoolExam(middleSchoolExam);
      if (!result.id) throw new Error();

      open('success', '成績情報の更新に成功しました');

      return result.id;
    } catch (e) {
      console.error(e);
      open(
        'error',
        '成績情報の更新ができませんでした。もう一度お試しください。',
      );

      return undefined;
    } finally {
      setCreating(false);
    }
  };

  return { creating, create, close, state };
};

export default useCreateMiddleSchoolExam;
