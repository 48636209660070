import React from 'react';
import { useForm } from 'react-hook-form';
import WorkShiftBulkChangeForm from '../../../../components/molecules/form/WorkShiftBulkChangeForm';
import { BulkChangeWorkShift } from '../../../../../types/form/bulk-change-work-shift';
import useBulkCreateWorkShift from '../../../../hooks/create/useBulkCreateWorkShift';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const formMethods = useForm<BulkChangeWorkShift>({
    defaultValues: {
      fromDate: '',
      toDate: '',
      enrolledCramSchool: '',
    },
  });

  const { creating, bulkCreate, state, close } = useBulkCreateWorkShift();

  const onSubmit = formMethods.handleSubmit((data) => {
    const { fromDate, toDate, enrolledCramSchool } = data;

    bulkCreate(fromDate, toDate, enrolledCramSchool);

    return true;
  });

  return (
    <WorkShiftBulkChangeForm
      formMethods={formMethods}
      onSubmit={onSubmit}
      creating={creating}
      snackbarState={state}
      snackbarClose={close}
    />
  );
};
