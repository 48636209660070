import clsx from 'clsx';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { FaSave, RiArrowGoBackFill } from 'react-icons/all';
import { Select as MuiSelect, MenuItem } from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import _ from 'lodash';

import { SchoolCalendar } from '../../../../../domain/models/school-calendar';
import useRole from '../../../../hooks/useRole';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import Button from '../../../atoms/button/Button';
import DotProgress from '../../../atoms/feedback/DotProgress';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import Backdrop from '../../../atoms/feedback/Backdrop';
import { Calendar } from './Calendar';
import { YearContext } from '../../../../context/YearContext';
import { EnrolledCramSchoolContext } from '../../../../context/EnrolledCramSchoolContext';
import './style.scss';

export type Props = {
  loading: boolean;
  schoolCalendar: SchoolCalendar;
  formMethods: UseFormMethods<SchoolCalendar>;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
  creating: boolean;
  selectProps: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
  selectPropsYear: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
};

const SchoolCalendarForm: FC<Props> = ({
  loading,
  schoolCalendar,
  formMethods,
  onSubmit,
  snackbarState,
  snackbarClose,
  creating,
  selectProps,
  selectPropsYear,
}) => {
  const { isViewer } = useRole();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, setValue } = formMethods;
  const [holidays, setHolidays] = useState<string[]>([]);
  const { enrolledCramSchool } = useContext(EnrolledCramSchoolContext);
  const { year } = useContext(YearContext);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);

    setHolidays(schoolCalendar.holidays);

    register('id');
    setValue('id', schoolCalendar?.id);
    register('school_name');
    setValue('school_name', enrolledCramSchool);
    register('year');
    setValue('year', year);
    register('holidays');
    setValue('holidays', schoolCalendar.holidays);
  }, [
    setHolidays,
    register,
    setValue,
    schoolCalendar,
    enrolledCramSchool,
    year,
  ]);

  const onClickDate = (fullDate: string) => {
    let newHolidays = _.cloneDeep(holidays);
    if (!newHolidays.some((holiday) => holiday === fullDate)) {
      // 休日に指定していなかったら指定する
      newHolidays.push(fullDate);
    } else {
      // 休日に指定されていたら削除する
      newHolidays = newHolidays.filter((holiday) => holiday !== fullDate);
    }

    newHolidays.sort();

    setHolidays(newHolidays);

    setValue('holidays', newHolidays);
  };

  return (
    <div>
      <h3 className="title pb-1">校舎マスター</h3>
      {selectProps ? (
        <div className="table-toolbar is-justify-content-flex-start">
          <MuiSelect
            label={selectProps.label}
            value={selectProps.value}
            onChange={selectProps.onChange}
            className="toolbar-select"
          >
            {selectProps.options.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </MuiSelect>
          <MuiSelect
            label={selectPropsYear.label}
            value={selectPropsYear.value}
            onChange={selectPropsYear.onChange}
            className="toolbar-select"
          >
            {selectPropsYear.options.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </MuiSelect>
        </div>
      ) : null}
      {loading ? (
        <DotProgress />
      ) : (
        <form
          onSubmit={onSubmit}
          className="detail-form card p-5 school-calendar"
        >
          <p className="has-text-grey mb-2">
            <small>※クリックで休日を設定してください。</small>
          </p>
          <div className="calendars-container">
            <div className="columns mb-6 pb-5">
              <div className="column is-one-third">
                <Calendar
                  yearMonth={`${year}/01`}
                  holidays={holidays}
                  onClickDate={onClickDate}
                />
              </div>
              <div className="column is-one-third">
                <Calendar
                  yearMonth={`${year}/02`}
                  holidays={holidays}
                  onClickDate={onClickDate}
                />
              </div>
              <div className="column is-one-third">
                <Calendar
                  yearMonth={`${year}/03`}
                  holidays={holidays}
                  onClickDate={onClickDate}
                />
              </div>
            </div>
            <div className="columns mb-6 pb-5">
              <div className="column is-one-third">
                <Calendar
                  yearMonth={`${year}/04`}
                  holidays={holidays}
                  onClickDate={onClickDate}
                />
              </div>
              <div className="column is-one-third">
                <Calendar
                  yearMonth={`${year}/05`}
                  holidays={holidays}
                  onClickDate={onClickDate}
                />
              </div>
              <div className="column is-one-third">
                <Calendar
                  yearMonth={`${year}/06`}
                  holidays={holidays}
                  onClickDate={onClickDate}
                />
              </div>
            </div>
            <div className="columns mb-6 pb-5">
              <div className="column is-one-third">
                <Calendar
                  yearMonth={`${year}/07`}
                  holidays={holidays}
                  onClickDate={onClickDate}
                />
              </div>
              <div className="column is-one-third">
                <Calendar
                  yearMonth={`${year}/08`}
                  holidays={holidays}
                  onClickDate={onClickDate}
                />
              </div>
              <div className="column is-one-third">
                <Calendar
                  yearMonth={`${year}/09`}
                  holidays={holidays}
                  onClickDate={onClickDate}
                />
              </div>
            </div>
            <div className="columns mb-6 pb-5">
              <div className="column is-one-third">
                <Calendar
                  yearMonth={`${year}/10`}
                  holidays={holidays}
                  onClickDate={onClickDate}
                />
              </div>
              <div className="column is-one-third">
                <Calendar
                  yearMonth={`${year}/11`}
                  holidays={holidays}
                  onClickDate={onClickDate}
                />
              </div>
              <div className="column is-one-third">
                <Calendar
                  yearMonth={`${year}/12`}
                  holidays={holidays}
                  onClickDate={onClickDate}
                />
              </div>
            </div>
          </div>
          <div className="btn-area">
            <Button
              buttonProps={{
                type: 'button',
                onClick: () => history.push('/'),
              }}
              className={clsx('with-icon', 'btn-primary')}
            >
              <RiArrowGoBackFill />
              戻る
            </Button>
            <Button
              buttonProps={{ type: 'submit', disabled: isViewer }}
              color="primary"
              className={clsx('with-icon', 'btn-primary')}
            >
              <FaSave />
              保存
            </Button>
          </div>
        </form>
      )}
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default SchoolCalendarForm;
