import { Row } from 'react-table';
import { MiddleSchoolReportList } from '../../types/table/middle-school-report-list';

export default (
  nextRow: Row<MiddleSchoolReportList>,
  currentRow: Row<MiddleSchoolReportList>,
): 0 | 1 | -1 => {
  const original = [
    '小1',
    '小2',
    '小3',
    '小4',
    '小5',
    '小6',
    '中1',
    '中2',
    '中3',
    '高1',
    '高2',
    '高3',
    '既卒',
  ];

  return original.findIndex((v) => v === nextRow?.values?.grade) >
    original.findIndex((v) => v === currentRow?.values?.grade)
    ? 1
    : -1;
};
