import React, { FC, PropsWithChildren, useContext, useState } from 'react';
import {
  Cell,
  CellValue,
  ColumnInstance,
  Row,
  TableInstance,
} from 'react-table';
import { useDebounce } from 'react-use';
import { InvoiceList } from '../../../../../types/table/invoice-list';
import { InvoiceStatus, WithdrawalBank } from '../../../../../data/form-data';
import { InvoiceContext } from '../../../../context/InvoiceContext';
import useRole from '../../../../hooks/useRole';
import { StudentInvoice } from '../../../../../domain/models/student-invoice';

const InputTransferCell: FC<PropsWithChildren<
  TableInstance<InvoiceList> & {
    cell: Cell<InvoiceList>;
    column: ColumnInstance<InvoiceList>;
    row: Row<InvoiceList>;
    value: CellValue<boolean | null>;
    updateTableData: (rowIndex: number, value: InvoiceList) => void;
  }
>> = ({ cell, value: initialValue, updateTableData }) => {
  const { create } = useContext(InvoiceContext);
  const [checked, setCheck] = useState<boolean | null>(initialValue);
  const { isViewer } = useRole();

  useDebounce(
    async () => {
      if (initialValue === checked) return;

      const newStudentInvoice: StudentInvoice = {
        id: cell.row.original.id,
        student_id: cell.row.original.student_id,
        status: cell.row.original.invoice_status as InvoiceStatus,
        invoice_month: cell.row.original.invoice_month,
        invoice_items: cell.row.original.invoice_items,
        withdrawal_date: cell.row.original.withdrawal_date,
        withdrawal_bank:
          (cell.row.original.withdrawal_bank as WithdrawalBank) || '',
        withdrawal_result: cell.row.original.withdrawal_result,
        consumption_tax: cell.row.original.consumption_tax || 0,
        subtotal: cell.row.original.subtotal || 0,
        total: cell.row.original.total || 0,
        payment_method: cell.row.original.payment_method,
        message: cell.row.original.message,
        remarks: cell.row.original.remarks,
        is_transfer: checked,
      };

      const newInvoiceList: InvoiceList = {
        id: cell.row.original.id,
        student_id: cell.row.original.student_id,
        student_num: cell.row.original.student_num,
        student_name: cell.row.original.student_name,
        student_status: cell.row.original.student_status,
        grade: cell.row.original.grade,
        enrolled_cram_school: cell.row.original.enrolled_cram_school,
        invoice_status: cell.row.original.invoice_status as InvoiceStatus,
        invoice_month: cell.row.original.invoice_month,
        withdrawal_date: cell.row.original.withdrawal_date,
        withdrawal_bank: cell.row.original.withdrawal_bank,
        total: cell.row.original.total,
        withdrawal_result: cell.row.original.withdrawal_result,
        invoice_items: cell.row.original.invoice_items,
        consumption_tax: cell.row.original.consumption_tax,
        subtotal: cell.row.original.subtotal,
        payment_method: cell.row.original.payment_method,
        message: cell.row.original.message,
        remarks: cell.row.original.remarks,
        is_transfer: checked,
      };

      const studentInvoiceId = create ? await create(newStudentInvoice) : '';
      if (studentInvoiceId) {
        newInvoiceList.id = studentInvoiceId;
      }

      updateTableData(cell.row.index, newInvoiceList);
    },
    500,
    [checked],
  );

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    <div className="form" onClick={(event) => event.stopPropagation()}>
      <input
        type="checkbox"
        name={cell.column.id}
        defaultChecked={checked ?? false}
        className="checkbox"
        onChange={(e) => {
          setCheck(e.target.checked);
        }}
        disabled={isViewer}
      />
    </div>
  );
};

export default InputTransferCell;
