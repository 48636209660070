import { Row } from 'react-table';
import exportCsv from '../utils/array/exportCsv';
import arrayToCsv from '../utils/array/arrayToCsv';
import { StudentList } from '../types/table/student-list';
import { STUDENT_STATUS_LABELS, StudentStatus } from '../data/form-data';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (rows: Row<StudentList>[]) => {
  const rowArrays = rows.map((row) => {
    const rowValues = Object.entries(row.values).filter((v) => {
      return !['studentId'].includes(v[0]);
    });

    return rowValues.map((rowValue): string => {
      switch (rowValue[0]) {
        case 'status': {
          const studentStatus = rowValue[1] as StudentStatus;

          return STUDENT_STATUS_LABELS[studentStatus];
        }
        default:
          break;
      }

      if (typeof rowValue[1] === 'number') {
        return String(rowValue[1]);
      }

      return typeof rowValue[1] === 'string' ? rowValue[1] : '';
    });
  });

  return exportCsv(arrayToCsv(rowArrays));
};
