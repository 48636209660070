import React, { FC, useContext, useEffect, useState } from 'react';
import { OptionTypeBase } from 'react-select';

import { ChatContext } from '../../../../context/ChatContext';
import FilterComponent from '../../../../components/pages/chat/Filter';

interface Props {
  anchorEl: HTMLButtonElement | HTMLAnchorElement | null;
}

const Filter: FC<Props> = ({ anchorEl }) => {
  const [parentName, setParentName] = useState('');
  const [studentNum, setStudentNum] = useState('');
  const [studentName, setStudentName] = useState('');
  const [studentGrade, setStudentGrade] = useState<OptionTypeBase[]>([]);
  const [studentStatus, setStudentStatus] = useState<OptionTypeBase[]>([]);
  const [examCramSchool, setExamCramSchool] = useState<OptionTypeBase[]>([]);
  const [schoolName, setSchoolName] = useState('');
  const { rooms, parents, studentBasics, setFilteredRooms } = useContext(
    ChatContext,
  );

  const reset = () => {
    setParentName('');
    setStudentNum('');
    setStudentName('');
    setStudentGrade([]);
    setStudentStatus([]);
    setExamCramSchool([]);
    setSchoolName('');
  };

  useEffect(() => {
    const roomMaps = rooms.map((room) => {
      const parentInfo = parents.find((parent) =>
        room.room_users.includes(parent.id),
      );
      const students = studentBasics.filter((basic) =>
        room.room_users.includes(basic.parent_id),
      );

      return {
        room,
        parentInfo,
        students,
      };
    });
    const filteredRooms = roomMaps
      .filter(({ parentInfo }) => {
        if (!parentName) return true;
        if (!parentInfo) return false;

        return parentInfo.name.includes(parentName);
      })
      .filter(({ students }) => {
        if (!studentNum) return true;
        if (!students.length) return false;

        return students.some((student) =>
          student.student_num.includes(studentNum),
        );
      })
      .filter(({ students }) => {
        if (!studentName) return true;
        if (!students.length) return false;

        return students.some((student) =>
          `${student.last_name} ${student.first_name}`.includes(studentName),
        );
      })
      .filter(({ students }) => {
        if (!studentGrade.length) return true;
        if (!students.length) return false;

        return students.some((student) =>
          studentGrade.some(({ value }) => value === student.grade),
        );
      })
      .filter(({ students }) => {
        if (!studentStatus.length) return true;
        if (!students.length) return false;

        return students.some((student) =>
          studentStatus.some(({ value }) => value === student.status),
        );
      })
      .filter(({ students }) => {
        if (!examCramSchool.length) return true;
        if (!students.length) return false;

        return students.some((student) =>
          examCramSchool.some(
            ({ value }) => value === student.enrolled_cram_school,
          ),
        );
      })
      .slice()
      .sort((a) => {
        if (!a.parentInfo) return -1;

        return 0;
      })
      .filter(({ students }) => {
        if (!schoolName) return true;
        if (!students.length) return false;

        return students.some((student) =>
          student.school_name.includes(schoolName),
        );
      })
      .map(({ room }) => room);
    setFilteredRooms(filteredRooms);
    // eslint-disable-next-line
  }, [
    parentName,
    studentNum,
    studentName,
    studentGrade,
    studentStatus,
    examCramSchool,
    schoolName,
    rooms,
  ]);

  return (
    <FilterComponent
      anchorEl={anchorEl}
      parentName={parentName}
      studentNum={studentNum}
      studentName={studentName}
      studentGrade={studentGrade}
      studentStatus={studentStatus}
      examCramSchool={examCramSchool}
      schoolName={schoolName}
      setParentName={setParentName}
      setStudentNum={setStudentNum}
      setStudentName={setStudentName}
      setStudentGrade={setStudentGrade}
      setStudentStatus={setStudentStatus}
      setExamCramSchool={setExamCramSchool}
      setSchoolName={setSchoolName}
      reset={reset}
    />
  );
};

export default Filter;
