import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useAsync } from 'react-use';
import { WeekDefaultList } from '../../../types/table/week-default-list';
import getStudentBasic from '../../../domain/services/get-student-basics';
import { AppContext } from '../../context/AppContext';
import { EnrolledCramSchoolContext } from '../../context/EnrolledCramSchoolContext';
import { DayOfWeekContext } from '../../context/DayOfWeekContext';
import {
  getLectureItem,
  jaLectureDaysArr,
  LECTURE_DAY_KEYS,
  StudentLectures,
} from '../../../domain/models/student-lectures';
import {
  ELEMENTARY_SCHOOL_GRADES,
  NOTES_JAPAN_SCHOOLS,
} from '../../../data/form-data';
import getStudentLectures from '../../../domain/services/get-student-lectures';
import { AdminUser } from '../../../domain/models/admin-user';
import getAdminUsers from '../../../domain/services/get-admin-users';
import { StudentBasic } from '../../../domain/models/student-basic';

export type Result = {
  loading: boolean;
  weekDefaultList: WeekDefaultList[];
  setWeekDefaultList: Dispatch<SetStateAction<WeekDefaultList[]>>;
  studentBasics?: StudentBasic[];
  studentLectures?: StudentLectures[];
  staffs?: AdminUser[];
};

const useFetchWeekDefaultLists = (): Result => {
  const { adminUser } = useContext(AppContext);
  const [weekDefaultList, setWeekDefaultList] = useState<WeekDefaultList[]>([]);
  const [studentBasics, setStudentBasics] = useState<StudentBasic[]>([]);
  const [studentLectures, setStudentLectures] = useState<StudentLectures[]>([]);
  const [staffs, setStaffs] = useState<AdminUser[]>([]);
  const { enrolledCramSchool } = useContext(EnrolledCramSchoolContext);
  const { dayOfWeek } = useContext(DayOfWeekContext);

  const enrolledCramSchoolQueryParams = ['editor', 'viewer'].includes(
    adminUser.role,
  )
    ? [
        {
          fieldPath: 'enrolled_cram_school',
          opStr: '==',
          value: adminUser.enrolled_cram_school,
        },
      ]
    : [
        {
          fieldPath: 'enrolled_cram_school',
          opStr: '==',
          value: enrolledCramSchool,
        },
      ];

  const { loading } = useAsync(async () => {
    const defaultQuery = [
      {
        fieldPath: 'status',
        opStr: 'in',
        value: ['enrolled', 'recess'],
      },
    ];

    const studentQueryParams = ['editor', 'viewer'].includes(adminUser.role)
      ? [
          ...defaultQuery,
          {
            fieldPath: 'enrolled_cram_school',
            opStr: '==',
            value: adminUser.enrolled_cram_school,
          },
        ]
      : [
          ...defaultQuery,
          {
            fieldPath: 'enrolled_cram_school',
            opStr: '==',
            value: enrolledCramSchool,
          },
        ];

    const { studentBasics: targetStudentBasics } = await getStudentBasic({
      queryParams: studentQueryParams,
    });
    setStudentBasics(targetStudentBasics);

    const { studentLectures: targetStudentLectures } = await getStudentLectures(
      {},
    );
    setStudentLectures(targetStudentLectures);

    // テーブルリスト用(useTable用)にデータを整形する
    // （曜日のフィルタリングは別の箇所で行う）
    const formattedWeekDefaults: WeekDefaultList[] = [];
    targetStudentBasics.forEach((studentBasic) => {
      const isNotesJapan = NOTES_JAPAN_SCHOOLS.some(
        (v) => v === studentBasic.enrolled_cram_school,
      );
      const isNotesElementarySchool =
        isNotesJapan &&
        ELEMENTARY_SCHOOL_GRADES.some((v) => v === studentBasic?.grade);

      const studentLecture = targetStudentLectures.find(
        (sl) => sl.student_id === studentBasic.id,
      );

      LECTURE_DAY_KEYS.forEach((lectureDayKey, index) => {
        const CurrentDayOfWeek = jaLectureDaysArr[index];

        formattedWeekDefaults.push({
          id: studentLecture?.id ?? '',
          student_id: studentBasic.id,
          student_num: studentBasic.student_num,
          student_name: `${studentBasic.last_name ?? ''}
                ${studentBasic.first_name ?? ''}`,
          grade: studentBasic.grade ?? '',
          day_of_week: CurrentDayOfWeek,
          lecture_1: getLectureItem(
            studentLecture,
            CurrentDayOfWeek,
            'lecture_1',
            isNotesJapan,
            isNotesElementarySchool,
          ),
          lecture_2: getLectureItem(
            studentLecture,
            CurrentDayOfWeek,
            'lecture_2',
            isNotesJapan,
            isNotesElementarySchool,
          ),
          lecture_3: getLectureItem(
            studentLecture,
            CurrentDayOfWeek,
            'lecture_3',
            isNotesJapan,
            isNotesElementarySchool,
          ),
          lecture_4: getLectureItem(
            studentLecture,
            CurrentDayOfWeek,
            'lecture_4',
            isNotesJapan,
            isNotesElementarySchool,
          ),
          lecture_5: getLectureItem(
            studentLecture,
            CurrentDayOfWeek,
            'lecture_5',
            isNotesJapan,
            isNotesElementarySchool,
          ),
        });
      });
    });

    setWeekDefaultList(formattedWeekDefaults);

    const { adminUsers } = await getAdminUsers({
      queryParams: enrolledCramSchoolQueryParams,
    });
    setStaffs(adminUsers);
  }, [enrolledCramSchool, dayOfWeek]);

  return {
    loading,
    weekDefaultList,
    setWeekDefaultList,
    studentBasics,
    studentLectures,
    staffs,
  };
};

export default useFetchWeekDefaultLists;
