import React, { FC, useContext } from 'react';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Column } from 'react-table';

import Table from '../Table';
import { ParentList } from '../../../../../types/table/parent-list';
import { TelInfo } from '../../../../../domain/models/parent-info';
import DefaultColumnFilter from '../DefaultColumnFilter';
import useReactTableFilter from '../../../../hooks/useReactTableFilter';
import { StudentBasic } from '../../../../../domain/models/student-basic';
import DotProgress from '../../../atoms/feedback/DotProgress';
import exportParentListsCsv from '../../../../../helpers/exportParentListsCsv';
import { FilterContext } from '../../../../context/FilterContext';

export type Props = {
  loading: boolean;
  parentList: ParentList[];
  columns: Column<ParentList>[];
};

const ParentListComponent: FC<Props> = ({ loading, parentList, columns }) => {
  const history = useHistory();
  const filterTypes = useReactTableFilter();
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const { setFilterKey } = useContext(FilterContext);
  const pageName = 'parent-list';

  useEffectOnce(() => {
    setFilterKey(pageName);
  });

  return (
    <div>
      <Table<ParentList>
        classes={{
          root: clsx('table-container'),
          table: clsx(
            'table',
            'is-fullwidth',
            'is-striped',
            'is-hoverable',
            'list-table',
          ),
        }}
        tableOptions={{
          data: parentList,
          columns,
          filterTypes,
          defaultColumn,
          manualPagination: false,
          initialState: {
            pageSize: 50,
          },
        }}
        theadThRender={(column) => {
          return (
            <th {...column.getHeaderProps()}>
              {
                // Render the header
                column.render('Header')
              }
            </th>
          );
        }}
        tbodyTdRender={(row, cell) => {
          switch (cell.column.id) {
            case 'tel': {
              const value = cell.value as TelInfo;

              return (
                <td
                  {...cell.getCellProps()}
                >{`${value.num}(${value.relationship})`}</td>
              );
            }
            case 'emergency_contacts': {
              const values = cell.value as TelInfo[];

              return (
                <td {...cell.getCellProps()}>
                  {values.map((value, index) => {
                    const v = `${value.num}(${value.relationship})`;
                    const key = `${cell.getCellProps().key}-${index}`;

                    return values.length > index ? (
                      <span key={key}>
                        {v}
                        <br />
                      </span>
                    ) : (
                      <span key={key}>{v}</span>
                    );
                  })}
                </td>
              );
            }
            case 'students': {
              const studentBasics = cell.value as StudentBasic[];

              return (
                <td {...cell.getCellProps()}>
                  {studentBasics.map((value, index) => {
                    const key = `${cell.getCellProps().key}-${value.id}`;
                    const name = `【${value.student_num}】${value.last_name} ${value.first_name}`;

                    return studentBasics.length > index ? (
                      <span key={key}>
                        {name}
                        <br />
                      </span>
                    ) : (
                      <span key={key}>{name}</span>
                    );
                  })}
                </td>
              );
            }
            default:
              return (
                <td {...cell.getCellProps()}>
                  {
                    // Render the cell contents
                    cell.render('Cell')
                  }
                </td>
              );
          }
        }}
        onClick={(row) => {
          const parentId: string | undefined =
            row.values.students instanceof Array && row.values.students.length
              ? (row.values.students[0] as StudentBasic).parent_id
              : undefined;
          history.push('/parent/detail', { parentId });
        }}
        pageName={pageName}
        exportCsvHelper={exportParentListsCsv}
      />
      {loading && <DotProgress />}
    </div>
  );
};

export default ParentListComponent;
