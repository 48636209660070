import { useAsync } from 'react-use';
import { useContext } from 'react';
import { DateTime } from 'luxon';

import { ClassTransfer } from '../../../domain/models/class-transfer';
import { QueryParams } from '../../../types/firestore-simple';
import { StudentBasic } from '../../../domain/models/student-basic';
import getClassTransfer from '../../../domain/services/get-class-transfer';
import getStudentBasics from '../../../domain/services/get-student-basics';
import { AppContext } from '../../context/AppContext';
import { dateFormat } from '../../../data/form-data';

export type Result = {
  loading: boolean;
  classTransfers: ClassTransfer[] | undefined;
  studentBasics: (StudentBasic | undefined)[] | undefined;
  error: Error | undefined;
};

const useFetchClassTransfers = (): Result => {
  const { adminUser } = useContext(AppContext);
  const queryParams: QueryParams<ClassTransfer> = ['editor', 'viewer'].includes(
    adminUser.role,
  )
    ? [
        {
          fieldPath: 'enrolled_cram_school',
          opStr: '==',
          value: adminUser.enrolled_cram_school,
        },
      ]
    : [];
  const state = useAsync(async () => {
    const { classTransfers } = await getClassTransfer({ queryParams });
    const filteredTransfers = classTransfers.filter((transfer) => {
      // 申請待ちは必ず表示する
      if (transfer.status === 'applying') return true;
      const today = DateTime.local();

      // 31日前まで申請できるのでそれ以降を表示
      return (
        DateTime.fromFormat(transfer.before_date, dateFormat) >=
          today.minus({ days: 31 }) &&
        DateTime.fromFormat(transfer.after_date, dateFormat) >=
          today.minus({ days: 31 })
      );
    });
    const sortedTransfers = filteredTransfers.sort((a, b) => {
      return a.before_date > b.before_date ? 1 : -1;
    });
    const studentBasics = await Promise.all(
      filteredTransfers.map(async (transfer) => {
        const res = await getStudentBasics({ id: transfer.student_id });

        return res.studentBasics.length ? res.studentBasics[0] : undefined;
      }),
    );

    return { classTransfers: sortedTransfers, studentBasics };
  }, []);
  if (state.error?.message) console.error(state.error);

  return {
    loading: state.loading,
    classTransfers: state.value?.classTransfers,
    studentBasics: state.value?.studentBasics,
    error: state.error,
  };
};

export default useFetchClassTransfers;
