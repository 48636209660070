import { useContext } from 'react';
import { IconType } from 'react-icons';
import {
  RiParentFill,
  AiFillHome,
  IoMdSchool,
  ImPencil2,
  BsListTask,
  BsCalendarFill,
  BsFillChatSquareDotsFill,
  FaFileInvoice,
  FaUsers,
  BsInfoCircleFill,
  AiFillSchedule,
  BsCalendar,
  CgCalendarToday,
  BiNews,
  AiTwotoneSchedule,
  AiFillMoneyCollect,
} from 'react-icons/all';

import { Role } from '../../data/form-data';
import { AppContext } from '../context/AppContext';

export type LinkItem = {
  id: string;
  icon: IconType;
  title: string;
  roles: Role[];
  path: string;
};

export const linkItems: LinkItem[] = [
  {
    id: 'top',
    icon: AiFillHome,
    title: 'TOP',
    roles: ['administrator', 'headquarters', 'editor', 'viewer'],
    path: '/',
  },
  {
    id: 'parent',
    icon: RiParentFill,
    title: '保護者情報マスター',
    roles: ['administrator', 'headquarters', 'editor', 'viewer'],
    path: '/parent',
  },
  {
    id: 'student',
    icon: IoMdSchool,
    title: '生徒情報マスター',
    roles: ['administrator', 'headquarters', 'editor', 'viewer'],
    path: '/student',
  },
  {
    id: 'student-task',
    icon: BsListTask,
    title: '生徒タスクマスター',
    roles: ['administrator', 'headquarters', 'editor', 'viewer'],
    path: '/student-task',
  },
  {
    id: 'student-score',
    icon: ImPencil2,
    title: '成績情報マスター',
    roles: ['administrator', 'headquarters', 'editor', 'viewer'],
    path: '/student-score',
  },
  {
    id: 'class-transfer',
    icon: BsCalendarFill,
    title: '授業振替申請マスター',
    roles: ['administrator', 'headquarters', 'editor', 'viewer'],
    path: '/class-transfer',
  },
  {
    id: 'chat',
    icon: BsFillChatSquareDotsFill,
    title: 'チャット機能',
    roles: ['administrator', 'headquarters', 'editor'],
    path: '/chat',
  },
  {
    id: 'invoice',
    icon: FaFileInvoice,
    title: '請求書管理マスター',
    roles: ['administrator', 'headquarters', 'editor', 'viewer'],
    path: '/invoice',
  },
  {
    id: 'staff',
    icon: FaUsers,
    title: '社員マスター',
    roles: ['administrator', 'headquarters', 'editor', 'viewer'],
    path: '/staff',
  },
  {
    id: 'periodic-info',
    icon: BsInfoCircleFill,
    title: '定期情報登録マスター',
    roles: ['administrator', 'headquarters', 'editor'],
    path: '/periodic-info/detail',
  },
  {
    id: 'timetable-detail',
    icon: AiFillSchedule,
    title: '授業日程表',
    roles: ['administrator', 'headquarters', 'editor', 'viewer'],
    path: '/timetable/detail',
  },
  {
    id: 'school-calendar',
    icon: BsCalendar,
    title: '校舎マスター',
    roles: ['administrator', 'headquarters', 'editor'],
    path: '/school-calendar/detail',
  },
  {
    id: 'week-default',
    icon: CgCalendarToday,
    title: '曜日マスター',
    roles: ['administrator', 'headquarters', 'editor'],
    path: '/week-default',
  },
  {
    id: 'daily-report-detail',
    icon: BiNews,
    title: '講師日報',
    roles: ['administrator', 'headquarters', 'editor'],
    path: '/daily-report/detail',
  },
  {
    id: 'work-shift-detail',
    icon: AiTwotoneSchedule,
    title: 'シフト管理',
    roles: ['administrator', 'headquarters', 'editor'],
    path: '/work-shift/detail',
  },
  {
    id: 'salary',
    icon: AiFillMoneyCollect,
    title: '給与データ',
    roles: ['administrator', 'headquarters', 'editor'],
    path: '/salary',
  },
];

const useLinkItems = (): { linkItems: LinkItem[] } => {
  const { adminUser } = useContext(AppContext);

  return {
    linkItems: linkItems.filter((item) => item.roles.includes(adminUser.role)),
  };
};

export default useLinkItems;
