import React, { FC, useContext } from 'react';

import { defaultParentInfo } from '../../../../../domain/models/parent-info';
import { ChatContext } from '../../../../context/ChatContext';
import { Room } from '../../../../../domain/models/rooms';
import RoomItem from '../../../atoms/chat/RoomItem';
import DotProgress from '../../../atoms/feedback/DotProgress';

interface Props {
  loading: boolean;
  error: Error | undefined;
  value: Room[] | undefined;
}

const Rooms: FC<Props> = ({ loading, error, value }) => {
  const { filteredRooms, parents, studentBasics } = useContext(ChatContext);

  if (loading) return <DotProgress />;

  if (error) {
    console.error(error);

    return <p>ルームの取得に失敗しました</p>;
  }

  if (!value?.length) return <p>ルームが存在しません</p>;

  return (
    <div className="rooms">
      {filteredRooms.map((room) => {
        const parentInfo = parents.find((parent) =>
          room.room_users.includes(parent.id),
        );
        const students = studentBasics.filter((basic) =>
          room.room_users.includes(basic.parent_id),
        );

        return (
          <RoomItem
            key={room.id}
            room={room}
            parentInfo={parentInfo || defaultParentInfo}
            studentBasics={students}
          />
        );
      })}
    </div>
  );
};

export default Rooms;
