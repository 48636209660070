import { useState } from 'react';

import firebase from 'firebase';
import createClassTransfer from '../../../domain/services/create-class-transfer';
import useSnackbar, { SnackbarState } from '../useSnackbar';
import { ClassTransfer } from '../../../domain/models/class-transfer';
import getStudentBasics from '../../../domain/services/get-student-basics';
import getRoomByParentId from '../../../domain/services/get-room-by-parent-id';
import createTalk from '../../../domain/services/create-talk';
import { Talk } from '../../../domain/models/talk';
import { AdminUser } from '../../../domain/models/admin-user';
import createRoom from '../../../domain/services/create-room';
import { Room } from '../../../domain/models/rooms';

export type Result = {
  creating: boolean;
  create: (classTransfer: ClassTransfer, adminUser: AdminUser) => void;
  state: SnackbarState;
  close: () => void;
};

const useCreateClassTransfer = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (classTransfer: ClassTransfer, adminUser: AdminUser) => {
    setCreating(true);
    try {
      const result = await createClassTransfer(classTransfer);
      if (!result.id) throw new Error();

      if (classTransfer.status !== 'applying') {
        const { studentBasics } = await getStudentBasics({
          id: classTransfer.student_id,
        });

        if (studentBasics.length) {
          const parentId = studentBasics[0].parent_id;
          const room = await getRoomByParentId(parentId);

          if (room) {
            const message = `${classTransfer.before_date}(${
              classTransfer.before_class
            })から${classTransfer.after_date}(${
              classTransfer.after_class
            })への振替は承認${
              classTransfer.status === 'approval'
                ? 'されました。'
                : 'されませんでした。'
            }`;

            await createTalk({
              room_id: room.id,
              user_id: adminUser.id,
              message,
              storage_path: '',
              date: firebase.firestore.Timestamp.now(),
            } as Talk);

            await createRoom({
              ...room,
              update_date: firebase.firestore.Timestamp.now(),
            } as Room);
          }
        }
      }

      open('success', '授業振替申請の更新に成功しました');
    } catch (e) {
      console.error(e);
      open(
        'error',
        '授業振替申請の更新ができませんでした。もう一度お試しください。',
      );
    } finally {
      setCreating(false);
    }
  };

  return { creating, create, close, state };
};

export default useCreateClassTransfer;
