import { HasId } from '@firestore-simple/web/dist/types';

export type MiddleSchoolReportScores = {
  japanese: number;
  society: number;
  math: number;
  science: number;
  music: number;
  art: number;
  health_physical: number;
  tech_home: number;
  english: number;
};

export type MiddleSchoolReport = HasId & {
  student_id: string;
  '1st_grader_report_1': MiddleSchoolReportScores;
  '1st_grader_report_2': MiddleSchoolReportScores;
  '1st_grader_report_3': MiddleSchoolReportScores;
  '2nd_grader_report_1': MiddleSchoolReportScores;
  '2nd_grader_report_2': MiddleSchoolReportScores;
  '2nd_grader_report_3': MiddleSchoolReportScores;
  '3rd_grader_report_1': MiddleSchoolReportScores;
  '3rd_grader_report_2': MiddleSchoolReportScores;
  '3rd_grader_report_3': MiddleSchoolReportScores;
};

export const getPrevKey = (
  object: { [key: string]: any },
  currentKey: string,
): string | undefined => {
  const objectKeys = Object.keys(object);
  const keyIndex = objectKeys.findIndex((value) => value === currentKey);
  const prevIndex = keyIndex - 1;

  return prevIndex >= 0 ? objectKeys[prevIndex] : undefined;
};

export const getSubjectScores = (
  subjectScores: MiddleSchoolReportScores,
): MiddleSchoolReportScores => {
  return {
    japanese: subjectScores.japanese || 0,
    society: subjectScores.society || 0,
    math: subjectScores.math || 0,
    science: subjectScores.science || 0,
    music: subjectScores.music || 0,
    art: subjectScores.art || 0,
    health_physical: subjectScores.health_physical || 0,
    tech_home: subjectScores.tech_home || 0,
    english: subjectScores.english || 0,
  };
};
