import { useState } from 'react';

import { PeriodicInfo } from '../../../domain/models/periodic-info';
import createPeriodicInfo from '../../../domain/services/create-periodic-info';
import useSnackbar, { SnackbarState } from '../useSnackbar';

export type Result = {
  creating: boolean;
  create: (periodicInfo: PeriodicInfo) => Promise<string | undefined>;
  state: SnackbarState;
  close: () => void;
};

const useCreatePeriodicInfo = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (
    periodicInfo: PeriodicInfo,
  ): Promise<string | undefined> => {
    setCreating(true);

    const result = await createPeriodicInfo(periodicInfo);

    open(
      result.id ? 'success' : 'error',
      result.id
        ? '定期情報の更新に成功しました'
        : '定期情報の更新ができませんでした。もう一度お試しください。',
    );
    setCreating(false);

    return result.id;
  };

  return { creating, create, close, state };
};

export default useCreatePeriodicInfo;
