import React, { ChangeEvent, PropsWithChildren, useCallback } from 'react';
import { TablePagination } from '@material-ui/core';
import { TableInstance } from 'react-table';

// eslint-disable-next-line @typescript-eslint/ban-types
const Pagination = <T extends {}>({
  instance,
  pageName,
}: PropsWithChildren<{ instance: TableInstance<T>; pageName: string }>) => {
  const {
    state: { pageIndex, pageSize, rowCount = instance.rows.length },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = instance;

  const handleChangePage = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      newPage: number,
    ) => {
      if (newPage === pageIndex + 1) {
        nextPage();
      } else if (newPage === pageIndex - 1) {
        previousPage();
      } else {
        gotoPage(newPage);
      }
    },
    [gotoPage, nextPage, pageIndex, previousPage],
  );

  const onChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
      localStorage.setItem(`${pageName}-rows-per-page`, event.target.value);
    },
    [pageName, setPageSize],
  );

  return rowCount ? (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
      component="div"
      count={rowCount}
      rowsPerPage={pageSize}
      page={pageIndex}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  ) : null;
};

export default Pagination;
