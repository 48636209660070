import { createContext, Dispatch, SetStateAction } from 'react';
import { JaLectureDay } from '../../domain/models/student-lectures';

export type DayOfWeekContextType = {
  dayOfWeek: JaLectureDay | '';
  setDayOfWeek: Dispatch<SetStateAction<JaLectureDay>>;
};

export const DayOfWeekContext = createContext<DayOfWeekContextType>({
  dayOfWeek: '',
  setDayOfWeek: () => null,
});
