import { HasId } from '@firestore-simple/web/dist/types';
import { Role } from '../../data/form-data';

export type Address = {
  postal_code: string;
  address: string;
};

export type PayrollTransfer = {
  bank_name: string;
  branch_name: string;
  account_number: string;
  account_name: string;
};

export type Transportation = {
  default_transportation: string;
  cost_per_day: number;
};

export type AdminUser = HasId & {
  uid: string;
  staff_num?: string;
  staff_code?: string;
  role: Role;
  employment_status: string;
  enrolled_cram_school: string;
  first_name: string;
  first_name_kana: string;
  last_name: string;
  last_name_kana: string;
  rank?: string;
  birthday?: string;
  phone_number: string;
  mail_address: string;
  hire_date?: string;
  termination_date?: string;
  current_address?: Address;
  resident_card_address?: Address;
  parents_home_address?: Address & { phone_number: string };
  image_path?: string;
  payroll_transfer?: PayrollTransfer;
  transportation?: Transportation;
};

// シフト管理にて授業数等を自動計算するためのAdminUser型
export type SelectedStaff = AdminUser & {
  imageUrl?: string; // image_pathを元にしたイメージ画像のURL
  shiftNum?: number; // 各コマに対する講師の持つ授業数
  isHalfTime?: boolean; // 前半or後半の授業どちらかしか持っていない場合（1コマ最大90分（プレイズの場合は60分）で、前半or後半どちらかの授業しか持っていない場合は45分で勤務時間を計算するのでそれを判定するための値）
  hasSettlementSubject?: boolean; // 定着授業を持っているか否か（※定着授業:講師1人で何十人でも対応可能。ただし、同じ講師が定着授業と理解授業を同時に担当することはできない）
  understandingSubjectNum?: number; // 理解授業を持っている数（※理解授業:講師1人で3人まで）
};

export const defaultAdminUser: AdminUser = {
  id: '',
  uid: '',
  staff_num: '',
  staff_code: '',
  role: 'administrator',
  employment_status: '',
  enrolled_cram_school: '',
  first_name: '',
  first_name_kana: '',
  last_name: '',
  last_name_kana: '',
  rank: '',
  birthday: '',
  phone_number: '',
  mail_address: '',
  hire_date: '',
  termination_date: '',
  current_address: { postal_code: '', address: '' },
  resident_card_address: { postal_code: '', address: '' },
  parents_home_address: { postal_code: '', address: '', phone_number: '' },
  image_path: '',
  payroll_transfer: {
    bank_name: '',
    branch_name: '',
    account_number: '',
    account_name: '',
  },
  transportation: { default_transportation: '', cost_per_day: 0 },
};
