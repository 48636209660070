import { Row } from 'react-table';
import exportCsv from '../utils/array/exportCsv';
import arrayToCsv from '../utils/array/arrayToCsv';
import { MiddleSchoolReportList } from '../types/table/middle-school-report-list';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (rows: Row<MiddleSchoolReportList>[]) => {
  const rowArrays = rows.map((row) => {
    const rowValues = Object.entries(row.values).filter((v) => {
      return ![
        'id',
        'student_id',
        'studentBasic',
        'reportKey',
        'middleSchoolReport',
      ].includes(v[0]);
    });

    return rowValues.map((rowValue): string => {
      if (typeof rowValue[1] === 'number') {
        return String(rowValue[1]);
      }

      return typeof rowValue[1] === 'string' ? rowValue[1] : '';
    });
  });

  return exportCsv(arrayToCsv(rowArrays));
};
