import { useState } from 'react';

import useSnackbar, { SnackbarState } from '../useSnackbar';
import createTimetable from '../../../domain/services/create-timetable';
import { Timetable } from '../../../domain/models/timetable';

export type Result = {
  creating: boolean;
  create: (timetable: Timetable) => Promise<string | undefined>;
  state: SnackbarState;
  close: () => void;
};

const useCreateTimetable = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (timetable: Timetable) => {
    setCreating(true);
    const result = await createTimetable(timetable);
    open(
      result.id ? 'success' : 'error',
      result.id
        ? '生徒情報の更新に成功しました'
        : '生徒情報の更新ができませんでした。もう一度お試しください。',
    );
    setCreating(false);

    return result.id;
  };

  return { creating, create, close, state };
};

export default useCreateTimetable;
