import React, { FC, useContext } from 'react';
import { useEffectOnce } from 'react-use';
import { Column } from 'react-table';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { ButtonProps } from '@material-ui/core';
import { DateTime } from 'luxon';

import { SalaryList } from '../../../../../types/table/salary-list';
import SalaryListComponent from '../../../../components/molecules/table/SalaryList';
import useFetchSalaryLists from '../../../../hooks/fetch/useFetchSalaryLists';
import { EnrolledCramSchoolContext } from '../../../../context/EnrolledCramSchoolContext';
import {
  SelectAllEnrolledCramSchools2,
  NotesJapanSchool,
  NotesPraiseSchool,
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_SCHOOLS,
} from '../../../../../data/form-data';
import SelectColumnFilter from '../../../../components/molecules/table/SelectColumnFilter';
import useRole from '../../../../hooks/useRole';
import { AppContext } from '../../../../context/AppContext';
import { YearMonthContext } from '../../../../context/YearMonthContext';

const columns: Column<SalaryList>[] = [
  {
    Header: 'スタッフ番号',
    accessor: 'staff_num',
    filter: 'includesStringFilter',
  },
  { Header: '氏名', accessor: 'name', filter: 'includesStringFilter' },
  {
    Header: '在籍校舎',
    accessor: 'enrolled_cram_school',
    disableFilters: true,
  },
  {
    Header: '雇用形態',
    accessor: 'employment_status',
    filter: 'includeSelectValue',
    Filter: SelectColumnFilter,
  },
  {
    Header: '出勤日数',
    accessor: 'worked_days',
    disableFilters: true,
  },
  {
    Header: '授業時間',
    accessor: 'lecture_time',
    disableFilters: true,
  },
  {
    Header: '事研時間',
    accessor: 'office_training_time',
    disableFilters: true,
  },
  {
    Header: '遅刻回数',
    accessor: 'behind_num',
    disableFilters: true,
  },
  {
    Header: '遅刻時間',
    accessor: 'behind_time',
    disableFilters: true,
  },
  {
    Header: '早退回数',
    accessor: 'early_leave_num',
    disableFilters: true,
  },
  {
    Header: '早退時間',
    accessor: 'early_leave_time',
    disableFilters: true,
  },
];

const SalaryListContainer: FC = () => {
  const { loading, salaryList } = useFetchSalaryLists();
  const { isAdministrator, isHeadquarters } = useRole();
  const { adminUser } = useContext(AppContext);
  const allSelectEnrolledCramSchools = [
    '全校舎',
    ...NOTES_JAPAN_SCHOOLS,
    ...NOTES_PRAISE_SCHOOLS,
  ];
  const selectEnrolledCramSchools =
    isAdministrator || isHeadquarters
      ? allSelectEnrolledCramSchools
      : [adminUser.enrolled_cram_school];
  const { enrolledCramSchool, setEnrolledCramSchool } = useContext(
    EnrolledCramSchoolContext,
  );
  const { yearMonth, setYearMonth } = useContext(YearMonthContext);

  useEffectOnce(() => {
    setEnrolledCramSchool(
      isAdministrator || isHeadquarters
        ? allSelectEnrolledCramSchools[0]
        : adminUser.enrolled_cram_school,
    );
  });

  const onChange: SelectInputProps['onChange'] = (event) => {
    setEnrolledCramSchool(
      event.target.value as
        | SelectAllEnrolledCramSchools2
        | NotesJapanSchool
        | NotesPraiseSchool,
    );
  };

  const onClickPreviousMonth: ButtonProps['onClick'] = () => {
    const previousMonth = DateTime.fromFormat(yearMonth, 'yyyy/MM')
      .minus({ months: 1 })
      .toFormat('yyyy/MM');
    setYearMonth(previousMonth);
  };

  const onClickNextMonth: ButtonProps['onClick'] = () => {
    const nextMonth = DateTime.fromFormat(yearMonth, 'yyyy/MM')
      .plus({ months: 1 })
      .toFormat('yyyy/MM');
    setYearMonth(nextMonth);
  };

  return (
    <SalaryListComponent
      loading={loading}
      salaryList={salaryList}
      columns={columns}
      toolbarSelectProps={{
        label: '',
        value: enrolledCramSchool,
        options: selectEnrolledCramSchools.map((school) => ({
          label: school,
          value: school,
        })),
        onChange,
      }}
      onClickPreviousMonth={onClickPreviousMonth}
      onClickNextMonth={onClickNextMonth}
    />
  );
};

export default SalaryListContainer;
