import React from 'react';
import { FieldError } from 'react-hook-form';
import Select, { Props as SelectProps } from 'react-select';

import clsx from 'clsx';
import ErrorMessage from '../ErrorMessage';

export type Props = {
  label?: string;
  selectProps: SelectProps;
  error?: FieldError;
  isAsterisk?: boolean;
  className?: string;
};

export default ({
  label,
  selectProps,
  error,
  isAsterisk,
  className,
}: Props) => {
  return (
    <div className={clsx('field', className)}>
      {label && (
        <p className="label">
          {label}
          {isAsterisk && <span className="has-text-danger ml-1">※</span>}
        </p>
      )}
      <div className="control">
        <Select
          {...selectProps}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          menuPortalTarget={document.querySelector('body')}
        />
      </div>
      <ErrorMessage message={error?.message} className="mt-2" />
    </div>
  );
};
