import { UseFormMethods } from 'react-hook-form/dist/types';
import React, { FC } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import { DateTime } from 'luxon';
import { TextField } from '@material-ui/core';
import { Motion, spring } from 'react-motion';
import { CgCloseO } from 'react-icons/cg';
import Select from 'react-select';
import clsx from 'clsx';
import { FaSave } from 'react-icons/all';
import useRole from '../../../../hooks/useRole';
import { BulkChangeInvoice } from '../../../../../types/form/bulk-change-invoice';
import { InvoiceItem } from '../../../../../domain/models/student-invoice';
import Button from '../../../atoms/button/Button';
import Textarea from '../../../atoms/form/Textarea';
import DateField from '../../../atoms/form/DatePicker';
import {
  INVOICE_STATUS,
  INVOICE_STATUS_LABELS,
} from '../../../../../data/form-data';
import ErrorMessage from '../../../atoms/form/ErrorMessage';
import Backdrop from '../../../atoms/feedback/Backdrop';
import { SnackbarState } from '../../../../hooks/useSnackbar';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import { INVOICE_ITEM_AUTOCOMPLETES } from '../../../../../data/data-list';

export type Props = {
  formMethods: UseFormMethods<BulkChangeInvoice>;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  creating: boolean;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
};

const InvoiceBulkChangeForm: FC<Props> = ({
  formMethods,
  onSubmit,
  creating,
  snackbarState,
  snackbarClose,
}) => {
  const { isViewer } = useRole();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { errors, register, control, watch, setValue, trigger } = formMethods;
  const { fields, append, remove } = useFieldArray<InvoiceItem>({
    control,
    name: 'invoiceItems',
  });

  const onChangeInvoiceItemName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const presetItem = INVOICE_ITEM_AUTOCOMPLETES.find((v) => {
      return v.name === event.target.value;
    });
    if (presetItem) {
      const { index } = event.target.dataset;
      if (!index) return;
      const amountField = document.getElementsByName(
        `invoice_items[${index}].amounts`,
      );
      (amountField[0] as HTMLInputElement).value = String(presetItem.amount);
    }
  };

  return (
    <div className="modal-box">
      <div className="modal-inner">
        <form onSubmit={onSubmit}>
          <datalist id="keywords">
            {INVOICE_ITEM_AUTOCOMPLETES.map((value) => (
              <option key={value.name} value={value.name}>
                {value.name}
              </option>
            ))}
          </datalist>
          <div>
            <table>
              <thead>
                <tr>
                  <th className="th-item">項目</th>
                  <th className="th-tax">税込</th>
                  <th className="th-amount">金額</th>
                  <th className="th-remove">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {fields.map((field, index) => {
                  const errorInvoiceItems = errors.invoice_items;

                  return (
                    <Motion
                      defaultStyle={{ scale: 0.9, opacity: 0 }}
                      style={{ scale: spring(1), opacity: 1 }}
                      key={field.id}
                    >
                      {(style) => (
                        <tr
                          style={{
                            transform: `scale(${style.scale})`,
                            opacity: style.opacity,
                          }}
                        >
                          <td className="td-item">
                            <input
                              type="text"
                              name={`invoice_items[${index}].label`}
                              ref={register({
                                required: '項目名を入力してください',
                              })}
                              autoComplete="off"
                              list="keywords"
                              data-index={index}
                              onChange={onChangeInvoiceItemName}
                            />
                            <ErrorMessage
                              message={
                                errorInvoiceItems &&
                                errorInvoiceItems[index] !== undefined
                                  ? errorInvoiceItems[index]?.label?.message
                                  : undefined
                              }
                              className="mt-2"
                            />
                          </td>
                          <td className="td-tax">
                            <input
                              type="checkbox"
                              className="checkbox"
                              name={`invoice_items[${index}].tax_included`}
                              ref={register()}
                            />
                          </td>
                          <td className="td-amount">
                            <input
                              type="number"
                              name={`invoice_items[${index}].amounts`}
                              step="1"
                              ref={register({
                                required: '金額を入力してください',
                              })}
                            />
                            <ErrorMessage
                              message={
                                errorInvoiceItems &&
                                errorInvoiceItems[index] !== undefined
                                  ? errorInvoiceItems[index]?.amounts?.message
                                  : undefined
                              }
                              className="mt-2"
                            />
                          </td>
                          <td className="td-remove">
                            <CgCloseO onClick={() => remove(index)} />
                          </td>
                        </tr>
                      )}
                    </Motion>
                  );
                })}
              </tbody>
            </table>
            <Button
              className="field"
              buttonProps={{ onClick: () => append({}) }}
            >
              項目を追加
            </Button>
            <div className="field">
              <p className="label">公開ステータス</p>
              <Controller
                name="status"
                control={control}
                defaultValue={null}
                as={
                  <div>
                    <Select
                      name="status"
                      options={INVOICE_STATUS.map((value) => ({
                        label: INVOICE_STATUS_LABELS[value],
                        value,
                      }))}
                      defaultValue={null}
                      onChange={async (value) => {
                        setValue(
                          'status',
                          value && 'value' in value ? value.value : '',
                        );
                        await trigger('status');
                      }}
                    />
                  </div>
                }
              />
            </div>
            <div className="field">
              <p className="label">支払予定日</p>
              <Controller
                name="withdrawal_date"
                control={control}
                defaultValue={null}
                as={
                  <DateField
                    DatePickerProps={{
                      label: '',
                      name: 'withdrawal_date',
                      value: watch('withdrawal_date')
                        ? DateTime.fromFormat(
                            watch('withdrawal_date') ?? '',
                            'yyyy/MM/dd',
                          )
                        : null,
                      format: 'yyyy/MM/dd',
                      TextFieldComponent: (props) => (
                        <TextField {...props} margin="none" />
                      ),
                      onChange: (v) => {
                        const date = v ? v.toFormat('yyyy/MM/dd') : null;
                        setValue('withdrawal_date', date ?? '');
                      },
                    }}
                  />
                }
              />
            </div>
            <div className="field">
              <Textarea
                label="備考"
                textareaProps={{
                  name: 'remarks',
                  ref: register(),
                }}
              />
            </div>
            <div className="btn-area">
              <Button
                buttonProps={{ type: 'submit', disabled: isViewer }}
                color="primary"
                className={clsx('with-icon', 'btn-primary')}
              >
                <FaSave />
                保存
              </Button>
            </div>
          </div>
        </form>
      </div>
      <Backdrop open={creating} />
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default InvoiceBulkChangeForm;
