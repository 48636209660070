import { useState } from 'react';
import useSnackbar, { SnackbarState } from '../useSnackbar';
import { WorkShift } from '../../../domain/models/work-shift';
import createWorkShift from '../../../domain/services/create-work-shift';

export type Result = {
  creating: boolean;
  create: (workShift: WorkShift) => Promise<string | undefined>;
  state: SnackbarState;
  close: () => void;
};

const useCreateWorkShift = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (workShift: WorkShift): Promise<string | undefined> => {
    setCreating(true);

    const result = await createWorkShift(workShift);

    open(
      result.id ? 'success' : 'error',
      result.id
        ? 'シフトの更新に成功しました'
        : 'シフトの更新ができませんでした。もう一度お試しください。',
    );
    setCreating(false);

    return result.id;
  };

  return { creating, create, close, state };
};

export default useCreateWorkShift;
