import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { Popper, Backdrop } from '@material-ui/core';

import TextAreaComponent from '../TextArea';
import './style.scss';

interface Props {
  anchorEl: HTMLButtonElement | HTMLAnchorElement | null;
  setAnchorEl: Dispatch<
    SetStateAction<HTMLButtonElement | HTMLAnchorElement | null>
  >;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  onClick: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const BatchSendingComponent: FC<Props> = ({
  anchorEl,
  setAnchorEl,
  message,
  setMessage,
  onClick,
  onChange,
}) => {
  return (
    <div>
      <Backdrop
        open={Boolean(anchorEl)}
        onClick={() => setAnchorEl(null)}
        style={{ zIndex: 1000 }}
      />
      <Popper
        open={Boolean(anchorEl)}
        placement="right-start"
        anchorEl={anchorEl}
        className="batch-sending"
      >
        <TextAreaComponent
          message={message}
          setMessage={setMessage}
          onClick={onClick}
          onChange={onChange}
        />
      </Popper>
    </div>
  );
};

export default BatchSendingComponent;
