import firebase from 'firebase';

import firestoreSimple from '../../lib/firebase-firestore';
import { Talk } from '../models/talk';
import { QueryParams } from '../../types/firestore-simple';
import { CollectionOrQuery } from './get-talks';

const onSnapshotTalks = ({
  queryParams,
  callback,
}: {
  queryParams?: QueryParams<Talk>;
  callback: (
    talk: Talk,
    changeType: firebase.firestore.DocumentChangeType,
  ) => void;
}) => {
  const collection = firestoreSimple.collection<Talk>({
    path: 'talks',
  });
  const query =
    queryParams && queryParams.length
      ? queryParams.reduce((prev: CollectionOrQuery, current) => {
          return prev.where(current.fieldPath, current.opStr, current.value);
        }, collection)
      : collection;
  if ('query' in query && 'startAt' in query) {
    query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot, toObject) => {
        querySnapshot.docChanges().forEach((change) => {
          const changedDoc = toObject(change.doc);
          callback(changedDoc, change.type);
        });
      },
    );
  }
};

export default onSnapshotTalks;
