import { useContext, useEffect, useState } from 'react';

import { StudentBasic } from '../../../domain/models/student-basic';
import { Timetable, defaultTimetable } from '../../../domain/models/timetable';
import { QueryParams } from '../../../types/firestore-simple';
import getStudentBasics from '../../../domain/services/get-student-basics';
import getTimetable from '../../../domain/services/get-timetables';
import { YearMonthContext } from '../../context/YearMonthContext';

export type Result = {
  loading: boolean;
  studentBasics: StudentBasic[];
  timetables: Timetable[];
};

const useFetchTimetables = ({
  id,
  queryParams,
}: {
  id?: string;
  queryParams?: QueryParams<Timetable>;
}): Result => {
  const [loading, setLoading] = useState(false);
  const [studentBasics, setStudentBasics] = useState<StudentBasic[]>([]);
  const [timetables, setTimetables] = useState<Timetable[]>([]);
  const { yearMonth } = useContext(YearMonthContext);

  useEffect(() => {
    void (async () => {
      setLoading(true);

      const basicResult = await getStudentBasics({ id });
      if (basicResult.studentBasics.length) {
        setStudentBasics(basicResult.studentBasics);
      }

      const timetablesResult = await getTimetable({
        id,
        queryParams,
      });
      if (timetablesResult.timetables.length) {
        setTimetables(timetablesResult.timetables);
      } else {
        setTimetables([defaultTimetable]);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [yearMonth]);

  return { loading, studentBasics, timetables };
};

export default useFetchTimetables;
