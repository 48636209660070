import clsx from 'clsx';
import React, { FC, LegacyRef } from 'react';
import { FieldError } from 'react-hook-form';

import Input from '../../../atoms/form/Input';
import './style.scss';

type Value = {
  name: string;
  defaultValue?: string;
  error?: FieldError;
  ref: LegacyRef<any>;
};

export type Props = {
  postalCode: Value;
  address: Value;
  isDisabled?: boolean;
};

const AddressItem: FC<Props> = ({ postalCode, address, isDisabled }) => {
  return (
    <div className={clsx('address')}>
      <div className="columns">
        <Input
          label="郵便番号"
          inputProps={{
            type: 'text',
            name: postalCode.name,
            ref: postalCode.ref,
            defaultValue: postalCode.defaultValue,
            disabled: isDisabled,
          }}
          error={postalCode.error}
          className="column is-one-fifth"
        />
        <Input
          label="住所"
          inputProps={{
            type: 'text',
            name: address.name,
            ref: address.ref,
            defaultValue: address.defaultValue,
            disabled: isDisabled,
          }}
          error={address.error}
          className="column is-four-fifths"
        />
      </div>
    </div>
  );
};

export default AddressItem;
