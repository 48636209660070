import firestoreSimple from '../../lib/firebase-firestore';
import { MiddleSchoolReport } from '../models/middle-school-report';

export type Result = {
  id?: string;
};

const createMiddleSchoolReport = async (
  middleSchoolReport: MiddleSchoolReport,
): Promise<Result> => {
  const collection = firestoreSimple.collection<MiddleSchoolReport>({
    path: 'middle_school_report',
  });

  const docId = middleSchoolReport.id
    ? await collection.set(middleSchoolReport).catch((error) => {
        console.error(error);
      })
    : await collection
        .add(middleSchoolReport)
        .catch((error) => console.error(error));

  return { id: docId || undefined };
};

export default createMiddleSchoolReport;
