import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';

import AdminWrapper from '../../components/layouts/AdminWrapper';
import Container from '../../components/layouts/Container';
import DailyReportDetailForm from '../../containers/molecules/form/DailyReportDetailForm';
import { PAGE_TITLE } from '../../../data/app-basic';
import { EnrolledCramSchoolContext } from '../../context/EnrolledCramSchoolContext';

const DailyReportDetail: FC = () => {
  const [enrolledCramSchool, setEnrolledCramSchool] = useState<string>('');

  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('講師日報')}</title>
      </Helmet>
      <Container>
        <EnrolledCramSchoolContext.Provider
          value={{
            enrolledCramSchool,
            setEnrolledCramSchool,
          }}
        >
          <DailyReportDetailForm />
        </EnrolledCramSchoolContext.Provider>
      </Container>
    </AdminWrapper>
  );
};

export default DailyReportDetail;
