import { Column } from 'react-table';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ButtonProps } from '@material-ui/core';
import { DateTime } from 'luxon';
import { InvoiceList } from '../../../../../types/table/invoice-list';
import SelectColumnFilter from '../../../../components/molecules/table/SelectColumnFilter';
import InvoiceListComponent from '../../../../components/molecules/table/InvoiceList';
import useFetchStudentInvoiceLists from '../../../../hooks/fetch/useFetchStudentInvoiceLists';
import DotProgress from '../../../../components/atoms/feedback/DotProgress';
import useCreateStudentInvoice from '../../../../hooks/create/useCreateStudentInvoice';
import SelectInvoiceStatusCell from '../../../../components/molecules/table/InvoiceList/SelectInvoiceStatusCell';
import { InvoiceContext } from '../../../../context/InvoiceContext';
import InputWithdrawalDateCell from '../../../../components/molecules/table/InvoiceList/InputWithdrawalDateCell';
import InputTransferCell from '../../../../components/molecules/table/InvoiceList/InputTransferCell';

const InvoiceListContainer: FC = () => {
  const {
    loading,
    invoiceList,
    invoiceMonth,
    setInvoiceMonth,
    setReacquire,
  } = useFetchStudentInvoiceLists();
  const { creating, create, state, close } = useCreateStudentInvoice();
  const [data, setData] = useState<InvoiceList[]>([]);
  const [bulkChanged, setBulkChanged] = useState<boolean>(false);

  useEffect(() => {
    if (bulkChanged) {
      setReacquire(true);
      setBulkChanged(false);
    }
  }, [bulkChanged, setReacquire]);

  useEffect(() => {
    setData(invoiceList);
  }, [invoiceList]);

  const updateTableData = (rowIndex: number, value: InvoiceList) => {
    setData((old) => {
      return old.map((row, index) => {
        if (rowIndex === index) {
          return {
            ...old[rowIndex],
            ...value,
          };
        }

        return row;
      });
    });
  };

  const columns: Column<InvoiceList>[] = useMemo(() => {
    return [
      {
        Header: '生徒番号',
        accessor: 'student_num',
        filter: 'includesStringFilter',
      },
      {
        Header: '名前',
        accessor: 'student_name',
        filter: 'includesStringFilter',
        disableSortBy: true,
      },
      {
        Header: '生徒ステータス',
        accessor: 'student_status',
        filter: 'includeSelectValue',
        Filter: SelectColumnFilter,
        disableSortBy: true,
      },
      {
        Header: '学年',
        accessor: 'grade',
        filter: 'includeSelectValue',
        Filter: SelectColumnFilter,
        disableSortBy: true,
      },
      {
        Header: '在籍校舎',
        accessor: 'enrolled_cram_school',
        filter: 'includeSelectValue',
        Filter: SelectColumnFilter,
        disableSortBy: true,
      },
      {
        Header: '公開ステータス',
        accessor: 'invoice_status',
        filter: 'includeSelectValue',
        Filter: SelectColumnFilter,
        disableSortBy: true,
        Cell: SelectInvoiceStatusCell,
      },
      {
        Header: '請求項目',
        accessor: 'invoice_items',
        filter: 'invoiceItemsFilter',
        disableSortBy: true,
      },
      {
        Header: '支払方法',
        accessor: 'payment_method',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: '支払予定日',
        accessor: 'withdrawal_date',
        disableFilters: true,
        disableSortBy: true,
        Cell: InputWithdrawalDateCell,
      },
      {
        Header: '引落銀行',
        accessor: 'withdrawal_bank',
        filter: 'includeSelectValue',
        Filter: SelectColumnFilter,
        disableSortBy: true,
      },
      {
        Header: '引落金額',
        accessor: 'total',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: '引落結果',
        accessor: 'withdrawal_result',
        filter: 'includeSelectValue',
        Filter: SelectColumnFilter,
        disableSortBy: true,
      },
      {
        Header: '振込',
        accessor: 'is_transfer',
        disableFilters: true,
        disableSortBy: true,
        Cell: InputTransferCell,
      },
      {
        Header: '',
        accessor: 'student_id',
        disableFilters: true,
        disableSortBy: true,
      },
    ];
  }, []);

  const onClickPreviousMonth: ButtonProps['onClick'] = () => {
    const previousMonth = DateTime.fromFormat(invoiceMonth, 'yyyy/MM')
      .minus({ months: 1 })
      .toFormat('yyyy/MM');
    close();
    setInvoiceMonth(previousMonth);
    setReacquire(true);
  };

  const onClickNextMonth: ButtonProps['onClick'] = () => {
    const nextMonth = DateTime.fromFormat(invoiceMonth, 'yyyy/MM')
      .plus({ months: 1 })
      .toFormat('yyyy/MM');
    close();
    setInvoiceMonth(nextMonth);
    setReacquire(true);
  };

  if (loading) return <DotProgress />;

  return (
    <InvoiceContext.Provider
      value={{
        data: [],
        invoiceMonth,
        onClickPreviousMonth,
        onClickNextMonth,
        updateTableData: () => null,
        create,
        creating,
        setBulkChanged,
      }}
    >
      <InvoiceListComponent
        loading={loading}
        data={data}
        updateTableData={updateTableData}
        columns={columns}
        snackbarState={state}
        snackbarClose={close}
      />
    </InvoiceContext.Provider>
  );
};

export default InvoiceListContainer;
