import _ from 'lodash';
import { Row } from 'react-table';
import exportCsv from '../utils/array/exportCsv';
import arrayToCsv from '../utils/array/arrayToCsv';
import { InvoiceList } from '../types/table/invoice-list';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (invoiceMonth: string, rows: Row<InvoiceList>[]) => {
  const rowArrays = rows.map((row) => {
    const values = _.filter(row.values, (__, key) => {
      return ![
        'student_name',
        'grade',
        'enrolled_cram_school',
        'invoice_status',
        'withdrawal_date',
        'withdrawal_bank',
        'withdrawal_result',
        'student_id',
        'invoice_items',
      ].includes(key);
    });
    const rowValues = Object.entries(values);

    return rowValues.map((rowValue): string => {
      if (rowValue[1] === null) {
        return '';
      }

      if (typeof rowValue[1] === 'number') {
        return String(rowValue[1]);
      }

      return typeof rowValue[1] === 'string' ? rowValue[1] : '';
    });
  });

  const fileName = invoiceMonth.replace('/', '');

  return exportCsv(arrayToCsv(rowArrays), fileName);
};
