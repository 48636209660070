import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AppBar from '../../../components/layouts/AppBar';
import { AppContext } from '../../../context/AppContext';
import { defaultAdminUser } from '../../../../domain/models/admin-user';

export default () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { setAdminUser } = useContext(AppContext);

  const handleOpen = (ele: HTMLButtonElement) => setAnchorEl(ele);
  const handleClose = () => setAnchorEl(null);

  const handleLinkButton = (pathname: string) => {
    setAnchorEl(null);
    history.push(pathname);
  };

  const handleSignOut = () => {
    history.push('/');
    setAdminUser(defaultAdminUser);
  };

  return (
    <AppBar
      anchorEl={anchorEl}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleLinkButton={handleLinkButton}
      handleSignOut={handleSignOut}
    />
  );
};
