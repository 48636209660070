import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { TaskTrial } from '../../../domain/models/task';
import { QueryParams } from '../../../types/firestore-simple';
import getTaskTrial from '../../../domain/services/get-task-trial';

export type Result = {
  loading: boolean;
  taskItems: TaskTrial | undefined;
};

const useFetchTaskTrial = ({
  id,
  queryParams,
}: {
  id?: string;
  queryParams?: QueryParams<TaskTrial>;
}): Result => {
  const [loading, setLoading] = useState(false);
  const [taskTrials, setTaskTrials] = useState<TaskTrial[]>([]);

  useEffectOnce(() => {
    void (async () => {
      setLoading(true);

      const result = await getTaskTrial({ id, queryParams });

      if (result.taskTrials.length) {
        setTaskTrials(result.taskTrials);
      }

      setLoading(false);
    })();
  });

  return {
    loading,
    taskItems: taskTrials.length ? taskTrials[0] : undefined,
  };
};

export default useFetchTaskTrial;
