import React, { FC, useContext } from 'react';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import ReactLinkify from 'react-linkify';

import { AdminUser } from '../../../../../domain/models/admin-user';
import { ParentInfo } from '../../../../../domain/models/parent-info';
import { Talk } from '../../../../../domain/models/talk';
import { ChatContext } from '../../../../context/ChatContext';
import { AppContext } from '../../../../context/AppContext';
import StorageFile from '../../../atoms/chat/StorageFIle';
import deleteTalk from '../../../../../domain/services/delete-talk';

interface Props {
  date: string;
  talks: Talk[];
  parentInfo: ParentInfo | undefined;
}

const DateTalk: FC<Props> = ({ date, talks, parentInfo }) => {
  const { adminUser } = useContext(AppContext);
  const { adminUsers } = useContext(ChatContext);

  const onClickDeleteMessage = async (talkId: string) => {
    if (window.confirm('メッセージを削除します。よろしいですか？')) {
      await deleteTalk(talkId);
    }
  };

  return (
    <div className="date-talk">
      <div className="date">
        <span>{date}</span>
      </div>
      <div className="talks">
        {talks.map((talk) => {
          const user: AdminUser | ParentInfo | undefined =
            adminUsers.find((u) => u.id === talk.user_id) || parentInfo;
          if (!user) return null;

          const userName =
            'name' in user ? user.name : `${user.last_name} ${user.first_name}`;

          let time = '';
          if (talk.date) {
            time = DateTime.fromJSDate(talk.date.toDate()).toLocaleString(
              DateTime.TIME_24_SIMPLE,
            );
          }

          return (
            <div
              key={talk.id}
              className={clsx('talk', {
                'is-current-user': user.id === adminUser.id,
              })}
            >
              <div className="talk-meta">
                <span className="name">{userName}</span>
                <span className="time">{time}</span>
              </div>

              {talk.storage_path && !talk.message ? (
                <StorageFile
                  key={talk.storage_path}
                  storagePath={talk.storage_path}
                  isCurrentUser={user.id === adminUser.id}
                />
              ) : (
                <span className="message">
                  <ReactLinkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        href={decoratedHref}
                        key={key}
                        target="blank"
                        rel="noopener noreferrer"
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    {talk.message}
                  </ReactLinkify>
                </span>
              )}
              {user.id === adminUser.id && (
                <div>
                  <span className="is-read">{talk.is_read ? '既読' : ''}</span>
                  <button
                    type="button"
                    className="delete-btn"
                    onClick={async () => {
                      await onClickDeleteMessage(talk.id);
                    }}
                  >
                    削除
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DateTalk;
