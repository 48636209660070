import { useState } from 'react';
import useSnackbar, { SnackbarState } from '../useSnackbar';
import { StudentInvoice } from '../../../domain/models/student-invoice';
import createStudentInvoice from '../../../domain/services/create-student-invoice';

export type Result = {
  creating: boolean;
  create: (studentInvoice: StudentInvoice) => Promise<string | undefined>;
  state: SnackbarState;
  close: () => void;
};

const useCreateStudentInvoice = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();

  const create = async (studentInvoice: StudentInvoice) => {
    setCreating(true);
    const result = await createStudentInvoice(studentInvoice);
    open(
      result.id ? 'success' : 'error',
      result.id
        ? '請求情報の更新に成功しました'
        : '請求情報の更新ができませんでした。もう一度お試しください。',
    );
    setCreating(false);

    return result.id;
  };

  return { creating, create, close, state };
};

export default useCreateStudentInvoice;
