import React, { FC, useState } from 'react';
import { Button, IconButton, ClickAwayListener } from '@material-ui/core';
import { RiFilter3Fill } from 'react-icons/all';

import { Room } from '../../../../../domain/models/rooms';
import Filter from '../../../../containers/pages/chat/Filter';
import Rooms from '../Rooms';
import BatchSending from '../../../../containers/pages/chat/BatchSending';

interface Props {
  loading: boolean;
  error: Error | undefined;
  value: Room[] | undefined;
}

const SidebarComponent: FC<Props> = ({ loading, error, value }) => {
  const [filterButton, setFilterButton] = useState<
    HTMLButtonElement | HTMLAnchorElement | null
  >(null);
  const [sendingButton, setSendingButton] = useState<
    HTMLButtonElement | HTMLAnchorElement | null
  >(null);

  return (
    <ClickAwayListener onClickAway={() => setFilterButton(null)}>
      <div className="chat-sidebar">
        <Filter anchorEl={filterButton} />
        <BatchSending anchorEl={sendingButton} setAnchorEl={setSendingButton} />
        <div className="sidebar-header">
          <IconButton
            area-label="Filter"
            onClick={(event) =>
              setFilterButton(filterButton ? null : event.currentTarget)
            }
          >
            <RiFilter3Fill />
          </IconButton>
          <Button
            variant="text"
            onClick={(event) =>
              setSendingButton(sendingButton ? null : event.currentTarget)
            }
          >
            一斉送信
          </Button>
        </div>
        <Rooms loading={loading} error={error} value={value} />
      </div>
    </ClickAwayListener>
  );
};

export default SidebarComponent;
