import firestoreSimple from '../../lib/firebase-firestore';
import { Talk } from '../models/talk';

export type Result = {
  id?: string;
};

const createTalk = async (talk: Talk): Promise<Result> => {
  const collection = firestoreSimple.collection<Talk>({
    path: 'talks',
  });

  const docId = talk.id
    ? await collection.set(talk).catch((error) => {
        console.error(error);
      })
    : await collection.add(talk).catch((error) => console.error(error));

  return { id: docId || undefined };
};

export default createTalk;
