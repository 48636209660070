import firestoreSimple from '../../lib/firebase-firestore';
import { StudentBasic } from '../models/student-basic';
import { SeparatedLecture } from '../models/student-lectures';
import { Timetable, getLectureKey } from '../models/timetable';

const bulkCreateTimetable = async (
  studentBasics: StudentBasic[],
  timetables: Timetable[],
  enrolled_cram_school: string,
  yearMonth: string,
  date: string,
  lecture: string,
  subject: SeparatedLecture | string,
): Promise<boolean> => {
  try {
    const targetLectureKey = getLectureKey(lecture, enrolled_cram_school, date);

    if (!targetLectureKey) return false;

    const collection = firestoreSimple.collection<Timetable>({
      path: 'timetable',
    });

    // timetablesに登録されていなければ新規作成（新規登録用データに整形）
    const addDataStudentBasics = studentBasics.filter((studentBasic) =>
      timetables.every((timetable) => timetable.student_id !== studentBasic.id),
    );
    const addData = addDataStudentBasics.map((studentBasic) => {
      return {
        student_id: studentBasic.id,
        year_month: yearMonth,
        schedule: [
          {
            date,
            [targetLectureKey]: {
              lecture,
              subject,
            },
          },
        ],
      };
    });

    // timetablesに登録されているものはそのまま更新（更新用データに整形）
    const setData = timetables.map((timetable) => {
      const schedule = timetable.schedule.filter(
        (scheduleItem) => scheduleItem.date !== date,
      );
      let newScheduleItem = timetable.schedule.find(
        (scheduleItem) => scheduleItem.date === date,
      );
      if (newScheduleItem) {
        newScheduleItem = {
          ...newScheduleItem,
          [targetLectureKey]: {
            lecture,
            subject,
          },
        };
      } else {
        newScheduleItem = {
          date,
          [targetLectureKey]: {
            lecture,
            subject,
          },
        };
      }
      schedule.push(newScheduleItem);

      return {
        ...timetable,
        schedule,
      };
    });

    // 一括新規登録
    await collection.bulkAdd(addData).catch((error) => console.error(error));
    // 一括更新
    await collection.bulkSet(setData).catch((error) => console.error(error));

    return true;
  } catch (e) {
    console.error(e);

    return false;
  }
};

export default bulkCreateTimetable;
