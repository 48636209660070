import { Dispatch, SetStateAction, useState } from 'react';
import { useAsync } from 'react-use';
import { StudentBasic } from '../../../domain/models/student-basic';
import getStudentBasics from '../../../domain/services/get-student-basics';
import getParents from '../../../domain/services/get-parent-info';
import { ParentInfo } from '../../../domain/models/parent-info';

export type Result = {
  loading: boolean;
  studentBasic: StudentBasic | null;
  parentInfo: ParentInfo | null;
  setId: Dispatch<SetStateAction<string>>;
};

const useFetchStudentBasic = ({ id }: { id: string }): Result => {
  const [studentId, setId] = useState<string>(id);
  const [studentBasic, setStudentBasic] = useState<StudentBasic | null>(null);
  const [parentInfo, setParentInfo] = useState<ParentInfo | null>(null);

  const { loading } = useAsync(async () => {
    const result = await getStudentBasics({
      id,
    });
    if (result.studentBasics.length) {
      setStudentBasic(result.studentBasics[0]);

      const { parents } = await getParents({
        id: result.studentBasics[0].parent_id,
      });

      if (parents.length) {
        setParentInfo(parents[0]);
      }
    }
  }, [studentId]);

  return { loading, studentBasic, parentInfo, setId };
};

export default useFetchStudentBasic;
