import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { LectureItemForShift } from '../../../../../domain/models/work-shift';
import { StudentBasic } from '../../../../../domain/models/student-basic';
import { AdminUser } from '../../../../../domain/models/admin-user';

export type Props = {
  staff: AdminUser | null;
  lectureItemForShift: LectureItemForShift;
  studentBasics: StudentBasic[];
};

export const TableRowShift = ({
  staff,
  lectureItemForShift,
  studentBasics,
}: Props) => {
  const studentBasic = studentBasics.find(
    (sb) => sb.id === lectureItemForShift.student_id,
  );

  return (
    <TableRow>
      <TableCell>{studentBasic?.student_num}</TableCell>
      <TableCell>
        {studentBasic?.last_name} {studentBasic?.first_name}
      </TableCell>
      <TableCell>{studentBasic?.enrolled_cram_school}</TableCell>
      <TableCell>{lectureItemForShift.grade}</TableCell>
      <TableCell>
        {typeof lectureItemForShift.subject !== 'string' &&
        typeof lectureItemForShift.teacher_id !== 'string' ? (
          <div>
            {lectureItemForShift.teacher_id.first === staff?.id ? (
              <div className="my-3 has-text-centered">
                <div className="mb-2">【前半】</div>
                <div className="subject-box">
                  {lectureItemForShift.subject.first}
                </div>
              </div>
            ) : null}
            {lectureItemForShift.teacher_id.latter === staff?.id ? (
              <div className="my-3 has-text-centered">
                <div className="mb-2">【後半】</div>
                <div className="subject-box">
                  {lectureItemForShift.subject.latter}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="my-3 has-text-centered">
            <div className="subject-box">{lectureItemForShift.subject}</div>
          </div>
        )}
      </TableCell>
      <TableCell>
        <div />
      </TableCell>
      <TableCell>
        <div className="write-only-box">
          <small>/コマ 連続</small>
        </div>
      </TableCell>
    </TableRow>
  );
};
