import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import config from './config';

export const firebaseApp = firebase.apps.length
  ? firebase.apps[0]
  : firebase.initializeApp(config);
export const firebaseAuth = firebaseApp.auth();
export const firestore = firebaseApp.firestore();
export const storage = firebaseApp.storage();
export const functions = firebase.functions();

void firebaseAuth
  .setPersistence(firebase.auth.Auth.Persistence.SESSION)
  .then(() => console.log('success'))
  .catch((e) => console.error(e));
