import React, { FC } from 'react';
import { Backdrop as MuiBackdrop, CircularProgress } from '@material-ui/core';

export type Props = {
  open: boolean;
};

const Backdrop: FC<Props> = ({ open }) => {
  return (
    <MuiBackdrop open={open} style={{ zIndex: 9999 }}>
      <CircularProgress style={{ color: '#fff' }} />
    </MuiBackdrop>
  );
};

export default Backdrop;
