import { Collection } from '@firestore-simple/web/dist/collection';
import { OmitId } from '@firestore-simple/web/dist/types';
import { Query } from '@firestore-simple/web';

import { ClassTransfer } from '../models/class-transfer';
import firestoreSimple from '../../lib/firebase-firestore';
import { QueryParams } from '../../types/firestore-simple';

export type CollectionOrQuery =
  | Collection<ClassTransfer, OmitId<ClassTransfer>>
  | Query<ClassTransfer, OmitId<ClassTransfer>>;

const getClassTransfer = async ({
  id,
  queryParams,
}: {
  id?: string;
  queryParams?: QueryParams<ClassTransfer>;
}): Promise<{
  classTransfers: ClassTransfer[];
}> => {
  const collection = firestoreSimple.collection<ClassTransfer>({
    path: 'class_transfer',
  });
  const query =
    queryParams && queryParams.length
      ? queryParams.reduce((prev: CollectionOrQuery, current) => {
          return prev.where(current.fieldPath, current.opStr, current.value);
        }, collection)
      : collection;
  let docs: ClassTransfer[] | undefined;
  if ('query' in query && 'startAt' in query) {
    docs =
      (await query.fetch().catch((error) => console.error(error))) || undefined;
  } else if (typeof id === 'string') {
    const result = await query.fetch(id).catch((error) => console.error(error));
    docs = result ? [result] : undefined;
  } else {
    docs =
      (await query.fetchAll().catch((error) => console.error(error))) ||
      undefined;
  }

  const classTransfers = docs instanceof Array ? docs : [];

  return { classTransfers };
};

export default getClassTransfer;
