import { useContext, useEffect, useState } from 'react';

import { StudentBasic } from '../../../domain/models/student-basic';
import { QueryParams } from '../../../types/firestore-simple';
import getStudentBasics from '../../../domain/services/get-student-basics';
import { EnrolledCramSchoolContext } from '../../context/EnrolledCramSchoolContext';

export type Result = {
  loading: boolean;
  studentBasics: StudentBasic[];
};

const useFetchStudentBasics = ({
  id,
  queryParams,
}: {
  id?: string;
  queryParams?: QueryParams<StudentBasic>;
}): Result => {
  const [loading, setLoading] = useState(false);
  const [studentBasics, setStudentBasics] = useState<StudentBasic[]>([]);
  const { enrolledCramSchool } = useContext(EnrolledCramSchoolContext);

  useEffect(() => {
    void (async () => {
      setLoading(true);

      const result = await getStudentBasics({
        id,
        queryParams,
      });
      if (result.studentBasics.length) {
        setStudentBasics(result.studentBasics);
      } else {
        setStudentBasics([]);
      }

      setLoading(false);
    })();

    // eslint-disable-next-line
  }, [enrolledCramSchool]);

  return { loading, studentBasics };
};

export default useFetchStudentBasics;
