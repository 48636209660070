import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Tab, Tabs } from '@material-ui/core';

import MiddleSchoolExamList from '../../containers/molecules/table/MiddoleSchoolExamList';
import { PAGE_TITLE } from '../../../data/app-basic';
import Container from '../../components/layouts/Container';
import AdminWrapper from '../../components/layouts/AdminWrapper';
import MiddleSchoolReportListContainer from '../../containers/molecules/table/MiddoleSchoolReportList';

const Score: FC = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('成績情報一覧')}</title>
      </Helmet>
      <Container>
        <Tabs
          value={tabIndex}
          onChange={(_, newIndex) => setTabIndex(newIndex)}
        >
          <Tab label="定期テスト" />
          <Tab label="通知表" />
          <Tab label="模試" />
        </Tabs>
        {tabIndex === 0 ? <MiddleSchoolExamList /> : null}
        {tabIndex === 1 ? <MiddleSchoolReportListContainer /> : null}
        {tabIndex === 2 ? (
          <div className="mt-5">
            <a
              className="button is-primary"
              href="//t-moshi.jp/online/m_individuals/login/"
              target="_blank"
              rel="noopener noreferrer"
            >
              新潟県統一模試会 個人ログイン画面
            </a>
          </div>
        ) : null}
      </Container>
    </AdminWrapper>
  );
};

export default Score;
