import { Dispatch, SetStateAction, useState } from 'react';
import { getLectureKey, Timetable } from '../../../domain/models/timetable';
import bulkCreateTimetable from '../../../domain/services/bulk-create-timetable';
import useSnackbar, { SnackbarState } from '../useSnackbar';
import { BulkChangeTimetable } from '../../../types/form/bulk-change-timetable';
import getTimetables from '../../../domain/services/get-timetables';
import getStudentBasics from '../../../domain/services/get-student-basics';
import { StudentBasic } from '../../../domain/models/student-basic';
import { STUDENT_STATUS } from '../../../data/form-data';

export type Result = {
  creating: boolean;
  bulkCreate: (data: BulkChangeTimetable) => void;
  state: SnackbarState;
  close: () => void;
  validate: (data: BulkChangeTimetable) => Promise<boolean>;
  isDialog: boolean;
  setIsDialog: Dispatch<SetStateAction<boolean>>;
  isValidateThrough: boolean;
  setIsValidateThrough: Dispatch<SetStateAction<boolean>>;
  errorMessage: string;
};

export type Properties = BulkChangeTimetable & {
  yearMonth?: string;
  studentBasics: StudentBasic[];
  timetables: Timetable[];
};

const useBulkCreateTimetable = (): Result => {
  const [creating, setCreating] = useState(false);
  const { open, close, state } = useSnackbar();
  const [isDialog, setIsDialog] = useState(false);
  const [isValidateThrough, setIsValidateThrough] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const reset = () => {
    setCreating(true);
    setIsDialog(false);
    setIsValidateThrough(false);
    setErrorMessage('');
  };

  const getProperties = async (
    data: BulkChangeTimetable,
  ): Promise<Properties> => {
    const { enrolled_cram_school } = data;
    const { grade } = data;
    const { date } = data;
    const { lecture } = data;
    const { subject } = data;

    let yearMonth: string | undefined;
    if (date && date.match(/\d{4}\/(0[1-9]|1[0-2])/g)) {
      const [yM] = date.match(/\d{4}\/(0[1-9]|1[0-2])/g)!;
      yearMonth = yM;
    }

    const { timetables } = await getTimetables({
      queryParams: [{ fieldPath: 'year_month', opStr: '==', value: yearMonth }],
    });

    const [, , , , enrolled] = STUDENT_STATUS;
    const { studentBasics } = await getStudentBasics({
      queryParams: [
        {
          fieldPath: 'status',
          opStr: '==',
          value: enrolled,
        },
        {
          fieldPath: 'enrolled_cram_school',
          opStr: '==',
          value: enrolled_cram_school,
        },
        { fieldPath: 'grade', opStr: '==', value: grade },
      ],
    });

    const filteredTimetables = timetables.filter((timetable) =>
      studentBasics.some(
        (studentBasic) => studentBasic.id === timetable.student_id,
      ),
    );

    return {
      enrolled_cram_school,
      grade,
      date,
      lecture,
      subject,
      yearMonth,
      studentBasics,
      timetables: filteredTimetables,
    };
  };

  const validate = async (data: BulkChangeTimetable) => {
    reset();

    const {
      enrolled_cram_school,
      grade,
      date,
      lecture,
      subject,
      yearMonth,
      timetables,
    } = await getProperties(data);

    if (
      !enrolled_cram_school ||
      !grade ||
      !date ||
      !lecture ||
      !subject ||
      (typeof subject !== 'string' && (!subject?.first || !subject?.latter))
    ) {
      setCreating(false);
      setErrorMessage('全ての項目を入力してください');

      return false;
    }

    if (!yearMonth) {
      setCreating(false);
      setErrorMessage('日付のフォーマットが正しくありません');

      return false;
    }

    const lectureKey = getLectureKey(lecture, enrolled_cram_school, date);
    if (!lectureKey) {
      setCreating(false);
      setErrorMessage('正しい授業時間を選んでください');

      return false;
    }

    // すでに指定された日付・授業時間に登録した生徒が1人でも含まれる場合は上書き確認ダイアログを出す
    const isAlreadyRegistered = timetables.some((timetable) => {
      const scheduleItem = timetable.schedule.find((si) => si.date === date);
      if (scheduleItem) {
        if (typeof scheduleItem[lectureKey]?.subject === 'string') {
          return !!scheduleItem[lectureKey]?.subject;
        }

        if (typeof scheduleItem[lectureKey]?.subject !== 'string') {
          return (
            !!scheduleItem[lectureKey]?.subject.first ||
            !!scheduleItem[lectureKey]?.subject.latter
          );
        }
      }

      return false;
    });
    if (isAlreadyRegistered) {
      setCreating(false);
      setIsDialog(isAlreadyRegistered);

      return false;
    }

    setCreating(false);

    return true;
  };

  const bulkCreate = async (data: BulkChangeTimetable) => {
    reset();

    const {
      enrolled_cram_school,
      grade,
      date,
      lecture,
      subject,
      yearMonth,
      studentBasics,
      timetables,
    } = await getProperties(data);

    let isSuccess;
    if (
      !enrolled_cram_school ||
      !grade ||
      !date ||
      !lecture ||
      !subject ||
      (typeof subject !== 'string' && (!subject?.first || !subject?.latter)) ||
      !yearMonth
    ) {
      isSuccess = false;
    } else {
      isSuccess = await bulkCreateTimetable(
        studentBasics,
        timetables,
        enrolled_cram_school,
        yearMonth,
        date,
        lecture,
        subject,
      );
    }

    open(
      isSuccess ? 'success' : 'error',
      isSuccess
        ? '生徒情報(授業日程)の一括更新に成功しました'
        : '生徒情報(授業日程)の一括更新ができませんでした。もう一度お試しください。',
    );
    setCreating(false);
  };

  return {
    creating,
    bulkCreate,
    state,
    close,
    validate,
    isDialog,
    setIsDialog,
    isValidateThrough,
    setIsValidateThrough,
    errorMessage,
  };
};

export default useBulkCreateTimetable;
