import React, { Dispatch, FC, SetStateAction } from 'react';
import Select, { OptionTypeBase } from 'react-select';
import { Popper } from '@material-ui/core';

import {
  GRADES,
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_SCHOOLS,
  STUDENT_STATUS_LABELS,
  StudentStatus,
} from '../../../../../data/form-data';
import ButtonPrimary from '../../../atoms/button/Button';

interface Props {
  anchorEl: HTMLButtonElement | HTMLAnchorElement | null;
  parentName: string;
  studentNum: string;
  studentName: string;
  studentGrade: OptionTypeBase[];
  studentStatus: OptionTypeBase[];
  examCramSchool: OptionTypeBase[];
  schoolName: string;
  setParentName: Dispatch<SetStateAction<string>>;
  setStudentNum: Dispatch<SetStateAction<string>>;
  setStudentName: Dispatch<SetStateAction<string>>;
  setStudentGrade: Dispatch<SetStateAction<OptionTypeBase[]>>;
  setStudentStatus: Dispatch<SetStateAction<OptionTypeBase[]>>;
  setExamCramSchool: Dispatch<SetStateAction<OptionTypeBase[]>>;
  setSchoolName: Dispatch<SetStateAction<string>>;
  reset: () => void;
}

const FilterComponent: FC<Props> = ({
  anchorEl,
  parentName,
  studentNum,
  studentName,
  studentGrade,
  studentStatus,
  examCramSchool,
  schoolName,
  setParentName,
  setStudentNum,
  setStudentName,
  setStudentGrade,
  setStudentStatus,
  setExamCramSchool,
  setSchoolName,
  reset,
}) => {
  return (
    <Popper
      open={Boolean(anchorEl)}
      placement="right-start"
      anchorEl={anchorEl}
      className="filter-box"
    >
      <div className="table-filters">
        <div className="field">
          <div className="label">保護者</div>
          <div className="control">
            <input
              name="name"
              className="input filter-input"
              value={parentName}
              onChange={(event) => setParentName(event.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <div className="label">生徒番号</div>
          <div className="control">
            <input
              name="name"
              className="input filter-input"
              value={studentNum}
              onChange={(event) => setStudentNum(event.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <div className="label">生徒名</div>
          <div className="control">
            <input
              name="name"
              className="input filter-input"
              value={studentName}
              onChange={(event) => setStudentName(event.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <div className="label">学年</div>
          <div className="control">
            <Select
              onChange={(value) => {
                setStudentGrade(
                  value instanceof Array && value.length ? value : [],
                );
              }}
              options={GRADES.map((v) => ({
                value: v,
                label: v,
              }))}
              value={studentGrade}
              isMulti
            />
          </div>
        </div>
        <div className="field">
          <div className="label">生徒ステータス</div>
          <div className="control">
            <Select
              onChange={(value) => {
                setStudentStatus(
                  value instanceof Array && value.length ? value : [],
                );
              }}
              options={Object.keys(STUDENT_STATUS_LABELS).map((key) => ({
                value: key,
                label: STUDENT_STATUS_LABELS[key as StudentStatus],
              }))}
              value={studentStatus}
              isMulti
            />
          </div>
        </div>
        <div className="field">
          <div className="label">在籍校舎</div>
          <div className="control">
            <Select
              onChange={(value) => {
                setExamCramSchool(
                  value instanceof Array && value.length ? value : [],
                );
              }}
              options={[...NOTES_JAPAN_SCHOOLS, ...NOTES_PRAISE_SCHOOLS].map(
                (value) => ({
                  value,
                  label: value,
                }),
              )}
              value={examCramSchool}
              isMulti
            />
          </div>
        </div>
        <div className="field">
          <div className="label">在籍学校</div>
          <div className="control">
            <input
              name="name"
              className="input filter-input"
              value={schoolName}
              onChange={(event) => setSchoolName(event.target.value)}
            />
          </div>
        </div>
      </div>
      <ButtonPrimary
        buttonProps={{
          type: 'button',
          onClick: reset,
        }}
        color="secondary"
      >
        RESET
      </ButtonPrimary>
    </Popper>
  );
};

export default FilterComponent;
