import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { StudentInvoice } from '../../../domain/models/student-invoice';
import getStudentInvoices from '../../../domain/services/get-student-invoices';

export type Result = {
  loading: boolean;
  studentInvoices: StudentInvoice[];
  setStudentId: Dispatch<SetStateAction<string>>;
  setInvoiceMonth: Dispatch<SetStateAction<string>>;
};

const useFetchStudentInvoices = ({
  currentStudentId,
  currentInvoiceMonth,
}: {
  currentStudentId: string;
  currentInvoiceMonth: string;
}): Result => {
  const [loading, setLoading] = useState(false);
  const [studentId, setStudentId] = useState<string>(currentStudentId);
  const [studentInvoices, setStudentInvoices] = useState<StudentInvoice[]>([]);
  const [invoiceMonth, setInvoiceMonth] = useState<string>(currentInvoiceMonth);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const result = await getStudentInvoices({
        queryParams: [
          { fieldPath: 'student_id', opStr: '==', value: studentId },
          { fieldPath: 'invoice_month', opStr: '==', value: invoiceMonth },
        ],
      });
      setStudentInvoices(result.studentInvoices);
      setLoading(false);
    })();
  }, [studentId, invoiceMonth]);

  return {
    loading,
    studentInvoices,
    setStudentId,
    setInvoiceMonth,
  };
};

export default useFetchStudentInvoices;
