import { createContext, Dispatch, SetStateAction } from 'react';
import { SnackbarState } from '../hooks/useSnackbar';
import { WeekDefaultList } from '../../types/table/week-default-list';
import { StudentLectures } from '../../domain/models/student-lectures';
import { AdminUser } from '../../domain/models/admin-user';
import { StudentBasic } from '../../domain/models/student-basic';

export type WeekDefaultContextType = {
  data: WeekDefaultList[];
  updateTableData: (rowIndex: number, value: WeekDefaultList) => void;
  creating: boolean;
  create?: (StudentLecture: StudentLectures) => Promise<string | undefined>;
  snackbarState?: SnackbarState;
  closeSnackbar?: () => void;
  setBulkChanged?: Dispatch<SetStateAction<boolean>>;
  weekDefaultList: WeekDefaultList[];
  setWeekDefaultList: Dispatch<SetStateAction<WeekDefaultList[]>>;
  studentBasics?: StudentBasic[];
  studentLectures?: StudentLectures[];
  staffs?: AdminUser[];
};

export const WeekDefaultContext = createContext<WeekDefaultContextType>({
  data: [],
  updateTableData: () => null,
  creating: false,
  setBulkChanged: () => null,
  weekDefaultList: [],
  setWeekDefaultList: () => null,
  studentBasics: [],
  studentLectures: [],
  staffs: undefined,
});
