import React, { FC } from 'react';

import SidebarComponent from '../../../../components/pages/chat/Sidebar';
import useFetchRooms from '../../../../hooks/fetch/useFetchRooms';

const SidebarContainer: FC = () => {
  const { loading, error, value } = useFetchRooms();

  return <SidebarComponent loading={loading} error={error} value={value} />;
};

export default SidebarContainer;
