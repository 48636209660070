import React from 'react';
import { useForm } from 'react-hook-form';

import ForgetPasswordForm from '../../../../components/molecules/form/ForgetPasswordForm';
import { ForgetPasswordData } from '../../../../../types/form/forget-password-data';
import usePasswordReset from '../../../../hooks/usePasswordReset';

export default () => {
  // TODO: https://github.com/react-hook-form/react-hook-form/issues/2887
  // eslint-disable-next-line
  const { handleSubmit, register, errors } = useForm<ForgetPasswordData>();
  const { onSubmit, loading, snackbarState } = usePasswordReset({
    handleSubmit,
  });

  return (
    <ForgetPasswordForm
      onSubmit={onSubmit}
      loading={loading}
      register={register}
      errors={errors}
      snackbarState={snackbarState}
    />
  );
};
