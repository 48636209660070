import React from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import queryString from 'query-string';
import useFetchParentInfo from '../../../../hooks/fetch/useFetchParentInfo';
import {
  defaultParentInfo,
  ParentInfo,
} from '../../../../../domain/models/parent-info';
import ParentInfoForm from '../../../../components/molecules/form/ParentInfoForm';
import useCreateParentInfo from '../../../../hooks/create/useCreateParentInfo';
import useFetchStudentBasics from '../../../../hooks/fetch/useFetchStudentBasics';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const location = useLocation<{ parentId: string | undefined }>();
  const queryStringId = queryString.parse(location.search).id;

  if (!location.state && !queryStringId) return null;

  const id = location.state?.parentId ?? (queryStringId as string);

  const { loading, parents } = useFetchParentInfo({ id });
  const { studentBasics } = useFetchStudentBasics({
    queryParams: [{ fieldPath: 'parent_id', opStr: '==', value: id }],
  });
  const { creating, create, close, state } = useCreateParentInfo();
  const formMethods = useForm<ParentInfo>({ defaultValues: parents[0] });
  const onSubmit = formMethods.handleSubmit((data) => {
    create({
      ...defaultParentInfo,
      ...data,
      id: parents[0].id,
      uid: parents[0].uid,
      mail_address: parents[0].mail_address,
    });
  });

  return (
    <ParentInfoForm
      loading={loading}
      parentInfo={parents[0]}
      studentBasics={studentBasics}
      formMethods={formMethods}
      onSubmit={onSubmit}
      creating={creating}
      snackbarState={state}
      snackbarClose={close}
    />
  );
};
