import React, { FC, useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { Controller, useFieldArray } from 'react-hook-form';
import { DateTime } from 'luxon';
import { TextField } from '@material-ui/core';

import { SnackbarState } from '../../../../hooks/useSnackbar';
import {
  documentRequestTaskItems,
  TaskDocumentRequest,
  TaskItem,
} from '../../../../../domain/models/task';
import DotProgress from '../../../atoms/feedback/DotProgress';
import Checkbox from '../../../atoms/form/Checkbox';
import DateField from '../../../atoms/form/DatePicker';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import { dateFormat } from '../../../../../data/form-data';
import useRole from '../../../../hooks/useRole';

export type Props = {
  loading: boolean;
  taskItems: TaskDocumentRequest | undefined;
  formMethods: UseFormMethods<TaskDocumentRequest>;
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  snackbarState: SnackbarState;
  snackbarClose: () => void;
};

const TaskDocumentRequestForm: FC<Props> = ({
  loading,
  taskItems,
  formMethods,
  onSubmit,
  snackbarState,
  snackbarClose,
}) => {
  const { isViewer } = useRole();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, control, setValue, watch, trigger } = formMethods;
  const { fields, append } = useFieldArray<TaskItem>({
    control,
    name: 'tasks',
  });

  useEffect(() => {
    if (!fields.length) {
      append(documentRequestTaskItems);
    }

    if (taskItems && taskItems.tasks.length) {
      setValue('tasks', taskItems.tasks);
    }
  }, [append, fields.length, setValue, taskItems]);

  return loading ? (
    <DotProgress />
  ) : (
    <div>
      <form onSubmit={onSubmit} className="detail-form card p-5">
        <input
          type="hidden"
          name="id"
          value={watch('id')}
          ref={register()}
          disabled={isViewer}
        />
        {fields.map((task, index) => {
          const namePrefix = `tasks[${index}]`;
          const dateName = `${namePrefix}.date`;

          return (
            <div key={namePrefix} className="field">
              <input
                type="hidden"
                name={`${namePrefix}.name`}
                value={task.name}
                ref={register()}
                disabled={isViewer}
              />
              <input
                type="hidden"
                name={`${namePrefix}.label`}
                value={task.label}
                ref={register()}
                disabled={isViewer}
              />
              <Checkbox
                label={task.label || ''}
                inputProps={{
                  ref: register(),
                  name: `${namePrefix}.done`,
                  defaultChecked: task.done,
                  onChange: () => onSubmit(),
                  disabled: isViewer,
                }}
              />
              {task.name === 'send_document' ? (
                <div className="horizontal-date">
                  <div className="label">資料発送日</div>
                  <Controller
                    name={dateName}
                    control={control}
                    defaultValue={task.date || null}
                    as={
                      <DateField
                        DatePickerProps={{
                          name: dateName,
                          value: watch(dateName)
                            ? DateTime.fromFormat(watch(dateName), dateFormat)
                            : (task.date &&
                                DateTime.fromFormat(task.date, dateFormat)) ||
                              null,
                          TextFieldComponent: (props) => (
                            <TextField
                              {...props}
                              variant="outlined"
                              margin="none"
                            />
                          ),
                          onChange: async (v) => {
                            setValue(dateName, v ? v.toFormat(dateFormat) : '');
                            await trigger(dateName);
                            await onSubmit();
                          },
                          disabled: isViewer,
                        }}
                      />
                    }
                  />
                </div>
              ) : null}
              {task.name === 'deadline' ? (
                <div className="horizontal-date">
                  <Controller
                    name={dateName}
                    control={control}
                    defaultValue={task.date || null}
                    as={
                      <DateField
                        DatePickerProps={{
                          name: dateName,
                          value: watch(dateName)
                            ? DateTime.fromFormat(watch(dateName), dateFormat)
                            : (task.date &&
                                DateTime.fromFormat(task.date, dateFormat)) ||
                              null,
                          TextFieldComponent: (props) => (
                            <TextField
                              {...props}
                              variant="outlined"
                              margin="none"
                            />
                          ),
                          onChange: async (v) => {
                            setValue(dateName, v ? v.toFormat(dateFormat) : '');
                            await trigger(dateName);
                            await onSubmit();
                          },
                          disabled: isViewer,
                        }}
                      />
                    }
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </form>
      <SnackbarWrapper
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        onClose={snackbarClose}
      />
    </div>
  );
};

export default TaskDocumentRequestForm;
