import React, { ChangeEvent, FC } from 'react';

import './style.scss';

export type Props = {
  name: string;
  value: string | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const FilterInput: FC<Props> = ({ name, value, onChange }) => {
  return (
    <input
      name={name}
      value={value}
      onChange={onChange}
      className="input filter-input"
    />
  );
};

export default FilterInput;
