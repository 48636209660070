import React, { FC, useContext } from 'react';
import { useEffectOnce } from 'react-use';
import clsx from 'clsx';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/all';

import { MiddleSchoolExamsList } from '../../../../../types/table/middle-school-exams-list';
import Table from '../Table';
import useReactTableFilter from '../../../../hooks/useReactTableFilter';
import DefaultColumnFilter from '../DefaultColumnFilter';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import columns from './columns';
import './style.scss';
import { ScoreExamContext } from '../../../../context/ScoreExamContext';
import {
  MiddleSchoolExamKeys,
  middleSchoolExamLabels,
} from '../../../../../data/form-data';
import exportMiddleSchoolExamsListCsv from '../../../../../helpers/exportMiddleSchoolExamsListCsv';
import { FilterContext } from '../../../../context/FilterContext';

const MiddleSchoolExamsListComponent: FC = () => {
  const {
    data,
    updateTableData,
    getLibraryCardIcon,
    closeSnackbar,
    snackbarState,
  } = useContext(ScoreExamContext);
  const filterTypes = useReactTableFilter();
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const { setFilterKey } = useContext(FilterContext);
  const pageName = 'middle-school-exams-list';

  useEffectOnce(() => {
    setFilterKey(pageName);
  });

  return (
    <div>
      <Table<MiddleSchoolExamsList>
        classes={{
          root: clsx('table-container'),
          table: clsx(
            'table',
            'is-fullwidth',
            'is-striped',
            'is-hoverable',
            'list-table',
          ),
        }}
        tableOptions={{
          data,
          columns,
          filterTypes,
          defaultColumn,
          pageCount: Math.ceil(data.length / 10),
          manualPagination: false,
          initialState: {
            sortBy: [{ id: 'examKey' }],
            hiddenColumns: ['id', 'student_id'],
            pageSize: 25,
          },
          updateTableData,
        }}
        theadThRender={(column) => {
          return (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              className={`column-${column.id}`}
            >
              <span>{column.render('Header')}</span>
              {column.isSorted &&
                (column.isSortedDesc ? (
                  <AiFillCaretDown fontSize="small" />
                ) : (
                  <AiFillCaretUp fontSize="small" />
                ))}
            </th>
          );
        }}
        tbodyTdRender={(row, cell) => {
          switch (cell.column.id) {
            case 'icon': {
              return (
                <td {...cell.getCellProps()}>
                  {getLibraryCardIcon(
                    row.index,
                    row.values as MiddleSchoolExamsList,
                  )}
                </td>
              );
            }
            case 'examKey': {
              return (
                <td {...cell.getCellProps()}>
                  {middleSchoolExamLabels[cell.value as MiddleSchoolExamKeys]}
                </td>
              );
            }
            default:
              return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
          }
        }}
        pageName={pageName}
        exportCsvHelper={exportMiddleSchoolExamsListCsv}
      />
      <SnackbarWrapper
        message={snackbarState?.message || ''}
        isOpening={snackbarState?.isOpening || false}
        variant={snackbarState?.variant || 'success'}
        onClose={closeSnackbar}
      />
    </div>
  );
};

export default MiddleSchoolExamsListComponent;
