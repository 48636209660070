import React, { FC, useContext } from 'react';
import { useEffectOnce } from 'react-use';
import clsx from 'clsx';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/all';

import Table from '../Table';
import useReactTableFilter from '../../../../hooks/useReactTableFilter';
import DefaultColumnFilter from '../DefaultColumnFilter';
import SnackbarWrapper from '../../../atoms/feedback/Snackbar';
import './style.scss';
import {
  MiddleSchoolReportKeys,
  middleSchoolReportLabels,
} from '../../../../../data/form-data';
import { ScoreReportContext } from '../../../../context/ScoreReportContext';
import { MiddleSchoolReportsList } from '../../../../../types/table/middle-school-reports-list';
import columns from './columns';
import exportMiddleSchoolReportsListCsv from '../../../../../helpers/exportMiddleSchoolReportsListCsv';
import { FilterContext } from '../../../../context/FilterContext';

const MiddleSchoolReportsListComponent: FC = () => {
  const { data, updateTableData, closeSnackbar, snackbarState } = useContext(
    ScoreReportContext,
  );
  const filterTypes = useReactTableFilter();
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const { setFilterKey } = useContext(FilterContext);
  const pageName = 'middle-school-reports-list';

  useEffectOnce(() => {
    setFilterKey(pageName);
  });

  return (
    <div>
      <Table<MiddleSchoolReportsList>
        classes={{
          root: clsx('table-container'),
          table: clsx(
            'table',
            'is-fullwidth',
            'is-striped',
            'is-hoverable',
            'list-table',
          ),
        }}
        tableOptions={{
          data,
          columns,
          filterTypes,
          defaultColumn,
          pageCount: Math.ceil(data.length / 10),
          manualPagination: false,
          initialState: {
            sortBy: [{ id: 'reportKey' }],
            hiddenColumns: ['id', 'student_id'],
            pageSize: 25,
          },
          updateTableData,
        }}
        theadThRender={(column) => {
          return (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              className={`column-${column.id}`}
            >
              <span>{column.render('Header')}</span>
              {column.isSorted &&
                (column.isSortedDesc ? (
                  <AiFillCaretDown fontSize="small" />
                ) : (
                  <AiFillCaretUp fontSize="small" />
                ))}
            </th>
          );
        }}
        tbodyTdRender={(row, cell) => {
          switch (cell.column.id) {
            case 'reportKey': {
              return (
                <td {...cell.getCellProps()}>
                  {
                    middleSchoolReportLabels[
                      cell.value as MiddleSchoolReportKeys
                    ]
                  }
                </td>
              );
            }
            default:
              return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
          }
        }}
        pageName={pageName}
        exportCsvHelper={exportMiddleSchoolReportsListCsv}
      />
      <SnackbarWrapper
        message={snackbarState?.message || ''}
        isOpening={snackbarState?.isOpening || false}
        variant={snackbarState?.variant}
        onClose={closeSnackbar}
      />
    </div>
  );
};

export default MiddleSchoolReportsListComponent;
