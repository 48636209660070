import firebase from 'firebase';

import firestoreSimple from '../../lib/firebase-firestore';
import { Room } from '../models/rooms';
import { QueryParams } from '../../types/firestore-simple';
import { CollectionOrQuery } from './get-rooms';

const onSnapshotRooms = ({
  queryParams,
  callback,
}: {
  queryParams?: QueryParams<Room>;
  callback: (room: Room) => void;
}) => {
  const collection = firestoreSimple.collection<Room>({
    path: 'rooms',
  });
  const query =
    queryParams && queryParams.length
      ? queryParams.reduce((prev: CollectionOrQuery, current) => {
          return prev.where(current.fieldPath, current.opStr, current.value);
        }, collection)
      : collection;
  if ('query' in query && 'startAt' in query) {
    query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot, toObject) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'modified') {
            const changedDoc = toObject(change.doc);
            callback(changedDoc);
          }
        });
      },
    );
  }
};

export default onSnapshotRooms;
