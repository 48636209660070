import React, { FC, useContext } from 'react';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/all';
import clsx from 'clsx';
import { Column } from 'react-table';

import { StudentList } from '../../../../../types/table/student-list';
import Table from '../Table';
import DefaultColumnFilter from '../DefaultColumnFilter';
import useReactTableFilter from '../../../../hooks/useReactTableFilter';
import DotProgress from '../../../atoms/feedback/DotProgress';
import {
  STUDENT_STATUS,
  STUDENT_STATUS_LABELS,
  StudentStatus,
} from '../../../../../data/form-data';
import exportStudentListCsv from '../../../../../helpers/exportStudentListCsv';
import { FilterContext } from '../../../../context/FilterContext';

export type Props = {
  loading: boolean;
  studentList: StudentList[];
  columns: Column<StudentList>[];
};

const StudentListComponent: FC<Props> = ({ loading, studentList, columns }) => {
  const history = useHistory();
  const filterTypes = useReactTableFilter();
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const { setFilterKey } = useContext(FilterContext);
  const pageName = 'student-list';

  useEffectOnce(() => {
    setFilterKey(pageName);
  });

  return (
    <div>
      <Table<StudentList>
        classes={{
          root: clsx('table-container'),
          table: clsx(
            'table',
            'is-fullwidth',
            'is-striped',
            'is-hoverable',
            'list-table',
          ),
        }}
        tableOptions={{
          data: studentList,
          columns,
          filterTypes,
          defaultColumn,
          manualPagination: false,
          maxMultiSortColCount: 3,
          orderByFn: (rows) => {
            return rows.slice().sort((a, b) => {
              const aStatus = STUDENT_STATUS.findIndex(
                (v) => v === a.original.status,
              );
              const bStatus = STUDENT_STATUS.findIndex(
                (v) => v === b.original.status,
              );
              if (aStatus !== bStatus) {
                return aStatus > bStatus ? 1 : -1;
              }

              const aNum = a.original.student_num;
              const bNum = b.original.student_num;
              if (aNum !== bNum) {
                return aNum > bNum ? 1 : -1;
              }

              return 0;
            });
          },
          initialState: {
            sortBy: [{ id: 'status', desc: true }, { id: 'student_num' }],
            hiddenColumns: ['studentId'],
            pageSize: 50,
          },
        }}
        theadThRender={(column) => {
          return (
            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
              <span>{column.render('Header')}</span>
              {column.isSorted &&
                (column.isSortedDesc ? (
                  <AiFillCaretDown fontSize="small" />
                ) : (
                  <AiFillCaretUp fontSize="small" />
                ))}
            </th>
          );
        }}
        tbodyTdRender={(row, cell) => {
          switch (cell.column.id) {
            case 'status': {
              const value = cell.value as StudentStatus;

              return (
                <td {...cell.getCellProps()}>{STUDENT_STATUS_LABELS[value]}</td>
              );
            }
            default:
              return (
                <td {...cell.getCellProps()}>
                  {
                    // Render the cell contents
                    cell.render('Cell')
                  }
                </td>
              );
          }
        }}
        onClick={(row) => {
          const studentId =
            row?.values?.studentId && typeof row?.values?.studentId === 'string'
              ? row.values.studentId
              : undefined;
          history.push('/student/detail', { studentId });
        }}
        pageName={pageName}
        exportCsvHelper={exportStudentListCsv}
      />
      {loading && <DotProgress />}
    </div>
  );
};

export default StudentListComponent;
