import React, { useContext } from 'react';
import {
  Cell,
  ColumnInstance,
  Row,
  TableOptions,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useEffectOnce } from 'react-use';

import Pagination from '../Table/Pagination';
import Toolbar from './Toolbar';
import './style.scss';
import { FilterContext } from '../../../../context/FilterContext';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface Props<T extends {}> {
  tableOptions: TableOptions<T> & { [key: string]: any };
  theadThRender: (column: ColumnInstance<T>) => React.ReactNode;
  tbodyTdRender: (row: Row<T>, cell: Cell<T>) => React.ReactNode;
  onClick?: (row: Row<T>, orderedStudentIds: string[]) => void;
  classes: {
    root: string;
    table: string;
  };
  importCsv: (invoiceMonth: string, file: File) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const InvoiceListTable = <T extends {}>({
  tableOptions,
  theadThRender,
  tbodyTdRender,
  onClick,
  classes,
  importCsv,
}: Props<T>) => {
  const { filters, setFilterKey } = useContext(FilterContext);
  const pageName = 'invoice-list';
  const savedPageSize = localStorage.getItem(`${pageName}-rows-per-page`);
  const pageSize = savedPageSize ? Number(savedPageSize) : 50;
  const instance = useTable(
    {
      ...tableOptions,
      initialState: filters.has(pageName)
        ? {
            ...tableOptions?.initialState,
            filters: filters.get(pageName),
            pageSize,
          }
        : { ...tableOptions?.initialState, pageSize },
    },
    useFilters,
    useSortBy,
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    rows,
  } = instance;

  const orderedStudentIds = rows.map((v) => {
    return v.values.student_id as string;
  });

  useEffectOnce(() => {
    setFilterKey(pageName);
  });

  return (
    <div>
      <Toolbar instance={instance} importCsv={importCsv} />
      <div className={classes.root}>
        <table {...getTableProps()} className={classes.table}>
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => theadThRender(column))}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);

              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => onClick && onClick(row, orderedStudentIds)}
                >
                  {row.cells.map((cell) => tbodyTdRender(row, cell))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="table-pagination">
        <Pagination instance={instance} pageName={pageName} />
      </div>
    </div>
  );
};

export default InvoiceListTable;
