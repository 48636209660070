import { ReactElement } from 'react';

import {
  Grade,
  MiddleSchoolExamKeys,
  NotesJapanSchool,
  NotesPraiseSchool,
} from '../../data/form-data';
import {
  MiddleSchoolExam,
  MiddleSchoolExamScores,
} from '../../domain/models/middle-school-exam';
import { StudentBasic } from '../../domain/models/student-basic';

export type MiddleSchoolExamList = {
  icon: ReactElement | null;
  middleSchoolExam: MiddleSchoolExam;
  studentBasic: StudentBasic;
  examKey: MiddleSchoolExamKeys;
  id: string;
  student_id: string;
  student_num: string;
  name: string;
  grade: Grade;
  enrolled_cram_school: NotesJapanSchool | NotesPraiseSchool;
  school_name: string;
  japanese: number;
  math: number;
  english: number;
  society: number;
  science: number;
  total: number;
  prevTotal: number;
  libraryCardPoint: number;
  prevScores: MiddleSchoolExamScores | undefined;
};

export const isTotalScoreUp = (target: MiddleSchoolExamList): boolean => {
  return target.prevTotal !== 0 && target.prevTotal < target.total;
};

export const isScoreUp = (
  target: MiddleSchoolExamList,
  targetSubject: string,
): boolean => {
  switch (targetSubject) {
    case 'japanese':
      return (
        target.prevScores !== undefined &&
        target.japanese > target.prevScores.japanese
      );
    case 'math':
      return (
        target.prevScores !== undefined && target.math > target.prevScores.math
      );
    case 'english':
      return (
        target.prevScores !== undefined &&
        target.english > target.prevScores.english
      );
    case 'science':
      return (
        target.prevScores !== undefined &&
        target.science > target.prevScores.science
      );
    case 'society':
      return (
        target.prevScores !== undefined &&
        target.society > target.prevScores.society
      );
    default:
      return false;
  }
};
