import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import AdminWrapper from '../../components/layouts/AdminWrapper';
import Container from '../../components/layouts/Container';
import ParentInfoForm from '../../containers/molecules/form/ParentInfoForm';
import { PAGE_TITLE } from '../../../data/app-basic';

const ParentDetail: FC = () => {
  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('保護者情報編集')}</title>
      </Helmet>
      <Container>
        <ParentInfoForm />
      </Container>
    </AdminWrapper>
  );
};

export default ParentDetail;
