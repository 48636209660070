import clsx from 'clsx';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { RiArrowGoBackFill } from 'react-icons/all';
import {
  Select as MuiSelect,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { ValueType } from 'react-select/src/types';

import {
  ELEMENTARY_SCHOOL_GRADES,
  NOTES_JAPAN_CLASSES,
  NOTES_JAPAN_SCHOOLS,
  NOTES_PRAISE_HOLIDAY_CLASSES,
  NOTES_PRAISE_WEEKDAYS_CLASSES,
  NotesJapanClass,
  NotesPraiseClass,
} from '../../../../../data/form-data';
import { PeriodicInfo } from '../../../../../domain/models/periodic-info';
import Select from '../../../atoms/form/Select';
import Button from '../../../atoms/button/Button';
import DotProgress from '../../../atoms/feedback/DotProgress';
import { StudentBasic } from '../../../../../domain/models/student-basic';
import {
  ScheduleItem,
  Timetable,
  formatSchedule,
} from '../../../../../domain/models/timetable';
import { TableRowCalendar } from './TableRowCalendar';
import './style.scss';
import { EnrolledCramSchoolContext } from '../../../../context/EnrolledCramSchoolContext';

export type Props = {
  loading: boolean;
  studentBasic: StudentBasic | null;
  timetable: Timetable | null;
  periodicInfo: PeriodicInfo | null;
  yearMonth: string;
  selectPropsEnrolledCramSchool: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
  selectPropsStatus: {
    label: string;
    value: string;
    options: { label: string; value: string }[];
    onChange: SelectInputProps['onChange'];
  };
  selectPropsStudentBasic: {
    value: ValueType<{ label: string; value: string }> | null;
    options: { label: string; value: string }[];
    onChange: (value: ValueType<{ label: string; value: string }>) => void;
    isDisabled: boolean;
    placeholder: string;
  };
};

const TimetableDetailForm: FC<Props> = ({
  loading,
  studentBasic,
  timetable,
  periodicInfo,
  yearMonth,
  selectPropsEnrolledCramSchool,
  selectPropsStatus,
  selectPropsStudentBasic,
}) => {
  const history = useHistory();
  const { enrolledCramSchool } = useContext(EnrolledCramSchoolContext);
  const [formattedYearMonth] = useState(
    DateTime.fromFormat(yearMonth, 'yyyy/MM'),
  );
  const [lastDay, setLastDay] = useState<number | undefined>(undefined);
  const [isNotesJapan, setIsNotesJapan] = useState(
    NOTES_JAPAN_SCHOOLS.some((v) => v === enrolledCramSchool),
  );
  const [classes, setClasses] = useState<
    NotesJapanClass[] | NotesPraiseClass[]
  >([]);
  const [isNotesElementarySchool, setIsNotesElementarySchool] = useState(false);
  const [formattedSchedule, setFormattedSchedule] = useState<ScheduleItem[]>(
    [],
  );

  useEffectOnce(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  });

  useEffect(() => {
    setLastDay(
      new Date(
        Number(yearMonth.split('/')[0]),
        Number(yearMonth.split('/')[1]),
        0,
      ).getDate(),
    );
  }, [yearMonth]);

  useEffect(() => {
    setIsNotesJapan(NOTES_JAPAN_SCHOOLS.some((v) => v === enrolledCramSchool));
  }, [setIsNotesJapan, enrolledCramSchool]);

  useEffect(() => {
    setClasses(
      isNotesJapan ? NOTES_JAPAN_CLASSES : [...NOTES_PRAISE_WEEKDAYS_CLASSES],
    );

    setIsNotesElementarySchool(
      isNotesJapan &&
        ELEMENTARY_SCHOOL_GRADES.some((v) => v === studentBasic?.grade),
    );
  }, [studentBasic, setClasses, isNotesJapan, setIsNotesElementarySchool]);

  useEffect(() => {
    if (studentBasic) {
      const schedule = formatSchedule(
        lastDay,
        yearMonth,
        timetable,
        isNotesJapan,
        isNotesElementarySchool,
        classes,
      );
      setFormattedSchedule(schedule);
    } else {
      setFormattedSchedule([]);
    }
  }, [
    studentBasic,
    timetable,
    classes,
    isNotesJapan,
    isNotesElementarySchool,
    lastDay,
    setFormattedSchedule,
    yearMonth,
  ]);

  return (
    <div className="detail-form timetable-detail">
      <h3 className="title pb-1">授業日程表</h3>
      {selectPropsEnrolledCramSchool ? (
        <div className="table-toolbar is-justify-content-flex-start mb-6">
          <div className="table-toolbar-item w-200px">
            <div className="is-size-7 has-text-grey">校舎</div>
            <MuiSelect
              label={selectPropsEnrolledCramSchool.label}
              value={selectPropsEnrolledCramSchool.value}
              onChange={selectPropsEnrolledCramSchool.onChange}
              className="toolbar-select"
            >
              {selectPropsEnrolledCramSchool.options.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </MuiSelect>
          </div>
          <div className="table-toolbar-item w-200px">
            <div className="is-size-7 has-text-grey">ステータス</div>
            <MuiSelect
              label={selectPropsStatus.label}
              value={selectPropsStatus.value}
              onChange={selectPropsStatus.onChange}
              className="toolbar-select"
            >
              {selectPropsStatus.options.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </MuiSelect>
          </div>
          <div className="table-toolbar-item w-350px">
            <div className="is-size-7 has-text-grey mb-2">生徒名</div>
            <Select
              selectProps={selectPropsStudentBasic}
              className="toolbar-select"
            />
          </div>
          <div className="table-toolbar-item w-200px">
            <div className="is-size-7 has-text-grey mb-2">学校名</div>
            <div className="white-box">{studentBasic?.school_name}</div>
          </div>
          <div className="table-toolbar-item w-80px">
            <div className="is-size-7 has-text-grey mb-2">学年</div>
            <div className="white-box">{studentBasic?.grade}</div>
          </div>
        </div>
      ) : null}
      {loading ? (
        <DotProgress />
      ) : (
        <div>
          <div className="mt-5 mb-6">
            <h4 className="title is-4">連絡事項</h4>
            <div className="white-box">
              {periodicInfo?.information
                ? periodicInfo?.information
                : '連絡事項はありません'}
            </div>
          </div>
          <div className="my-5">
            <h4 className="title is-4">
              授業日程(
              {`${formattedYearMonth.year}年${formattedYearMonth.month}月`})
            </h4>
            {studentBasic && formattedSchedule.length ? (
              <>
                <Table className="table mb-5">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className={clsx(
                          'has-text-centered',
                          'has-background-grey-lighter',
                          'has-text-weight-bold',
                        )}
                      >
                        日付
                      </TableCell>
                      <TableCell
                        component="th"
                        className={clsx(
                          'has-text-centered',
                          'has-background-grey-lighter',
                        )}
                      >
                        {isNotesJapan ? 'N1' : 'P1/P4'}
                      </TableCell>
                      <TableCell
                        component="th"
                        className={clsx(
                          'has-text-centered',
                          'has-background-grey-lighter',
                        )}
                      >
                        {isNotesJapan ? 'N2' : 'P2/P5'}
                      </TableCell>
                      <TableCell
                        component="th"
                        className={clsx(
                          'has-text-centered',
                          'has-background-grey-lighter',
                        )}
                      >
                        {isNotesJapan ? 'N3' : 'P3/P6'}
                      </TableCell>
                      <TableCell
                        component="th"
                        className={clsx(
                          'has-text-centered',
                          'has-background-grey-lighter',
                        )}
                      >
                        {isNotesJapan ? 'N4' : 'P4/P7'}
                      </TableCell>
                      <TableCell
                        component="th"
                        className={clsx(
                          'has-text-centered',
                          'has-background-grey-lighter',
                        )}
                      >
                        {isNotesJapan ? 'N5' : 'P5/P8'}
                      </TableCell>
                      <TableCell
                        component="th"
                        className={clsx(
                          'has-text-centered',
                          'has-background-grey-lighter',
                        )}
                      >
                        特別授業
                      </TableCell>
                      <TableCell
                        component="th"
                        className={clsx(
                          'has-text-centered',
                          'has-background-grey-lighter',
                        )}
                      >
                        行事予定
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formattedSchedule.map((scheduleItem, index) => (
                      <TableRowCalendar
                        key={scheduleItem.date}
                        scheduleItem={scheduleItem}
                        index={index}
                        isNotesElementarySchool={isNotesElementarySchool}
                        periodicInfo={periodicInfo}
                      />
                    ))}
                  </TableBody>
                </Table>
              </>
            ) : (
              <div>対象生徒を選択してください。</div>
            )}
          </div>
          <div className="btn-area">
            <Button
              buttonProps={{
                type: 'button',
                onClick: () => history.push('/'),
              }}
              className={clsx('with-icon', 'btn-primary')}
            >
              <RiArrowGoBackFill />
              戻る
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimetableDetailForm;
