import React from 'react';
import { useForm } from 'react-hook-form';
import TimetableBulkChangeForm from '../../../../components/molecules/form/TimetableBulkChangeForm';
import { BulkChangeTimetable } from '../../../../../types/form/bulk-change-timetable';
import useBulkCreateTimetable from '../../../../hooks/create/useBulkCreateTimetable';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const formMethods = useForm<BulkChangeTimetable>({
    defaultValues: {
      enrolled_cram_school: null,
      grade: null,
      date: null,
      lecture: null,
      subject: null,
    },
  });
  const {
    creating,
    bulkCreate,
    state,
    close,
    validate,
    isDialog,
    setIsDialog,
    isValidateThrough,
    setIsValidateThrough,
    errorMessage,
  } = useBulkCreateTimetable();
  const onSubmit = formMethods.handleSubmit(async (data) => {
    if (!isValidateThrough) {
      const isSuccessValidate = await validate(data);
      if (!isSuccessValidate) return false;
    }
    bulkCreate(data);

    return true;
  });

  return (
    <TimetableBulkChangeForm
      formMethods={formMethods}
      onSubmit={onSubmit}
      creating={creating}
      snackbarState={state}
      snackbarClose={close}
      isDialog={isDialog}
      setIsDialog={setIsDialog}
      isValidateThrough={isValidateThrough}
      setIsValidateThrough={setIsValidateThrough}
      errorMessage={errorMessage}
    />
  );
};
