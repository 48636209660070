import { Room } from '../models/rooms';
import firestoreSimple from '../../lib/firebase-firestore';

const getRoomByParentId = async (
  parentId: string,
): Promise<Room | undefined> => {
  return new Promise<Room | undefined>((resolve) => {
    const collection = firestoreSimple.collection<Room>({
      path: 'rooms',
    });

    collection
      .where('room_users', 'array-contains', parentId)
      .fetch()
      .then((rooms) => {
        if (rooms.length) {
          resolve(rooms[0]);
        } else {
          resolve(undefined);
        }
      })
      .catch((error) => console.error(error));
  });
};

export default getRoomByParentId;
