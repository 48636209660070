import firestoreSimple from '../../lib/firebase-firestore';
import { PeriodicInfo } from '../models/periodic-info';

export type Result = {
  id?: string;
};

const createPeriodicInfo = async (
  periodicInfo: PeriodicInfo,
): Promise<Result> => {
  const collection = firestoreSimple.collection<PeriodicInfo>({
    path: 'periodic_info',
  });

  const docId = periodicInfo.id
    ? await collection.set(periodicInfo).catch((error) => {
        console.error(error);
      })
    : await collection.add(periodicInfo).catch((error) => console.error(error));

  return { id: docId || undefined };
};

export default createPeriodicInfo;
