import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { BsPaperclip } from 'react-icons/all';

import Button from '../../../atoms/button/Button';
import './style.scss';

interface Props {
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  onClick: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TextAreaComponent: FC<Props> = ({
  message,
  setMessage,
  onClick,
  onChange,
}) => {
  return (
    <div className="room-textarea">
      <div className="input-wrap">
        <label className="icon-file">
          <input
            type="file"
            hidden
            accept="image/png, image/jpeg, application/pdf"
            onChange={onChange}
          />
          <BsPaperclip />
        </label>
        <textarea
          className="textarea"
          placeholder="メッセージを入力してください"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
      </div>
      <Button buttonProps={{ disabled: !message, onClick }} color="primary">
        送信
      </Button>
    </div>
  );
};

export default TextAreaComponent;
