import { HasId } from '@firestore-simple/web/dist/types';

export type TelInfo = {
  num: string;
  relationship: string;
};

export type ParentInfo = HasId & {
  uid: string;
  name: string;
  relationship: string;
  postal_code: string;
  prefecture: string;
  city: string;
  address: string;
  tel_info: TelInfo;
  emergency_contacts: TelInfo[];
  work_address_name: string;
  work_address_tel_num: string;
  withdrawal_bank: string;
  mail_address: string;
};

export const defaultParentInfo: ParentInfo = {
  id: '',
  uid: '',
  name: '',
  relationship: '',
  postal_code: '',
  prefecture: '',
  city: '',
  address: '',
  tel_info: { num: '', relationship: '' },
  emergency_contacts: [],
  work_address_name: '',
  work_address_tel_num: '',
  withdrawal_bank: '',
  mail_address: '',
};
