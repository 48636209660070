import React, { FC } from 'react';

import './style.scss';

export type Props = {
  label: string;
  inputProps: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
};

const Checkbox: FC<Props> = ({ label, inputProps }) => {
  return (
    <label className="checkbox">
      <input type="checkbox" {...inputProps} />
      {label}
    </label>
  );
};

export default Checkbox;
