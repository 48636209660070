import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import AdminWrapper from '../../components/layouts/AdminWrapper';
import Container from '../../components/layouts/Container';
import StaffForm from '../../containers/molecules/form/StaffForm';
import { PAGE_TITLE } from '../../../data/app-basic';

const StaffDetail: FC = () => {
  return (
    <AdminWrapper>
      <Helmet>
        <title>{PAGE_TITLE('社員情報登録')}</title>
      </Helmet>
      <Container>
        <StaffForm isCreate />
      </Container>
    </AdminWrapper>
  );
};

export default StaffDetail;
