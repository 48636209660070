import { useContext, useState, Dispatch, SetStateAction } from 'react';
import { useEffectOnce } from 'react-use';

import { StaffList } from '../../../types/table/staff-list';
import getAdminUsers from '../../../domain/services/get-admin-users';
import { AppContext } from '../../context/AppContext';
import { Role, ROLE_LABELS } from '../../../data/form-data';
import useRole from '../useRole';

export type Result = {
  loading: boolean;
  staffList: StaffList[];
  setStaffList: Dispatch<SetStateAction<StaffList[]>>;
  allStaffList: StaffList[];
};

const useFetchStaffs = (): Result => {
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState<StaffList[]>([]);
  const [allStaffList, setAllStaffList] = useState<StaffList[]>([]); // 校舎セレクトボックスでフィルターをかけるためのstate
  const { adminUser } = useContext(AppContext);
  const { isEditor, isViewer, enrolledCramSchool } = useRole();
  const queryParams =
    isEditor || isViewer
      ? [
          {
            fieldPath: 'enrolled_cram_school',
            opStr: '==',
            value: enrolledCramSchool,
          },
          {
            fieldPath: 'uid',
            opStr: '==',
            value: adminUser.uid,
          },
        ]
      : undefined;
  // ※校舎管理の場合は「自身のデータ」と「スタッフたちのデータ」が必要なのでクエリを2回叩く（orWhereの代案）
  const queryParams2 = isEditor
    ? [
        {
          fieldPath: 'enrolled_cram_school',
          opStr: '==',
          value: enrolledCramSchool,
        },
        {
          fieldPath: 'role',
          opStr: '==',
          value: 'viewer' as Role,
        },
      ]
    : undefined;

  useEffectOnce(() => {
    void (async () => {
      setLoading(true);

      let { adminUsers: staffs } = await getAdminUsers({ queryParams });
      if (isEditor) {
        const { adminUsers: viewerStaffs } = await getAdminUsers({
          queryParams: queryParams2,
        });
        staffs = [...staffs, ...viewerStaffs];
      }

      if (staffs) {
        const values = staffs.map((staff) => {
          return {
            staffId: staff.id,
            staff_num: staff.staff_num ? staff.staff_num : '未設定',
            name: `${staff.last_name} ${staff.first_name}`,
            enrolled_cram_school: staff.enrolled_cram_school,
            role: ROLE_LABELS[staff.role],
            employment_status: staff.employment_status,
            termination_date: staff.termination_date,
            is_terminated: !!staff.termination_date,
          };
        });

        setStaffList(values);

        setAllStaffList(values);
      }

      setLoading(false);
    })();
  });

  return { loading, staffList, setStaffList, allStaffList };
};

export default useFetchStaffs;
